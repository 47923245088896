<h1 class="page-header">My Login Activity</h1>

<div class="table-responsive">
    <table class="table table-hover table-striped">
        <tr>
            <th>Action</th>
            <th>Date</th>
        </tr>
        <tr *ngFor="let item of logdata" class="active">
            <td>{{ item.type }}</td>
            <td>{{ item.date }}</td>
        </tr>
    </table>
</div>
