import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-notification-banner',
  templateUrl: './notification-banner.component.html',
  styleUrls: ['./notification-banner.component.css']
})
export class NotificationBannerComponent {
  @Input() message: string = '';
  @Input() link: string = '';
  @Input() linkText: string = 'Learn More';
  @Input() type: 'info' | 'warning' | 'success' | 'error' = 'info';
}
