import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

interface Notification {
  message: string;
  link: string;
  linkText?: string;
  type: 'info' | 'warning' | 'success' | 'error';
}

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  private notificationUrl = 'api/notification'; // Your API endpoint

  constructor(private http: HttpClient) { }

  getNotification(): Observable<Notification> {
    return this.http.get<Notification>(this.notificationUrl);
  }
}
