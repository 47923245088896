import { DatePipe } from '@angular/common';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { jqxDropDownListComponent } from 'jqwidgets-ng/jqxdropdownlist';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import { jqxNotificationComponent } from 'jqwidgets-ng/jqxnotification';
import { NgxSpinnerService } from 'ngx-spinner';
import { asyncScheduler } from 'rxjs';
import { BackendService } from 'src/app/services/backend.service';
import { ExcelService } from 'src/app/services/excel.service';
import { FileAccessService } from 'src/app/services/fileaccess.service';
import Swal from 'sweetalert2';

declare var tippy: any;
declare const $: any;
let PageNumberGlobal: any;
let customStartDateContainer;
let customEndDateContainer;
let customsearchButtonContainer;

@Component({
  selector: 'app-court-orders',
  templateUrl: './court-orders.component.html',
  styleUrls: ['./court-orders.component.css']
})
export class CourtOrdersComponent implements OnInit {

  @ViewChild('audioRef') audioRef: ElementRef;
  hidestatus: string;
  customSearch = false;
  selectedStartDate: any;
  selectedEndDate: any;
  startmin: any;
  startmax: any;
  endmin: any;
  endmax: any;
  mode: string;
  orderindex = [];
  completedJobIdArray = [];
  requiredJobsArray = [];
  nonRequiredJobsArray = [];
  NumberOfOrders = 0;
  MinEndDate: any;
  MaxStartDate: any;
  endDateEvent = false;
  selectedDateOption: any;
  selectedStatusOption: string;
  UnixStartDate: any;
  UnixEndDate: any;
  selectedDDLindex: any;
  totalCountGlobal: any;
  IsSqlDB = false;
  token: any;
  buttonCounter: any;
  sqlData: any;
  isRefresh = false;
  IsAudioPlay: boolean;
  element = $("<div id = 'gridpager' style='margin-left: 10px; margin-top: 11px; width: 100%; height: 100%;'></div>");
  progress: number;
  heightOffset: number = 120;
  selectedIndex: number = 0;
  dateRange: string;
  DisplayCustomStartDate: any;
  DisplayCustomEnddate: any;
  editmode = '';
  OrderArray = [];
  constructor(private backendService: BackendService,
    private excelService: ExcelService,
    private httpClient: HttpClient,
    private datePipe: DatePipe,
    private spinner: NgxSpinnerService,
    private router: Router,
    private fileaccess: FileAccessService) { }
  @ViewChild('myGrid', { static: false }) myGrid: jqxGridComponent;
  @ViewChild('msgNotification', { static: false }) msgNotification: jqxNotificationComponent;
  @ViewChild('myDropDownList', { static: false }) myDropDownList: jqxDropDownListComponent;
  @ViewChild('myDateDropDownList', { static: false }) myDateDropDownList: jqxDropDownListComponent;
  @Input() fileToPlay: string;
  startDateEventOpen(event: any): void {
    this.endDateEvent = false;
  }

  onStartDateValueChanged(event: any): void {
    if (this.endDateEvent == false) {
      var selecteddate = event.args.date;
      var dateevent = new Date();
      this.MinEndDate = selecteddate.setDate(selecteddate.getDate());
      this.MinEndDate = new Date(this.MinEndDate);
      this.endmin = new Date(this.MinEndDate);
      this.selectedStartDate = this.datePipe.transform(selecteddate, 'yyyy-MM-ddT00:00')
      this.endmin = new Date(this.selectedStartDate);
      this.DisplayCustomStartDate = new Date(this.selectedStartDate);
    }
  }

  onEndDateValueChanged(event: any): void {
    this.endDateEvent = true;
    var selecteddate = event.args.date;
    var dateevent = new Date();
    this.MaxStartDate = selecteddate.setDate(selecteddate.getDate());
    this.MaxStartDate = new Date(this.MaxStartDate);
    this.startmax = new Date(this.MaxStartDate);
    this.selectedEndDate = this.datePipe.transform(selecteddate, 'yyyy-MM-ddT00:00')
    this.DisplayCustomEnddate = new Date(this.selectedEndDate);
  }
  dateOptions: any[] =
    [
      { html: '<div>Today</div>', title: 'Today' },
      { html: '<div>Yesterday</div>', title: 'Yesterday' },
      { html: '<div>This week</div>', title: 'This week (Sunday to today)' },
      { html: '<div>Last 7 days</div>', title: 'Last 7 days (six days ago to today)' },
      { html: '<div>This month</div>', title: 'This month (1st of this month to today)' },
      { html: '<div>Last 31 days</div>', title: 'Last 31 days (30 days ago to today)' },
      { html: '<div>Custom Date Range</div>', title: 'Custom' },
    ];
  updatefilterconditions = (type: string, defaultconditions: any): string[] => {
    let stringcomparisonoperators = ['EMPTY', 'NOT_EMPTY', 'CONTAINS', 'DOES_NOT_CONTAIN', 'STARTS_WITH', 'ENDS_WITH', 'EQUAL'];
    let numericcomparisonoperators = ['LESS_THAN', 'GREATER_THAN'];
    let datecomparisonoperators = ['LESS_THAN', 'GREATER_THAN'];
    let booleancomparisonoperators = ['EQUAL', 'NOT_EQUAL'];
    switch (type) {
      case 'stringfilter':
        return stringcomparisonoperators;
      case 'numericfilter':
        return numericcomparisonoperators;
      case 'datefilter':
        return datecomparisonoperators;
      case 'booleanfilter':
        return booleancomparisonoperators;
    }
    this.spinner.hide();
  };
  height;
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.height = event.target.innerHeight - this.heightOffset;
    this.height = this.height - 30;
  }
  getHeight() {
    this.height = (window.screen.height - this.heightOffset);
    window.dispatchEvent(new Event('resize'));
  }
  getJSONResponse(value: any) {
    return JSON.parse(JSON.stringify(value));
  }
  convertStartDatetoUnix(date) {
    var reqstartDate = this.datePipe.transform(date, 'yyyy-MM-dd 00:00:00');
    var StringtoDate = new Date(reqstartDate);
    this.UnixStartDate = (StringtoDate.getTime() / 1000);
    this.UnixStartDate = this.UnixStartDate - 1;
  }
  convertEndDatetoUnix(date) {
    var reqEndDate = this.datePipe.transform(date, 'yyyy-MM-dd 23:59:59');
    var StringtoDate = new Date(reqEndDate);
    this.UnixEndDate = (StringtoDate.getTime() / 1000);
    this.UnixEndDate = this.UnixEndDate + 1;
  }
  onProgressUpdate(newProgress: number) {
    this.progress = newProgress;
  }
  searchData() {
    PageNumberGlobal = 1;
    this.getShowstatus(this.selectedStatusOption);
  }
  ngAfterViewInit(): void {

    setTimeout(() => {
      // TIPPY JS.
      tippy('[data-tippy-content]');
    }, 500);
    if (this.buttonCounter == 1) {
      this.source.showtoolbar = true;
      this.createButtons();
    }

    let dateOpt = JSON.parse(JSON.stringify(this.dateOptions[0]));
    const tmp = document.createElement('DIV');
    tmp.innerHTML = dateOpt.html;
    this.selectedDateOption = tmp.textContent || tmp.innerText || '';
  }

  ngOnInit() {
    this.dateRange = "Today"
    var iSuserloggedIN = localStorage.getItem('userloggedIN')
    if (iSuserloggedIN == "false") {
      this.backendService.setUserLoggedIn(false);
    }
    this.spinner.show();
    this.buttonCounter = 0;
    this.getHeight();
    this.IsSqlDB = true;
    setTimeout(() => {
      this.spinner.hide();
    }, 10000);
    asyncScheduler.schedule(() => {
      this.getDatesByOption(this.dateRange);
      this.getsqlData(this.selectedStartDate, this.selectedEndDate);
    })
    this.IsSqlDB = false;
    this.hidestatus = "Unhidden";
  }

  onPageChanged(event: any): void {
    this.spinner.show();
  }
  orderGridFilter(event: any): void {
    this.spinner.show();
  }
  getsqlData(startDate, endDate) {

    this.spinner.show();
    this.buttonCounter = this.buttonCounter + 1;
    if (this.buttonCounter == 1) {
      this.source.showtoolbar = true;
      this.createButtons();
    }
    this.convertStartDatetoUnix(startDate);
    this.convertEndDatetoUnix(endDate);

    this.source.data = { StartDate: this.UnixStartDate, EndDate: this.UnixEndDate };
    this.source.url = this.backendService.getorderswithfilterurl();
    if (this.isRefresh == false) {
    }
    this.myGrid.updatebounddata();
    this.isRefresh = false;
  }

  source: any = {
    datatype: 'json',
    datafields: [
      { name: 'organizationName', type: 'string' },
      { name: 'departmentName', type: 'string' },
      { name: 'authorName', type: 'string' },
      { name: 'status', type: 'string' },
      { name: 'createdDate', type: 'date' },
      { name: 'hearingDate', type: 'date' },
      { name: 'createdByUserName', type: 'string' },
      { name: 'language', type: 'string' },
      { name: 'customerTat', type: 'date' },
      { name: 'jobTypeDisplayName', type: 'string' },
      { name: 'jurisdiction', type: 'string' },
      { name: 'caseNumber', type: 'number' },
      { name: 'format', type: 'string' },
      { name: 'highPriority', type: 'bool' },
      { name: 'deptId', type: 'string' },
      { name: 'organizationExtId', type: 'string' },
      { name: 'departmentExtId', type: 'string' },
      { name: 'jobTypeExtId', type: 'string' },
      { name: 'authorExtId', type: 'string' },
      { name: 'orderFormId', type: 'string' },
      { name: 'id', type: 'number' }
    ],
    sortcolumn: 'createdDate',
    sortdirection: 'desc',
    loader: false,
    enablebrowserselection: true,
    keyboardnavigation: false,
    beforeprocessing: (data: any) => {
      this.source.totalrecords = data.totalRows;
      this.totalCountGlobal = data.totalRows.length;
      this.columns = this.columns;
      var results = data.rows;
      //console.log("data:::", data);
      for (const index in results) {
        const val = results[index];
        var reqDate = this.datePipe.transform(val.createdDate, 'yyyy-MM-dd HH:mm:ss UTC');
        val.createdDate = new Date(reqDate).toString();
        var reqDate = this.datePipe.transform(val.hearingDate, 'yyyy-MM-dd UTC');
        val.hearingDate = new Date(reqDate).toString();
      }
      this.sqlData = results;
      this.spinner.hide();

    },
    filter: () => {
      // update the grid and send a request to the server.
      this.myGrid.updatebounddata('filter');
    }, sort: () => {
      // update the grid and send a request to the server.
      this.myGrid.updatebounddata('sort');
    }
  };


  sQLdataAdapter: any = new jqx.dataAdapter(this.source, {
    autoBind: true,
    beforeSend: function (jqXHR, settings) {
      this.token = localStorage.getItem('token');
      if (this.token == null) {
        jqXHR.abort();
      }
      jqXHR.setRequestHeader('Authorization', 'Bearer ' + this.token);
    },
    contentType: 'application/json; charset=utf-8',
    loadError: function (xhr, status, error) {
      this.sendNotification("Unable to get the jobs. Please refresh the page.", 'error')
    }
  });

  ///////////////////////

  pagerrenderer = () => {
    this.element.empty();
    var datainfo = this.myGrid.getdatainformation();
    var paginginfo = datainfo.paginginformation;
    ///Appending Buttons
    var data = this.myGrid.getrows();
    var jobcount = data.length;
    this.totalCountGlobal = datainfo.rowscount;
    //labels
    var pageSizelabel = $("<div style='font-size: 13px; margin: 2px 100px; margin-top:-4px; font-weight: bold; float: right;'></div>");
    var dropdown = $("<div style='margin-right: -75px; margin-top: -5px; padding: 0px; float: right; cursor: pointer;'> </div>")
    var leftButton = $("<div style='padding: 0px; float: right; cursor: pointer;'><div style='margin-left: 45px; width: 16px; height: 16px;'></div></div>")
    leftButton.find('div').addClass('jqx-icon-arrow-left');
    leftButton.width(36);
    var rightButton = $("<div style='padding: 0px; margin: 0px 3px; float: right; cursor: pointer;'><div style='margin-left: 150px; width: 16px; height: 16px;'></div></div>");
    rightButton.find('div').addClass('jqx-icon-arrow-right');
    rightButton.width(36);

    var CurrentPageJobslabel = $("<div style='font-size: 13px; margin: 2px 0px; margin-top:-4px; font-weight: bold; float: left;'></div>");

    if (jobcount > 0) {
      var data = this.myGrid.getrows();
      this.NumberOfOrders = data.length;
      CurrentPageJobslabel.text("Order(s) displayed: " + this.NumberOfOrders);
      pageSizelabel.text(1 + paginginfo.pagenum * paginginfo.pagesize + "-" + Math.min(parseInt(datainfo.rowscount), (paginginfo.pagenum + 1) * paginginfo.pagesize) + ' of ' + datainfo.rowscount);
      var datasource = [];
      for (var i = 1; i <= paginginfo.pagescount; i++) {
        datasource.push(i);
      }
      if (PageNumberGlobal == 1) {
        this.selectedDDLindex = 0;
      }
      if (paginginfo.pagescount > 6) {
        dropdown.jqxDropDownList({ source: datasource, selectedIndex: this.selectedDDLindex, dropDownVerticalAlignment: "top", theme: "material", width: 52, height: 28, scrollBarSize: 5 });
      }
      else {
        dropdown.jqxDropDownList({ source: datasource, selectedIndex: this.selectedDDLindex, dropDownVerticalAlignment: "top", theme: "material", width: 52, height: 28, autoDropDownHeight: true });
      }
      dropdown.on('select', (event) => {
        var args = event.args;
        if (args) {
          this.selectedDDLindex = args.index;
          if (args.index >= 0)
            PageNumberGlobal = args.item.label;
          this.myGrid.gotopage(args.index);
        }
      });
      // update buttons states.
      var leftbuttonresult = paginginfo.pagescount * paginginfo.pagenum;
      if (leftbuttonresult == 0) {
        leftButton.find('div').addClass("disabled");
      }
      else {
        leftButton.find('div').removeClass("disabled");
      }
      var rightbuttonresult = paginginfo.pagenum + 1;
      if (rightbuttonresult == paginginfo.pagescount) {
        rightButton.find('div').addClass("disabled");
      }
      else {
        rightButton.find('div').removeClass("disabled");
      }
      rightButton.click((event) => {

        if (rightbuttonresult < paginginfo.pagescount) {
          // go to next page.
          if (paginginfo.pagenum == 0) {
            PageNumberGlobal = 2;
          }
          else {
            PageNumberGlobal = parseInt(paginginfo.pagenum) + 2;
          }
          this.selectedDDLindex = this.selectedDDLindex + 1;
          this.myGrid.gotonextpage();
        }
      });
      leftButton.click((event) => {
        if (leftbuttonresult > 0) {
          // go to prev page.
          PageNumberGlobal = parseInt(paginginfo.pagenum);
          this.selectedDDLindex = this.selectedDDLindex - 1;
          this.myGrid.gotoprevpage();
        }
      });
      pageSizelabel.appendTo(this.element);
      dropdown.appendTo(this.element);
      leftButton.appendTo(this.element);
      rightButton.appendTo(this.element);
      CurrentPageJobslabel.appendTo(this.element);
      return this.element;
    }
    else {
      CurrentPageJobslabel.text("Job(s) displayed: " + 0);
      pageSizelabel.text(0 + "-" + 0 + ' of ' + 0);
      CurrentPageJobslabel.appendTo(this.element);
      return this.element;
    }
  }
  excelBtnOnClick() {
    var data = this.myGrid.getrows();
    var exportdata = [];
    for (var item of data) {
      const newitem = {
        'Organization': item.organizationName,
        'Department': item.departmentName,
        'Requestor': item.authorName,
        'Status': item.status,
        'Created Date': item.createdDate,
        'Hearing Date': item.hearingDate,
        'Owner': item.createdByUserName,
        'Audio Language': item.language,
        'Customer Turn Around Time': item.customerTat,
        'Job Type': item.jobTypeDisplayName,
        'Jurisdiction': item.jurisdiction,
        'Case Number': item.caseNumber,
        'Audio Format': item.format,
        'High Priority': item.highPriority,
      }
      exportdata.push(newitem);
    }
    this.excelService.exportAsExcelFile(exportdata, 'Order-List');
    this.spinner.hide();
  };
  async getFile(url: string) {
    const httpOptions = {
      responseType: 'blob' as 'json'
    };
    const res = await this.httpClient.get(url, httpOptions).toPromise().catch((err: HttpErrorResponse) => {
      const error = err.error;
      return error;
    });
    return res;
  }

  sendNotification(message, messageType) {
    this.msgNotification.elementRef.nativeElement.querySelector('.jqx-notification-content').innerHTML = message;
    this.msgNotification.template(messageType);
    this.msgNotification.open();
  }
  getDatesByOption(DateValue) {
    let startdate;
    let endDate;
    var dateevent = new Date();
    this.updateCustomSearch(false);
    endDate = JSON.stringify(dateevent);
    endDate = endDate.slice(1, 11);
    DateValue = DateValue.replace(/['"]+/g, '');
    if (DateValue == "Today") {
      startdate = this.datePipe.transform(dateevent, 'yyyy-MM-dd');
      endDate = this.datePipe.transform(dateevent, 'yyyy-MM-dd');
    }
    if (DateValue == "Yesterday") {
      let aDate = new Date();
      aDate.setDate(aDate.getDate() - 1);
      startdate = this.datePipe.transform(aDate, 'yyyy-MM-dd');
      endDate = this.datePipe.transform(aDate, 'yyyy-MM-dd');
    }

    if (DateValue == "Last 7 days (six days ago to today)") {
      var startdateevent = dateevent.setDate(dateevent.getDate() - 6);
      startdate = new Date(startdateevent);
      startdate = JSON.stringify(startdate);
      startdate = startdate.slice(1, 11);
    }
    if (DateValue == "This week (Sunday to today)") {
      var dateNow = new Date();
      var dateToday = new Date(dateNow.getFullYear(), dateNow.getMonth(), dateNow.getDate());
      var dateSunday = new Date(dateToday.getTime() - dateToday.getDay() * 24 * 3600 * 1000);
      var previousSunday = new Date(dateSunday);
      var startdateevent = previousSunday.setDate(previousSunday.getDate());
      var startdateevent = previousSunday.setDate(previousSunday.getDate() + 1);
      startdate = new Date(startdateevent);
      startdate = JSON.stringify(startdate);
      startdate = startdate.slice(1, 11);
    }
    if (DateValue == "This month (1st of this month to today)") {
      var dateNow = new Date();
      var firstDay = new Date(dateNow.getFullYear(), dateNow.getMonth(), 1);
      var firstday = new Date(firstDay);
      var startdateevent = dateevent.setDate(firstday.getDate());
      startdate = new Date(startdateevent);
      startdate = JSON.stringify(startdate);
      startdate = startdate.slice(1, 11);
    }
    if (DateValue == "Last 31 days (30 days ago to today)") {
      var startdateevent = dateevent.setDate(dateevent.getDate() - 29);
      startdate = new Date(startdateevent);
      startdate = JSON.stringify(startdate);
      startdate = startdate.slice(1, 11);
    }
    if (DateValue == "Custom") {

      this.customSearch = true;
      var startdateevent = dateevent.setDate(dateevent.getDate());
      startdate = new Date(startdateevent);
      this.startmax = new Date(startdateevent);
      /////endmin
      var todayDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
      startdate = this.datePipe.transform(todayDate, 'yyyy-MM-dd');
      endDate = this.datePipe.transform(todayDate, 'yyyy-MM-dd');
      this.startmax = new Date();
      this.endmax = new Date()
      this.updateCustomSearch(true);
      startdate = this.selectedStartDate == "" ? todayDate : this.datePipe.transform(this.selectedStartDate, 'yyyy-MM-dd');
      endDate = this.selectedEndDate == "" ? this.datePipe.transform(this.endmax, 'yyyy-MM-dd') : this.datePipe.transform(this.selectedEndDate, 'yyyy-MM-dd');
    }
    this.selectedStartDate = startdate;
    this.selectedEndDate = endDate;
  };

  updateCustomSearch(statusbar) {
    if (statusbar) {
      customStartDateContainer.style.display = "block";
      customEndDateContainer.style.display = "block";
      $('#customsearchButton').show();
    }
    else {
      customStartDateContainer.style.display = "none";
      customEndDateContainer.style.display = "none";
      $('#customsearchButton').hide();
    }
  }

  onDateOptionChange(event: any): void {
    this.spinner.show();
    PageNumberGlobal = 1;
    this.selectedStartDate = '';
    this.selectedEndDate = '';
    this.customSearch = false;
    let startdate;
    let endDate;
    this.selectedDateOption = event.args.item.value;
    var DateObj = new Date();
    ////End date
    var dateevent = new Date();
    endDate = JSON.stringify(dateevent);
    endDate = endDate.slice(1, 11);
    this.getJobsByStatus(JSON.stringify(this.selectedDateOption));
  };
  onStatusChange(event: any): void {
    PageNumberGlobal = 1;
    this.myGrid.clearselection();
    this.hidestatus = "";
    this.requiredJobsArray = [];
    this.nonRequiredJobsArray = [];

    this.selectedStatusOption = event.args.item.value;

    this.getShowstatus(this.selectedStatusOption);
    this.hidestatus = this.selectedStatusOption;
  };
  getPageData() {
    this.getShowstatus(this.selectedStatusOption);
  };
  getShowstatus(value) {

    this.hidestatus = value;
    this.getsqlData(this.selectedStartDate, this.selectedEndDate);
  }
  getJobsByStatus(value: any) {

    this.hidestatus = value;
    this.getDatesByOption(JSON.stringify(this.selectedDateOption));
    this.getsqlData(this.selectedStartDate, this.selectedEndDate);
    this.myGrid.updatebounddata();
  }

  counter: number = 1;
  tooltiprenderer = (element: any): void => {
    console.log("element", element[0]);
    let id = `toolTipContainer${this.counter}`;
    element[0].id = id;
    let content = element[0].innerHTML;
    console.log("content", content);
    this.counter++;
  }

  visibilityrenderer = (row, columnfield, value, defaulthtml, columnproperties, rowdata, htmlElement) => {
    if (value == true) {
      return '<i class="material-icons" style="cursor:pointer">visibility</i>';
    }
    else {
      return '<i class="material-icons" style="cursor:pointer">visibility_off</i>';
    }
  };

  ChooseBoolItems: any[] = [
    { value: 1, label: "Yes" },
    { value: 0, label: "No" }
  ];

    columns: any[] =
    [
      { text: 'Organization', datafield: 'organizationName', width: '10%', filtertype: 'input', align: 'center' },
      { text: 'Department', datafield: 'departmentName', filtertype: 'input', width: '10%', align: 'center' },
      { text: 'Requestor', datafield: 'authorName', filtertype: 'input', width: '9%', align: 'center' },
      { text: 'Status', datafield: 'status', filtertype: 'input', width: '12%', align: 'center' },
      { text: 'Created Date', datafield: 'createdDate', width: '15%', filterable: false, align: 'center' },
      { text: 'Hearing Date', datafield: 'hearingDate', width: '15%', filterable: false, cellsformat: 'MMMM-dd-yyyy', align: 'center' },
      { text: 'Owner', datafield: 'createdByUserName', filtertype: 'input', width: '13%', align: 'center' },
      { text: 'Job Type', datafield: 'jobTypeDisplayName', filtertype: 'input', width: '12%', align: 'center' },
      { text: 'Audio Language', datafield: 'language', filtertype: 'input', width: '15%', align: 'center' },
      { text: 'Customer Turn Around Time', datafield: 'customerTat', cellsformat: 'MMMM-dd-yyyy HH:mm', filtertype: 'input', width: '15%', align: 'center',hidden :true },
      { text: 'Jurisdiction', datafield: 'jurisdiction', filtertype: 'input', width: '15%', align: 'center',hidden : true },
      { text: 'Case Number', datafield: 'caseNumber', filtertype: 'input', width: '15%', align: 'center' },
      { text: 'Audio Format', datafield: 'format', filtertype: 'input', width: '15%', align: 'center' ,hidden :true  },
      { text: 'High Priority', datafield: 'highPriority', filtertype: 'input', width: '15%', align: 'center' },
      { text: 'deptId', datafield: 'deptId', filtertype: 'input', width: '15%', align: 'center', hidden: true },
      { text: 'organizationExtId', datafield: 'organizationExtId', filtertype: 'input', width: '15%', align: 'center', hidden: true },
      { text: 'departmentExtId', datafield: 'departmentExtId', filtertype: 'input', width: '15%', align: 'center', hidden: true },
      { text: 'jobTypeExtId', datafield: 'jobTypeExtId', filtertype: 'input', width: '15%', align: 'center', hidden: true },
      { text: 'authorExtId', datafield: 'authorExtId', filtertype: 'input', width: '15%', align: 'center', hidden: true },
      { text: 'orderFormId', datafield: 'orderFormId', filtertype: 'input', width: '15%', align: 'center', hidden: true },
      { text: 'orderId', datafield: 'id', filtertype: 'input', width: '15%', align: 'center', hidden: true }
    ];


  rendergridrows = (params: any): any => {
    return params.data;
  }
  ///////////////////////buttons creation///////////////
  createButtonsContainers(statusbar: any): void {

    if (statusbar[0] == null || statusbar[0] == 'undefined') {
    }
    else {
      let buttonsContainer = document.createElement('div');
      buttonsContainer.style.cssText = 'overflow: hidden; position: fixed; margin: 5px; border-color: none; background: none; width: 90%';

      // let editDocumentContainer = document.createElement('div');
      // editDocumentContainer.id = 'editDocumentButton';
      // editDocumentContainer.className = 'panelButtonclass';
      // editDocumentContainer.style.cssText = 'float: left; margin-left: 5px;';

      // ////Download button
      // let downloadButtonContainer = document.createElement('div');
      // downloadButtonContainer.id = 'downloadButton';
      // downloadButtonContainer.className = 'panelButtonclass';
      // downloadButtonContainer.style.cssText = 'float: left; margin-left: 5px;';
      ////remove selected rows
      let RemoveSelectionContainer = document.createElement('div');
      RemoveSelectionContainer.id = 'RemoveButton';
      RemoveSelectionContainer.className = 'panelButtonclass';
      RemoveSelectionContainer.style.cssText = 'float: left; margin-left: 5px;';
      let datepickerButtonContainer = document.createElement('div');
      datepickerButtonContainer.id = "datepickerButton";
      datepickerButtonContainer.className = 'panelButtonclass';
      datepickerButtonContainer.style.cssText = 'theme: material; float: right; margin-left: 5px;';

      customStartDateContainer = document.createElement('div');
      customEndDateContainer = document.createElement('div');
      customsearchButtonContainer = document.createElement('div');

      customStartDateContainer.id = "customstartdate"
      customEndDateContainer.id = "customenddate"
      customsearchButtonContainer.id = 'customsearchButton';


      customStartDateContainer.style.cssText = 'theme: material;float: right; margin-left: 5px;';
      customEndDateContainer.style.cssText = 'theme: material;float: right; margin-left: 5px;';
      customsearchButtonContainer.style.cssText = 'theme: material;float: right; margin-left: 5px;';

      customsearchButtonContainer.style.display = "none";
      customStartDateContainer.style.display = "none";
      customEndDateContainer.style.display = "none";

      let refreshContainerCourtOrderList = document.createElement('div');
      refreshContainerCourtOrderList.id = 'refreshButton';
      refreshContainerCourtOrderList.className = 'panelButtonclass';
      refreshContainerCourtOrderList.style.cssText = 'float: left; margin-left: 5px;';

      let exportContainerCourtOrderList = document.createElement('div');
      exportContainerCourtOrderList.id = 'exportButton';
      exportContainerCourtOrderList.className = 'panelButtonclass';
      exportContainerCourtOrderList.style.cssText = 'float: left; margin-left: 5px;';

      let createCourtorderButtonContainer = document.createElement('button');
      createCourtorderButtonContainer.id = 'createCourtorderButton';
      createCourtorderButtonContainer.innerText = 'New Order';
      createCourtorderButtonContainer.classList.add('btn', 'btn-viq', 'btn-viq-primary', 'pull-right');
      createCourtorderButtonContainer.style.cssText = 'float: left; border-radius :10px;';

      // let editButtonContainer = document.createElement('div');
      // editButtonContainer.id = 'editButtonOrder';
      // editButtonContainer.className = 'panelButtonclass';
      // editButtonContainer.style.cssText = 'float: left; margin-left: 5px;';

      buttonsContainer.appendChild(createCourtorderButtonContainer);
      //buttonsContainer.appendChild(editButtonContainer);
      // buttonsContainer.appendChild(editDocumentContainer);
      // buttonsContainer.appendChild(downloadButtonContainer);
      buttonsContainer.appendChild(RemoveSelectionContainer);

      buttonsContainer.appendChild(refreshContainerCourtOrderList);
      buttonsContainer.appendChild(exportContainerCourtOrderList);

      statusbar[0].appendChild(buttonsContainer);
    }
  }
  initializeTooltip(selector, content) {
    tippy(selector, {
      content: content,
      arrow: true,
      placement: 'top', // Adjust as needed
    });
  }

  createButtons(): void {
    //////////////remove selection button
    let removeSelectionButtonOptions = {
      width: 40, height: 25, value: '<img src="../../../assets/img/icon/clear.png" alt="Clear"  class="user-listicon"/>'//'<i class="material-icons" title = "Clear selection" style="cursor:pointer">remove_circle</i>',
    }
    let removeSelectionButton = jqwidgets.createInstance('#RemoveButton', 'jqxButton', removeSelectionButtonOptions);
    this.initializeTooltip('#RemoveButton', 'Clear Selection');
    removeSelectionButton.addEventHandler('click', (event: any): void => {
      this.orderindex = [];
      this.orderindex = this.myGrid.getselectedrowindexes();
      if (this.orderindex.length > 0) {
        this.removeSelectedRows();
      }
      else {
        this.sendNotification("No order is selected. Please select a valid order.", "error");
      }
    });
    /////////////////download button
    // let downloadButtonOptions = {
    //   width: 40, height: 25, value: '<img src="../../../assets/img/icon/download-document.png" alt="Download Document"  class="user-listicon"/>',//'<i class="material-icons" title= "Disable" style="cursor:pointer">remove_circle</i>',
    //   imgPosition: 'center', textPosition: 'center',
    // }
    // let downloadButton = jqwidgets.createInstance('#downloadButton', 'jqxButton', downloadButtonOptions);
    // this.initializeTooltip('#downloadButton', 'Download Document');
    // downloadButton.addEventHandler('click', (event: any): void => {
    //   this.orderindex = [];
    //   this.requiredJobsArray = [];
    //   this.nonRequiredJobsArray = [];
    //   this.completedJobIdArray = [];
    //   this.mode = "download";
    //   //Need to implement 
    // });

    let refreshButtonOptions = {
      width: 40, height: 25, value: '<img src="../../../assets/img/icon/refresh.png" alt="Refresh"  class="user-listicon"/>',
      imgPosition: 'center', theme: 'material', textPosition: 'center', template: 'link'
    }
    let refreshButton = jqwidgets.createInstance('#refreshButton', 'jqxButton', refreshButtonOptions);
    this.initializeTooltip('#refreshButton', 'Refresh order List');
    refreshButton.addEventHandler('click', (event: any): void => {
      this.refreshGrid();
    });

    let exportButtonOptions = {
      width: 40, height: 25, value: '<img src="../../../assets/img/icon/export.png" alt="Export"  class="user-listicon"/>',
      imgPosition: 'center', theme: 'material', textPosition: 'center', template: 'link'
    }
    let exportButton = jqwidgets.createInstance('#exportButton', 'jqxButton', exportButtonOptions);
    this.initializeTooltip('#exportButton', 'Export order List');
    exportButton.addEventHandler('click', (event: any): void => {
      this.spinner.show();
      const rows = this.myGrid.getrows();
      if (rows.length > 0) {
        // Get the browser's time zone offset in minutes
        this.excelBtnOnClick();
      } else {
          this.sendNotification("No records found",'error');
          this.spinner.hide();
      }
    });

    // let editDocumentButtonOptions = {
    //   width: 40, height: 25, value: '<img src="../../../assets/img/icon/edit-document.png" alt="Edit Document" class="user-listicon"/>',
    //   imgPosition: 'center', theme: 'material', textPosition: 'center', template: 'link'
    // }
    // let editDocumentButton = jqwidgets.createInstance('#editDocumentButton', 'jqxButton', editDocumentButtonOptions);
    // this.initializeTooltip('#editDocumentButton', 'View Document');
    // editDocumentButton.addEventHandler('click', async (event: any) => {
    //   this.IsAudioPlay = false;
    //   this.orderindex = [];
    //   this.orderindex = this.myGrid.getselectedrowindexes();
    //   //Need to implement 
    // });
    // let editButtonOptions = {
    //   width: 40, height: 25, value: '<img src="../../../assets/img/icon/edit.png"  alt= "Edit Order"  class="user-listicon"/>'
    // }
    // let editButtonOrder = jqwidgets.createInstance('#editButtonOrder', 'jqxButton', editButtonOptions);
    // this.initializeTooltip('#editButtonOrder', 'Edit Order');
    // editButtonOrder.addEventHandler('click', (event: any): void => {
    //   this.orderindex = this.myGrid.getselectedrowindexes();
    //   this.setOrdersListString();
    //   if (this.orderindex.length == 1) {
    //     this.OrderArray.forEach((field) => {
    //       this.backendService.orderDetails = field;
    //       const route = '/admin/uploadCourtOrder';
    //       const url = `${route}`;
    //       this.router.navigate([url], {
    //         queryParams: { title: 'Update Order' }
    //       });
    //     });
    //   }
    //   else {
    //     this.sendNotification("No order is selected. Please select a valid order.", "error");
    //   }
    // });

    let createCourtorderButton = jqwidgets.createInstance('#createCourtorderButton', 'jqxButton');
    this.initializeTooltip('#createCourtorderButton', 'Create Order');
    createCourtorderButton.addEventHandler('click', async (event: any) => {

      const route = '/admin/uploadCourtOrder';
      const url = `${route}`;
      this.router.navigate([url], {
        queryParams: { title: 'Create Order' }
      });

    });
  }
  setOrdersListString() {
    // this.usernameslist = "";
    this.orderindex = [];
    this.OrderArray = [];

    this.orderindex = this.myGrid.getselectedrowindexes();
    this.orderindex.forEach((field) => {
      const row = this.myGrid.getrowdata(field);
      this.OrderArray.push(row);
    });
  }
  refreshGrid() {

    var isUserlogin = this.backendService.CheckIsUserLoggedIn();
    if (isUserlogin) {
      this.isRefresh = true;
      this.requiredJobsArray = [];
      this.nonRequiredJobsArray = [];
      this.myGrid.clearselection();
      this.getJobsByStatus(this.hidestatus);
      this.heightOffset = 120;
    }

  }
  removeSelectedRows() {
    this.myGrid.clearselection();
    this.orderindex = [];
    this.requiredJobsArray = [];
    this.nonRequiredJobsArray = [];
  }

  calcProgress(fileObj, IsProgress, progressEvent) {
    if (IsProgress) {
      this.progress = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
    }
  }
  sweetalert() {
    var unRequiredJobs = 0;
    var requiredstring = "";
    var reqsubstring = "";
    var titleString = "Confirmation"
    if (this.mode == "download") {
      if (this.completedJobIdArray.length > 0) {
        unRequiredJobs = this.orderindex.length - this.completedJobIdArray.length;
        requiredstring = "You have selected " + unRequiredJobs + " uncompleted job(s) and " + this.completedJobIdArray.length + " completed job. Uncompleted job(s) are unable to download. ";
      }
      reqsubstring = "Do you want to " + this.mode + " the " + (this.mode == "download" ? "job" : "selected " + this.orderindex.length + " job(s)") + "?"
    }
    if (this.mode == "unhide") {
      if (this.nonRequiredJobsArray.length > 0) {
        unRequiredJobs = this.nonRequiredJobsArray.length;
        requiredstring = "You have selected " + unRequiredJobs + " unhidden job(s) and " + this.requiredJobsArray.length + " hidden job(s). Unhidden job(s) are unable to modify. ";
      }
      reqsubstring = "Do you want to " + this.mode + " the selected " + this.requiredJobsArray.length + " hidden job(s)?"
    }
    if (this.mode == "hide") {
      if (this.nonRequiredJobsArray.length > 0) {
        unRequiredJobs = this.nonRequiredJobsArray.length;
        requiredstring = "You have selected " + unRequiredJobs + " hidden job(s) and " + this.requiredJobsArray.length + " unhidden job(s). Hidden job(s) are unable to modify. ";
      }
      reqsubstring = "Do you want to " + this.mode + " the selected " + this.requiredJobsArray.length + " unhidden job(s)?"
    }
    if (this.mode == "delete") {
      reqsubstring = "Do you want to " + this.mode + " the selected " + this.orderindex.length + " job(s)?"
    }
    if (this.mode == "resubmit") {
      reqsubstring = "Do you want to " + this.mode + " the selected " + this.orderindex.length + " job(s)?"
    }
    if (this.mode == "editdraft") {
      titleString = "Confirmation";
      reqsubstring = "Your professional edit request will be processed within the standard turnaround time as defined for your account.  Thank you!";
    }
    if (this.mode == "audioDownload") {
      reqsubstring = "Do you want to download the selected audio?"
    }
    if (this.mode == "zipDownload") {
      reqsubstring = "Do you want to download Zip?"
    }
    Swal.fire({
      customClass: {
        popup: 'format-pre2'
      },
      title: titleString,
      text: (unRequiredJobs > 0 ? requiredstring : "") + reqsubstring,
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: `Yes`,
      denyButtonText: `Cancel`,
      confirmButtonColor: '#187EB1',
      cancelButtonColor: '#5b5b5b',
    }).then(async (result) => {
      if (result.value) {
      }
    })
  }
  Rowclick(event: any): void {
    this.IsAudioPlay = false;
    this.heightOffset = 120;
    this.getHeight();
  }
  async onRowdoubleclick(event) {
    const rowIndex = event.args.rowindex;
    const orderId = this.myGrid.getrowdata(rowIndex).id;
    console.log("orderId", orderId);
    await this.backendService.setOrderId(orderId);
    const route = '/admin/orderDetails';
        const url = `${route}`;
        this.router.navigate([url], {
        queryParams: { title: 'Order View' }
    });
    
  }
  
}
