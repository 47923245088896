import { Router, ActivatedRoute } from '@angular/router';
import { BackendService } from './../services/backend.service';
import { Component, OnInit, ElementRef, OnDestroy, ViewChild, Inject } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { jqxNotificationComponent } from 'jqwidgets-ng/jqxnotification';
import { jqxWindowComponent } from 'jqwidgets-ng/jqxwindow';
import { JwtHelperService } from '@auth0/angular-jwt';
import { MatDialog } from '@angular/material/dialog';
import { ForceChangePasswordComponent } from './force-change-password/force-change-password.component';
import { environment } from "src/environments/environment";
import { NgxSpinnerService } from 'ngx-spinner';
import { DOCUMENT } from '@angular/common';
import { LoginResponse } from '../models/apiRequestModels';
import { MFAComponent } from './mfa/mfa-otp.component';
import { ContentObserver } from '@angular/cdk/observers';
import { ResponseMessage } from '../models/ResponseMessage';
import { Title } from '@angular/platform-browser';
import { removeSpaces } from '../core/utils/validators';
import Swal from 'sweetalert2';

declare var $: any;
let errormessage = "";

@Component({
    selector: 'app-login-cmp',
    templateUrl: './login.component.html',
    styleUrls: ['./login.scss']
})

export class LoginComponent implements OnInit, OnDestroy {
    brandname = localStorage.getItem('brand');
    bgimage: string;
    test: Date = new Date();
    private toggleButton: any;
    private sidebarVisible: boolean;
    private nativeElement: Node;
    private userConfig: any = null;
    private loginresponse: LoginResponse;
    private mfaloginresponse: LoginResponse;
    returnUrl: string;
    private currentURL;
    helper = new JwtHelperService();
    show: boolean = false;
    webSiteLogo = '../../assets/myviq/assets/images/VIQ-Logo.svg';
    loginForm = new FormGroup({
        userName: new FormControl(null, Validators.required),
        password: new FormControl(null, Validators.required)
    });
    errormessage = "";
    usernameGlobal = "";
    passwordGlobal = "";
    versionNumber = "";
    buildVersion = "";

    constructor(
        private element: ElementRef,
        private backendService: BackendService,
        private router: Router,
        private route: ActivatedRoute,
        private formBuilder: FormBuilder,
        public dialog: MatDialog,
        private spinner: NgxSpinnerService,
        @Inject(DOCUMENT) private document: Document,
        public title: Title,

    ) {
        this.nativeElement = element.nativeElement;
        this.sidebarVisible = false;
        this.bgimage = './assets/img/' + this.brandname + '/login_bg.png'
        var idpuser = localStorage.getItem('idpuser')
        var iSuserloggedIN = localStorage.getItem('userloggedIN')
        this.webSiteLogo = '../../app/coreui/assets/images/VIQ-Logo.svg';
        this.currentURL = this.document.location.href;
        let currentpage = new URL(this.currentURL).pathname;
        localStorage.setItem('page',  currentpage.replace(/[^a-zA-Z0-9]/g, ''));
        if(iSuserloggedIN == "true")
        {
        }
        if(idpuser == "true")
        {
            localStorage.setItem('idpuser', 'false');
            localStorage.removeItem('idpemail');
        }
    }

    @ViewChild('msgNotification', { static: false }) msgNotification: jqxNotificationComponent;
    @ViewChild('windowReference', { static: false }) window: jqxWindowComponent;
    @ViewChild('autofocus', { static: false }) autofocus: ElementRef;
    notificationTemplate = 'success';
    resetform: FormGroup;
    submitted = false;
    ngOnInit() {
        this.title.setTitle('MyVIQ');
        this.versionNumber = environment.Version;
        this.buildVersion = environment.BuildVersion;
        $('#login-pwd').bind('cut copy paste', function (e) {
            e.preventDefault(); //disable cut,copy,paste
        });
        var navbar: HTMLElement = this.element.nativeElement;
        this.toggleButton = navbar.getElementsByClassName('navbar-toggle')[0];
        const body = document.getElementsByTagName('body')[0];
        body.classList.add('login-page');
        body.classList.add('off-canvas-sidebar');
        const card = document.getElementsByClassName('card')[0];
    }
    sidebarToggle() {
        var toggleButton = this.toggleButton;
        var body = document.getElementsByTagName('body')[0];
        var sidebar = document.getElementsByClassName('navbar-collapse')[0];
        if (this.sidebarVisible == false) {
            setTimeout(function () {
                toggleButton.classList.add('toggled');
            }, 500);
            body.classList.add('nav-open');
            this.sidebarVisible = true;
        } else {
            this.toggleButton.classList.remove('toggled');
            this.sidebarVisible = false;
            body.classList.remove('nav-open');
        }
    }
    ngOnDestroy() {
        const body = document.getElementsByTagName('body')[0];
        body.classList.remove('login-page');
        body.classList.remove('off-canvas-sidebar');
    }

    get f() { return this.loginForm.controls; }

    get resetf() {
        return this.resetform.controls;
    }


    openChangePasswordDialog(): void {
        const dialogRef = this.dialog.open(ForceChangePasswordComponent, {
            width: '35%',
            data: { "currentpassword": this.passwordGlobal, "currentusername": this.usernameGlobal },
            disableClose: true
        });
    }
    openDialog(): void {
        const dialogRef = this.dialog.open(ForceChangePasswordComponent, {
            width: '35%',
            data: { "currentpassword": this.passwordGlobal, "currentusername": this.usernameGlobal },
            disableClose: true
        });
    }
    openMFADialog(): void {
        const dialogRef = this.dialog.open(MFAComponent, {
            width: '35%',
            data: { "currentpassword": this.passwordGlobal, "currentusername": this.usernameGlobal, "loginresponse": this.loginresponse, "Verification" : false },
            disableClose: true
        });
        dialogRef.afterClosed().subscribe(mfaResult => {

            if (mfaResult.event == 'MFA') {
                if (mfaResult != null) {
                    this.mfaloginresponse = mfaResult.data;
                    this.setloggedinUser(mfaResult)
                    this.spinner.hide();
                }
            }
        });
    }

    sendNotification(message, messageType) {
        this.msgNotification.elementRef.nativeElement.querySelector('.jqx-notification-content').innerHTML = message;
        this.msgNotification.template(messageType);
        this.msgNotification.open();
    }

    async setloggedinUser(result: any) {
        localStorage.removeItem('token');
        localStorage.removeItem('accessToken');
        localStorage.removeItem('user_attribute');
        localStorage.setItem('token', result.data.token);
        localStorage.setItem('accessToken', result.data.accessToken);
        localStorage.setItem('refreshtoken', result.data.refreshtoken);
        localStorage.setItem('user_attribute', this.loginForm.controls.userName.value);
        localStorage.removeItem('user_isadmin');
        this.userConfig = this.backendService.getJSONResponse(result.data);
        localStorage.setItem('user_isadmin', result.data.userManagementDto.isAdmin);
        localStorage.setItem('id', result.data.userManagementDto.id);
        localStorage.setItem('userName', result.data.userManagementDto.userName);
        localStorage.setItem('user_roleName', result.data.userManagementDto.roleName);
        localStorage.setItem('user_jobColumnsList', result.data.userManagementDto.jobColumnsList);
        localStorage.setItem('user_jobColumnsOrder', result.data.userManagementDto.jobColumnsOrder);
        localStorage.setItem('user_customStartdate', result.data.userManagementDto.customStartdate);
        localStorage.setItem('user_customEnddate', result.data.userManagementDto.customEnddate);
        localStorage.setItem('user_dateRange', result.data.userManagementDto.dateRange);
        localStorage.setItem('tenantId', result.data.userManagementDto.tenantId);
        localStorage.setItem('user_authorExtId', result.data.userManagementDto.authorExtId);
        localStorage.setItem('allowTranscriptEditing', result.data.userManagementDto.allowTranscriptEditing);
        localStorage.setItem('isMFA', result.data.userManagementDto.isMFA);
        localStorage.setItem('phone_number_verified', result.data.userManagementDto.phone_number_verified);
        localStorage.setItem('phoneverifyskipcount', result.data.userManagementDto.phoneverifyskipcount);
        localStorage.setItem('estimationMessage',result.data.userManagementDto.estimationMessage);
        localStorage.setItem('isEstimateEnable',result.data.userManagementDto.isEstimateEnable);
        localStorage.setItem('CustomerNotesEnable', result.data.userManagementDto.isCustomerNotesEnable);
        localStorage.setItem('JobListQuery',result.data.userManagementDto.jobquery);
        localStorage.setItem('UserResendQuery',result.data.userManagementDto.userquery);
        localStorage.setItem('MaintenanceMessage',result.data.userManagementDto.maintenanceMessage);
        localStorage.setItem('MaintenanceStartTime',result.data.userManagementDto.maintenanceStartTime);
        localStorage.setItem('MaintenanceEndTime',result.data.userManagementDto.maintenanceEndTime);
        localStorage.setItem('IsEnable',result.data.userManagementDto.isEnable);
        localStorage.setItem('EditorAutoSaveInterval',result.data.userManagementDto.editorAutoSaveInterval);
        localStorage.setItem('ReleaseNotesFileName',result.data.userManagementDto.releaseNotesFileName);
        localStorage.setItem('iscloseBanner','false'); 
        if(result.data.userManagementDto.isMFA && !result.data.userManagementDto.phone_number_verified )
        {
            localStorage.setItem('password', this.passwordGlobal);
        }
        this.setReturnUrl();
        this.backendService.setUserLoggedIn(true);
        this.router.navigateByUrl(this.returnUrl);
    }
    login() {
        this.usernameGlobal = this.loginForm.controls.userName.value;
        this.passwordGlobal = this.loginForm.controls.password.value;
        if (this.usernameGlobal == "" || this.usernameGlobal == null) {
            this.sendNotification("Please enter user name", 'error');
        }
        else if(/\s/.test(this.usernameGlobal)){
            this.sendNotification("No space in the user name", 'error');
        }
        else if (this.passwordGlobal == "" || this.passwordGlobal == null) {
            this.sendNotification("Please enter password", 'error');
        }
        if (this.loginForm.valid) {
            this.spinner.show();
            this.backendService.authnticate((this.loginForm.controls.userName.value), this.loginForm.controls.password.value).subscribe(
                    res => {
                     console.log("authnticate result...", res);
                    const result = this.backendService.getJSONResponse(res);
                    if (result != null) {
                        this.loginresponse = JSON.parse(JSON.stringify(result.data))
                        if (result.data.isSuccess == true) {
                            if (result.data.userStatus == ResponseMessage.authenticated) {
                                localStorage.removeItem('token');
                                localStorage.removeItem('accessToken');
                                localStorage.removeItem('user_attribute');
                                localStorage.setItem('token', result.data.token);
                                localStorage.setItem('refreshtoken', result.data.refreshtoken);
                                localStorage.setItem('accessToken', result.data.accessToken);
                                localStorage.setItem('user_attribute', this.loginForm.controls.userName.value);
                                localStorage.removeItem('user_isadmin');
                                this.userConfig = this.backendService.getJSONResponse(result.data);
                                localStorage.setItem('user_isadmin', result.data.userManagementDto.isAdmin);
                                localStorage.setItem('id', result.data.userManagementDto.id);
                                localStorage.setItem('userName', result.data.userManagementDto.userName);
                                localStorage.setItem('user_roleName', result.data.userManagementDto.roleName);
                                localStorage.setItem('user_jobColumnsList', result.data.userManagementDto.jobColumnsList);
                                localStorage.setItem('user_jobColumnsOrder', result.data.userManagementDto.jobColumnsOrder);
                                localStorage.setItem('user_customStartdate', result.data.userManagementDto.customStartdate);
                                localStorage.setItem('user_customEnddate', result.data.userManagementDto.customEnddate);
                                localStorage.setItem('user_dateRange', result.data.userManagementDto.dateRange);
                                localStorage.setItem('tenantId', result.data.userManagementDto.tenantId);
                                localStorage.setItem('user_authorExtId', result.data.userManagementDto.authorExtId);
                                localStorage.setItem('allowTranscriptEditing', result.data.userManagementDto.allowTranscriptEditing);
                                localStorage.setItem('departments', result.data.userManagementDto.departments);
                                localStorage.setItem('estimationMessage',result.data.userManagementDto.estimationMessage);
                                localStorage.setItem('isEstimateEnable',result.data.userManagementDto.isEstimateEnable);
                                localStorage.setItem('isForeignLanguage',result.data.userManagementDto.isForeignLanguage);
                                localStorage.setItem('CustomerNotesEnable', result.data.userManagementDto.isCustomerNotesEnable);
                                localStorage.setItem('JobListQuery',result.data.userManagementDto.jobquery);
                                localStorage.setItem('UserResendQuery',result.data.userManagementDto.userquery);
                                localStorage.setItem('MaintenanceMessage',result.data.userManagementDto.maintenanceMessage);
                                localStorage.setItem('MaintenanceStartTime',result.data.userManagementDto.maintenanceStartTime);
                                localStorage.setItem('MaintenanceEndTime',result.data.userManagementDto.maintenanceEndTime);
                                localStorage.setItem('IsEnable',result.data.userManagementDto.isEnable);
                                localStorage.setItem('EditorAutoSaveInterval',result.data.userManagementDto.editorAutoSaveInterval);
                                localStorage.setItem('ReleaseNotesFileName',result.data.userManagementDto.releaseNotesFileName);
                                localStorage.setItem('iscloseBanner','false');
                                this.backendService.checkUserTokenExpired(result.data.token);
                                this.setReturnUrl();
                                this.backendService.setUserLoggedIn(true);
                                this.router.navigateByUrl(this.returnUrl);
                            }
                        }
                        else {
                            if (result.data.userStatus == ResponseMessage.NEW_PASSWORD_REQUIRED) {
                                this.spinner.hide();
                                this.openDialog();

                            }
                           
                            if (result.data.userStatus == ResponseMessage.USER_IS_DISABLED) {
                                this.errormessage = ResponseMessage.USER_IS_DISABLED;
                                this.sendNotification(this.errormessage, 'error');
                                this.spinner.hide();

                            }
                            if (result.data.userStatus == ResponseMessage.SMS_MFA) {
                                this.spinner.hide();
                                this.openMFADialog();
                                //this.setloggedinUser(result.data.token);

                            }
                            if (result.data.userStatus == ResponseMessage.INCORRECT_USERNAME_PASSWORD) {
                                this.errormessage = "Invalid Username/Password."
                                this.sendNotification(this.errormessage, 'error');
                                this.spinner.hide();
                            }
                            if (result.data.userStatus == ResponseMessage.EMAIL_ADDRESS_NOT_CONFIRMED) {
                                this.errormessage = ResponseMessage.EMAIL_ADDRESS_NOT_CONFIRMED
                                this.sendNotification(this.errormessage, 'error');
                                this.spinner.hide();
                            }
                            if (result.data.userStatus == ResponseMessage.USER_NOT_FOUND) {
                                this.errormessage = "User not found"
                                this.sendNotification(this.errormessage, 'error');
                                this.spinner.hide();
                            }
                            if (result.data.userStatus == ResponseMessage.PASSWORD_EXPIRED) {
                                this.errormessage = "PASSWORD_EXPIRED."
                                this.sendNotification(this.errormessage, 'error');
                                this.spinner.hide();
                                this.openChangePasswordDialog();
                            }
                            if (result.data.userStatus == null) {
                                this.errormessage = ResponseMessage.USER_NOT_FOUND;
                                this.sendNotification(this.errormessage, 'error');
                                this.spinner.hide();
                            }
                            if(result.data.userStatus==ResponseMessage.PasswordResetRequiredException){
                                this.spinner.hide();
                                Swal.fire("Password Reset Required. Please reset the password using forgot password option. ",'', "error");
                            }
                        }

                    }


                },
                err => {
                    var errorresult = err.error.message;
                    if (errorresult == "invalidUsernameorPassword") {
                        this.errormessage = "Invalid Username/Password."
                        this.sendNotification(this.errormessage, 'error');
                    }
                    if (errorresult == "newPasswordRequired") {
                        this.openDialog();
                    }
                    this.spinner.hide();

                }
            )
        }
    }

    setReturnUrl() {

        if (this.backendService.getJSONResponse(this.userConfig).userManagementDto.isTNC == 'false') {

            this.returnUrl = '/termconditions';
        }
        else
            this.returnUrl = '/admin';
    }
    ViqIdplogin(){
        console.log("ViqIdplogin");
        window.open('https://dev-viq-test-auth.auth.ca-central-1.amazoncognito.com/oauth2/authorize?identity_provider=VIQ-Azure-AD-Test&redirect_uri=https://devnsconnect.aiassist.ai/nclanding&response_type=CODE&client_id=1inag0vhqu04sv5l74gj65kta8&scope=aws.cognito.signin.user.admin%20email%20openid%20phone%20profile',"_self");

    }
      // PASSWORD TOGGLE.
today: number = Date.now();
  passwordToggle() {
    this.show = !this.show;
  }
    isLoginClicked: boolean = false;
    public executeImportantAction(): void {
        this.isLoginClicked = true;
        if (this.loginForm.invalid) {
          this.isLoginClicked = false;
          return;
        }
      }
}

