import { OnInit,Component, ElementRef, OnDestroy,ViewChild } from "@angular/core";
import { FormGroup,FormControl,FormBuilder, Validators } from "@angular/forms";
import { BackendService } from './../../services/backend.service';
import { MatDialog } from '@angular/material/dialog';
import { environment } from "src/environments/environment";
import { NgxSpinnerService } from 'ngx-spinner';
import { Router, ActivatedRoute } from '@angular/router';
import { jqxNotificationComponent  } from 'jqwidgets-ng/jqxnotification';

@Component({
    selector: 'app-terms-and-conditions',
    templateUrl: './terms-and-conditions.component.html',
    styleUrls: ['./terms-and-conditions.component.css'],
  })
  export class TermsAndConditionsComponent implements OnInit {
    
    returnUrl: string ;
    errormessage="";
    termsAndConditionData?:{};
    constructor(
      private backendService: BackendService,
      private router: Router,
      private route: ActivatedRoute,
      private formBuilder: FormBuilder,
      public dialog: MatDialog,
      private spinner: NgxSpinnerService,
      ) {}

      @ViewChild('msgNotification', { static: false }) msgNotification: jqxNotificationComponent;

    ngOnInit(): void {
      this.spinner.show();
      this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/admin';
      this.getTermsAndConditions()
    }
  
    form: FormGroup;
    submitted = false;

    getTermsAndConditions(){
      this.backendService.getTermsAndConditionByTenant(localStorage.getItem('tenantId')).subscribe(
          res=>{
            
            this.termsAndConditionData = JSON.parse(res['data'].tncjson);
            console.log("this.termsAndConditionData ", this.termsAndConditionData );
            this.spinner.hide();
          },
          err => {
             console.log("error result :::",err.error.message)
              let errorResult= err.error.message;
              this.sendNotification(errorResult, 'error')
              this.spinner.hide();
          })
        }

    acceptTerms(){
      this.spinner.show();
      let userName=localStorage.getItem('userName');
      let id=localStorage.getItem('id');
      this.backendService.updateusertnc(userName, id, true).subscribe(
        res => {
          let result=this.backendService.getJSONResponse(res);
          if(result && result.data.isTNC){
            this.router.navigateByUrl(this.returnUrl);
          }
          else    
           this.exitApplication();// if isTNC is false in response, then exit the application.
        },
        err => {
           console.log("error result :::",err.error.message)
            let errorResult= err.error.message;
            this.sendNotification(errorResult, 'error')
         
            this.spinner.show();
        })
    }

    //on exit, just logout the user.
    exitApplication(){
      this.dialog.closeAll();
      this.backendService.isLoggedIn = false;
      localStorage.removeItem('token');
      localStorage.removeItem('user_attribute');
      console.log("IDPUser",localStorage.getItem('idpuser'));
      this.backendService.setUserLoggedIn(false);
      if(localStorage.getItem('idpuser') == "true")
      {
        this.router.navigateByUrl('/nclanding');
      }
      else
      {
        this.router.navigateByUrl('/');
      }
    }

    sendNotification(message, messageType){
      this.msgNotification.elementRef.nativeElement.querySelector('.jqx-notification-content').innerHTML = message;
      this.msgNotification.template(messageType);
      this.msgNotification.open();
    }
  }

  
  