import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { jqxNotificationComponent } from 'jqwidgets-ng/jqxnotification';
import { jqxTextAreaComponent } from 'jqwidgets-ng/jqxtextarea';
import { NgxSpinnerService } from 'ngx-spinner';
import { JobReleaseHoldStateEnum } from 'src/app/models/apiRequestModels';
import { BackendService } from 'src/app/services/backend.service';

@Component({
  selector: 'app-job-hold',
  templateUrl: './job-hold.component.html',
  styleUrls: ['./job-hold.component.css']
})
export class JobHoldComponent implements OnInit {

  jobRows: any;
  @ViewChild('commentsComponent', { static: false }) commentsComponent: jqxTextAreaComponent;
  @ViewChild('msgNotification', { static: false }) msgNotification: jqxNotificationComponent;
  jobReleaseHoldStateOptions: { label: string, value: JobReleaseHoldStateEnum }[];
  defaultOption: string;
  showingTabs: boolean = true;
  selectedIndex: number = 0;
  statusService = 0;
  jobReleaseHoldselectedValue: JobReleaseHoldStateEnum;
  commentValue: string = '';
  onHold: boolean;
  isButtonEnabled: boolean = false;

  constructor(
    private backendService: BackendService,
    private spinner: NgxSpinnerService,
    public dialogRef: MatDialogRef<JobHoldComponent>,
    @Inject(MAT_DIALOG_DATA) public jobdata: any
  ) { }

  ngOnInit(): void {
    this.jobReleaseHoldStateOptions = [
      { label: 'Ready for Typing/Editing', value: JobReleaseHoldStateEnum.TypingOrEditing },
      { label: 'Ready for Review', value: JobReleaseHoldStateEnum.Review }
    ];
    this.defaultOption = this.jobReleaseHoldStateOptions[1].label;
    this.jobReleaseHoldselectedValue = this.jobReleaseHoldStateOptions[1].value;
    this.jobRows = this.jobdata.row;
    this.showingTabs = (this.jobRows.status == "RUNNING") ? true : false;
    this.selectedIndex = (this.showingTabs == true) ? 0 : 1;
    this.onHold = (this.showingTabs == true) ? true : false;
    this.commentValue = (this.selectedIndex == 0) ?((this.jobdata.row.removeComment != null) ? this.jobdata.row.removeComment:(this.jobdata.row.onHoldComment != null ?  this.jobdata.row.onHoldComment: null)):((this.jobdata.row.removeComment != null) ? this.jobdata.row.removeComment : this.jobdata.row.onHoldComment);
  }
  
  sendNotification(message, messageType) {
    this.msgNotification.elementRef.nativeElement.querySelector('.jqx-notification-content').innerHTML = message;
    this.msgNotification.template(messageType);
    this.msgNotification.open();
  }
  HoldandRemoveDetails() {
    this.spinner.show()
    this.selectedIndex = this.showingTabs == true ? 0 : 1;
    if (this.selectedIndex == 0) {
      this.backendService.JobHoldOrRemove(this.jobRows.jobId, this.onHold, this.commentValue, 0).subscribe((res: any) => {
        res.success ? (this.sendNotification(res.message, 'success'), this.dialogRef.close()) : (this.sendNotification(res.message, 'error'), this.spinner.hide());
      },
        err => {
          console.log(err);
          this.sendNotification(err.message, 'error');
          this.spinner.hide();
        }
      )
    }
    else {
      this.backendService.JobHoldOrRemove(this.jobRows.jobId, this.onHold, this.commentValue, this.jobReleaseHoldselectedValue).subscribe((res: any) => {
        res.success ? (this.sendNotification(res.message, 'success'), this.dialogRef.close()) : (this.sendNotification(res.message, 'error'), this.spinner.hide());
      },
        err => {
          console.log(err);
          this.sendNotification(err.message, 'error');
          this.spinner.hide();
        })
    }
  }
  onCommentsChange(): void {
    this.isButtonEnabled = this.commentValue.trim().length > 0;
  }
  tabClick(tab) {
    this.selectedIndex = tab.index;
  }
  StatusChangeEvent(event) {
    const JobReleaseHoldselectedLabel = event.option.value;
    const JobReleaseHoldselectedOption = this.jobReleaseHoldStateOptions.find(option => option.label === JobReleaseHoldselectedLabel);
    if (JobReleaseHoldselectedOption) {
      this.jobReleaseHoldselectedValue = JobReleaseHoldselectedOption.value;
    }
  }
  dialogClose() {
    this.dialogRef.close("clear");
  }
}
