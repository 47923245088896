import { Component, OnInit, Inject, ViewChild, EventEmitter, Output } from '@angular/core';
import { NgxFileDropEntry, FileSystemFileEntry } from 'ngx-file-drop';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router, ActivatedRoute, NavigationStart } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { BackendService } from './../../../services/backend.service';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
    selector: 'app-form-data',
    templateUrl: './form-data.component.html',
    styleUrls: ['./form-data.component.css']
})
export class FormDataComponent implements OnInit {
    @Output()
    notify:EventEmitter<string> = new EventEmitter<string>();

    progress: number;
    FileType = "";
    formDataList: any;
    registerForm = new FormGroup({});
    public originalFile: File;
    public ListOfNames: string;
    public isDuplicate: boolean;
    public ListOfAudioFiles: NgxFileDropEntry[] = [];
    returnUrl: string;
    canUpload: boolean = false;
    MediaFileUploadUrl = "";
    
    //@ViewChild('file', { static: false }) file
    //@ViewChild('msgNotification', { static: false }) msgNotification: jqxNotificationComponent;
    removable = true;
    constructor(
        //private formBuilder: FormBuilder,
        private backendService: BackendService,
        public dialogRef: MatDialogRef<FormDataComponent>,
        private router: Router,
        private spinner: NgxSpinnerService,
        private route: ActivatedRoute,
        @Inject(MAT_DIALOG_DATA) public jobData: any) { }


    ngOnInit() {
        console.log("open form data dialog ", this.jobData)
        this.formDataList = this.jobData.formDataList;
        console.log("formDataList ", this.formDataList)
        this.router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
              // Call your dialogClose() method when navigating to a different tab
              this.dialogClose();
            }
          });
    }

    dialogClose() {
        this.dialogRef.close();
    }

    getJSONResponse(value: any) {
        return JSON.parse(JSON.stringify(value));
    }
}