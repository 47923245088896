import { jqxButtonModule } from 'jqwidgets-ng/jqxbuttons';
import { jqxPanelModule } from 'jqwidgets-ng/jqxpanel';
import { jqxCheckBoxModule } from 'jqwidgets-ng/jqxcheckbox';
import { jqxGridModule } from 'jqwidgets-ng/jqxgrid';
import { NgModule } from '@angular/core';
import { jqxDropDownListModule } from 'jqwidgets-ng/jqxdropdownlist';
import { jqxWindowModule } from 'jqwidgets-ng/jqxwindow';
import { jqxFormModule } from 'jqwidgets-ng/jqxform';
import { jqxNotificationModule  } from 'jqwidgets-ng/jqxnotification';

import { jqxDragDropModule } from 'jqwidgets-ng/jqxdragdrop'; 
import { jqxListBoxModule } from 'jqwidgets-ng/jqxlistbox';
import { jqxTooltipModule } from 'jqwidgets-ng/jqxtooltip';
import { jqxDateTimeInputModule } from 'jqwidgets-ng/jqxdatetimeinput';
import { jqxTextAreaModule } from 'jqwidgets-ng/jqxtextarea';
import { jqxExpanderModule }   from 'jqwidgets-ng/jqxexpander';

@NgModule({
   imports: [
    jqxDropDownListModule,
    jqxGridModule,
    jqxPanelModule,
    jqxCheckBoxModule,
    jqxButtonModule,
    jqxWindowModule,
    jqxFormModule,
    jqxNotificationModule,
    jqxListBoxModule ,
    jqxDragDropModule ,
    jqxTooltipModule,
    jqxDateTimeInputModule,
    jqxTextAreaModule,
    jqxExpanderModule
   ],
   exports: [
    jqxDropDownListModule,
    jqxGridModule,
    jqxPanelModule,
    jqxCheckBoxModule,
    jqxButtonModule,
    jqxWindowModule,
    jqxFormModule,
    jqxNotificationModule,
    jqxListBoxModule ,
    jqxDragDropModule ,
    jqxTooltipModule,
    jqxDateTimeInputModule,
    jqxTextAreaModule,
    jqxExpanderModule
   ],
   providers: [
   ]
})

export class JQXMaterialModule { }