import { NgxSpinnerService } from 'ngx-spinner';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { jqxDropDownListComponent } from 'jqwidgets-ng/jqxdropdownlist';
import { Component, OnInit, ViewChild, ElementRef, ViewEncapsulation } from '@angular/core';
import { jqxNotificationComponent } from 'jqwidgets-ng/jqxnotification';
import { firstValueFrom, Observable, of, zip } from 'rxjs';
import { map, startWith, tap } from 'rxjs/operators';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { environment } from "src/environments/environment";
import { jqxTextAreaComponent } from 'jqwidgets-ng/jqxtextarea';
import { DatePipe } from '@angular/common';

import Swal from 'sweetalert2';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { BackendService } from 'src/app/services/backend.service';
import { AttachmentsDialogData, EstimateDialogData, FileToUpload, MediaDialogData, OrderInputDto, Organizations } from 'src/app/models/apiRequestModels';
import { UploadOrderMediaComponent } from './upload-order-media/upload-order-media.component';
import { UploadMediaComponent } from 'src/app/upload-job/upload-media/upload-media.component';
import { NgxFileDropEntry } from 'ngx-file-drop';
import { jqxDateTimeInputComponent } from 'jqwidgets-ng/jqxdatetimeinput';
import { FileAccessService } from 'src/app/services/fileaccess.service';
import { UploadAudioFileComponent } from 'src/app/tables/table-list/upload-audiofile/upload-audiofile.component';


declare const $: any;
let departmentList;
let Audiofile = new FileToUpload();
let Attachmentfile = new FileToUpload();
let ListOfOriginalFiles = [];
let ListzipFiles = [];
let ListOfMergeAudioFiles = [];
let MergeAudioFileNameList = [];
let ListOfAttachmentFiles = [];
let ListOfZippedDocuments = [];
let ListOfZipFiles = [];
let Attachmentscount1 = 0;
let MergeAudioFilescount = 0;
let CompleteAudioFilescount = 0;
let CompleteAttachmentscount = 0;

export interface dropdownmodel {
  id: string;
  name: string;
  orderFormTypes: string;
  formName: string
}
@Component({
  selector: 'app-upload-court-order',
  templateUrl: './upload-court-order.component.html',
  styleUrls: ['./upload-court-order.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class UploadCourtOrderComponent implements OnInit {
  title = 'Create Order';
  progress: number;
  departmentControl = new FormControl();
  jobtypeControl = new FormControl();
  formtypeControl = new FormControl();
  authorControl = new FormControl();
  Sensitive = new FormControl();
  defaultRadioButtonControl = new FormControl();
  CertificateRequired = new FormControl();
  LanguageControl = new FormControl();
  departmentOptions: any[];
  deptfilteredOptions: Observable<dropdownmodel[]>;
  jobTypeOptions: any[];
  jobTypefilteredOptions: Observable<dropdownmodel[]>;
  formTypeOptions: any[];
  formTypefilteredOptions: Observable<dropdownmodel[]>;
  authorOptions: any[];
  authorfilteredOptions: Observable<dropdownmodel[]>;
  orderCreationForm: FormGroup;
  selectedFormTypeId: number;
  selectedJobTypeExtId = "";
  selectedJobTypeDisplayName = "";
  selectedDepartmentId = "";
  selectedDeptExtId = "";
  selectedDeptID: Number;
  selectedAuthorId = "";
  selectedAuthorName = "";
  selectedAuthorExtId = "";
  selectedFormTypeName = "";
  selectedDepartmentName = "";
  formTypeValues: any;
  departmentValues = [];
  authorValues = [];
  showFormFields = false;
  formfieldsList = [];
  JobId = "";
  messages: string[] = [];
  maxdate: any;
  selectedDate: any;
  hearingDate: any;
  mode: string;
  interval: any;
  fileUpload = new FormControl();
  removable = true;
  FormName: string;
  organizationControl = new FormControl();
  organizationfilteredOptions: Observable<Organizations[]>;
  organizationOptions: any[];
  DepartmentDetails: any;
  FormFieldsGroup = new FormGroup({});
  selectedOrgExtId = "";
  uploadMediaDialog: MatDialogRef<UploadMediaComponent>;
  mediaDialogData: any;
  attachmentsDialogData: any;
  estimateDialogData: any;
  orderData: any;
  orderdetails: any;
  isEditMode = false;
  buttonText = 'Create Order'
  isshowEstimation: boolean = false;
  public ListOfAudioFiles: NgxFileDropEntry[] = [];
  ListOfAttachments: FileToUpload[] = [];
  ListOfAudioFiles1: FileToUpload[] = [];
  public originalFile: File;
  FileType = "";
  IsMergeAudios = false;
  totalEstimateAmount: number;
  numberOfMins: number;
  jobCostMinuteRateEstimation = "";
  receiptEmail: string;
  estimateJobTypeExtId = "";
  estimateJobTypeDisplayName = "";
  selectedIndex: number;
  public isDuplicate: boolean;
  fileCount: number;
  public ListOfNames: string;
  maxNumberOfFiles: number;
  exceedFileLimit: boolean;
  dialogRef: MatDialogRef<UploadAudioFileComponent>;





  @ViewChild('formTypeList', { static: false }) formTypeList: jqxDropDownListComponent;
  @ViewChild('departmentList', { static: false }) departmentList: jqxDropDownListComponent;
  @ViewChild('authorList', { static: false }) authorList: jqxDropDownListComponent;
  @ViewChild('msgNotification', { static: false }) msgNotification: jqxNotificationComponent;
  @ViewChild('fileUploader', { static: false }) fileUploader: ElementRef;
  @ViewChild('file', { static: false }) file;
  @ViewChild('myDateTimeInput', { static: false }) myDateTimeInput: jqxDateTimeInputComponent;

  constructor(
    private backendService: BackendService,
    private spinner: NgxSpinnerService,
    public dialog: MatDialog,
    private datePipe: DatePipe,
    private router: Router,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
     private fileacces: FileAccessService,
  ) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.title = params['title'] || 'Create Order';
      console.log('Title received from query params:', this.title);
      // Optional: Load based on title or other param logic
      if (this.title === 'Update Order') {
        this.isEditMode = true;
        this.buttonText = 'Update Order'
        setTimeout(() => {
          this.loadEditOrder();
        }, 3000);
        // self.clearData();
      }
    });
    this.route.queryParams.subscribe(params => {
      if (params['title']) {
        this.title = params['title'];
      }
    });
    var isUserlogin = this.backendService.CheckIsUserLoggedIn();
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        Swal.close();
      }
    });
    if (isUserlogin) {
      this.spinner.show();
      this.loadOrganizations();
      this.maxdate = new Date();
      this.clear();
      this.originalFile = null;
      this.ListOfNames = "";
      this.maxNumberOfFiles = 100;
      this.exceedFileLimit = false;
      var dateevent = new Date();
      this.organizationControl = new FormControl('');
      this.orderCreationForm = this.formBuilder.group({
        ishighPriority: new FormControl(false),
        language: new FormControl(''),
        caseNumber: new FormControl(null),
        jurisdiction: new FormControl(''),
      });
    }


  }
  loadEditOrder() {
    this.orderData = this.backendService.orderDetails;
    if (this.orderData == "") {
      this.goToOrderList();
    }
    // if (this.orderData != '') {
    //     this.isEditMode = true;
    //     this.title = 'Update Order';
    //     this.buttonText = 'Update';
    // }
    if (!this.isEditMode) {


      this.markAsUnTouched(true);
      this.spinner.hide();
    } else {
      this.orderdetails = this.orderData;
      console.log(" Order details: ", this.orderdetails)
      // this.orderCreationForm.setValue["ishighPriority"] = this.orderdetails.highPriority
      // const selectedFormType = this.findFormTypeById(this.orderdetails.orderFormId);

      // if (selectedFormType) {
      //   this.formtypeControl.setValue(selectedFormType);
      // } else {
      //   console.warn(`Form Type with ID ${this.orderdetails.orderFormId} not found`);
      // }
      // const item = this.formTypeList.getItemByValue(this.orderdetails.orderFormId);
      this.orderCreationForm.setValue({
        ishighPriority: this.orderdetails.highPriority,
        language: this.orderdetails.language,
        caseNumber: this.orderdetails.caseNumber,
        jurisdiction: '',
      });
      this.spinner.hide();
    }
  }
  ngAfterViewInit(): void {
  }


  private departmentfilter(name: any): dropdownmodel[] {
    const filterValue = name.toLowerCase();
    return this.departmentOptions.filter(option => option.name.toLowerCase().indexOf(filterValue) >= 0);
  }
  private jobTypefilter(name: any): dropdownmodel[] {
    const filterValue = name.toLowerCase();
    return this.jobTypeOptions.filter(option => option.name.toLowerCase().indexOf(filterValue) >= 0);
  }
  private formTypefilter(name: any): dropdownmodel[] {
    const filterValue = name.toLowerCase();
    return this.formTypeOptions.filter(option => option.formName.toLowerCase().indexOf(filterValue) >= 0);
  }
  private authorfilter(name: any): dropdownmodel[] {
    const filterValue = name.toLowerCase();
    return this.authorOptions.filter(option => option.name.toLowerCase().indexOf(filterValue) >= 0);
  }
  displayFn(dropdownmodel: dropdownmodel): string {
    return dropdownmodel && dropdownmodel.name ? dropdownmodel.name : '';
  }
  displayFnForOrganization(dropdownmodel: Organizations): string {
    return dropdownmodel && dropdownmodel.customerName ? dropdownmodel.customerName : '';
  }
  displayFnForFormTypes(dropdownmodel: dropdownmodel): string {
    return dropdownmodel && dropdownmodel.formName ? dropdownmodel.formName : '';
  }
  jobtypeValuesList = []
  async loadJobTypeValues(departmentId) {
    let jobTypeArray = [];
    this.jobtypeValuesList = [];
    var JobTypeDetails = await this.backendService.getDepartmentJobTypeValues(departmentId).toPromise();
    var jobtypeList = this.backendService.getJSONResponse(JobTypeDetails['data'].result);
    jobtypeList = jobtypeList.filter(item => item.externalId !== "Default");
    this.jobtypeValuesList = jobtypeList;
    for (let jobtype of jobtypeList) {
      let data = { 'id': jobtype.id, 'name': jobtype.displayName, 'externalid': jobtype.externalId, 'firstDraftEnabled': jobtype.firstDraftEnabled }
      jobTypeArray.push(data);
    }
    jobTypeArray.sort((a, b) => a.name.localeCompare(b.name));
    this.jobTypeOptions = jobTypeArray;
    this.jobTypefilteredOptions = this.jobtypeControl.valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value['name']),
        map(name => name ? this.jobTypefilter(name) : this.jobTypeOptions.slice()),
      );
    if (this.jobTypeOptions.length == 1) {
      this.jobtypeControl.setValue(this.jobTypeOptions[0]);
      this.selectedJobTypeExtId = this.jobTypeOptions[0].externalid;
      this.selectedJobTypeDisplayName = this.jobTypeOptions[0].name;
    }
  }
  async loadFormTypeValues(departmentId) {
    const depts = await firstValueFrom(this.deptfilteredOptions);
    const selectedDepartment = depts.find(dept => dept.id === departmentId);

    if (selectedDepartment != null) {
      this.formTypeOptions = JSON.parse(selectedDepartment.orderFormTypes);
      console.log("this.formTypeOptions::", this.formTypeOptions);
      this.formTypefilteredOptions = this.formtypeControl.valueChanges
        .pipe(
          startWith(''),
          map(value => typeof value === 'string' ? value : value['formName']),
          map(formName => formName ? this.formTypefilter(formName) : this.formTypeOptions.slice()),
        );
      if (this.formTypeOptions.length == 1) {
        this.formtypeControl.setValue(this.formTypeOptions[0]);
        this.selectedFormTypeId = this.formTypeOptions[0].id;
        this.formTypeChangeEvent(this.selectedFormTypeId);
      }
    } else {
      this.sendNotification("No Form Types From this Department", "error");
    }
    this.spinner.hide();
  }
  goToOrderList() {
    const route = '/admin/courtorders';
    this.router.navigate([route]); // Correct way to navigate
  }

  departmentSingleOptionChangeEvent(event: any): void {
    if (event.id) {
      this.selectedDepartmentId = event.id;
      this.selectedDeptExtId = event.deptExtId;
      this.selectedDeptID = event.deptId;
      this.formtypeControl.setValue('');
      this.authorControl.setValue('');
      this.loadAuthors(event.id, event.deptExtId);
      this.loadJobTypeValues(event.id);
      this.loadFormTypeValues(event.id);
      this.isshowEstimation = true;
      localStorage.setItem('departments', event.id);
    }
  };
  authorValuesList = []
  async loadAuthors(customerId, deptExtId) {
    let authorArray = [];
    this.authorValuesList = [];
    let AuthorDetails;
    await this.backendService.getAuthors(customerId, deptExtId).subscribe(
      res => {
        let NCauthorListList = this.backendService.getJSONResponse(res['data'].authorList);
        if (NCauthorListList != null) {
          if (NCauthorListList.length != 0) {
            const authorList = NCauthorListList;
            this.authorValuesList = authorList;
            for (let author of authorList) {
              let data = { 'id': author.id, 'name': author.authorKey + ' (' + author.authorValue + ')' }
              authorArray.push(data);
            }
            this.authorOptions = authorArray;
            this.authorfilteredOptions = this.authorControl.valueChanges
              .pipe(
                startWith(''),
                map(value => typeof value === 'string' ? value : value['name']),
                map(name => name ? this.authorfilter(name) : this.authorOptions.slice()),
              );
            if (this.authorOptions.length == 1) {
              this.authorControl.setValue(this.authorOptions[0]);
              this.selectedAuthorId = this.authorOptions[0].id;
              this.selectedAuthorName = "";
              this.selectedAuthorExtId = "";
            }
          }
        }
        let NetScribeauthorList = this.backendService.getJSONResponse(res['data'].nsAuthorList);
        if (NetScribeauthorList != null) {
          if (NetScribeauthorList.length != 0) {
            const authorList = NetScribeauthorList;
            this.authorValuesList = authorList;
            for (let author of authorList) {
              let data = { 'id': author.customerId, 'name': author.authorKey + ' (' + author.authorValue + ')', 'authorKey': author.authorKey, 'authorValue': author.authorValue }
              authorArray.push(data);
            }
            authorArray.sort((a, b) => a.name.localeCompare(b.name));
            this.authorOptions = authorArray;
            this.authorfilteredOptions = this.authorControl.valueChanges
              .pipe(
                startWith(''),
                map(value => typeof value === 'string' ? value : value['name']),
                map(name => name ? this.authorfilter(name) : this.authorOptions.slice()),
              );
            if (this.authorOptions.length == 1) {

              this.authorControl.setValue(this.authorOptions[0]);
              this.selectedAuthorId = "";
              this.selectedAuthorName = this.authorOptions[0].authorKey;
              this.selectedAuthorExtId = this.authorOptions[0].authorValue;
            }
          }
          else {
            this.sendNotification('Unable to get Authors From NetScribe, Please check in NetScribe.', 'error');
          }
        }
        this.spinner.hide();
      },
      err => {
        const er = this.backendService.getJSONResponse(err);
        this.sendNotification('Unable to get Authors From NetScribe, Please check in NetScribe.', 'error');
        this.backendService.AWSLog("error", "Problem occurred while getting author list.: " + err).subscribe();
        this.spinner.hide();
      }
    );
  };
  loadAuthorValues(customerId) {
    let authorArray = [];
    this.authorValuesList = [];
    this.backendService.getAuthors(customerId, null).subscribe(
      res => {
        this.spinner.hide()
        const authorList = this.backendService.getJSONResponse(res).data.authorList;
        this.authorValuesList = authorList;
        for (let author of authorList) {
          let data = { 'id': author.id, 'name': author.authorKey + ' (' + author.authorValue + ')' }
          authorArray.push(data);
        }
        this.authorOptions = authorArray;
        this.authorfilteredOptions = this.authorControl.valueChanges
          .pipe(
            startWith(''),
            map(value => typeof value === 'string' ? value : value['name']),
            map(name => name ? this.authorfilter(name) : this.authorOptions.slice()),
          );
        if (this.authorOptions.length == 1) {
          this.authorControl.setValue(this.authorOptions[0]);
          this.selectedAuthorId = this.authorOptions[0].id;
          this.selectedAuthorName = this.authorOptions[0].authorKey;
          this.selectedAuthorExtId = this.authorOptions[0].authorValue;
        }
      }
    );
  }
  async loadFormTypeDetails(formTypeId) {
    this.showFormFields = false;
    this.selectedFormTypeId = formTypeId;
    this.backendService.getformsandadditionalfields(formTypeId).subscribe(response => {
      console.log('Response:', response);
      let fieldsListresponse;
      fieldsListresponse = this.backendService.getJSONResponse(response); // Ensure this function returns the expected JSON
      this.formfieldsList = fieldsListresponse.data.formFields;
      this.formfieldsList.forEach(formfield => {
        this.FormFieldsGroup.addControl(formfield.propertyName, new FormControl());
        if (formfield.fieldType == 'dropdown') {
          formfield.options = JSON.parse(formfield.options)
        }
      });
      this.spinner.hide();
    });
    this.showFormFields = true;

  }

  sendNotification(message, messageType) {
    this.msgNotification.elementRef.nativeElement.querySelector('.jqx-notification-content').innerHTML = message;
    this.msgNotification.template(messageType);
    this.msgNotification.open();
  }
  jobTypeChangeEvent(event: any): void {
    if (event) {
      this.selectedJobTypeExtId = event.externalid;
      this.selectedJobTypeDisplayName = event.name;
    }
  };
  formTypeChangeEvent(id: any): void {
    if (id) {
      this.spinner.show();
      this.selectedFormTypeId = id;
      this.loadFormTypeDetails(this.selectedFormTypeId);
    }
  };
  departmentChangeEvent(event: any): void {
    if (event.option.value.id) {
      this.spinner.show();
      this.selectedDepartmentId = event.option.value.id;
      this.selectedDeptExtId = event.option.value.deptExtId;
      this.selectedDeptID = event.option.value.deptId;
      this.loadAuthors(event.option.value.id, event.option.value.deptExtId);
      this.loadJobTypeValues(event.option.value.id);
      this.loadFormTypeValues(event.option.value.id);
      this.departmentOptions[0] = event.option.value;
      this.isshowEstimation = (this.selectedDepartmentId =="" && this.selectedDeptExtId == "") ? false : true;
      localStorage.setItem('departments', event.option.value.id);

      this.spinner.hide();
    }
  };
  loadAuthorValuesByDeptExtId(deptExtId) {
    let authorArray = [];
    this.authorValuesList = [];
    this.backendService.getAuthorsByDeptExtId(deptExtId).subscribe(
      res => {
        this.spinner.hide()
        const authorList = this.backendService.getJSONResponse(res).data.nsAuthorList;
        this.authorValuesList = authorList;
        for (let author of authorList) {
          let data = { 'id': author.customerId, 'name': author.authorKey + ' (' + author.authorValue + ')', 'authorKey': author.authorKey, 'authorValue': author.authorValue }
          authorArray.push(data);
        }
        this.authorOptions = authorArray;
        this.authorfilteredOptions = this.authorControl.valueChanges
          .pipe(
            startWith(''),
            map(value => typeof value === 'string' ? value : value['name']),
            map(name => name ? this.authorfilter(name) : this.authorOptions.slice()),
          );
        if (this.authorOptions.length == 1) {

          this.authorControl.setValue(this.authorOptions[0]);
          this.selectedAuthorId = "";
          this.selectedAuthorName = this.authorOptions[0].authorKey;
          this.selectedAuthorExtId = this.authorOptions[0].authorValue;
        }
      },
      err => {
        const er = this.backendService.getJSONResponse(err);
        this.sendNotification('Problem occurred while checking author list. ' + JSON.stringify(er), 'error');
        this.backendService.AWSLog("error", "Problem occurred while checking author list.: " + err).subscribe();
        this.spinner.hide();
      }
    );
  }
  clearDropdownSelection() {
    this.selectedDepartmentId = '';
    this.selectedDeptExtId = '';
    this.selectedDeptID = null;
    this.selectedFormTypeId = null;
    this.selectedAuthorId = '';
    this.selectedAuthorExtId = '';
    this.selectedAuthorName = '';
    this.selectedOrgExtId = '';
  }
  clearDropdownValues() {
    return of([])
  }
  departmentChange() {
    this.selectedDepartmentId = '';
    this.selectedDeptExtId = '';
    this.selectedDeptID = null;
    this.selectedFormTypeId = null;
    this.selectedAuthorId = '';
    this.selectedAuthorName = '';
    this.selectedAuthorExtId = '';
    this.selectedJobTypeExtId = '';
    this.selectedJobTypeDisplayName = '';
    this.showFormFields = false;
    this.jobtypeControl.setValue('');
    this.formtypeControl.setValue('');
    this.authorControl.setValue('');
    this.jobTypefilteredOptions = this.clearDropdownValues();
    this.formTypefilteredOptions = this.clearDropdownValues();
    this.authorfilteredOptions = this.clearDropdownValues();
    this.isshowEstimation = (this.selectedDepartmentId =="" && this.selectedDeptExtId == "") ? false : true;
  }
  jobtypeChange() {
    this.selectedJobTypeExtId = '';
    this.selectedJobTypeDisplayName = '';
    this.jobtypeControl.setValue('');
  }
  formtypeChange() {
    this.selectedFormTypeId = null;
    this.showFormFields = false;
    this.formtypeControl.setValue('');
  }
  authorChange() {
    this.selectedAuthorId = '';
    this.selectedAuthorExtId = '';
    this.selectedAuthorName = '';
  }
  authorChangeEvent(event: any): void {
    if (event) {
      this.selectedAuthorName = event.authorKey;
      this.selectedAuthorExtId = event.authorValue;
      this.selectedAuthorId = event.id;
    }
  };

  isValidFormFieldsGroup(controlName, error) {
    return this.FormFieldsGroup.controls[controlName] && this.FormFieldsGroup.controls[controlName].hasError(error);
  }
  isValid(controlName, error) {
    return this.orderCreationForm && this.orderCreationForm.controls[controlName].hasError(error);
  }
  markAsTouched(value: boolean) {
    Object.keys(this.FormFieldsGroup.controls).forEach(field => {
      const control = this.FormFieldsGroup.get(field);
      if (control instanceof FormControl) {
        if (this.isValidFormFieldsGroup(field, 'required')) {
          this.FormFieldsGroup.controls[field].markAsTouched({ onlySelf: value });
        }
      }
    });
  }
  clearJobDataSelection() {
    this.mode = "clear";
    this.sweetalert();
  }
  sweetalert() {
    var reqsubstring = "";
    if (this.mode == "clear") {
      reqsubstring = "Are you sure, you want to clear the selected data? All the selected and/or recorded files will be deleted.";
    }
    Swal.fire({
      text: reqsubstring,
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: `Yes`,
      cancelButtonText: `No`,
      denyButtonText: `Cancel`,
      confirmButtonColor: '#187EB1',
      cancelButtonColor: '#5b5b5b',
    }).then((result) => {
      console.log("result", result.isDismissed);
      if (result.value) {
        if (this.mode == "clear") {
          this.clear();
        }
      }
    })
  }
  clear() {
    this.remove();
    this.removeJobAttachments();
    this.clearInputData();
    this.markAsUnTouched(true);
  }
  remove() {
    // this.departmentControl.setValue('');
    // this.authorControl.setValue('');
    // this.jobtypeControl.setValue('');
    // this.organizationControl.setValue('');
    // this.formtypeControl.setValue('');
    this.hearingDate = null;
    this.ListOfAudioFiles = [];
    this.ListOfAudioFiles1 = [];
    ListOfOriginalFiles = [];
    this.originalFile = null;
    ListzipFiles = [];
    ListOfMergeAudioFiles = [];
    MergeAudioFileNameList = [];
    ListOfAttachmentFiles = [];
    this.receiptEmail = "";
    this.numberOfMins = null;
    this.totalEstimateAmount = null;
    ListOfZipFiles = [];
    this.ListOfNames = "";
    this.file = null;
    this.hearingDate = new Date();
    //this.ListOfAttachments = null;
  }


  markAsUnTouched(value: boolean) {
    Object.keys(this.FormFieldsGroup.controls).forEach(field => {
      const control = this.FormFieldsGroup.get(field);
      if (control instanceof FormControl) {
        if (this.isValidFormFieldsGroup(field, 'required')) {
          this.FormFieldsGroup.controls[field].markAsUntouched({ onlySelf: value });
        }
      }
    });
  }
  clearDropDownData() {
    this.formTypeList.uncheckAll();
    this.departmentList.uncheckAll();
    this.authorList.uncheckAll();
    this.selectedAuthorId = '';
    this.selectedAuthorName = '';
    this.selectedAuthorExtId = '';
    this.selectedDepartmentId = '';
    this.selectedDeptExtId = '';
    this.selectedDeptID = null;
    this.selectedFormTypeId = null;
  }
  clearInputData() {
    Object.keys(this.FormFieldsGroup.controls).forEach(field => {
      var fielddetails = this.formfieldsList.filter(x => x.propertyName == field);
      if (fielddetails[0].defaultValue == null || fielddetails[0].defaultValue == "") {
        const control = this.FormFieldsGroup.get(field);
        if (control instanceof FormControl) {
          this.FormFieldsGroup.controls[field].setValue('');
        }
      }
    });
  }
  validationBeforeSubmit() {
    let message = '';
    let result = true;
    if (this.selectedOrgExtId.trim() == '') {
      message = 'Please select organization.';
      result = false;
    }
    else if (this.selectedDepartmentId.trim() == '') {
      message = 'Please select a Department.';
      result = false;
    }
    else if (this.selectedJobTypeExtId == null) {
      message = 'Selected Job Type does not have External Id.';
      result = false;
    }
    else if (this.selectedJobTypeExtId.trim() == '') {
      message = 'Please select a Job Type.';
      result = false;
    }
    else if (this.selectedAuthorExtId.trim() == '') {
      message = 'Please select a Requestor.';
      result = false;
    }
    else if (this.authorValuesList.length > 0) {
      if (environment.region == "ap-southeast-2") {
        //Production-Australia Environment
        if (this.selectedAuthorExtId.trim() == '') {
          message = 'Please select an Author';
          result = false;
        }
      }
      else {
        if (this.selectedAuthorId.trim() == '' && this.selectedAuthorExtId.trim() == '') {
          message = 'Please select an Author';
          result = false;
        }
      }
    }
    else if (this.orderCreationForm.controls.caseNumber.value == '') {
      message = 'Please enter caseNumber.';
      result = false;
    }
    else if (this.orderCreationForm.controls.language.value == '') {
      message = 'Please enter language.';
      result = false;
    }
    else if (this.selectedFormTypeId == null) {
      message = 'Please select a Form Type.';
      result = false;
    }
    if (!result)
      this.sendNotification(message, 'error');
    return result;
  }
  convertStartDatetoUnix(date) {
    var reqdate = this.datePipe.transform(date, 'yyyy-MM-dd');
    //current time
    var currenttime = new Date();
    var time = this.datePipe.transform(currenttime, 'HH:mm:ss');
    //Combine both date and time
    var datetime = reqdate + "T" + time;
    let newDate = new Date(datetime);
    var reqstartDate = this.datePipe.transform(newDate, 'yyyy-MM-dd HH:mm:ss');
    var StringtoDate = new Date(reqstartDate);
    this.selectedDate = (StringtoDate.getTime() / 1000);
    this.selectedDate = this.selectedDate + 1;
  }
  async createOrder() {
    this.spinner.show();
    var date = this.myDateTimeInput.val();
    var hearingDate = this.datePipe.transform(date, 'yyyy-MM-dd HH:mm:ss');
    this.hearingDate = hearingDate;
    this.markAsTouched(true);
    if (this.validationBeforeSubmit() && this.FormFieldsGroup.valid) {
      const ordercreatemodel = this.createorderModel();
      ordercreatemodel.attachmentFiles = this.ListOfAttachments;
      ordercreatemodel.audioFiles = this.ListOfAudioFiles1;
      ordercreatemodel.numberofMinutesforEstimation = this.numberOfMins;
      ordercreatemodel.totalEstimationCost = this.totalEstimateAmount;
      ordercreatemodel.perMinuteEstimationRate = this.jobCostMinuteRateEstimation;
      this.backendService.OrderCreate(ordercreatemodel).subscribe(
        res => {
          const result = this.backendService.getJSONResponse(res);
          console.log("result", result);
          if(result.data.multipleAudioFilesUrl == null && result.data.orderId != null){
            this.sendNotification("Order Created Sucessfully", "success");
            this.clearInputData();
            this.spinner.hide();
          }
          else{
            if (this.ListOfAudioFiles1.length > 0) {
              this.uploadMergeAudioFiles(result.data[0].multipleAudioFilesUrl, result.data[0].orderAttachmentUrl);
            }
            if (this.ListOfAttachments.length > 0) {
              this.uploadAttachments(result.data[0].orderAttachmentUrl);
            }
            this.sendNotification("Order Created Sucessfully", "success");
            this.clearInputData();
          }
        },
        err => {
          const er = this.backendService.getJSONResponse(err);
          this.backendService.AWSLog("error", "Problem occurred while submitting the job with filename: Merge file Actual Error : " + err).subscribe();
          this.spinner.hide();
        }
      );
    }
    else {
      console.log('invalid')
      localStorage.removeItem('spinnerStatus');
      localStorage.setItem('spinnerStatus', "false");
      this.spinner.hide();
    }
  }
  async uploadAttachmentOnlyToBucket(filedetails, attachmenturl) {
      this.progress = 0;
      const fileObject = {
        name: filedetails.name,
        size: (filedetails.size / (1024 * 1024)).toFixed(2) + ' MB',
        progress: 0,
        path: null
      };
  
      (await this.fileacces.uploadWithProgress(attachmenturl, filedetails)).subscribe({
        next: (event: any) => {
          this.spinner.show();
          if(event.type === 'progress') {
            this.progress = event.value;
          } else if(event.type === 'response') {
            CompleteAttachmentscount = CompleteAttachmentscount + 1;
          this.progress = null;
          if (Attachmentscount1 == CompleteAttachmentscount) {
            if (Attachmentscount1 == 1) {
              Swal.fire(Attachmentscount1 + ' - Attachment Uploaded successfully.', '', 'success');
            }
            else {
              Swal.fire(Attachmentscount1 + ' - Attachments Uploaded successfully.', '', 'success');
            }
          }
          this.spinner.hide();
          }
        },
        error: (err) => {
          console.log(err);
          this.progress = null;
          Swal.fire('Attachment file upload failed.', '', 'error');
          this.backendService.AWSLog("error", this.JobId + "Error - uploadAttachmentOnlyToBucket Method :::  " + JSON.stringify(err)).subscribe();
          this.removeFileByName(filedetails.name);
          this.spinner.hide();
        }
      });
    }

    async uploadAttachmentToBucket(filedetails, attachmenturl, filepath, NCJobId, jobAttachmentUrl) {
        this.progress = 0;
        const fileObject = {
          name: filedetails.name,
          size: (filedetails.size / (1024 * 1024)).toFixed(2) + ' MB',
          progress: 0,
          path: null
        };
        (await this.fileacces.uploadWithProgress(attachmenturl, filedetails)).subscribe({
          next: (event: any) => {
            if(event.type === 'progress') {
              this.progress = event.value;
            } else if(event.type === 'response') {
              CompleteAudioFilescount = CompleteAudioFilescount + 1;
              this.progress = null;
              if (MergeAudioFilescount == CompleteAudioFilescount && NCJobId != null) {
                //console.log("MergeAudioFileProcess... NCJobId :::" + NCJobId + " FilePath :::: " + filepath)
                if (MergeAudioFilescount == 1) {
                  Swal.fire(MergeAudioFilescount + ' - File submitted successfully.', '', 'success');
                } else {
                  Swal.fire(MergeAudioFilescount + ' - Files submitted successfully.', '', 'success');
                }
                this.sendNotification("Order Created Sucessfully", "success");
                this.clearInputData();
                this.spinner.hide();
                this.spinner.hide();
                this.clear();
              }
            }
          },
          error: (err) => {
            console.log(err);
            this.progress = null;
            this.sendNotification('Audio file upload failed.', 'error');
            // this.backendService.AWSLog("error", this.JobId + "Error - uploadAudioFileToBucket Method :::  " + JSON.stringify(err)).subscribe();
            // this.removeFileByName(filedetails.name);
            // var title = "Failed to upload the media file, please upload it again.";
            // this.openDialog(this.JobId, title);
            this.spinner.hide();
          }
        });  
     
      }
    async MergeAudioFileProcess(jobId, bucketPath, filenames, jobAttachmentUrl) {
      this.backendService.MergeAudioFilesToNSW(jobId, bucketPath, filenames).subscribe(
        res => {
          this.spinner.hide();
        },
        err => {
          const er = this.backendService.getJSONResponse(err);
        }
      );
    }
    // openDialog(jobId, title): void {
    //     if (this.dialogRef == null) {
    //       this.dialogRef = this.dialog.open(UploadAudioFileComponent, {
    //         width: '35%',
    //         data: { "jobId": jobId, "title": title },
    //         disableClose: true
    //       });
    //       this.dialogRef.afterClosed().subscribe(result => {
    //         this.dialogRef = null;
    //       });
    //     }
    //   }
  async uploadMergeAudioFiles(jobMergeAudioFilesUrl, jobAttachmentUrl) {
    let listofAttachmentsUrls = [];
    var splitted;
    listofAttachmentsUrls = jobMergeAudioFilesUrl;
    var counter = 0;
    var filepath;
    listofAttachmentsUrls.forEach(async attachmenturl => {
      counter = counter + 1;
      splitted = attachmenturl.split("/", 7);
      var AttachmentFileName = splitted[5];
      AttachmentFileName = AttachmentFileName.substring(0, AttachmentFileName.indexOf("?"));
      ListOfMergeAudioFiles.forEach(async AudioFile => {
        if (AttachmentFileName == AudioFile.FileName) {
          MergeAudioFilescount = MergeAudioFilescount + 1;
          filepath = splitted[3] + "/" + splitted[4];
          await this.uploadAttachmentToBucket(AudioFile, attachmenturl, filepath, splitted[4], jobAttachmentUrl);
          this.spinner.hide();
        }
      });
    });
  };
  async uploadAttachments(jobAttachmentUrl) {
    let listofAttachmentsUrls = [];
    var Attachmentscount = 0;
    var splitted;
    listofAttachmentsUrls = jobAttachmentUrl;
    listofAttachmentsUrls.forEach(attachmenturl => {
      splitted = attachmenturl.split("/", 7);
      var AttachmentFileName = splitted[5];
      AttachmentFileName = AttachmentFileName.substring(0, AttachmentFileName.indexOf("?"));
      ListOfAttachmentFiles.forEach(attachment => {
        if (AttachmentFileName == attachment.filename) {
          Attachmentscount = Attachmentscount + 1;
          Attachmentscount1 = Attachmentscount;
          this.uploadAttachmentOnlyToBucket(attachment, attachmenturl);
        }
      });
    });
    this.spinner.hide();
  };
  createorderModel() {
    let id = localStorage.getItem('id')
    const ordercreatemodel = new OrderInputDto();
    ordercreatemodel.deptId = this.selectedDeptID;
    ordercreatemodel.organizationExtId = this.selectedOrgExtId;
    ordercreatemodel.departmentExtId = this.selectedDeptExtId;
    ordercreatemodel.jobTypeExtId = this.selectedJobTypeExtId;
    ordercreatemodel.authorExtId = this.selectedAuthorExtId;
    ordercreatemodel.jurisdiction = '';
    ordercreatemodel.customerTat = "2025-03-01 00:00:00";
    ordercreatemodel.caseNumber = this.orderCreationForm.controls.caseNumber.value ? this.orderCreationForm.controls.caseNumber.value : "";
    ordercreatemodel.hearingDate = this.hearingDate;
    ordercreatemodel.format = ".mp3"
    ordercreatemodel.language = this.orderCreationForm.controls.language.value ? this.orderCreationForm.controls.language.value : "";
    ordercreatemodel.highPriority = this.orderCreationForm.controls.ishighPriority.value ? this.orderCreationForm.controls.ishighPriority.value : false;
    ordercreatemodel.orderFormId = this.selectedFormTypeId;
    ordercreatemodel.CreatedBy = Number(id);
    ordercreatemodel.formData = {
      FormFieldsData: JSON.stringify(this.FormFieldsGroup.value)
    };;
    ordercreatemodel.authorName = this.selectedAuthorName;
    ordercreatemodel.jobTypeDisplayName = this.selectedJobTypeDisplayName;
    return ordercreatemodel;
  }
  onValueChanged(event: any): void {
    var selecteddate = event.args.date;
    console.log("selected selecteddate ::: ", selecteddate);
    this.hearingDate = this.datePipe.transform(selecteddate, 'yyyy-MM-dd HH:mm:ss');
  }

  onProgressUpdate(newProgress) {
    this.progress = newProgress.progress;
  }
  organizationChange() {
    this.departmentControl.setValue('');
    this.selectedOrgExtId = '';
    this.selectedDepartmentId = '';
    this.selectedDeptExtId = '';
    this.selectedDeptID = null;
    this.selectedFormTypeId = null;
    this.selectedAuthorId = '';
    this.selectedAuthorName = '';
    this.selectedAuthorExtId = '';
    this.selectedJobTypeExtId = '';
    this.selectedJobTypeDisplayName = '';
    this.showFormFields = false;
    this.jobtypeControl.setValue('');
    this.formtypeControl.setValue('');
    this.authorControl.setValue('');
    this.deptfilteredOptions = this.clearDropdownValues();
    this.jobTypefilteredOptions = this.clearDropdownValues();
    this.formTypefilteredOptions = this.clearDropdownValues();
    this.authorfilteredOptions = this.clearDropdownValues();
  }
  async organizationChangeEvent(event) {
    this.spinner.show();
    if (event.option.value.id) {
      let item = event.option.value;
      this.selectedOrgExtId = item.custExtId;
      localStorage.setItem('estimationMessage',item.estimationMessage);
      if (item) {
        await this.loadDepartments(item.custExtId, item.tenantId);
        this.spinner.hide();
      }
      else {
        this.spinner.hide();
      }
    }

  };
  async loadDepartments(OrgExtId, TenantId) {
    this.departmentOptions = [];
    const dept_res = await this.loadDepartmentValues(OrgExtId, TenantId);
    departmentList = this.backendService.getJSONResponse(dept_res).data.departmentList;
    this.DepartmentDetails = departmentList;
    for (let department of this.DepartmentDetails) {
      //TODO: FD
      let data = {
        'id': department.id,
        'name': department.name,
        'deptExtId': department.deptExtId,
        'isFutureOrder': department.isFutureOrder,
        'isForeignLanguage': department.isForeignLanguage,
        'isForeignLanguageFirstDraft': department.isForeignLanguageFirstDraft,
        'isSensitive': department.isSensitive,
        'isCertification': department.isCertification,
        'isNotarization': department.isNotarization,
        'defaultForeignLanguageService': department.defaultForeignLanguageService,
        'defaultSourceForeignLanguage': department.defaultSourceForeignLanguage,
        'allowMultipleJobs': department.allowMultipleJobs,
        'isUploadProgressively': department.isUploadProgressively,
        'orderFormTypes': department.orderFormTypes,
        'deptId': department.deptId
      }
      this.departmentOptions.push(data);
    }
    this.deptfilteredOptions = this.departmentControl.valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value['name']),
        map(name => name ? this.departmentfilter(name) : this.departmentOptions.slice()),
      );
    if (this.departmentOptions.length == 1) {
      this.departmentControl.setValue(this.departmentOptions[0]);
      this.selectedDepartmentId = this.departmentOptions[0].id;
      this.selectedDeptExtId = this.departmentOptions[0].deptExtId;
      this.selectedDeptID = this.departmentOptions[0].deptId;
      this.departmentSingleOptionChangeEvent(this.departmentOptions[0]);
    }
  }
  loadDepartmentValues(OrgExtId, TenantId) {
    return this.backendService.getDepartmentsByOrganization(OrgExtId, TenantId).toPromise();
  }
  async loadOrganizations() {
    const orgs_res = await this.loadOrganizationValues();
    this.loadOrgsValuesForDropDown(orgs_res);
  }
  loadOrganizationValues() {
    this.organizationOptions = [];
    return this.backendService.getCustomers().toPromise();
  }
  loadOrgsValuesForDropDown(org_res) {
    this.organizationOptions = [];
    let orgsList = this.backendService.getJSONResponse(org_res).data.customerList;
    for (let org of orgsList) {
      let data = {
        "id": org.id,
        "customerName": org.customerName + ' (' + org.tenantName + ')',
        "custExtId": org.custExtId,
        "directBucket": org.directBucket,
        "dbId": org.dbId,
        "ncurl": org.ncurl,
        "tenantId": org.tenantId,
        "tenantName": org.tenantName,
        "emailTemplateId": org.emailTemplateId,
        "sourceType": org.sourceType,
        "isFutureOrder": org.isFutureOrder,
        "estimationMessage" : org.estimationMessage
      }
      this.organizationOptions.push(data);
    }
    this.organizationfilteredOptions = this.organizationControl.valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value['name']),
        map(name => name ? this.organizationfilter(name) : this.organizationOptions.slice())
      );
       
    this.spinner.hide();
  }
  selectMediaFiles() {
    if (this.uploadMediaDialog == null) {
      this.mediaDialogData = new MediaDialogData();
      this.mediaDialogData.ListOfAudioFiles = this.ListOfAudioFiles;
      this.mediaDialogData.ListOfOriginalFiles = ListOfOriginalFiles;
      this.mediaDialogData.originalFile = this.originalFile;
      this.mediaDialogData.FileType = this.FileType;
      this.mediaDialogData.ListzipFiles = ListzipFiles;
      this.mediaDialogData.ListOfAudioFiles1 = this.ListOfAudioFiles1;
      this.mediaDialogData.IsMergeAudios = true;
      this.mediaDialogData.ListOfMergeAudioFiles = ListOfMergeAudioFiles;
      this.mediaDialogData.MergeAudioFileNameList = MergeAudioFileNameList;
      this.mediaDialogData.isUploadProgressively = true;
      this.uploadMediaDialog = this.dialog.open(UploadMediaComponent, {
        width: '82%',
        data: { ref: "media", isjob: false, "data": this.mediaDialogData },
        disableClose: true
      });
      this.uploadMediaDialog.afterClosed().subscribe(result => {
        this.uploadMediaDialog = null;
        if (result != "Clear") {
          this.ListOfAudioFiles = [];
          this.ListOfAudioFiles = result["ListOfAudioFiles"];
          ListOfOriginalFiles = result["ListOfOriginalFiles"];
          this.originalFile = result["originalFile"];
          this.FileType = result["FileType"];
          ListzipFiles = result["ListzipFiles"];
          this.ListOfAudioFiles1 = result["ListOfAudioFiles1"];
          this.IsMergeAudios = result["IsMergeAudios"];
          ListOfMergeAudioFiles = result["ListOfMergeAudioFiles"];
          MergeAudioFileNameList = result["MergeAudioFileNameList"];
        }
        this.mediaDialogData = null;
      });
    }
  }
  attachDocuments() {
    if (this.uploadMediaDialog == null) {
      this.attachmentsDialogData = new AttachmentsDialogData();
      this.attachmentsDialogData.ListOfAttachments = this.ListOfAttachments;
      this.attachmentsDialogData.ListOfAttachmentFiles = ListOfAttachmentFiles;
      this.uploadMediaDialog = this.dialog.open(UploadMediaComponent, {
        width: '80%',
        data: { ref: "attachDocs", "data": this.attachmentsDialogData },
        disableClose: true
      });
      this.uploadMediaDialog.afterClosed().subscribe(result => {
        this.uploadMediaDialog = null;
        if (result != "Clear") {
          this.ListOfAttachments = result["ListOfAttachments"];
          ListOfAttachmentFiles = result["ListOfAttachmentFiles"];
        }
        this.attachmentsDialogData = null;
      });
    }
  }
  calculateEstimate() {
    if (this.uploadMediaDialog == null) {
      this.estimateDialogData = new EstimateDialogData();
      this.selectedIndex = 0;
      this.estimateDialogData.showAcceptButton = true;
      this.estimateDialogData.receiptEmail = this.receiptEmail;
      this.estimateDialogData.totalEstimateAmount = this.totalEstimateAmount;
      this.estimateDialogData.numberOfMins = this.numberOfMins;
      this.estimateDialogData.jobCostMinuteRateEstimation = this.jobCostMinuteRateEstimation;
      this.estimateDialogData.estimateJobTypeDisplayName = this.estimateJobTypeDisplayName;
      this.estimateDialogData.estimateJobTypeExtId = this.estimateJobTypeExtId;
      this.estimateDialogData.selectedIndex = this.selectedIndex;
      this.uploadMediaDialog = this.dialog.open(UploadMediaComponent, {
        width: '45%',
        data: { ref: "estimate", "data": this.estimateDialogData },
        disableClose: true
      });
      this.uploadMediaDialog.afterClosed().subscribe(result => {
        this.uploadMediaDialog = null;
        if (result != "Clear") {
          this.totalEstimateAmount = result["totalEstimateAmount"];
          this.numberOfMins = result["numberOfMins"];
          this.jobCostMinuteRateEstimation = result["jobCostMinuteRateEstimation"];
          this.receiptEmail = result["receiptEmail"];
          this.estimateJobTypeDisplayName = result["estimateJobTypeDisplayName"];
          this.estimateJobTypeExtId = result["estimateJobTypeExtId"];
          this.selectedIndex = result["selectedIndex"];
        }
        this.estimateDialogData = null;
      });
    }
  }
  private organizationfilter(name: any): Organizations[] {
    const filterValue = name.toLowerCase();
    return this.organizationOptions.filter(option => option.customerName.toLowerCase().indexOf(filterValue) === 0);
  }
  getListOfAudioFiles(): number {
    return this.ListOfAudioFiles.length ? this.ListOfAudioFiles.length : 0;
  }
  getListOfAttachmentFiles(): number {
    return this.ListOfAttachments.length ? this.ListOfAttachments.length : 0;
  }
  removeJobAudioFiles() {
    this.ListOfAudioFiles1 = [];
    Audiofile = new FileToUpload();
  }
  removeFileByName(fileName) {
    if (this.IsMergeAudios == true) {
      this.remove();
    }
    else {
      var ListAfterRemove = [];
      ListOfOriginalFiles.forEach(filedetails => {
        if (filedetails.name == fileName) {
        }
        else {
          ListAfterRemove.push(filedetails);
        }
      });
      ListOfOriginalFiles = ListAfterRemove;
      this.ListOfAudioFiles = ListOfOriginalFiles;
      if (this.ListOfAudioFiles.length == 0) {
        ListOfOriginalFiles = [];
        ListzipFiles = [];
      }
      this.file = null;
      this.originalFile = null;
    }
  }
  removeJobAttachments() {
      this.ListOfAttachments = [];
      Attachmentfile = new FileToUpload();
      ListOfZippedDocuments = [];
    }
  removeAttachmentByName(fileName) {
    var ListofAttachmentsAfterRemove = [];
    this.ListOfAttachments.forEach(filedetails => {
      if (filedetails.fileName == fileName) {
      }
      else {
        ListofAttachmentsAfterRemove.push(filedetails);
      }
    });
    this.ListOfAttachments = ListofAttachmentsAfterRemove;
    ListOfZippedDocuments = ListofAttachmentsAfterRemove;
    this.resetFileUploader();
  }
  resetFileUploader() {
    console.log("this.fileUploader", this.fileUploader);
    if (this.fileUploader) {
      this.fileUploader.nativeElement.value = null;
    }
  }
  isEstimate(): boolean {
    return (this.totalEstimateAmount) ? true : false;
  }
  ngOnDestroy() {
    localStorage.setItem('estimationMessage',null);
  }
}
