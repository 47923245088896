import { OnInit, Component, ElementRef, Inject, OnDestroy, ViewChild } from "@angular/core";
import { FormGroup, FormControl, FormBuilder, Validators } from "@angular/forms";
import { MatDialog } from '@angular/material/dialog';
import { jqxNotificationComponent } from 'jqwidgets-ng/jqxnotification';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-view-tnc',
  templateUrl: './view-tnc.component.html',
  styleUrls: ['./view-tnc.component.css'],
})

export class ViewTNCComponent implements OnInit {

  errormessage = "";
  termsAndConditionData?: {};

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<ViewTNCComponent>,
    @Inject(MAT_DIALOG_DATA) public tncData: any
  ) { }

  @ViewChild('msgNotification', { static: false }) msgNotification: jqxNotificationComponent;

  ngOnInit(): void {
    console.log("open tnc dialog ", this.tncData)
    this.termsAndConditionData = this.tncData.tnc;
  }
  dialogClose() {
    this.dialogRef.close();
  }
}


