<h1 mat-dialog-title><img alt="Replace Transcription" class="img-icon mr-2" src="../../../assets/img/icon/replace.png" />
  Replace Transcript File:<button mat-dialog-close type="button" class="close pull-right"
    (click)='dialogClose()' aria-label="Close">&times;</button></h1>

<div class="progress" *ngIf="progress">
  <div class="progress-bar" [style.width]="progress + '%'">{{progress}}%</div>
</div>
<hr>
<div mat-dialog-content style="overflow:visible !important;">
  <div class="row">
    <div class="col-md-4">
      <button class="btn btn-outline-primary btn-viq-primary-outline addtrans-btn attachment-icon mt-2"
        onclick="document.getElementById('getFile').click()">
        <i class="fa fa-folder-open mr-1"> </i>
        Add Transcript</button>
      <input type="file" #fileUploader id="getFile" style="display:none" (change)="onFileChange($event)" /><br />
    </div>
    <div class="col-md-8">
      <p class="header-text-2" style="margin-left: 2.5rem !important;">Selected Transcript File: </p>
      <hr style="margin-left: 2.5rem !important;">
      <div style="overflow-y: hidden; overflow-x: hidden;width: 90%;
        margin-top: 10px; display: block; margin-left: 2.5rem !important;">
        <mat-chip-list aria-hidden="true">
          <mat-chip style="font-size: 10px;" *ngFor="let item of ListOfTranscripts" [selectable]="selectable"
            [removable]="removable" (removed)="removeTranscriptsByName(item.fileName)">
            <span class="mat-text-span" title="{{item.fileName}}"> {{item.fileName}} </span>  
            <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
          </mat-chip>
        </mat-chip-list>
      </div>
    </div>
  </div>
</div>
<mat-dialog-actions align="end" style="margin-right: 20px; margin-bottom: 2px;">
  <button class='btn btn-viq btn-viq-primary pull-right' (click)='uploadTranscript()'>Submit</button>
  <button class='btn btn-viq-secondary pull-right ml-1' (click)='dialogClose()'>Cancel</button>
</mat-dialog-actions>

<jqxNotification #msgNotification [position]="'top-right'" [opacity]="1.0" [autoOpen]="false"
    [autoClose]="true" [animationOpenDelay]="800" [autoCloseDelay]="5000" template.success>
</jqxNotification>