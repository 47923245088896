import { Component, OnInit, Inject, ViewChild, EventEmitter, Output } from '@angular/core';
import { NgxFileDropEntry, FileSystemFileEntry } from 'ngx-file-drop';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router, ActivatedRoute, NavigationStart } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { BackendService } from './../../../services/backend.service';
import { FormGroup, FormControl } from '@angular/forms';
import { jqxListBoxComponent } from 'jqwidgets-ng/jqxlistbox';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import { jqxNotificationComponent } from 'jqwidgets-ng/jqxnotification';
import { asyncScheduler } from 'rxjs';
import { DatePipe } from '@angular/common';

@Component({
    selector: 'app-column-chooser',
    templateUrl: './column-chooser.component.html',
    styleUrls: ['./column-chooser.component.css']
})

export class ColumnChooserComponent implements OnInit {
    @Output()
    notify: EventEmitter<string> = new EventEmitter<string>();


    IsCheckedColumns: boolean = false;
    UserColumnsList: any[];
    myGrid: jqxGridComponent;
    ShowColumns: any[];
    userrole: any;

    @ViewChild('msgNotification', { static: false }) msgNotification: jqxNotificationComponent;
    @ViewChild('columnList', { static: false }) columnList: jqxListBoxComponent;

    //@ViewChild('msgNotification', { static: false }) msgNotification: jqxNotificationComponent;
    removable = true;
    constructor(
        //private formBuilder: FormBuilder,
        private backendService: BackendService,
        public dialogRef: MatDialogRef<ColumnChooserComponent>,
        private router: Router,
        private spinner: NgxSpinnerService,
        private route: ActivatedRoute,
        private datePipe: DatePipe,
        @Inject(MAT_DIALOG_DATA) public jobData: any) { }

    ngOnInit() {
        this.myGrid = this.jobData.myGrid;
        this.ShowColumns = this.jobData.ShowColumns;
        this.userrole = localStorage.getItem('user_roleName');
        this.router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
              // Call your dialogClose() method when navigating to a different tab
              this.dialogClose();
            }
          });
    }

    ngAfterViewInit() {
        this.userrole !== "SuperAdmin" ? this.columnList.removeItem('customerNotes') : this.columnList.enableItem('customerNotes');
    }

    sendNotification(message, messageType) {
        this.msgNotification.elementRef.nativeElement.querySelector('.jqx-notification-content').innerHTML = message;
        this.msgNotification.template(messageType);
        this.msgNotification.open();
    }

    saveChanges(): void {
        console.log("CloseColumnsDDL starts...");
        if (this.IsCheckedColumns) {
            this.UserColumnsList = [];

            this.columnList.getItems().forEach(element => {
                var reqvalue = "{label:" + "'" + element.label + "', value: " + "'" + element.value + "', checked: " + element.checked + "},"
                this.UserColumnsList.push({ label: element.label, value: element.value, checked: element.checked });
            });

            //console.log("this.UserColumnsList", this.UserColumnsList);
            var userjobColumnsOrder = localStorage.getItem('user_jobColumnsOrder');
            var userjobcolList = JSON.stringify(this.UserColumnsList);;
            console.log("userjobcolList", userjobcolList)
            let userName = localStorage.getItem('userName');
            let id = localStorage.getItem('id');
           var customStartdate= localStorage.getItem('user_customStartdate');
           var customEnddate=localStorage.getItem('user_customEnddate');
           var dateRange=localStorage.getItem('user_dateRange');
            var userid = Number(id);
            //console.log("userid ::: " + userid + " userName ::: " + userName);
            //Store selected columns in DataBase.
            this.spinner.show();
            if(dateRange == "custom"){
                this.backendService.updateUserJobColumns(userName, userid, userjobcolList, userjobColumnsOrder,dateRange,customStartdate,customEnddate)
                .subscribe(res => {
                    console.log("res", res);
                    localStorage.removeItem('user_jobColumnsList');
                    localStorage.setItem('user_jobColumnsList', userjobcolList);
                    const result = this.backendService.getJSONResponse(res);
                    this.spinner.hide();
                    this.dialogRef.close("change");
                },
                    (err) => {
                        this.sendNotification("Audio is in conversion. Please try after some time.", "error");
                        this.spinner.hide();
                        this.dialogRef.close();
                    }
                );
            }
            else {
                var originalDate = '0001-01-01T00:00:00';
                const unixTimestamp = Math.floor(new Date(this.datePipe.transform(originalDate, 'yyyy-MM-dd')).getTime() / 1000);
                this.backendService.updateUserJobColumns(userName, userid, userjobcolList, userjobColumnsOrder, dateRange, unixTimestamp.toString(), unixTimestamp.toString())
                    .subscribe(res => {
                       // console.log("res", res);
                        localStorage.removeItem('user_jobColumnsList');
                        localStorage.setItem('user_jobColumnsList', userjobcolList);
                        const result = this.backendService.getJSONResponse(res);
                        this.spinner.hide();
                        this.dialogRef.close("change");
                    },
                        (err) => {
                            this.sendNotification("Audio is in conversion. Please try after some time.", "error");
                            this.spinner.hide();
                            this.dialogRef.close();
                        }
                    );
            }
           

        } else {
            this.sendNotification("No columns selected to change.", 'error')
        }
    }

    listOnSelect(event: any): void {
        if (event.args) {
            this.IsCheckedColumns = true;
            // console.log("event", event.args);
            this.myGrid.beginupdate();
            if (event.args.item.checked) {
                this.myGrid.showcolumn(event.args.item.value);
            }
            else {
                this.myGrid.hidecolumn(event.args.item.value);
            }
            this.myGrid.endupdate();
        }
    }

    dialogClose() {
        this.spinner.show();
        this.dialogRef.close();
    }
}