import { jqxNotificationComponent } from 'jqwidgets-ng/jqxnotification';
import { BackendService } from './../../../services/backend.service';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ResponseMessage } from 'src/app/models/ResponseMessage';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
    selector: 'app-change-password',
    templateUrl: './change-password.component.html',
    styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {

    form: FormGroup;
    submitted = false;
    oldPassword: any;
    newPassword: any;
    confirmPassword: any;
    ShownewPassword: boolean = false;
    ShowConfirmPassword: boolean = false;

    @ViewChild('msgNotification', { static: false }) msgNotification: jqxNotificationComponent;

    constructor(private formBuilder: FormBuilder,
        private backendService: BackendService,
        public dialogRef: MatDialogRef<ChangePasswordComponent>,
        private spinner: NgxSpinnerService,
        @Inject(MAT_DIALOG_DATA) public data: any) { }

    ngOnInit() {
        this.form = this.formBuilder.group({
            oldPassword: new FormControl(),
            newPassword: new FormControl(),
            confirmPassword: new FormControl()
        }, {
            validator: MustMatch('newPassword', 'confirmPassword')
        });
    }

    sendNotification(message, messageType) {
        this.msgNotification.elementRef.nativeElement.querySelector('.jqx-notification-content').innerHTML = message;
        this.msgNotification.template(messageType);
        this.msgNotification.open();
    }

    AvoidSpace() {
        this.sendNotification("Please enter the valid special character(Space is not allowed).", "error")
        return false;
    }
    passwordToggleForNewPassword() {
        this.ShownewPassword= !this.ShownewPassword;
    }
    passwordToggleForConfirmPassword(){
        this.ShowConfirmPassword=!this.ShowConfirmPassword
    }

    get f() {
        return this.form.controls;
    }
    isPasswordMatch = false;

    newPasswordChangeEvent() {
        const upperCase = document.getElementById('pwd-upper-case');
        const number = document.getElementById('pwd-number');
        const characters = document.getElementById('pwd-characters');
        const lowerCase = document.getElementById('pwd-lower-case');
        const specialCharacters = document.getElementById('pwd-special-characters');
        const value = this.form.controls.newPassword.value;

        if (value) {
            this.isPasswordMatch = true;
            if (value.match('(.*[A-Z])')) {
                
                upperCase.classList.add('password-text-underline');
            } else {
                this.isPasswordMatch = false;
                upperCase.classList.remove('password-text-underline');
            }

            if (value.match('(.*[0-9])')) {
                // this.isPasswordMatch = true;
                number.classList.add('password-text-underline');
            } else {
                this.isPasswordMatch = false;
                number.classList.remove('password-text-underline');
            }

            if (value.match('(.*[a-z])')) {
                // this.isPasswordMatch = true;
                lowerCase.classList.add('password-text-underline');
            } else {
                this.isPasswordMatch = false;
                lowerCase.classList.remove('password-text-underline');
            }

            if (value.match(/^[!@#$%^&*?a-zA-Z0-9]+$/)) {
                // this.isPasswordMatch = true;
                specialCharacters.classList.add('password-text-underline');
            } else {
                this.isPasswordMatch = false;
                specialCharacters.classList.remove('password-text-underline');
            }

            if (value.length >= 8) {
                // this.isPasswordMatch = true;
                characters.classList.add('password-text-underline');
            } else {
                this.isPasswordMatch = false;
                characters.classList.remove('password-text-underline');
            }
        }
        if (value.length == 0) {
            this.isPasswordMatch = false;
            upperCase.classList.remove('password-text-underline');
            number.classList.remove('password-text-underline');
            characters.classList.remove('password-text-underline');
            lowerCase.classList.remove('password-text-underline');
            specialCharacters.classList.remove('password-text-underline');
        }

    }
    // isPasswordMatch = false;

    // newPasswordChangeEvent() {
    //     const upperCase = document.getElementById('pwd-upper-case');
    //     const number = document.getElementById('pwd-number');
    //     const characters = document.getElementById('pwd-characters');
    //     const lowerCase = document.getElementById('pwd-lower-case');
    //     const specialCharacters = document.getElementById('pwd-special-characters');
    //     const value = this.form.controls.newPassword.value;

    //     if (value) {
    //         if (value.match('(.*[A-Z])')) {
    //             this.isPasswordMatch = true;
    //             upperCase.classList.add('password-text-underline');
    //         } else {
    //             this.isPasswordMatch = false;
    //             upperCase.classList.remove('password-text-underline');
    //         }

    //         if (value.match('(.*[0-9])')) {
    //             this.isPasswordMatch = true;
    //             number.classList.add('password-text-underline');
    //         } else {
    //             this.isPasswordMatch = false;
    //             number.classList.remove('password-text-underline');
    //         }

    //         if (value.match('(.*[a-z])')) {
    //             this.isPasswordMatch = true;
    //             lowerCase.classList.add('password-text-underline');
    //         } else {
    //             this.isPasswordMatch = false;
    //             lowerCase.classList.remove('password-text-underline');
    //         }

    //         if (value.match('(.*[^a-zA-Z0-9])')) {
    //             this.isPasswordMatch = true;
    //             specialCharacters.classList.add('password-text-underline');
    //         } else {
    //             this.isPasswordMatch = false;
    //             specialCharacters.classList.remove('password-text-underline');
    //         }

    //         if (value.length >= 8) {
    //             this.isPasswordMatch = true;
    //             characters.classList.add('password-text-underline');
    //         } else {
    //             this.isPasswordMatch = false;
    //             characters.classList.remove('password-text-underline');
    //         }
    //     }
    //     if (value.length == 0) {
    //         this.isPasswordMatch = false;
    //         upperCase.classList.remove('password-text-underline');
    //         number.classList.remove('password-text-underline');
    //         characters.classList.remove('password-text-underline');
    //         lowerCase.classList.remove('password-text-underline');
    //         specialCharacters.classList.remove('password-text-underline');
    //     }

    // }

    closeChangePasswordDialog() {
        this.submitted = false;
    }
    isValid(controlName, error) {
        return this.form.controls[controlName].hasError(error);
    }

    dialogClose() {
        this.dialogRef.close();
    }

    changePassword() {

        this.oldPassword = this.form.controls.oldPassword;
        this.newPassword = this.form.controls.newPassword;
        this.confirmPassword = this.form.controls.confirmPassword;
        if (this.oldPassword.value === null) {
            this.sendNotification("Please enter old password", 'error');
        }
        else if (this.newPassword.value === null) {
            this.sendNotification("Please enter new password", 'error');
        }
        else if (this.confirmPassword.value === null) {
            this.sendNotification("Please enter confirm password", 'error');
        }
        else if(!this.isPasswordMatch)
        {
            this.sendNotification('Please follow the password rules.', 'error');
        }
        else {
            this.submitted = true;
            if (this.submitted && this.form.valid && this.isPasswordMatch) {
                this.spinner.show();
                const data = {
                    'userName': localStorage.getItem('user_attribute'),
                    'password': this.oldPassword.value,
                    'newpassword': this.newPassword.value,
                    'accessToken':localStorage.getItem('accessToken')
                }
                this.backendService.changePassword(data).subscribe(
                    res => {
                        const result = this.backendService.getJSONResponse(res);
                        if(result!= null)
                        {
                            console.log("ChangePassword Result:::", result);
                            if(result.data.isSuccess == true)
                            {
                                if(result.data.userStatus == ResponseMessage.PASSWORD_UPDATED)
                                {
                                    this.submitted = false;
                                    this.sendNotification("Password Updated. Please log in with the new password.", 'success');
                                    this.dialogClose();
                                    // this.dialogRef.close();
                                    console.log('force change password results :: ', res);
                                    //Log out
                                    this.backendService.isLoggedIn = false;
                                    this.backendService.setUserLoggedIn(false);
                                    this.spinner.hide();
                                }
                               
    
                            }
                            else
                            {
                                if(result.data.userStatus == ResponseMessage.UNABLE_TO_UPDATED)
                                {
                                    this.submitted = false;
                                    this.sendNotification(ResponseMessage.UNABLE_TO_UPDATED, 'error');
                                    this.spinner.hide();
                                }
                                if(result.data.userStatus == ResponseMessage.OLDPASSWORD_MATCHED)
                                {
                                    this.submitted = false;
                                    this.sendNotification("Entered password is being used in the last 5 Instances, please use a new password.", 'error');
                                    this.spinner.hide();
                                }
                                if(result.data.userStatus == ResponseMessage.PASSWORDCHANGE_UNSUCCESSFUL)
                                {
                                    this.submitted = false;
                                    this.sendNotification(ResponseMessage.PASSWORDCHANGE_UNSUCCESSFUL, 'error');
                                    this.spinner.hide();
                                }
                                if(result.data.userStatus == ResponseMessage.INCORRECT_USERNAME_PASSWORD)
                                {
                                    this.submitted = false;
                                    this.sendNotification('INCORRECT_OLD _PASSWORD', 'error');
                                    this.spinner.hide();
                                }
                                if(result.data.userStatus == ResponseMessage.USER_NOT_FOUND)
                                {
                                    this.submitted = false;
                                    this.sendNotification(ResponseMessage.USER_NOT_FOUND, 'error');
                                    this.spinner.hide();
                                }
                                if(result.data.userStatus == ResponseMessage.LimitExceededException)
                                {
                                    this.submitted = false;
                                    this.sendNotification(ResponseMessage.LimitExceededException + ". Attempt limit exceeded, please try after 30 Mins.", 'error');
                                    this.spinner.hide();
                                }
                                if(result.data.userStatus == ResponseMessage.InvalidPasswordException)
                                {
                                    this.submitted = false;
                                    this.sendNotification(ResponseMessage.InvalidPasswordException + ". Password contains unsupportable special characters. Please check.", 'error');
                                    this.spinner.hide();
                                }
                                if(result.data.userStatus == ResponseMessage.TooManyRequestsException)
                                {
                                    this.submitted = false;
                                    this.sendNotification(ResponseMessage.TooManyRequestsException + ".", 'error');
                                    this.spinner.hide();
                                }
                                if(result.data.userStatus == ResponseMessage.InternalErrorException)
                                {
                                    this.submitted = false;
                                    this.sendNotification(ResponseMessage.InternalErrorException + ".", 'error');
                                    this.spinner.hide();
                                }
                                if(result.data.userStatus == ResponseMessage.InvalidParameterException)
                                {
                                    this.submitted = false;
                                    this.sendNotification(ResponseMessage.InvalidParameterException + ".", 'error');
                                    this.spinner.hide();
                                }
                                if(result.data.userStatus == ResponseMessage.ERROR)
                                {
                                    this.submitted = false;
                                    this.sendNotification(ResponseMessage.ERROR + ". Failed to update the password.",  'error');
                                    this.spinner.hide();
                                }
                            }
                        }
                        // this.submitted = false;
                        // this.sendNotification("Password Updated. Please log in with the new password.", 'success');
                        // this.dialogClose();
                        // // this.dialogRef.close();
                        // console.log('change password results :: ', res);
                    },
                    err => {
                        var errormessage = "";
                        console.log('error occured while changing the password', err.error.data.message)
                        if (err.status == 401) {
                            if (err.error.data.message = "Incorrect username or password.") {
                                errormessage = "Incorrect Old Password.";
                            }
                            this.sendNotification(errormessage, 'error');
                        }
                        this.spinner.hide();
                    }
                );
            }
        }

    }

}


// custom validator to check that two fields match
export function MustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
        const control = formGroup.controls[controlName];
        const matchingControl = formGroup.controls[matchingControlName];

        if (matchingControl.errors && !matchingControl.errors.mustMatch) {
            // return if another validator has already found an error on the matchingControl
            return;
        }

        // set error on matchingControl if validation fails
        if (control.value !== matchingControl.value) {
            matchingControl.setErrors({ mustMatch: true });
        } else {
            matchingControl.setErrors(null);
        }
    }
}
