import { MaterialModule } from './../../material.module';

import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { JQXMaterialModule } from './../../tables/jqx-material.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NavbarComponent } from './navbar.component';
import { MatButtonModule } from '@angular/material/button';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { ProgressBarComponent } from './progress-bar/progress-bar.component';
import { NotificationBannerComponent } from './../../notification-banner/notification-banner.component';

@NgModule({
    imports: [ RouterModule, CommonModule, MatButtonModule, JQXMaterialModule, ReactiveFormsModule, FormsModule,MaterialModule],
    declarations: [ NavbarComponent, ChangePasswordComponent, ProgressBarComponent,NotificationBannerComponent ],
    exports: [ NavbarComponent, ProgressBarComponent ],
    entryComponents: [ChangePasswordComponent]
})

export class NavbarModule {}
