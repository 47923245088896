<!-- wraper-login-main -->
<div class="wraper-login-main position-relative overflow-hidden">
    <div class="container-fluid px-0">
        <!-- login-main -->
        <div class="login-main d-flex">
            <!-- login-main-form -->
            <div class="login-main-form d-flex flex-wrap justify-content-center position-relative z-index-2">
                <!-- login-main-form-curve -->
                <div class="login-main-form-curve position-absolute">
                    <img src="../../assets/myviq/assets/images/login/Form-Right-Curve.png" alt="Form-Right-Curve">
                </div>
                <!-- login-main-form-curve -->
                <!-- login-main-form-holder -->
                <div class="login-main-form-holder w-100 h-100 d-flex flex-wrap justified-items-center">
                    <div class="align-self-center w-100">
                        <!-- login-main-form-heading -->
                        <div class="login-main-form-heading section-heading text-center">
                            <div class="brand-logo">
                                <img src="./assets/img/{{brandname}}/VIQ_Logo.png" alt="VIQ">
                            </div>
                            <h1 class="title">Forgot Password</h1>
                            <h2 class="subtitle">Enter your registered user name to continue</h2>
                        </div>
                        <!-- login-main-form-heading -->
                        <!-- login-main-form-section -->
                        <div class="login-main-form-section contact-form mx-auto">
                            <form [formGroup]="form">
                                <!-- form-row -->
                                <div class="form-row d-block mx-0">
                                    <mat-form-field>
                                        <mat-label>User Name <span>*</span></mat-label>
                                        <input id="uname" matInput type="text" (keydown.enter)="sendVerificationCode()"
                                            formControlName='userName' autofocus>

                                        <mat-error *ngIf="form.dirty && f.userName.errors && f.userName.errors.required"
                                            style="margin-top:10px !important; color:red;">
                                            <span *ngIf="form.get('userName').errors.required">Please enter a valid userName ID.</span>
                                        </mat-error>
                                    </mat-form-field>
                                    <div class="justify-content-center" style="padding-top:0px;padding-bottom: 25px; ">
                                        <span>Already have a verification code?</span>
                                        <span class="login-help" style="padding-left: 5px;font-weight: 400;text-transform: capitalize; "><a
                                            class="hyperlink" (click)="EnterVerificationCode()">Click Here</a></span>
                                    </div>
                                </div>
                                <div class="form-row d-block mx-0 mb-0">
                                    <button class="login-button w-100" mat-flat-button color="primary"
                                        (click)="sendVerificationCode()">Request
                                    </button>
                                </div>
                                <div class="form-row d-block mt-5 mx-0 text-center">
                                    <p class="login-help">Go back to <a class="hyperlink" (click)="backtoLogin()">Log in</a></p>
                                </div>
                                <!-- form-row -->
                            </form>
                        </div>
                        <!-- login-main-form-section -->
                    </div>
                    <div class="align-self-end w-100 mt-5">
                        <!-- login-main-form-footer -->
                        <div class="login-main-form-footer text-center">
                            <!-- copyright-note -->
                            <ul class="copyright-note d-xl-none d-lg-none d-md-block d-sm-block d-block">&copy; VIQ
                                Solutions Inc.
                                {{today | date: 'yyyy'}}. All Rights Reserved.</ul>
                            <!-- copyright-note -->
                        </div>
                        <!-- login-main-form-footer -->
                    </div>
                </div>
                <!-- login-main-form-holder -->
            </div>
            <!-- login-main-form -->
            <!-- login-main-poster -->
            <div
                class="login-main-poster flex-fill d-xl-flex d-lg-flex d-md-none d-sm-none d-none flex-wrap justify-content-center position-relative z-index-1">
                <div class="align-self-center w-100 py-5">
                    <!-- login-main-poster-heading -->
                    <div class="login-main-poster-heading section-heading text-center">
                        <div class="poster-image mx-auto mb-5">
                            <!-- ../../assets/myviq/assets/images/login/Login-Poster.png -->
                            <img src="../../assets/myviq/assets/images/login/Forget-Poster.png" alt="Forget-Poster">
                        </div>
                        <h3 class="title mx-auto">Securely speed the capture, creation, and management of large volumes
                            of
                            information</h3>
                    </div>
                    <!-- login-main-poster-heading -->
                </div>
                <!-- login-main-poster-footer -->
                <div class="login-main-poster-footer position-absolute w-100 text-center">
                    <!-- copyright-note -->
                    <ul class="copyright-note" style="list-style: none; padding: 0; margin: 0;"><li style="display: inline;">&copy; VIQ Solutions Inc. {{today | date: 'yyyy'}}. All Rights Reserved.</li>
                    </ul>
                    <!-- copyright-note -->
                </div>
                <!-- login-main-poster-footer -->
            </div>
            <!-- login-main-poster -->
        </div>
        <!-- login-main -->
    </div>
</div>
<jqxNotification #msgNotification [position]="'top-right'" [opacity]="1.0" [autoOpen]="false"
    [autoClose]="true" [animationOpenDelay]="800" [autoCloseDelay]="5000" template.success>
</jqxNotification>
<!-- wraper-login-main -->