import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { BackendService } from './../services/backend.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class SupportComponent implements OnInit {
  
  contactEmail: string;
  contactNumber: string;
  username: string

  getJSONResponse(value: any) {
    return JSON.parse(JSON.stringify(value));
  }
  constructor(private backendService: BackendService, private spinner: NgxSpinnerService,) {
    this.username = localStorage.getItem('user_attribute');
  }

  ngOnInit() {
    this.spinner.show();
    this.backendService.getsupportdetailsbyusername(this.username).subscribe(
      res => {
        console.log("authnticate result...", res);
        const result = this.backendService.getJSONResponse(res);
        this.contactEmail = result.data.supportEmail;
        this.contactNumber = result.data.supportContactNo;
        this.spinner.hide();
      },
    )
  }
}
