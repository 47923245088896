<div class="notification-banner" [ngClass]="type">
  <div class="scrolling-banner">
    <div class="scrolling-content">
      {{ message }}
    </div>
  </div>
  <!-- <marquee behavior="scroll" direction="left">{{ message }}</marquee> -->
    <!-- <span>{{ message }}</span> -->
    <a *ngIf="link" [href]="link" target="_blank">{{ linkText }}</a>
  </div>
  