const { config } = require("../assets/resource/config.json");
////// development -- Dev
export const environment = {
  production: true,
  region: 'ca-central-1',
  identityPoolId: 'ca-central-1:9878e2fd-8703-48f7-8db4-11e5f65d9909',
  userPoolId: 'ca-central-1_ltuHZ6c7Q',
  clientId: '1inag0vhqu04sv5l74gj65kta8',
  rekognitionBucket: 'rekognition-pics',
  albumName: "usercontent",
  bucketRegion: 'ca-central-1',
  ddbTableName: 'dev_nswconnector_userlogintrail',
  cognito_idp_endpoint: '',
  cognito_identity_endpoint: '',
  sts_endpoint: '',
  dynamodb_endpoint: 'com.amazonaws.ca-central-1.dynamodb',
  s3_endpoint: '',
  bucketname: 'dev-client-nswconnector',
  home: '/home/',
  accessKeyId: "",
  secretAccessKey: "",
  sessionTimeOut:300,
  endpoint:'https://devnsconnect.aiassist.ai/api/v1',
  AudioFileSize : config.AudioFileSize,
  BuildVersion: config.BuildVersion,
  Version: config.Version,
  SqlDB : true,
  EnvironmentName : 'dev',
  baseUrls:'devnsconnect.aiassist.ai,devnswconn.aiassist.ai,devnscadmin.aiassist.ai,uk-myapp.com',
  nswbaseUrls:"https://test.netscribe.net",
  froalaEditorLicenseKey: 'iTB2xC5B4C3C2C3F1E1pZGCTRSAPJWTLPLZHTQQb1JGZxE2F2G2E1B10C2D2E6F1G2=='
};