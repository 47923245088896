import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FootPedalService {
  private socket: WebSocket;
  private footPedalEventSubject = new Subject<string>();

  constructor() {
    this.connectWebSocket();
  }

  private connectWebSocket(): void {
    this.socket = new WebSocket('ws://127.0.0.1:3000/media');

    this.socket.onopen = () => {
      console.log('WebSocket connection established');
      this.socket.send('NSC');
    };

    this.socket.onmessage = (event) => {
      //console.log('Foot Pedal Event:', event.data);
      this.footPedalEventSubject.next(event.data); // Emit events to subscribers
    };

    this.socket.onerror = (error) => {
      console.error('WebSocket Error:', error);
    };

    this.socket.onclose = () => {
      console.log('WebSocket connection closed');
    };
  }

  // Observable to subscribe to foot pedal events
  getFootPedalEvents(): Observable<string> {
    return this.footPedalEventSubject.asObservable();
  }
}
