import { BackendService } from './backend.service';
import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRoute, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { JwtHelperService } from "@auth0/angular-jwt";

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate{

  helper = new JwtHelperService();

  constructor(
    private backendService: BackendService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot){

    var userlogin = this.backendService.CheckIsUserLoggedIn();
    if(userlogin)
    {
        const token = localStorage.getItem('token');
        if(!this.helper.isTokenExpired(token)){
          this.backendService.isLoggedIn = true;
          // this.router.navigate(['/admin']);
          return true;
        }
    }
    this.backendService.setUserLoggedIn(false);
    this.router.navigate(['/'],{ queryParams: { returnUrl: state.url }});
    return false;
  
  }
}
