import { jqxNotificationComponent } from 'jqwidgets-ng/jqxnotification';
import { BackendService } from './../../../services/backend.service';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { Component, OnInit, Inject, ViewChild, EventEmitter, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxFileDropEntry, FileSystemFileEntry } from 'ngx-file-drop';
import axios from 'axios';
import { Router, ActivatedRoute, NavigationStart } from '@angular/router';
import { environment } from "src/environments/environment";
import { NgxSpinnerService } from 'ngx-spinner';

let zipFile;
let ListzipFiles = [];
let ListZipFailedFiles;
let fileMinSize = environment.AudioFileSize;
let ListOfOriginalFiles = [];
let ListOfZipFiles = [];
let NewNSWJobNumber;
let OldNSWJobNumber;

@Component({
    selector: 'app-replace-audiofile',
    templateUrl: './replace-audiofile.component.html',
    styleUrls: ['./replace-audiofile.component.css']
})
export class ReplaceAudioFileComponent implements OnInit {
    @Output()
    notify: EventEmitter<string> = new EventEmitter<string>();

    progress: number;
    FileType = "";

    public originalFile: File;
    public ListOfNames: string;
    public isDuplicate: boolean;
    public ListOfAudioFiles: NgxFileDropEntry[] = [];
    returnUrl: string;
    canUpload: boolean = false;
    MediaFileUploadUrl = "";
    @ViewChild('file', { static: false }) file
    @ViewChild('msgNotification', { static: false }) msgNotification: jqxNotificationComponent;
    removable = true;
    constructor(private formBuilder: FormBuilder,
        private backendService: BackendService,
        public dialogRef: MatDialogRef<ReplaceAudioFileComponent>,
        private router: Router,
        private spinner: NgxSpinnerService,
        private route: ActivatedRoute,
        @Inject(MAT_DIALOG_DATA) public jobdata: any) { }


    ngOnInit() {
        this.ListOfAudioFiles = [];
        ListOfOriginalFiles = [];
        this.notify.emit("send data");
        NewNSWJobNumber = 0;
        OldNSWJobNumber = 0;
        this.router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
              // Call your dialogClose() method when navigating to a different tab
              this.dialogClose();
            }
          });
    }


    fileOver(event) {
        console.log('fileOver event : ' + event);
    }
    fileLeave(event) {
        console.log('fileLeave event : ' + event);
    }
    removeFileByName(fileName) {
        var ListAfterRemove = [];
        ListOfOriginalFiles.forEach(filedetails => {
            if (filedetails.name == fileName) {
                //  console.log("removed fileName:::" + fileName)
            }
            else {
                ListAfterRemove.push(filedetails);
            }
        });
        ListOfOriginalFiles = ListAfterRemove;
        this.ListOfAudioFiles = ListOfOriginalFiles;
        if (this.ListOfAudioFiles.length == 0) {
            this.canUpload = false;
            ListOfOriginalFiles = [];
            ListzipFiles = [];
        }
        this.file = null;
        this.originalFile = null;
    }
    public dropped(files: NgxFileDropEntry[]) {

        //this.ListOfAudioFiles = files;
        //ListzipFiles = [];
        for (const droppedFile of files) {
            // Is it a file?
            if (droppedFile.fileEntry.isFile) {
                if (this.ListOfAudioFiles.length == 1) {
                    this.sendNotification('Please select only one Audio.', 'error');
                }
                else {
                    this.isDuplicate = false;
                    const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
                    fileEntry.file((file: File) => {
                        this.file = file;
                        this.originalFile = file;
                        this.FileType = file.name.split(/[.;+_]/).pop().toLowerCase();

                        if (this.originalFile.size > fileMinSize) {
                            // if(this.FileType === "mp3" || this.FileType === "wav" || this.FileType === "mp2" || this.FileType === "m4a" || this.FileType === "m4v" || this.FileType === "dss" || this.FileType === "ds2" || this.FileType === "aac" || this.FileType === "wma" || this.FileType === "oga" || this.FileType === "trm" || this.FileType === "mp4" || this.FileType === "m4v" || this.FileType === "opus" || this.FileType === "avi" || this.FileType === "zip"|| this.FileType === "mkv"|| this.FileType === "ogg")
                            if (this.FileType === "mp3" || this.FileType === "wav" || this.FileType === "mp2" || this.FileType === "m4a" || this.FileType === "m4v" || this.FileType === "dss" || this.FileType === "ds2" || this.FileType === "aac" || this.FileType === "wma" || this.FileType === "oga" || this.FileType === "trm" || this.FileType === "mp4" || this.FileType === "m4v" || this.FileType === "opus" || this.FileType === "avi" || this.FileType === "ogg" || this.FileType === "mkv" || this.FileType === "wmv" || this.FileType === "vob" || this.FileType === "mov" || this.FileType === "zip") {
                                //////For Zip format

                                if (this.FileType === "zip") {
                                }
                                ///////Normal audio files
                                else {
                                    ListOfZipFiles = [];
                                    ///Duplicate filename  verification
                                    ListOfOriginalFiles.forEach(filedetails => {
                                        if (filedetails.name == this.originalFile.name) {
                                            this.isDuplicate = true;
                                        }
                                    })
                                    ///
                                    if (this.isDuplicate == true) {
                                        this.sendNotification(this.originalFile.name + " is already exist.", "error");
                                    }
                                    else {
                                        if (this.ListOfNames == "") {
                                            this.ListOfNames = this.originalFile.name;
                                        }
                                        else {
                                            this.ListOfNames = this.ListOfNames + ", " + this.originalFile.name;
                                        }
                                        ListOfOriginalFiles.push(this.originalFile);
                                        this.canUpload = true;
                                    }
                                    this.ListOfAudioFiles = ListOfOriginalFiles;
                                }
                            }
                            else {
                                //  this.remove();
                                this.sendNotification('Selected file is ' + this.FileType + '. Please upload files with mp2, mp3, wav, m4a, dss, ds2, aac, wma, oga, trm and zip formats.', 'error');
                            }
                        }
                        else {
                            // this.remove();
                            this.sendNotification('Please upload files with mininum 5kb size.', 'error');
                        }
                    })
                }
            }
        }

    }
    sendNotification(message, messageType) {
        this.msgNotification.elementRef.nativeElement.querySelector('.jqx-notification-content').innerHTML = message;
        this.msgNotification.template(messageType);
        this.msgNotification.open();
    }


    dialogClose() {
        this.ListOfAudioFiles = [];
        ListOfOriginalFiles = [];
        this.dialogRef.close();
    }

    getJSONResponse(value: any) {
        return JSON.parse(JSON.stringify(value));
    }

    async uploadAudio() {

        if(this.ListOfAudioFiles.length == 0) {
            this.sendNotification('Please select an audio file.', 'error');
            return;
        }

        this.spinner.show();
        // console.log("jobid:::" + this.jobdata.jobId);
        let FileExtension = '';
        let FileName = '';
        ListOfOriginalFiles.forEach(filedetails => {
            FileExtension = filedetails.name.split(/[.;+_]/).pop().toLowerCase();
            FileName = filedetails.name;
        })

        var UploadURLDetails = this.getJSONResponse(await this.backendService.replaceAudioInNSC(this.jobdata.jobId, this.jobdata.jobStatus, FileExtension, FileName).toPromise()).data;
        console.log("ReplaceURL::" + JSON.stringify(UploadURLDetails));

        ListOfOriginalFiles.forEach(filedetails => {
            this.MediaFileUploadUrl = "";
            // console.log("filedetails:::" + JSON.stringify(filedetails));
            this.backendService.AWSLog("info", "Re-Upload Job Process Starts... Non-ZipFile Process...Total files count :::" + ListOfOriginalFiles.length + "Audio File Details::: FileName::" + filedetails.name +
                "  FileSize::" + filedetails.size + "  FileType::" + filedetails.type)
            this.canUpload = false;
            this.MediaFileUploadUrl = UploadURLDetails.mediaFileUploadUrl;
            OldNSWJobNumber = UploadURLDetails.oldNSJobNumber;
            NewNSWJobNumber = UploadURLDetails.newNSJobNumber;
            this.uploadAudioFileToBucket(filedetails, this.jobdata.jobId);
            this.spinner.hide();
        })

    }
    calcProgress(fileObj, progressEvent) {
        this.progress = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
    }
    async uploadAudioFileToBucket(filedetails, jobId) {
        this.progress = 0;
        this.backendService.AWSLog("info", "uploadAudioFileToBucket Method starts... FileDetails ::: FileName::" + filedetails.name +
            "  FileSize::" + filedetails.size + "  FileType::" + filedetails.type + " Jobid ::: " + jobId + "  MediaFileUploadUrl :::" + this.MediaFileUploadUrl).subscribe();
        // Progress bar
        //
        const fileObject = {
            name: filedetails.name,
            size: (filedetails.size / (1024 * 1024)).toFixed(2) + ' MB',
            progress: 0,
            path: null
        };

        axios.put(this.MediaFileUploadUrl, filedetails, {
            onUploadProgress: this.calcProgress.bind(this, fileObject)
        })
            .then(res => {
                console.log(res);
                this.progress = null;
                console.log("Audio file upload result:::", JSON.stringify(res.data));
                this.backendService.AWSLog("info", jobId + "RePlace uploadAudioFileToBucket Method - Successfully Uploaded :::  " + JSON.stringify(res.data)).subscribe();
                this.dialogClose();
                this.sendNotification('Audio Replaced for the JobId: ' + jobId + '. Old NetScribe JobNumber: ' + OldNSWJobNumber + ' with New NetScribe JobNumber: ' + NewNSWJobNumber, 'success');
                //this.sendNotification('Audio file Replaced successfully.', 'success');
                //window.location.reload();
                this.spinner.hide();
                setTimeout(() => {
                    const jobstatusLink = document.getElementById('joblist-status-dropdown');
                    const ele = (jobstatusLink.children[0] as HTMLElement);
                    const jobrefreshLink = document.getElementById('joblist-refresh');
                    (jobrefreshLink.children[0] as HTMLElement).click();
                }, 5000);

            })
            .catch(err => {
                console.log(err);
                this.progress = null;
                this.sendNotification('Audio file replace failed.', 'error');
                this.backendService.AWSLog("error", jobId + "Error - Resubmit uploadAudioFileToBucket Method :::  " + JSON.stringify(err)).subscribe();
                this.spinner.hide();
            })
    }

}

