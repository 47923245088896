import { Component, OnInit, SimpleChanges,Input } from '@angular/core';
import { BackendService } from 'src/app/services/backend.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-order-information',
  templateUrl: './order-information.component.html',
  styleUrls: ['./order-information.component.css']
})
export class OrderInformationComponent implements OnInit {
  @Input() dataFromParent: any;
  orderResponse : any = [];
  orderInfo : any = [];
  courtInfo : any = [];
  caseNumber : string;
  orderFormData: any = [];
  requestorDetails: any = [];
  courtDetails: any=[];
  addressLines: string[] = [];
  constructor( private spinner: NgxSpinnerService,
    private backendService: BackendService) { }
  ngOnInit(): void {
    // this.spinner.hide();
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes['dataFromParent'] && changes['dataFromParent'].currentValue) {
      // console.log('Data received in child:', this.dataFromParent);
      this.orderResponse = this.dataFromParent;
      this.orderInfo = this.orderResponse.orderInfo;
      this.orderFormData = this.orderResponse.formData;
      this.courtInfo = this.orderResponse.courtInfo;
      this.caseNumber = this.orderResponse.caseNumber;
      this.requestorDetails = this.orderResponse.primaryReq;
    }
  }
  isPaid: boolean = false;
  
  splitCamelCase(camelCaseString: string): string {
    return camelCaseString
      .replace(/([a-z])([A-Z])/g, '$1 $2')  // Add space before uppercase letters
      .replace(/^./, str => str.toUpperCase()); // Capitalize the first letter of the result
  }
}
