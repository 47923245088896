<!-- <p>edit-order works!</p> -->
<div class="main-content">
    <div class="container-fluid">
        <hr class="hr-css">
        <div class='row'>
            <div class='col-md-12'>
                <div class='row'>
                    <div class="row col-md-12">
                        <div class="col-md-2">
                            <button mat-raised-button color="primary" (click)="goToOrderList()"
                                style="background-color: white;color: #187EB1 !important;">
                                ← Back to Order List
                            </button>
                        </div>
                        <div class="col-md-10">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class='row'>
            <div class="row col-md-12">
                <div class='row'>
                    <div class="row col-md-12">
                        <div class="col-md-4">
                            <div class='card' style="height: 72vh;overflow-y: scroll;">
                                <div class="card-body">
                                    <div class="card-container">
                                        <mat-card *ngFor="let card of cards" class="example-card" style="padding: 0px;"
                                            [disabled]="!card.isCompleted">
                                            <div class='row'>
                                                <div class="row col-md-12">
                                                    <div class="col-md-10">


                                                        <mat-card-header class="card-header"
                                                            style="padding: 10px;margin-left: -10%;margin-top: 5%;">
                                                            <mat-icon aria-hidden="false" aria-label="Status Icon">
                                                                <!-- Conditional Icon based on isCompleted -->
                                                                <ng-container *ngIf="card.isCompleted; else bufferIcon">
                                                                    <mat-icon>check_circle</mat-icon>
                                                                </ng-container>
                                                                <ng-template #bufferIcon>
                                                                    <img src="../../../assets/img/icon/circle-loading.png"
                                                                        style="padding-bottom: 40%;" />
                                                                    <!-- Use hourglass_empty instead of buffer -->
                                                                </ng-template>
                                                            </mat-icon>
                                                            <div class="header-left">

                                                                <mat-card-title>{{
                                                                    card.title
                                                                    }}</mat-card-title>
                                                            </div>

                                                        </mat-card-header>
                                                    </div>
                                                    <div class="col-md-2">

                                                        <mat-card-actions class="header-right">
                                                            <button mat-icon-button (click)="toggleCard(card)"
                                                                [disabled]="!card.isCompleted">
                                                                <mat-icon>{{ card.isExpanded ? 'expand_less' :
                                                                    'expand_more'
                                                                    }}</mat-icon>
                                                            </button>
                                                        </mat-card-actions>
                                                    </div>

                                                </div>
                                            </div>

                                            <mat-card-content *ngIf="card.isExpanded" class="card-content">
                                                <div class="top-section">
                                                    <button type="button"
                                                        class="btn btn-viq btn-viq-primary pull-right">Upload
                                                        EX107</button>
                                                    <span>View Text</span>
                                                </div>

                                                <div class="notes-section">
                                                    <h7>Notes</h7>
                                                    <span class="edit-text">Edit</span>
                                                </div>

                                                <p class="content-text">
                                                    {{ card.content }}
                                                </p>

                                                <div class="bottom-section">
                                                    <input type="date" class="date-picker" />
                                                    <button>Completed</button>
                                                </div>
                                            </mat-card-content>
                                        </mat-card>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class='card' style="height: 72vh; overflow-y: auto;">
                                <div class="card-body" id="orderdetails">
                                    <div *ngFor="let order of orders" class="order-item">
                                        <span><strong>Order No:</strong> {{ order.orderNo }}</span>
                                        <span><strong>Judge:</strong> {{ order.judge }}</span>
                                        <span><strong>Primary Requestor:</strong> {{ order.primaryRequestor }}</span>
                                        <span><strong>Secondary Requestor:</strong> {{ order.secondaryRequestor }}</span>
                                        <span><strong>Address:</strong> {{ order.address }}</span>
                                        <hr />
                                      </div>
                                </div>
                            </div>

                        </div>
                        <div class="col-md-4">
                            <div class='card' style="height: 72vh; display: flex; flex-direction: column;">
                                <div class="card-body" id="chatBox"
                                    style="flex: 1; overflow-y: auto; display: flex; flex-direction: column-reverse;">
                                </div>
                                <div class="chat-input"
                                    style="display: flex; align-items: center; padding: 10px; background: #f8f9fa; border-top: 1px solid #ccc;">
                                    <input type="text" [(ngModel)]="message" class="form-control"
                                        placeholder="Type a message..."
                                        style="flex: 1; margin-right: 5px; padding: 5px; font-size: 14px;">
                                    <button (click)="sendMessage('message')" class="btn btn-primary"
                                        style="padding: 5px 10px; font-size: 14px;">
                                        <i class="fas fa-comment"></i>
                                    </button>
                                    <button (click)="sendMessage('email')" class="btn btn-secondary"
                                        style="margin-left: 5px; padding: 5px 10px; font-size: 14px;">
                                        <i class="fas fa-envelope"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>