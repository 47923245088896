import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-edit-order',
  templateUrl: './edit-order.component.html',
  styleUrls: ['./edit-order.component.css']
})
export class EditOrderComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void {
  }
  goToOrderList() {
    const route = '/admin/courtorders';
    this.router.navigate([route]); // Correct way to navigate
  }
  cards = [
    { 
      title: 'EX107 Signed', 
      subtitle: 'Subtitle 1', 
      content: 'This is the content for card 1.',
      isCompleted: true,  // Example of completed status
      isExpanded: false
    },
    { 
      title: 'Permission Received', 
      subtitle: 'Subtitle 2', 
      content: 'This is the content for card 2.',
      isCompleted: true, // Example of incomplete status
      isExpanded: false
    },
    { 
      title: 'Estimate & T&C Sent', 
      subtitle: 'Subtitle 3', 
      content: 'This is the content for card 3.',
      isCompleted: false,  // Example of completed status
      isExpanded: false
    },{ 
      title: 'Requstor Approved EX107', 
      subtitle: 'Subtitle 1', 
      content: 'This is the content for card 1.',
      isCompleted: false,  // Example of completed status
      isExpanded: false
    },
    { 
      title: 'HMCTC Approved EX107', 
      subtitle: 'Subtitle 2', 
      content: 'This is the content for card 2.',
      isCompleted: false, // Example of incomplete status
      isExpanded: false
    },
    { 
      title: 'Recording Received', 
      subtitle: 'Subtitle 3', 
      content: 'This is the content for card 3.',
      isCompleted: false,  // Example of completed status
      isExpanded: false
    },
    { 
      title: 'Work Started', 
      subtitle: 'Subtitle 2', 
      content: 'This is the content for card 2.',
      isCompleted: false, // Example of incomplete status
      isExpanded: false
    },
    { 
      title: 'Completed', 
      subtitle: 'Subtitle 3', 
      content: 'This is the content for card 3.',
      isCompleted: false,  // Example of completed status
      isExpanded: false
    }
  ];
  message: string = '';

  sendMessage(type: string) {
      if (this.message.trim()) {
          let chatBox = document.getElementById('chatBox');
          let newMessage = document.createElement('div');
          newMessage.innerHTML = `<strong>${type === 'email' ? 'Email Sent:' : 'Message:'}</strong> ${this.message}`;
          chatBox?.appendChild(newMessage);
          this.message = '';
          chatBox?.scrollTo({ top: chatBox.scrollHeight, behavior: 'smooth' });
      }
  }
  orders = [
    {
      orderNo: 'ORD12345',
      judge: 'Judge John Doe',
      primaryRequestor: 'Alice Smith',
      secondaryRequestor: 'Bob Johnson',
      address: '123 Court St, New York, NY'
    },
    {
      orderNo: 'ORD67890',
      judge: 'Judge Jane Doe',
      primaryRequestor: 'Charlie Brown',
      secondaryRequestor: 'Emily White',
      address: '456 Justice Rd, Los Angeles, CA'
    }
  ];
  toggleCard(card: any) {
    card.isExpanded = !card.isExpanded;
  }

}
