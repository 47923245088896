import { integer } from "aws-sdk/clients/cloudfront";
import { int } from "aws-sdk/clients/datapipeline";
import { bool } from "aws-sdk/clients/signer";
import { NgxFileDropEntry, FileSystemFileEntry } from 'ngx-file-drop';

export class UserData {
  userName: string;
  custExtId: string;
  isCognitoUser: boolean;
  isadmin: string;
  deptExtId: string[];
  id: integer;
  isMMP: string;
  firstName: string;
  middleName: string;
  lastName: string;
  roleId: integer;
  authorExtId: string;
  MobileNo: string;
  password: string;
  isMFA: boolean;
  allowTranscriptEditing: boolean;
  TenantId: string;
  emailTemplateId: integer;
  email: string;
  addNSAuthor: boolean;
  profile: string;
  webSite: string;
  homeDirectory: string;
  bucket: string;
  status: string;
  isActive: boolean;
  hostTenants: string;
  isSuperAuthor: boolean;
  mmpDefaultDepartment:string;
}
export class UserConfigInputModel {
  userData: UserData;
  pooldata: PollDataModel;
}
export class PollDataModel {
  UserPoolId: string;
  Username: string;
  TemporaryPassword?: string;
  DesiredDeliveryMediums?: string[];
  MessageAction?: string;
  UserAttributes: UserAttribute[];
  IsResend:boolean;
}
export class UserAttribute {
  Name: string;
  Value: string;
}
export class RegistrationUser {
  name: string;
  email: string;
  phone_number: string;
  password: string;
  resend: boolean;
  organization: Organizations;
  Isadmin: boolean;
  IsMMP: boolean;
  id: integer;
  firstName: string;
  middleName: string;
  lastName: string;
  roleId: integer;
  authorExtId: string;
  IsMFA: boolean;
  allowTranscriptEditing: boolean;
  TenantId: string;
  EmailProfile: string;
  ncUrl: string;
  addNSAuthor: boolean;
  isActive: boolean;
  status: string;
  hostTenants: string;
  IsSuperAuthor: boolean;
  mmpDefaultDepartment:string;
}
export class Organizations {
  id: string;
  customerName: string;
  custExtId: string;
  directBucket: string;
  deptExtId: string[];
  ncurl: string;
  tenantId: int;
  tenantName: string;
  emailTemplateId: string;
}
export class Tenants {
  id: string;
  tenancyName: string;
  ncurl: string;
  tenantId: int;
}
export class NewPasswordUser {
  username: string;
  existingPassword: string;
  confirmationCode: string;
  confirmationpassword: string;
  password: string;
}
export class pageParamsDto {
  pageNumber: string;
  pageSize: string;
}
export class LoggingModel {
  type: string;
  message: string;
  dateTime: string;
  status: string;
}
export class FileToUpload {
  fileName: string = "";
  fileSize: number = 0;
  fileType: string = "";
  lastModifiedTime: number = 0;
  lastModifiedDate: Date = null;
  fileAsBase64: string = "";
}
export class JobCreateInputDto {
  fileName: string = "";
  formTypeId: string = "";
  departmentId: string = "";
  FormData: any;
  AuthorId: string = "";
  AuthorExtId: string = "";
  AuthorKey: string = "";
  referenceId = "";
  callbackUrl = "";
  attachmentFiles: FileToUpload[];
  audioFiles: FileToUpload[];
  createdDate: string = "";
  notes: string = "";
  language: string = "";
  jobTypeExtId: string = "";
  JobTypeDisplayName: string = "";
  dictatedDate: string = "";
  sensitive: bool = false;
  certificateRequired: bool = false;
  isForeignLanguage: bool = false;
  isForeignLanguageFirstDraft: bool = false;
  CustomerNotes: string = "";
  isZip: bool = false;
  zipPath: string = "";
  formattedDictatedDate: string = "";
  oTAudioFileNames: string = "";
  foreignLanguageType: string;
  notarization: bool = false;
  ForeignLanguageServiceType: number;
  targetLanguage: string;
  totalEstimationCost: number;
  numberofMinutesforEstimation: number;
  perMinuteEstimationRate = "";
}
export class JobCreateDto {
  jobId: string = "";
  mediaFileUploadUrl: string = "";
  message: string = "";
  filename: string = "";
  jobAttachmentUrl: [];
  multipleAudioFilesUrl = [];
}
export class TenantsDto {
  tenancyName: string = '';
  tenantID: string = '';
  ncurl: string = '';
  id: number = 0;
  isDelete: bool = false;
  isActive: bool = false;
  inboundPassword: string = '';
  EmailTemplateId: number = 1;
  SupportContactNo: string;
  SupportEmail: string;
  tncjson: string;
  RecipientEmail: string;
  estimationMessage:string;
  isCustomerNotesEnable:bool=false;
  humanFLWorkPoolExtID:string;
}
export class OrganizationsDto {
  orgExtId: string = '';
  organizationName: string = '';
  externalFieldMapping: string = '';
  id: number = 0;
  departmentParseType: string = '';
  outGoingFileNameType: string = '';
  acquisition: string = '';
  isNSDirectUpload: bool = false;
  isDelete: bool = false;
  isActive: bool = false;
  directBucket: string = '';
  parsingType: string = '';
  textFileDelimiter: string = '';
  xmlFileSourceNode: string = '';
  externalBucket: string = '';
  externalOutputFolderkey: string = '';
  stateFieldMapping: string = '';
  rushFieldMapping: string = '';
  invoiceOutputFieldMapping: string = '';
  distributionputFieldMapping: string = '';
  nsDistributionputFieldMapping: string = '';
  xmlFieldMapping: string = '';
  folderDate: string = '';
  extraFileDownloadFormat: string = '';
  requiredClientBucketSave: string = '';
  departmentSpecificFolders: bool = false;
  pgpEncryptionRequired: bool = false;
  invoice_email_list: string = '';
  teFileFormat: string = '';
  isAspose: bool = false;
  isOldProcess: bool = false;
  isProcessotherFolder: bool = false;
  processFolder: string = '';
  externalInputFolderkey: string = '';
  pgpPassword: string = '';
  pgpDecryptionRequired: bool = false;
  isIDXEnabled: bool = false;
  truncateKeyword: string = '';
  emailDistributionList: string = '';
  cutoffDuration: string = '';
  truncateFilePath: string = '';
  internalBucket: string = '';
  isOriginalFileName: bool = false;
  organizationId: string = '';
  password: string = '';
  inboundPassword: string = '';
  tenantId: number = 0;
  ConnectorId: number = 0;
  connectorPassword: string = '';
  isNSAutoUpload: bool = false;
  isAudioUploadBySAS: bool = false;
  isFutureOrder: bool = false;
  sourceType: string = '';
  isForeignLanguage: bool = false;
  isSensitive: bool = false;
  isCertification: bool = false;
  isNotarization: bool = false;
  isEstimateEnable:bool=false;
  allowMultipleJobs:bool=false;
  IsForeignLanguageFirstDraft: bool = false;
  defaultForeignLanguageService: string = '';
  defaultSourceForeignLanguage:string ='';
  isDefaultAuthorEmail: bool = false;
  defaultEmail: string = '';
  estimationMessage:string ='';
  isACKEnabled: bool = false;
}
export class DepartmentsDto {
  bucket: string;
  orgExtId: string;
  deptExtId: string;
  departmentName: string;
  organizationName: any;
  defaultAuthor: string;
  documentDownloadFormat: string;
  organizationId: string;
  departmentId: string;
  fieldMapping: string;
  formTypes: string;
  jobFilesFolderKey: string;
  orgId: Number;
  fileTypes: string;
  isDelete: bool;
  displayName: string;
  id: number = 0;
  inputFolderKey: string;
  awxFolderPath: string;
  requiresPDFConversion: bool;
  custTimeZone: string;
  timeDifference: number;
  defaultFormTypeId: string;
  subrogation: string;
  regions: string;
  outputFolderKey: string;
  useCaseId: string;
  field1: string;
  field2: string;
  field3: string;
  field4: string;
  field5: string;
  field6: string;
  field7: string;
  field8: string;
  field9: string;
  field10: string;
}
export class Departments {
  orgId: string;
  departmentName: string;
  deptExtId: string;
  displayName: string;
  documentDownloadFormat: string;
  formTypes: string;
  organizationName: string;
  isDelete: bool;
  field1: string;
  field2: string;
  field3: string;
  field4: string;
  field5: string;
  field6: string;
  field7: string;
  field8: string;
  field9: string;
  field10: string;
}
export class customers {
  custExtId: string;
  customerName: string;
  dbId: string;
  directBucket: string;
  id: string;
}
export class OutPutFileNameSource {
  id: string;
  fieldSource: string;
  fileNameSourceId: string;
  type: string;
}
export class Roles {
  id: string;
  createdDate: string;
  isActive: bool;
  roleName: string;
  roleDisplayName: string;
}
export class Authors {

  id: string;
  authorKey: string;
  authorValue: string;
  customerId: string;
  oneTimeSpeaker: string[];
}

export class LoginResponse {

  userStatus: string;
  userName: string;
  token: string;
  refreshtoken: string;
  expirationTime: Date;
  IsSuccess: bool;
  sessionID: string;
}
export class userStatusModel {
  userName: string;
  isActive: bool;
  status: string;
}
export class NotesDialogData {
  Notes: string;
  custNotes: string;
}
export class MediaDialogData {
  ListOfAudioFiles: NgxFileDropEntry[] = [];
  ListOfOriginalFiles = [];
  canUpload: boolean = false;
  originalFile: File;
  FileType: string;
  ListzipFiles = [];
  ListOfAudioFiles1: FileToUpload[] = [];
  IsMergeAudios = false;
  ListOfMergeAudioFiles = [];
  MergeAudioFileNameList = [];

}
export class AttachmentsDialogData {
  ListOfAttachments: FileToUpload[] = [];
  ListOfAttachmentFiles = [];
}
export class ColorCodes {
  colorCode: string;
  colorValue: string;
}
export class transcriptionrequest {
  jobId: string;
  transcript: string ;
}
export class EstimateDialogData {
  showAcceptButton: bool;
  totalEstimateAmount: number;
  numberOfMins: number;
  jobCostMinuteRateEstimation = "";
  receiptEmail:string;
  estimateJobTypeExtId = "";
  estimateJobTypeDisplayName = "";
  selectedIndex:number;
  selectedLanguage:string;
  selectedFLlanguage:number;
  isForeignLanguage:bool;
}
export enum JobReleaseHoldStateEnum
{
  TypingOrEditing = 1,
  Review = 2
};
export class gridInputModel {
  query: string;
  jobColumnsList:string;
  jobColumnsListOrder:string;
  browserTimeZoneOffset:number;
  gridJobModel: any | null;
}
export class UsergridInputModel {
  query: string;
  userGridModel : any | null;
}
// export class systemSettingsDto
// {
//   maintenanceMessage:string;
//   maintenanceStartTime:Date;
//   maintenanceEndTime:Date;
//   isEnable:bool;
//   editorAutoSaveInterval?:number;
//   releaseNotesFileName:string;
// }
