<div class="wrapper">
    <div class="sidebar" data-color="viq-logo-color" data-background-color="white">
        <!-- data-image="./assets/img/sidebar-1.jpg" -->
        <app-sidebar-cmp></app-sidebar-cmp>
        <!-- <div class="sidebar-background"></div>  style="background-image: url(assets/img/background.png)" -->
        <!-- <div class="sidebar-background" [ngStyle]="{'background-image':' url(' + sidebarimage + ')'}" ></div> -->
         <!-- style="background-image: url(assets/img/Nova/sidebar-6.png)"> -->
    </div>
    <div class="main-panel">
        <app-navbar-cmp></app-navbar-cmp>
        <router-outlet></router-outlet>
    </div>
    <!-- <app-fixedplugin></app-fixedplugin> -->
    <div class="footer">
        <app-footer-cmp></app-footer-cmp>
    </div>
</div>