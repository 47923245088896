import { Inject, Injectable } from "@angular/core";
import { AuthenticationDetails, CognitoUser, CognitoUserAttribute } from "amazon-cognito-identity-js";
import * as AWS from 'aws-sdk';
import { environment } from "src/environments/environment";
import { CognitoCallback, CognitoUtil, Callback } from "./cognito.service";
import { ApiGatewayService } from "./api.service";
import { UserConfigInputModel, RegistrationUser, NewPasswordUser, UserData, PollDataModel } from "../models/apiRequestModels";
let baseurl;
@Injectable({
    providedIn: 'root'
})
export class UserRegistrationService {
    requestUrl = localStorage.getItem('requestUrl');
    constructor(@Inject(CognitoUtil) public cognitoUtil: CognitoUtil, private apiserivce: ApiGatewayService) {
    let lstbaseUrls = environment.baseUrls.split(",");
    baseurl = "https://"+lstbaseUrls[0];
    }
    public userConfigModelList: UserConfigInputModel[];
    register(user: RegistrationUser, callback: CognitoCallback): void {
        console.log("UserRegistrationService: user is " + user);
        let attributeList = [];
        let validattributeList = [];
        let dataEmail = {
            Name: 'email',
            Value: user.email
        };
        let dataNickname = {
            Name: 'nickname',
            Value: user.name
        };
        let homedirectory = {
            Name: 'custom:homedirectory',
            Value: environment.home + user.organization.directBucket
        };
        let bucketname = {
            Name: 'custom:bucketname',
            Value: environment.bucketname
        };
        let phone_number_verified = {
            Name: 'phone_number_verified',
            Value: 'false'
        };
        let email_verified = {
            Name: 'email_verified',
            Value: 'True'
        };
        let userstatus = {
            Name: 'userstatus',
            Value: 'NEW_PASSWORD_REQUIRED'
        };
        attributeList.push(new CognitoUserAttribute(dataEmail));
        attributeList.push(new CognitoUserAttribute(dataNickname));
        attributeList.push(new CognitoUserAttribute(homedirectory));
        attributeList.push(new CognitoUserAttribute(bucketname));
        attributeList.push(new CognitoUserAttribute(userstatus));
        console.log("this.cognitoUtil.getUserPool() " + JSON.stringify(this.cognitoUtil.getUserPool()));
        this.cognitoUtil.getUserPool().signUp(user.name, user.password, attributeList, validattributeList, function (err, result) {
            if (err) {
                callback.cognitoCallback(err.message, null);
            } else {
                console.log("UserRegistrationService: registered user is " + result);
                callback.cognitoCallback(null, result);
            }
        });
    }

    adminregister(user: RegistrationUser, callback: CognitoCallback): void {
        console.log("user", user);
        let confirmregistrationUser = new NewPasswordUser();
        let website = baseurl;
        //this.cognitoUtil.getAWS();
        const COGNITO_CLIENT = new AWS.CognitoIdentityServiceProvider({
            apiVersion: "2016-04-19",
            region: environment.region
        });
       let userConfigModel = new UserData();
        let homedirectory = "/home";
        userConfigModel.custExtId = user.organization == null ? null : user.organization.custExtId;
        userConfigModel.deptExtId = user.organization == null ? null : user.organization.deptExtId;
        userConfigModel.userName = user.name;
        userConfigModel.isMMP = user.IsMMP + "";
        userConfigModel.isadmin = user.Isadmin + "";
        userConfigModel.authorExtId = user.authorExtId;
        userConfigModel.firstName = user.firstName;
        userConfigModel.middleName = user.middleName;
        userConfigModel.lastName = user.lastName;
        userConfigModel.MobileNo = user.phone_number;
        userConfigModel.roleId = user.roleId;
        userConfigModel.password = user.password;
        userConfigModel.isMFA = user.IsMFA;
        userConfigModel.allowTranscriptEditing = user.allowTranscriptEditing;
        userConfigModel.email = user.email;
        userConfigModel.TenantId = user.TenantId;
        userConfigModel.webSite=user.email;
        userConfigModel.webSite=user.ncUrl;
        userConfigModel.profile=user.EmailProfile;
        userConfigModel.addNSAuthor=user.addNSAuthor;
        userConfigModel.bucket=environment.bucketname;
        userConfigModel.isActive=user.isActive;
        userConfigModel.hostTenants = user.hostTenants;
        userConfigModel.isSuperAuthor = user.IsSuperAuthor;
        userConfigModel.mmpDefaultDepartment=user.mmpDefaultDepartment;
        if(user.ncUrl)
        {
            website = user.ncUrl;
        }
        if(user.resend)
        {
            userConfigModel.id = user.id;
        }
        if (user.Isadmin) {
            homedirectory = homedirectory
        }
        else {
            homedirectory = environment.home + user.organization.directBucket
        }
        let poolData: PollDataModel;
        if (user.resend == true) {
            if (user.email == null) {
                poolData = {
                    UserPoolId: null,
                    Username: user.name,
                    DesiredDeliveryMediums: ["EMAIL"],
                    TemporaryPassword: user.password,
                    MessageAction: "RESEND",
                    IsResend:true,
                    UserAttributes: [
                        {
                            Name: "website",
                            Value: website
                        },
                        {
                            Name: "custom:homedirectory",
                            Value: homedirectory
                        },
                        {
                            Name: 'custom:bucketname',
                            Value: environment.bucketname
                        },
                        {
                            Name: 'custom:isadmin',
                            Value: user.Isadmin + ""
                        },
                        {
                            Name: "profile",
                            Value: user.EmailProfile
                        }
                    ]
                };
            }
            else if (user.phone_number == '' || user.phone_number == null) {
                poolData = {
                    UserPoolId: null,
                    Username: user.name,
                    DesiredDeliveryMediums: ["EMAIL"],
                    TemporaryPassword: user.password,
                    MessageAction: "RESEND",
                    IsResend:true,
                    UserAttributes: [
                        {
                            Name: "website",
                            Value: website
                        },
                        {
                            Name: "email",
                            Value: user.email
                        },
                        {
                            Name: "email_verified",
                            Value: "true"
                        },
                        {
                            Name: "custom:homedirectory",
                            Value: homedirectory
                        },
                        {
                            Name: 'custom:bucketname',
                            Value: environment.bucketname
                        },
                        {
                            Name: 'custom:isadmin',
                            Value: user.Isadmin + ""
                        },
                        {
                            Name: "profile",
                            Value: user.EmailProfile
                        }
                    ]
                }
            }
            else {
                poolData = {
                    UserPoolId: null,
                    Username: user.name,
                    DesiredDeliveryMediums: ["EMAIL"],
                    TemporaryPassword: user.password,
                    MessageAction: "RESEND",
                    IsResend:true,
                    UserAttributes: [
                        {
                            Name: "website",
                            Value: website
                        },
                        {
                            Name: "email",
                            Value: user.email
                        },
                        {
                            Name: "email_verified",
                            Value: "true"
                        },
                        {
                            Name: "phone_number",
                            Value: user.phone_number
                        },
                        {
                            Name: "phone_number_verified",
                            Value: "false"
                        },
                        {
                            Name: "custom:homedirectory",
                            Value: homedirectory
                        },
                        {
                            Name: 'custom:bucketname',
                            Value: environment.bucketname
                        },
                        {
                            Name: 'custom:isadmin',
                            Value: user.Isadmin + ""
                        },
                        {
                            Name: "profile",
                            Value: user.EmailProfile
                        }
                    ]
                };
            }
        }
        else {
            if (user.email == null) {
                poolData = {
                    UserPoolId: null,
                    Username: user.name,
                    TemporaryPassword: user.password,
                    IsResend:false,
                    UserAttributes: [
                        {
                            Name: "website",
                            Value: website
                        },
                        {
                            Name: "custom:homedirectory",
                            Value: homedirectory
                        },
                        {
                            Name: 'custom:bucketname',
                            Value: environment.bucketname
                        },
                        {
                            Name: 'custom:isadmin',
                            Value: user.Isadmin + ""
                        },
                        {
                            Name: "profile",
                            Value: user.EmailProfile
                        }
                    ]
                };
            }
            else if (user.phone_number == '' || user.phone_number == null) {
                poolData = {
                    UserPoolId: null,
                    Username: user.name,
                    IsResend:false,
                    UserAttributes: [
                        {
                            Name: "website",
                            Value: website
                        },
                        {
                            Name: "email",
                            Value: user.email
                        },
                        {
                            Name: "email_verified",
                            Value: "true"
                        },
                        {
                            Name: "custom:homedirectory",
                            Value: homedirectory
                        },
                        {
                            Name: 'custom:bucketname',
                            Value: environment.bucketname
                        },
                        {
                            Name: 'custom:isadmin',
                            Value: user.Isadmin + ""
                        },
                        {
                            Name: "profile",
                            Value: user.EmailProfile
                        }
                    ]
                }
            }
            else {
                poolData = {
                    UserPoolId: null,
                    Username: user.name,
                    DesiredDeliveryMediums: ["EMAIL"],
                    TemporaryPassword: user.password,
                    IsResend:false,
                    UserAttributes: [
                        {
                            Name: "website",
                            Value: website
                        },
                        {
                            Name: "email",
                            Value: user.email
                        },
                        {
                            Name: "email_verified",
                            Value: "true"
                        },
                        {
                            Name: "phone_number",
                            Value: user.phone_number
                        },
                        {
                            Name: "phone_number_verified",
                            Value: "false"
                        },
                        {
                            Name: "custom:homedirectory",
                            Value: homedirectory
                        },
                        {
                            Name: 'custom:bucketname',
                            Value: environment.bucketname
                        },
                        {
                            Name: 'custom:isadmin',
                            Value: user.Isadmin + ""
                        },
                        {
                            Name: "profile",
                            Value: user.EmailProfile
                        }
                    ]
                };
            }
        }
        let result;
        console.log(" poolData: " + JSON.stringify(poolData));
        userConfigModel.homeDirectory = homedirectory;
        const userConfigModelData: UserConfigInputModel = {
            userData: userConfigModel, 
            pooldata: poolData         
        };
        this.apiserivce.UserConfig(userConfigModelData).subscribe(
            response => {
                result = response;
                if(response.success == false){
                    console.log("User account already exists:: " + JSON.stringify(response));
                    callback.cognitoCallback(response.message, response);
                }
                else{
                    console.log("UserConfig: data message:: " + JSON.stringify(response));
                    callback.cognitoCallback("User Created Successfully", response);
                }
            },
            error => {
                console.error("UserConfig: error message:: " + JSON.stringify(error));
                callback.cognitoCallback("Failed to create user", error);
            }
        );
        // if (user.email == null) {
        //     confirmregistrationUser.existingPassword = user.password;
        //     confirmregistrationUser.password = user.password;
        //     confirmregistrationUser.username = user.name;
        //     console.log(confirmregistrationUser);
        //     this.newPassword(confirmregistrationUser, callback);
        // }
        callback.cognitoCallback(null, result);
    }
    getcognitouserlist(callback: CognitoCallback): void {
        //this.cognitoUtil.getAWS();
        const COGNITO_CLIENT = new AWS.CognitoIdentityServiceProvider({
            apiVersion: "2016-04-19",
            region: environment.region
        });

        let param = {

            UserPoolId: null,
            Limit:60,
        };
        COGNITO_CLIENT.listUsers(param, (error, data) => {
            if (error) {
                callback.cognitoCallback(error.message, null);
            } else {
                callback.cognitoCallback(null, data);
            }
        });

    }
    getcognitonextuserlist(PaginationToken:string ,callback: CognitoCallback): void {
        //this.cognitoUtil.getAWS();
        const COGNITO_CLIENT = new AWS.CognitoIdentityServiceProvider({
            apiVersion: "2016-04-19",
            region: environment.region
        });
        let param = {

            UserPoolId: null,
            Limit:60,
            PaginationToken:PaginationToken
        };
        COGNITO_CLIENT.listUsers(param, (error, data) => {
            if (error) {
                callback.cognitoCallback(error.message, null);
            } else {
                callback.cognitoCallback(null, data);
            }
        });
    }

    confirmRegistration(username: string, confirmationCode: string, callback: CognitoCallback): void {
        let userData = {
            Username: username,
            Pool: this.cognitoUtil.getUserPool()
        };
        let cognitoUser = new CognitoUser(userData);
        cognitoUser.confirmRegistration(confirmationCode, true, function (err, result) {
            if (err) {
                callback.cognitoCallback(err.message, null);
            } else {
                callback.cognitoCallback(null, result);
            }
        });
    }

    resendCode(username: string, callback: CognitoCallback): void {
        let userData = {
            Username: username,
            Pool: this.cognitoUtil.getUserPool()
        };
        let cognitoUser = new CognitoUser(userData);
        cognitoUser.resendConfirmationCode(function (err, result) {
            if (err) {
                callback.cognitoCallback(err.message, null);
            } else {
                callback.cognitoCallback(null, result);
            }
        });
    }

    newPassword(newPasswordUser: NewPasswordUser, callback: CognitoCallback): void {
        console.log(newPasswordUser);
        // Get these details and call
        let authenticationData = {
            Username: newPasswordUser.username,
            Password: newPasswordUser.existingPassword,
        };
        let authenticationDetails = new AuthenticationDetails(authenticationData);
        let userData = {
            Username: newPasswordUser.username,
            Pool: this.cognitoUtil.getUserPool()
        };
        console.log("UserLoginService: Params set...Authenticating the user");
        let cognitoUser = new CognitoUser(userData);
        console.log("UserLoginService: config is " + AWS.config);
        cognitoUser.authenticateUser(authenticationDetails, {
            newPasswordRequired: function (userAttributes, requiredAttributes) {
                // User was signed up by an admin and must provide new
                // password and required attributes, if any, to complete
                // authentication.
                // the api doesn't accept this field back
                delete userAttributes.email_verified;
                cognitoUser.completeNewPasswordChallenge(newPasswordUser.password, requiredAttributes, {
                    onSuccess: function (result) {
                        callback.cognitoCallback(null, userAttributes);
                    },
                    onFailure: function (err) {
                        callback.cognitoCallback(err, null);
                    }
                });
            },
            onSuccess: function (result) {
                callback.cognitoCallback(null, result);
            },
            onFailure: function (err) {
                callback.cognitoCallback(err, null);
            }
        });
    }
    adminUpdateUserAttributes(user: RegistrationUser, callback: CognitoCallback): void {

        let confirmregistrationUser = new NewPasswordUser();
        let website= baseurl;
        //this.cognitoUtil.getAWS();
        const COGNITO_CLIENT = new AWS.CognitoIdentityServiceProvider({
            apiVersion: "2016-04-19",
            region: environment.region
        });

        let userConfigModel = new UserData();
        let homedirectory = "/home";
        userConfigModel.userName = user.name;
        userConfigModel.isadmin = user.Isadmin + "";
        userConfigModel.isMMP = user.IsMMP + "";
        userConfigModel.id = user.id;
        userConfigModel.authorExtId = user.authorExtId;
        userConfigModel.firstName = user.firstName;
        userConfigModel.middleName = user.middleName;
        userConfigModel.lastName = user.lastName;
        userConfigModel.MobileNo = user.phone_number;
        userConfigModel.roleId = user.roleId;
        userConfigModel.password = user.password;
        userConfigModel.TenantId = user.TenantId;
        userConfigModel.isMFA = user.IsMFA;
        userConfigModel.allowTranscriptEditing = user.allowTranscriptEditing;
        userConfigModel.webSite=user.email;
        userConfigModel.webSite=user.ncUrl;
        userConfigModel.profile=user.EmailProfile;
        userConfigModel.addNSAuthor=user.addNSAuthor;
        userConfigModel.bucket=environment.bucketname;
        userConfigModel.custExtId = user.organization ?  user.organization.custExtId : null;
        userConfigModel.deptExtId = user.organization ?  user.organization.deptExtId : null;
        userConfigModel.isActive = user.isActive;
        userConfigModel.email = user.email;
        userConfigModel.status = user.status;
        userConfigModel.hostTenants = user.hostTenants;
        userConfigModel.isSuperAuthor = user.IsSuperAuthor;
        userConfigModel.mmpDefaultDepartment=user.mmpDefaultDepartment;
        if(user.ncUrl)
        {
            website = user.ncUrl;
        }
        if (user.Isadmin) {
            homedirectory = homedirectory
        }
        else {
            homedirectory = environment.home + user.organization.directBucket
        }
        let poolData: PollDataModel;
        if (user.resend == true) {
            if (user.email == null) {
                poolData = {

                    UserPoolId: null,
                    Username: user.name,
                    IsResend:false,
                    UserAttributes: [
                        {
                            Name: "website",
                            Value: website
                        },
                        {
                            Name: "custom:homedirectory",
                            Value: homedirectory
                        },
                        {
                            Name: 'custom:bucketname',
                            Value: environment.bucketname
                        },
                        {
                            Name: 'custom:isadmin',
                            Value: user.Isadmin + ""
                        },
                        {
                            Name: "profile",
                            Value: user.EmailProfile
                        }
                    ]
                };
            }
            else if(user.phone_number == '' ||user.phone_number == null ){
                poolData = {
                UserPoolId: null,
                Username: user.name,
                IsResend:false,
                UserAttributes: [
                    {
                        Name: "website",
                        Value: website
                    },
                    {
                        Name: "email",
                        Value: user.email
                    },
                    {
                        Name: "email_verified",
                        Value: "true"
                    },
                    {
                        Name: "custom:homedirectory",
                        Value: homedirectory
                    },
                    {
                        Name: 'custom:bucketname',
                        Value: environment.bucketname
                    },
                    {
                        Name: 'custom:isadmin',
                        Value: user.Isadmin + ""
                    },
                    {
                        Name: "profile",
                        Value: user.EmailProfile
                    }
                ]
            }
        }
            else {
                poolData = {

                    UserPoolId: null,
                    Username: user.name,
                    MessageAction: "RESEND",
                    IsResend:true,
                    UserAttributes: [
                        {
                            Name: "website",
                            Value: website
                        },
                        {
                            Name: "email",
                            Value: user.email
                        },
                        {
                            Name: "email_verified",
                            Value: "true"
                        },
                        {
                            Name: "custom:homedirectory",
                            Value: homedirectory
                        },
                        {
                            Name: 'custom:bucketname',
                            Value: environment.bucketname
                        },
                        {
                            Name: 'custom:isadmin',
                            Value: user.Isadmin + ""
                        },
                        {
                            Name: "profile",
                            Value: user.EmailProfile
                        }
                    ]
                };
            }
        }
        else {
            if (user.email == null) {
                poolData = {

                    UserPoolId: null,
                    Username: user.name,
                    IsResend:false,
                    UserAttributes: [
                        {
                            Name: "website",
                            Value: website
                        },
                        {
                            Name: "custom:homedirectory",
                            Value: homedirectory
                        },
                        {
                            Name: 'custom:bucketname',
                            Value: environment.bucketname
                        },
                        {
                            Name: 'custom:isadmin',
                            Value: user.Isadmin + ""
                        },
                        {
                            Name: "profile",
                            Value: user.EmailProfile
                        }
                    ]
                };
            } else if(user.phone_number == '' ||user.phone_number == null ){
                poolData = {
                UserPoolId: null,
                Username: user.name,
                IsResend:false,
                UserAttributes: [
                    {
                        Name: "website",
                        Value: website
                    },
                    {
                        Name: "email",
                        Value: user.email
                    },
                    {
                        Name: "email_verified",
                        Value: "true"
                    },
                    {
                        Name: "custom:homedirectory",
                        Value: homedirectory
                    },
                    {
                        Name: 'custom:bucketname',
                        Value: environment.bucketname
                    },
                    {
                        Name: 'custom:isadmin',
                        Value: user.Isadmin + ""
                    },
                    {
                        Name: "profile",
                        Value: user.EmailProfile
                    }
                ]
            }
        }
            else {
                poolData = {

                    UserPoolId: null,
                    Username: user.name,
                    IsResend:false,
                    UserAttributes: [
                        {
                            Name: "website",
                            Value: website
                        },
                        {
                            Name: "email",
                            Value: user.email
                        },
                        {
                            Name: "email_verified",
                            Value: "true"
                        },
                        {
                            Name: "phone_number",
                            Value: user.phone_number
                        },
                        {
                            Name: "phone_number_verified",
                            Value: "false"
                        },
                        {
                            Name: "custom:homedirectory",
                            Value: homedirectory
                        },
                        {
                            Name: 'custom:bucketname',
                            Value: environment.bucketname
                        },
                        {
                            Name: 'custom:isadmin',
                            Value: user.Isadmin + ""
                        },
                        {
                            Name: "profile",
                            Value: user.EmailProfile
                        }
                    ]
                };
            }
        }
        let result;
        userConfigModel.homeDirectory=homedirectory;
        const userConfigModelData: UserConfigInputModel = {
            userData: userConfigModel, 
            pooldata: poolData         
          };
          this.apiserivce.UserConfig(userConfigModelData).subscribe(response => {
            result = response;
            console.log("UserConfig: data message:: "+ JSON.stringify(response));
            callback.cognitoCallback("User Updated Successfully", response);
        },
        error => {
            console.error("UserConfig: error message:: " + JSON.stringify(error));
            callback.cognitoCallback("Failed to update user", error);
        });
        // if (user.email == null) {
        //     confirmregistrationUser.existingPassword = user.password;
        //     confirmregistrationUser.password = user.password;
        //     confirmregistrationUser.username = user.name;
        //     console.log(confirmregistrationUser);
        //     this.newPassword(confirmregistrationUser, callback);
        // }
       callback.cognitoCallback(null, result);
    }
    admingetuser(user: string, callback: CognitoCallback): void {
        //this.cognitoUtil.getAWS();
        const COGNITO_CLIENT = new AWS.CognitoIdentityServiceProvider({
            apiVersion: "2016-04-19",
            region: environment.region
        });
        let poolData;
        poolData = {
            UserPoolId: environment.userPoolId,
            Username: user
        }

        COGNITO_CLIENT.adminGetUser(poolData, (error, data) => {
            if (error) {
                console.log("adminGetUser: error is: " + JSON.stringify(error));
                console.log("adminGetUser: error message is: " + error.message);
                callback.cognitoCallback(user, error);
            }
            if (data != null) {
                 callback.cognitoCallback("Get User data Successfully", data);
            }
        });
    }
    admindisableUser(user: string, callback: Callback) {
        //this.cognitoUtil.getAWS();
        const COGNITO_CLIENT = new AWS.CognitoIdentityServiceProvider({
            apiVersion: "2016-04-19",
            region: environment.region
        });
        let poolData;
        poolData = {
            UserPoolId: environment.userPoolId,
            Username: user
        }
        COGNITO_CLIENT.adminDisableUser(poolData, (error, data) => {
            if (error) {
                callback.callbackWithParam(error);
            }
            if (data != null) {
                callback.callbackWithParam(data);
            }
        });
    }

    adminenableUser(user: string, callback: CognitoCallback): void {
        //this.cognitoUtil.getAWS();
        const COGNITO_CLIENT = new AWS.CognitoIdentityServiceProvider({
            apiVersion: "2016-04-19",
            region: environment.region
        });
        let poolData;
        poolData = {
            UserPoolId: environment.userPoolId,
            Username: user
        }
        COGNITO_CLIENT.adminEnableUser(poolData, (error, data) => {
            if (error) {
                callback.cognitoCallback(user, error);
            }
            if (data != null) {
                callback.cognitoCallback("User Enable Successfully", data);
            }
        });
    }
}
