import { Component,Inject, OnInit } from '@angular/core';
import { BackendService } from './../../../services/backend.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router, ActivatedRoute, NavigationStart } from '@angular/router';

@Component({
  selector: 'app-file-names',
  templateUrl: './file-names.component.html',
  styleUrls: ['./file-names.component.css']
})
export class FileNamesComponent implements OnInit {

  fileNamesList: any;
  constructor(private backendService: BackendService,
    public dialogRef: MatDialogRef<FileNamesComponent>,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public Data: any) { }

  ngOnInit(): void {
    console.log("open file names dialog ", this.Data)
        this.fileNamesList = this.Data.fileNamesList;
        console.log("fileNamesList ", this.fileNamesList)
        this.router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
              // Call your dialogClose() method when navigating to a different tab
              this.dialogClose();
            }
          });
  }
  dialogClose() {
    this.dialogRef.close();
  }
}
