<h1 mat-dialog-title> <img class="img-icon mr-2" src="{{ image }}" alt="{{altTitleNames}}" />
  {{ dialogTitle }}:<button mat-dialog-close type="button" class="close pull-right" (click)='dialogClose()'
    aria-label="Close">&times;</button></h1>
<hr>
<div mat-dialog-content>
  <div class="row" *ngIf="showNotes" style="margin-left: 1px;">
    <div class="col-md-12">
      <p class="header-text-1" style="font-weight: 500;">Typist Notes</p>
      <jqxTextArea aria-hidden="true" #notesComponent [width]="'99%'" [height]="175" maxLength=1000>
      </jqxTextArea>
    </div>
    <div class="col-md-12 mt-2 mb-1" *ngIf="showCustomerNotes">
      <p class="header-text-1" style="font-weight: 500;">Customer Notes</p>
      <jqxTextArea aria-hidden="true" #customerNotesComponent [width]="'99%'" [height]="175" maxLength=1000>
      </jqxTextArea>
    </div>
  </div>
  <div class="row" *ngIf="showEstimateDetails" style="margin-left: 1px;">
    <mat-tab-group style="width: 97%;" (selectedTabChange)="tabClick($event)" [(selectedIndex)]="selectedIndex">
      <mat-tab label="Estimate" *ngIf="!isForeignLanguage">
        <form [formGroup]='estimationForm'>
          <div class="row" style="padding-left:2%;  padding-top: 3%;">
            <div class="col-md-6">
              <mat-form-field class="example-full-width">
                <mat-label>Job Type</mat-label>
                <input id='jobtype-values' type="text" matInput [formControl]="EstimatejobtypeControl"
                  [matAutocomplete]="jobtypeValuesAuto" (change)='jobtypeChange()'>
                <mat-autocomplete id='jobtype-values-auto' #jobtypeValuesAuto="matAutocomplete"
                  [displayWith]="displayFn" (optionSelected)="jobTypeChangeEvent($event.option.value)">
                  <mat-option *ngFor="let jobtype of jobTypefilteredOptions | async" [value]="jobtype">
                    {{jobtype.name}}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div>
            <div class="col-md-6">
            </div>
          </div>
          <div class="row" style="padding-left:2%;">
            <div class="col-md-6">
              <mat-form-field class="example-full-width">
                <mat-label>Number of Minutes</mat-label>
                <input matInput formControlName="numberOfMins" required type='text' (input)="onInput($event)"
                  pattern="[0-9]{1,6}" (keyup)="changeMinutes()" maxlength="6">
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field class="example-full-width">
                <mat-label>Receipt email for Estimation</mat-label>
                <input matInput formControlName="receiptEmail" maxlength=200 type='email'
                  pattern="^[a-zA-Z0-9]+(\.[a-zA-Z0-9]+)*@[a-zA-Z0-9-]+\.(co\.in|com|cc|org|net|tld|uk|ca|us)$">
                <mat-error *ngIf="isValid('receiptEmail', 'pattern')">
                  Receipt Email is <strong>not valid</strong>
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="row" style="padding-left:2%">
            <div class="col-md-6" *ngIf="showEstimateAmount">
              <label id="mylabel" style="color:#2CABE2;font-weight:1000; padding-top: 3%;">Total Amount : $
                {{totalEstimateAmount | number:'1.2-2'}}</label> &nbsp;
            </div>
            <div class="col-md-6"></div>
          </div>
          <div class="row" *ngIf="showEstimateAmount">
            <p style="text-align: left; margin-left: 3%;">{{DisplayEstimationMessage}}</p>
          </div>
          <div class="row" style="padding-left: 2%;">
            <div class="col-md-6">
              <button class='btn btn-viq btn-viq-primary ' (click)='GenerateEstimate()'>Generate Estimate</button>
            </div>
            <div class="col-md-6">
              <button class='btn btn-viq btn-viq-primary' *ngIf="showEstimateAmount && showAcceptButton"
                (click)='AcceptEstimate()'>Accept Estimate</button>
            </div>
          </div>
          <div *ngIf="showEstimateAmount && showAcceptButton" style="padding-top:2% ">
            <div class="row" style="padding-left:45%;">
              <button class="tncbutton" [disabled]='submitted' (click)='viewTnC()'>T & Cs</button>
            </div>
            <div class="row">
              <p style="text-align: left; margin-left: 3%;"><strong>Disclaimer: </strong>This is only an estimate.The
                final price will be calculated once the transcript has been completed.</p>
            </div>
          </div>
        </form>
      </mat-tab>
      <mat-tab style="margin-left: 10px;" label="FL Estimate" *ngIf="isForeignLanguage">
        <form [formGroup]='estimationForm'>
          <div class="row" style="padding-top: 3%; margin-left:10px">
            <div class="col-md-6">
              <mat-form-field class="example-full-width">
                <mat-label>Foreign Language Order</mat-label>
                <input type="text" matInput [formControl]="ForeignLanguageControl" [matAutocomplete]="fllanguageAuto"
                  (change)='fllanguageChange()'>
                <mat-autocomplete #fllanguageAuto="matAutocomplete" [displayWith]="displayFnFL"
                  (optionSelected)="flLanguageChangeEvent($event)">
                  <mat-option *ngFor="let FLlanguage of ForeignLanguagevalues" [value]="FLlanguage" [selectedIndex]="0">
                    {{ FLlanguage.name }}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div>
            <div class="col-md-6" style="padding-left: 2%">
              <mat-form-field class="example-full-width">
                <mat-label>Language</mat-label>
                <input type="text" matInput [formControl]="languageControl" [matAutocomplete]="languageAuto">
                <mat-autocomplete #languageAuto="matAutocomplete" [displayWith]="displayFnLanguage"
                  (optionSelected)="languageChangeEvent($event)">
                  <mat-option *ngFor="let language of languages" [value]="language" [selectedIndex]="0">
                    {{ language.Language }}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div>
          </div>
          <div class="row" style="margin-left:10px">
            <div class="col-md-6">
              <mat-form-field class="example-full-width">
                <mat-label>Number of Minutes</mat-label>
                <input matInput formControlName="numberOfMins" required type='text' (input)="onInput($event)"
                  pattern="[0-9]{1,6}" (keyup)="changeMinutes()" maxlength="6">
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field class="example-full-width">
                <mat-label>Estimate Email Recipient</mat-label>
                <input matInput formControlName="receiptEmail" maxlength=200 type='email'
                  pattern="^[a-zA-Z0-9]+(\.[a-zA-Z0-9]+)*@[a-zA-Z0-9-]+\.(co\.in|com|cc|org|net|tld|uk|ca|us)$">
                <mat-error *ngIf="isValid('receiptEmail', 'pattern')">
                  Receipt Email is <strong>not valid</strong>
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="row" style=" margin-left: 10px;">
            <div class="col-md-6">
              <label id="mylabel" class="font-red-shade">There is a minimum charge of $150.00 </label> &nbsp;
            </div>
            <div class="col-md-6" *ngIf="showEstimateAmount">
              <label id="mylabel" style="color:#2CABE2;font-weight:1000;">Total Amount : $ {{totalEstimateAmount |
                number:'1.2-2'}}</label> &nbsp;
            </div>
          </div>
          <div class="row" style="margin-left: 10px;">
            <div class="col-md-6">
              <button class='btn btn-viq btn-viq-primary ' (click)='GenerateEstimate()'>Generate Estimate</button>
            </div>
            <div class="col-md-6">
              <button class='btn btn-viq btn-viq-primary' *ngIf="showEstimateAmount && showAcceptButton"
                (click)='AcceptEstimate()'>Accept Estimate</button>
            </div>
          </div>
          <div class="row" style="margin-left: 18px;" *ngIf="showEstimateAmount">
            <p>{{DisplayEstimationMessage}}</p>
          </div>
          <div *ngIf="showEstimateAmount && showAcceptButton" style="padding-top:1%; ">
            <div class="row" style="padding-left:45%;">
              <button class="tncbutton" [disabled]='submitted' (click)='viewTnC()'>T & Cs</button>
            </div>
            <div class="row" style="margin-left: 18PX; margin-right: 5px;">
              <p><strong>Disclaimer: </strong>This is only an estimate.The final price will be calculated once the
                transcript has been completed.</p>
            </div>
          </div>
        </form>
      </mat-tab>
    </mat-tab-group>
  </div>
  <div class='row' *ngIf="!showNotes">
    <div class='col-md-5'>
      <div *ngIf="isjob">
        <div class="row" style="margin-left: 5px;" *ngIf="showTransSelection">
          <div class="col-md-12" *ngIf="!showRecordzone">
            <mat-radio-group aria-label="Select an option">
              <mat-radio-button #sepTrans (click)="TranscriptionTypeClick(1)" value="1" [checked]="!IsMergeAudios">
                Separate transcript per Audio/Video file
              </mat-radio-button>
              <br>
              <mat-radio-button #oneTrans (click)="TranscriptionTypeClick(2)" value="2" [checked]="IsMergeAudios"
                style="margin-left: 0.1%;">Combine Audio/Video files into one transcript
              </mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
        <div class='row' style="margin-left: 5px;" *ngIf="showTransSelectionMsg">
          <div class="col-md-12">
            <span class="upload-Audio-formats-text font-blue-shade" style="font-size: 13px;">* If you have
              one
              or more audio files and want a transcript for each audio file, choose Separate Transcripts. If
              you have multiple audio files that you want put into one document, choose One Transcript.</span>
          </div>
        </div>
      </div>

      <div class="row" style="text-align: center; " *ngIf="showFileDragzone">
        <div class="col-md-12" style="margin-left: 20px !important; margin-top: 20px !important;">
          <ngx-file-drop id="dropZone" dropZoneLabel="Drop files here" (onFileDrop)="dropped($event)"
            (onFileOver)="fileOver($event)" (onFileLeave)="fileLeave($event)" (multiple)="true">
            <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
              <div class="col-sm-6" style="margin-top: 10px;">
                <p class="header-text-1">Drag and drop the files here to upload </p>
              </div>
              <div class="col-sm-6 ">
                <button type="button"
                  class="btn btn-outline-primary btn-viq-primary-outline attachment-btn attachment-icon"
                  (click)="openFileSelector()">
                  <i class="fa fa-folder-open mr-1"> </i>
                  {{ dragButtonTitle }} </button>
              </div>
              <div class="col-sm-6" *ngIf="showMediaUpload">
                <div *ngIf="IsMergeAudios || IsMasterJob; else elseBlock">
                  <span class="upload-Audio-formats-text font-red-shade">Please maintain same format
                    for
                    all
                    Audios/Videos to Merge.</span>
                </div>
                <ng-template #elseBlock>
                  <span class="upload-Audio-formats-text font-red-shade">
                    Please upload files with
                    mp2/mp3/mp4/wav/m4a/m4v /dss/ds2/aac/wma/oga/trm/ opus/avi/ogg/mkv/wmv/ vob/mov/mts/zip/mod
                    formats.</span>
                </ng-template>
              </div>
              <div class="col-sm-6" *ngIf="showAttachment">
                <span class="upload-Audio-formats-text font-red-shade">Please attach supporting
                  documents here.</span>
              </div>
            </ng-template>
          </ngx-file-drop>
        </div>
      </div>
      <div class="row" style="text-align: center;" *ngIf="showRecordzone">
        <div class="col-md-5 mt-4 ml-auto mr-auto ">
          <div class="col-sm-12 mt-3" *ngIf="!IsMergeAudios">
            <p class="header-text-2">Record Audio</p>
            <br>
          </div>
          <div *ngIf="!IsMergeAudios">
            <button class="drag-down-button" (click)="startRecording()" (click)="OnclickStartButton()"
              *ngIf="startRecordShow">
              <i class="material-icons-outlined material-icons-outlined-upload">mic_none</i>
            </button>
          </div>
          <p style="margin-bottom: -10px; color:red ;font-size:medium;font-weight: bold;" *ngIf="RecordingTimer">
            Recording {{hr}}:{{min}}:{{sec}}</p>
          <p style="margin-bottom: -10px; color:red ;font-size:medium;font-weight: bold;;" *ngIf="PauseRecordingTimer">
            Paused {{hr}}:{{min}}:{{sec}}</p>
          <div class="mt-4">
            <button mat-min-fab class="btn btn-warning btn-fab" (click)="pause()" (click)="OnclickPauseButton()"
              *ngIf="pauseButtonShow">
              <i class="material-icons">pause_circle</i>
            </button>
            <button mat-min-fab class="btn btn-primary btn-fab" (click)="resume()" *ngIf="resumeRecordShow">
              <i class="material-icons">play_circle</i>
            </button>
            <button mat-min-fab class="btn btn-viq-secondary btn-fab" (click)="stopRecording()" *ngIf="stopButtonShow">
              <i class="material-icons">stop_circle</i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-7 upload-icon-dragzone" *ngIf="showFileList">
      <div class="row mt-1 " style="margin-right: 2px; margin-left: 40px;">
        <p class="header-text-2 ml-2">{{ filelistTitle }}: {{ getFilesCount() }}</p>

        <div class='col-md-12 scroll' *ngIf="showAudioFileList">
          <hr>
          <div *ngIf="IsMergeAudios; else elseBlock">
            <div style="overflow-y: hidden; overflow-x: hidden;  ">
              <mat-chip-list aria-hidden="true">
                <mat-chip *ngFor="let item of ListOfAudioFiles" [selectable]="selectable" [removable]="removable"
                  (removed)="removeFileByName(item.name)">
                  <span class="mat-text-span" style="width: 95% !important" title="{{item.FileName}}"> {{item.FileName}}
                  </span>
                  <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                </mat-chip>
              </mat-chip-list>
            </div>
          </div>
          <ng-template #elseBlock>
            <div style="overflow-y: hidden; overflow-x: hidden; ">
              <mat-chip-list aria-hidden="true">
                <mat-chip *ngFor="let item of ListOfAudioFiles" [selectable]="selectable" [removable]="removable"
                  (removed)="removeFileByName(item.name)">
                  <span class="mat-text-span" style="width: 95% !important" title="{{item.name}}"> {{item.name}} </span>
                  <div class="mat-icon-div">
                    <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                  </div>
                </mat-chip>
              </mat-chip-list>
            </div>
          </ng-template>
        </div>
        <div class='col-md-12 scroll' *ngIf="!showAudioFileList">
          <hr>
          <div style="overflow-y: hidden; overflow-x: hidden;  ">
            <mat-chip-list aria-hidden="true">
              <mat-chip *ngFor="let item of ListOfAttachments" [selectable]="selectable" [removable]="removable"
                (removed)="removeAttachmentByName(item.fileName)">
                <span class="mat-text-span" style="width: 95% !important" title="{{item.fileName}}"> {{item.fileName}}
                </span>
                <div class="mat-icon-div">
                  <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                </div>
              </mat-chip>
            </mat-chip-list>
          </div>
        </div>
      </div>
    </div>
    <div class="progress col-md-12 ml-2 mr-2" *ngIf="progress">
      <div class="progress-bar" [style.width]="progress + '%'">{{progress}}%</div>
    </div>
  </div>
</div>
<mat-dialog-actions *ngIf="!showEstimateDetails" align="end" style="margin-right: 20px; margin-bottom: 2px; ">
  <button class='btn btn-viq btn-viq-primary pull-right' [disabled]="!isSaveEnabled()"
    (click)='SaveDetails()'>Save</button>
  <button class='btn btn-viq-secondary pull-right ml-1' (click)='dialogClose()'>Cancel</button>
</mat-dialog-actions>
<jqxNotification #msgNotification [position]="'top-right'" [opacity]="1.0" [autoOpen]="false" [autoClose]="true"
  [animationOpenDelay]="800" [autoCloseDelay]="5000" template.success>
</jqxNotification>