import { Router, ActivatedRoute } from '@angular/router';
import { BackendService } from './../../../app/services/backend.service';
import { Component, OnInit, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { jqxNotificationComponent } from 'jqwidgets-ng/jqxnotification';
//import { jqxWindowComponent } from 'jqwidgets-ng/jqxwindow';
//import { JwtHelperService } from '@auth0/angular-jwt';
import { MatDialog } from '@angular/material/dialog';
import { ForgotPasswordDialogComponent } from '../forgot-password-dialog/forgot-password-dialog.component';
// import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
    selector: 'app-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.css'],
})



export class ForgotPasswordComponent implements OnInit {
    brandname = localStorage.getItem('brand');
    bgimage: string;
    test: Date = new Date();
    private toggleButton: any;
    private sidebarVisible: boolean;
    private nativeElement: Node;

    form = new FormGroup({
        userName: new FormControl(null, Validators.required),
    });

    errormessage = "";
    usernameGlobal = "";


    constructor(
        private element: ElementRef,
        private backendService: BackendService,
        public dialog: MatDialog,
        private router: Router
    ) {
    }

    @ViewChild('msgNotification', { static: false }) msgNotification: jqxNotificationComponent;
    @ViewChild('autofocus', { static: false }) autofocus: ElementRef;

    ngAfterViewInit(): void {
       // if()
            this.element.nativeElement.focus();
    }

    ngOnInit() {
        var navbar: HTMLElement = this.element.nativeElement;
        this.toggleButton = navbar.getElementsByClassName('navbar-toggle')[0];
        const body = document.getElementsByTagName('body')[0];
        body.classList.add('login-page');
        body.classList.add('off-canvas-sidebar');
       // const card = document.getElementsByClassName('card')[0];
        //setTimeout(function () {
            // after 1000 ms we add the class animated to the login/register card
           // card.classList.remove('card-hidden');
       // }, 700);

    }

    get f() { return this.form.controls; }

    openDialog(): void {
        const dialogRef = this.dialog.open(ForgotPasswordDialogComponent, {
            width: '50%',
            data: { "currentusername": this.usernameGlobal },
            disableClose: true
        });
    }

    sendNotification(message, messageType) {
        this.msgNotification.elementRef.nativeElement.querySelector('.jqx-notification-content').innerHTML = message;
        this.msgNotification.template(messageType);
        this.msgNotification.open();
    }
    sendVerificationCode() {
        this.usernameGlobal = this.form.controls.userName.value;
        if (this.form.controls.userName.value == null) {
            this.sendNotification("Please enter user name", 'error');
        }
        if (this.form.valid)
            this.backendService.getVerificationCode(this.form.controls.userName.value).subscribe(
                res => {
                    const result = this.backendService.getJSONResponse(res);
                    this.openDialog();
                    console.log("sendverificationcode :::" + JSON.stringify(result))
                    this.sendNotification("We sent a verification code to your registered email", 'info');
                },
                err => {
                    console.log("error result :::", err);
                    this.sendNotification(err.error.data.message, 'error');
                }
            )
    }
    EnterVerificationCode() {
        // console.log("welcomeEnterVerificationCode "+ this.form.controls.userName.value);
        if (this.form.controls.userName.value == null) {
            this.form.markAsDirty({ onlySelf: true });
            this.sendNotification("Please Enter User Name", 'error');
        }
        this.usernameGlobal = this.form.controls.userName.value;
        if (this.form.valid)
            this.openDialog();
    }
    today: number = Date.now();
    backtoLogin() {
        this.router.navigateByUrl('/');
    }
}


