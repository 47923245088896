import { Component, Input, Output, EventEmitter } from "@angular/core";

@Component({
    selector: 'app-progress-bar',
    templateUrl: 'progress-bar.component.html',
    styleUrls: ['progress-bar.component.css']
})

export class ProgressBarComponent {
    @Input() progress: number = 0;
    @Output() progressUpdated = new EventEmitter<number>();
    
    updateProgress(newProgress: number){
        this.progress = newProgress;
        this.progressUpdated.emit(this.progress);
    }
}