import { Directive, ElementRef, HostListener } from '@angular/core';
 
@Directive({
  selector: '[appDraggable]'
})
export class DraggableDirective {
  private isDragging = false;
  private offsetX = 0;
  private offsetY = 0;
 
  constructor(private el: ElementRef) {
    // Setting position to relative or absolute to enable movement
    this.el.nativeElement.style.position = 'absolute';
  }
 
  @HostListener('mousedown', ['$event']) onMouseDown(event: MouseEvent) {
    this.isDragging = true;
    this.offsetX = event.clientX - this.el.nativeElement.getBoundingClientRect().left;
    this.offsetY = event.clientY - this.el.nativeElement.getBoundingClientRect().top;
    event.preventDefault();  // Prevent text selection
  }
 
  @HostListener('document:mousemove', ['$event']) onMouseMove(event: MouseEvent) {
    if (this.isDragging) {
      const x = event.clientX - this.offsetX;
      const y = event.clientY - this.offsetY;
      this.el.nativeElement.style.left = `${x}px`;
      this.el.nativeElement.style.top = `${y}px`;
    }
  }
 
  @HostListener('document:mouseup', ['$event']) onMouseUp() {
    this.isDragging = false;
  }
}