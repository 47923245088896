<h1 mat-dialog-title><img class="img-icon mr-2" src="../../../assets/img/icon/password-icon.png" alt="Password Icon" />
  Forgot password <button mat-dialog-close type="button" class="close pull-right" (click)='dialogClose()' aria-label="Close">&times;</button></h1>
<hr>

<div mat-dialog-content>
  <div class="row">
    <div class="col-md-6">
      <form [formGroup]='form'>
        <div class="form-row">
          <div class="form-group col-md-12">
            <mat-form-field class="example-full-width">
              <mat-label>Verification code</mat-label>
              <input matInput formControlName="verificationcode" name="verificationcode" required type='text'  autocomplete="off">
              <mat-error *ngIf="isValid('verificationcode', 'required')">
                Verification code is <strong>required</strong>
              </mat-error>
            </mat-form-field>
            <mat-form-field class="example-full-width">
              <mat-label>New Password</mat-label>
              <input matInput formControlName="newPassword" required (keyup)="newPasswordChangeEvent()" (keydown.space)="AvoidSpace()" type='password'  [type]="ShownewPassword ? 'text' : 'password'" maxlength=30>
              <div class="action-icon" matSuffix (click)="passwordToggleForNewPassword()">
                <i class="viq-icon {{ShownewPassword ? 'viq-eye-close' : 'viq-eye-open'}}"></i>
              </div>
              <mat-error *ngIf="isValid('newPassword', 'required')">
                New Password is <strong>required</strong>
              </mat-error>
            </mat-form-field>
            <mat-form-field class="example-full-width">
              <mat-label>Confirm Password</mat-label>
              <input matInput formControlName="confirmPassword" required (keydown.space)="AvoidSpace()" (keydown.enter)="changePassword()" type='password'  [type]="ShowConfirmPassword ? 'text' : 'password'" maxlength=30>
              <div class="action-icon" matSuffix (click)="passwordToggleForConfirmPassword()">
                <i class="viq-icon {{ShowConfirmPassword ? 'viq-eye-close' : 'viq-eye-open'}}"></i>
              </div>
              <mat-error *ngIf="isValid('confirmPassword', 'required')">
                Confirm Password is <strong>required</strong>
              </mat-error>
              <mat-error *ngIf="isValid('confirmPassword', 'mustMatch')">
                Confirm Password must match with New Password
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </form>
    </div>
    <div class="col-md-6 mt-3">
      <h4 class="header-text-1">New Password must contain:</h4>
      <div class='password-text'>
        <span id="pwd-characters"><p>At least 8 characters</p></span>
        <span id="pwd-lower-case"><p>At least 1 lower case letter (a-z)</p></span>
        <span id="pwd-upper-case"><p>At least 1 upper case letter (A-Z)</p></span>
        <span id="pwd-number"><p>At least 1 number (0-9)</p></span>
        <span id="pwd-special-characters"><p>At least 1 special character: !@#$%^&*?(space not allowed)</p></span>
      </div>
    </div>
  </div>
</div>
<mat-dialog-actions align="end" style="margin-right: 20px; margin-bottom: 2px;">
  <button class='btn btn-viq btn-viq-primary pull-right'
    (click)='changePassword()'>Submit</button>
  <button class='btn btn-viq-secondary pull-right ml-1' 
    (click)='dialogClose()'>Cancel</button>
</mat-dialog-actions>

<jqxNotification #msgNotification [position]="'top-right'" [opacity]="1.0" [autoOpen]="false"
    [autoClose]="true" [animationOpenDelay]="800" [autoCloseDelay]="5000" template.success>
</jqxNotification>
