import { UploadModule } from './upload-job/upload.module';
import { UploadJobComponent } from './upload-job/upload-job.component';
import { AuthGuard } from './services/auth-guard.service';
import { TableListComponent } from './tables/table-list/table-list.component';
import { LoginComponent } from './login/login.component';
import { Routes } from '@angular/router';

import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';
import { ForceChangePasswordComponent } from './login/force-change-password/force-change-password.component';
import { ForgotPasswordComponent } from './login/forgot-password/forgot-password.component';
import { AdminGuard } from './services/admin-guard.service';
import { TermsAndConditionsComponent } from './login/terms-and-conditions/terms-and-conditions.component';
import { MFAComponent } from './login/mfa/mfa-otp.component';
import { SupportComponent } from './support/support.component';
import { UserLandingComponent } from './login/user-landing/user-landing.component';
import { LoginGuard } from './services/login-guard.service';
import { SSOLoginGuard } from './services/ssologin-guard.service';
import { UserProfileComponent } from './user-profile/user.profile.component';
import { TranscriptionComponent } from './transcription/transcription.component';
import { AuditComponent } from './adminpages/audit/audit.component';
import { NetScribeUploaderComponent } from './adminpages/net-scribe-uploader/net-scribe-uploader.component';


export const AppRoutes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [LoginGuard]
  },
  {
    path: 'nclanding',
    component: UserLandingComponent,
    canActivate: [SSOLoginGuard],
    data: {
      title: 'User Landing Page'
    }
  },
  {
    path: 'forcechange',
    component: ForceChangePasswordComponent
  },
  {
    path: 'mfa',
    component: MFAComponent
  },
  {
    path: 'forgotpassword',
    component: ForgotPasswordComponent
  },
  {
    path: 'termconditions',
    component: TermsAndConditionsComponent
  },
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: 'admin',
    component: AdminLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'job-list',
        loadChildren: () => import('../app/tables/tables.module').then(m => m.TablesModule)
        //loadChildren: './tables/tables.module#TablesModule'
      },
      {
        path: 'upload-job',
        loadChildren: () => import('../app/upload-job/upload.module').then(m => m.UploadModule)
        //loadChildren: './upload-job/upload.module#UploadModule'
      },
      {
        path: 'support',
        component: SupportComponent
      },
      {
        path: 'audit',
        component: AuditComponent
      },
      {
        path: 'NetScribeUploader',
        component: NetScribeUploaderComponent
      },
      {
        path: 'user-profile',
        component: UserProfileComponent
      },
      {
        path: 'transcription',
        component: TranscriptionComponent
      },
      {
        path: '',
        redirectTo: 'job-list',
        pathMatch: 'full'
      },
      {
        path: 'adminpages',
        loadChildren: () => import('../app/adminpages/adminpages.module').then(m => m.AdminPagesModule),
        //loadChildren: './adminpages/adminpages.module#AdminPagesModule',
        canActivate: [AdminGuard]
      }
    ]
  }
];
