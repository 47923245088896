import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { finalize } from 'rxjs/operators';
import { BackendService } from '../services/backend.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class BaseAuthInterceptor implements HttpInterceptor {

  constructor(private spinner: NgxSpinnerService, private backendService: BackendService) {}
  baseURL = environment.endpoint;
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    //this.spinner.show();
    //localStorage.setItem('spinnerStatus', "true");
    if (req.url.includes(environment.endpoint)) {
    let token = localStorage.getItem('token');
    let userName = localStorage.getItem('userName');
    let refreshToken = localStorage.getItem('refreshtoken');
    var iSuserloggedIN = localStorage.getItem('userloggedIN')
    if(iSuserloggedIN == "false")
    {
        this.backendService.setUserLoggedIn(false);
    }
    if (token != "undefined" && token != null) {

         var istokenExpired = this.backendService.checkUserTokenExpired(token);
          if(istokenExpired)
          {

            this.backendService.gettokenwithrefresh(userName,refreshToken).subscribe(
              res => {
                
                console.log("authnticate result...", res);
                const result = this.backendService.getJSONResponse(res);
                if (result != null) {
                  if(result.success)
                  {
                    if(result.data.token)
                    {
                      localStorage.removeItem('token');
                      localStorage.removeItem('refreshtoken');
                      localStorage.setItem('token', result.data.token);
                      localStorage.setItem('refreshtoken', result.data.refreshtoken);
                    }
                }
              }
              },
              err => {
                // console.log("error result :::", err.error.message)
                var errorresult = err.error.message;
                console.log("errorresult...", errorresult);
                if (errorresult == "invalidUsernameorPassword") {
                }

            })
          }
  
          return next.handle(
            req.clone({
              headers: req.headers.append('Authorization', token)
            })
        ).pipe(finalize(() =>  
        
        //setTimeout(() => {
          {
            //this.spinner.hide()
            //localStorage.removeItem('spinnerStatus');
            //localStorage.setItem('spinnerStatus', "false");
          }
          
        //}, 4500)
        ));
    
    }
  }
    return next.handle(req).pipe(finalize(() =>
    {
      //this.spinner.hide()
      //localStorage.removeItem('spinnerStatus');
      //localStorage.setItem('spinnerStatus', "false");
    }))
    //setTimeout(() => {
  
   // }, 4000) ));
  }
}
@Injectable()
export class BaseNSAuthInterceptor implements HttpInterceptor {

  constructor(private spinner: NgxSpinnerService, private backendService: BackendService) {}
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    //this.spinner.show();
    //localStorage.setItem('spinnerStatus', "true");
    if (req.url.includes(environment.nswbaseUrls)) {
    let token = localStorage.getItem('nswauthtoken');
    let userName = localStorage.getItem('userName');
    if (token != "undefined" && token != null) {

         var istokenExpired = this.backendService.checkUserTokenExpired(token);
          if(istokenExpired)
          {
            console.log("expired");
            localStorage.setItem('nswauthtoken','');
          }
  
          return next.handle(
            req.clone({
              headers: req.headers.append('Authorization', token)
            })
        ).pipe(finalize(() =>  
        {}
          
      
        ));
    
    }
  }
    return next.handle(req).pipe(finalize(() =>
    {
   
    }))
    
  }
}