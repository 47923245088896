import { jqxNotificationComponent } from 'jqwidgets-ng/jqxnotification';
import { BackendService } from './../../../services/backend.service';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { Component, OnInit, Inject, ViewChild, EventEmitter, Output, ElementRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxFileDropEntry, FileSystemFileEntry } from 'ngx-file-drop';
import axios from 'axios';
import { Router, ActivatedRoute, NavigationStart } from '@angular/router';
import { environment } from "src/environments/environment";
import { NgxSpinnerService } from 'ngx-spinner';
import { FileToUpload } from 'src/app/models/apiRequestModels';

let ListOfTranscriptFiles = [];
let Transcriptfile = new FileToUpload();
let FileExtension = '';
let FileName = '';

@Component({
    selector: 'app-replace-transcriptfile',
    templateUrl: './replace-transcriptfile.component.html',
    styleUrls: ['./replace-transcriptfile.component.css']
})
export class ReplaceTranscriptFileComponent implements OnInit {
    @Output()
    notify: EventEmitter<string> = new EventEmitter<string>();

    progress: number;
    FileType = "";
    ListOfTranscripts: FileToUpload[] = [];

    public originalFile: File;
    public ListOfNames: string;
    public isDuplicate: boolean;
    public ListOfAudioFiles: NgxFileDropEntry[] = [];
    returnUrl: string;
    canUpload: boolean = false;
    MediaFileUploadUrl = "";
    @ViewChild('file', { static: false }) file
    @ViewChild('msgNotification', { static: false }) msgNotification: jqxNotificationComponent;
    @ViewChild('fileUploader', { static: false }) fileUploader: ElementRef;

    removable = true;
    constructor(private formBuilder: FormBuilder,
        private backendService: BackendService,
        public dialogRef: MatDialogRef<ReplaceTranscriptFileComponent>,
        private router: Router,
        private spinner: NgxSpinnerService,
        private route: ActivatedRoute,
        @Inject(MAT_DIALOG_DATA) public jobdata: any) { }


    ngOnInit() {
        // this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/admin';
        ListOfTranscriptFiles = [];
        this.ListOfTranscripts = [];
        this.notify.emit("send data");
        this.router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
              // Call your dialogClose() method when navigating to a different tab
              this.dialogClose();
            }
          });
    }
    async onFileChange(event) {
        // Any file(s) selected from the input?
        if (event.target.files && event.target.files.length > 0) {
            for (let index = 0; index < event.target.files.length; index++) {
                let file = event.target.files[index];
                var filenamewithoutextension = file.name.substr(file.name.lastIndexOf('\\') + 1);
                filenamewithoutextension = filenamewithoutextension.substr(0, filenamewithoutextension.lastIndexOf('.'));
                let noWhiteSpace = filenamewithoutextension.replace(/ /g, "_");
                var desiredFilename = noWhiteSpace.replace(/[^\w\s]/gi, '')
                let Fileextension = file.name.split(/[.;+_]/).pop().toLowerCase();
                FileExtension = Fileextension;
                FileName = file.name;
                var desiredFileNameWithExtension = desiredFilename + "." + Fileextension;
                file["filename"] = desiredFileNameWithExtension;
                ListOfTranscriptFiles.push(file);
                this.readAndUploadTranscriptFile(file);
            }
        }
    }
    private readAndUploadTranscriptFile(theFile: any) {
        // Removing special chars from filename
        if (this.ListOfTranscripts.length == 0) {
            var filenamewithoutextension = theFile.name.substr(theFile.name.lastIndexOf('\\') + 1);
            filenamewithoutextension = filenamewithoutextension.substr(0, filenamewithoutextension.lastIndexOf('.'));
            let noWhiteSpace = filenamewithoutextension.replace(/ /g, "_");
            var desiredFilename = noWhiteSpace.replace(/[^\w\s]/gi, '')
            let Fileextension = theFile.name.split(/[.;+_]/).pop().toLowerCase();
            var desiredFileNameWithExtension = desiredFilename + "." + Fileextension;

            Transcriptfile = new FileToUpload();
            Transcriptfile.fileName = desiredFileNameWithExtension;
            Transcriptfile.fileSize = theFile.size;
            Transcriptfile.fileType = theFile.type;
            Transcriptfile.lastModifiedTime = theFile.lastModified;
            Transcriptfile.lastModifiedDate = theFile.lastModifiedDate;
            this.ListOfTranscripts.push(Transcriptfile);
        }
        else if (this.ListOfTranscripts.length == 1) {
            this.sendNotification("Please select only one Transcript.", "error");
        }
    }

    removeTranscriptsByName(fileName) {
        var ListofTranscriptsAfterRemove = [];
        this.ListOfTranscripts.forEach(filedetails => {
            if (filedetails.fileName == fileName) {
            }
            else {
                ListofTranscriptsAfterRemove.push(filedetails);
            }
        });
        this.ListOfTranscripts = ListofTranscriptsAfterRemove;
        this.resetFileUploader();
    }
    resetFileUploader() {
        this.fileUploader.nativeElement.value = null;
    }


    async uploadTranscript() {

        if (this.ListOfTranscripts.length == 0) {
            this.sendNotification("Please select a transcript to upload.", "error");
            return;
        }

        this.spinner.show();
        // console.log("uploadTranscript::: JOBID" + this.jobdata.jobId + " JobStatus " + this.jobdata.jobStatus + " FileExt " + FileExtension + " FileName " + FileName);
        var UploadURLDetails = this.getJSONResponse(await this.backendService.replaceTranscriptInNSC(this.jobdata.jobId, this.jobdata.jobStatus, FileExtension, FileName).toPromise()).data;
        // console.log("ReplaceURL::" + JSON.stringify(UploadURLDetails));
        if (UploadURLDetails.mediaFileUploadUrl == null) {
            this.spinner.hide();
            this.sendNotification(UploadURLDetails.message, "error");
        }
        else {
            if (ListOfTranscriptFiles.length == 1) {
                ListOfTranscriptFiles.forEach(attachment => {
                    console.log("attachment", attachment);
                    this.uploadTranscriptToBucket(attachment, UploadURLDetails);
                })
            }
        }

    }
    async uploadTranscriptToBucket(filedetails, UploadURLDetails) {
        this.progress = 0;
        const fileObject = {
            name: filedetails.name,
            size: (filedetails.size / (1024 * 1024)).toFixed(2) + ' MB',
            progress: 0,
            path: null
        };
        axios.put(UploadURLDetails.mediaFileUploadUrl, filedetails, {
            onUploadProgress: this.calcProgress.bind(this, fileObject)
        })
            .then(async res => {
                this.dialogClose();
                this.sendNotification('Transcript Replaced for the JobId: ' + this.jobdata.jobId
                    // '. Old NetScribe JobNumber: '
                    //+ OldNSWJobNumber +' with New NetScribe JobNumber: '+ NewNSWJobNumber
                    , 'success');
                this.spinner.hide();
            })
            .catch(err => {
                console.log(err);
                this.progress = null;
                this.sendNotification('Transcipt file upload failed.', 'error');
                this.backendService.AWSLog("error", this.jobdata.jobid + "Error - Replace Transcipt :::  " + JSON.stringify(err)).subscribe();
                this.spinner.hide();
            })
    }

    calcProgress(fileObj, progressEvent) {
        this.progress = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
    }
    sendNotification(message, messageType) {
        this.msgNotification.elementRef.nativeElement.querySelector('.jqx-notification-content').innerHTML = message;
        this.msgNotification.template(messageType);
        this.msgNotification.open();
    }


    dialogClose() {
        this.dialogRef.close();
    }
    getJSONResponse(value: any) {
        return JSON.parse(JSON.stringify(value));
    }

}

