import { NgModule } from '@angular/core';
import {  MatDatepickerModule} from '@angular/material/datepicker';
import {  MatDialogModule} from '@angular/material/dialog';
import {  MatAutocompleteModule} from '@angular/material/autocomplete';
import {  MatButtonModule} from '@angular/material/button';
import {  MatButtonToggleModule} from '@angular/material/button-toggle';
import {  MatCardModule} from '@angular/material/card';
import {  MatCheckboxModule} from '@angular/material/checkbox';
import {  MatChipsModule} from '@angular/material/chips';
import {  MatStepperModule} from '@angular/material/stepper';
import {  MatExpansionModule} from '@angular/material/expansion';
import {  MatGridListModule} from '@angular/material/grid-list';
import {  MatIconModule} from '@angular/material/icon';
import {  MatInputModule} from '@angular/material/input';
import {  MatListModule} from '@angular/material/list';
import {  MatMenuModule} from '@angular/material/menu';
import {  MatNativeDateModule} from '@angular/material/core';
import {  MatPaginatorModule} from '@angular/material/paginator';
import {  MatProgressBarModule} from '@angular/material/progress-bar';
import {  MatSelectModule} from '@angular/material/select';
import {  MatRippleModule} from '@angular/material/core';
import {  MatRadioModule} from '@angular/material/radio';
import {  MatProgressSpinnerModule} from '@angular/material/progress-spinner';

import {  MatToolbarModule} from '@angular/material/toolbar';
import {  MatTabsModule} from '@angular/material/tabs';
import {  MatTableModule} from '@angular/material/table';
import {  MatSortModule} from '@angular/material/sort';
import {  MatSnackBarModule} from '@angular/material/snack-bar';
import {  MatSlideToggleModule} from '@angular/material/slide-toggle';
import {  MatSliderModule} from '@angular/material/slider';
import {  MatSidenavModule} from '@angular/material/sidenav';

import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
    exports: [
      MatAutocompleteModule,
      MatButtonModule,
      MatButtonToggleModule,
      MatCardModule,
      MatCheckboxModule,
      MatChipsModule,
      MatStepperModule,
      MatDatepickerModule,
      MatDialogModule,
      MatExpansionModule,
      MatGridListModule,
      MatIconModule,
      MatInputModule,
      MatListModule,
      MatMenuModule,
      MatNativeDateModule,
      MatPaginatorModule,
      MatProgressBarModule,
      MatProgressSpinnerModule,
      MatRadioModule,
      MatRippleModule,
      MatSelectModule,
      MatSidenavModule,
      MatSliderModule,
      MatSlideToggleModule,
      MatSnackBarModule,
      MatSortModule,
      MatTableModule,
      MatTabsModule,
      MatToolbarModule,
      MatTooltipModule
    ],
    declarations: []
  })
  export class MaterialModule {}