<div class="main-content">
  <div class="container-fluid">
      <br>
      <div class="row">
          <div class="col-md-12">
              <div class="card">
                  <div class="card-body">
                    <div class="card-content card-scrollable">
                      <p class="text-support"><span style="white-space:pre;">{{contactEmail}}</span><br />
                        <span style="white-space:pre;">{{contactNumber}}</span></p>
                    </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>



