
import { NgxSpinnerService } from 'ngx-spinner';
import { BackendService } from './../../services/backend.service';
import { Component, OnInit, ViewChild, HostListener, Input, ViewEncapsulation } from '@angular/core';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid'
import { jqxNotificationComponent } from 'jqwidgets-ng/jqxnotification';
import { FormControl } from '@angular/forms';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { asyncScheduler, forkJoin, tap } from 'rxjs';
import { Router } from '@angular/router';
import { ExcelService } from 'src/app/services/excel.service';

declare var tippy: any;
declare const $: any;
let PageNumberGlobal: any;
let customStartDateContainer;
let customEndDateContainer;
let customsearchButtonContainer;
let organizationList;

@Component({
  selector: 'app-audit',
  templateUrl: './audit.component.html',
  styleUrls: ['./audit.component.css']
})
export class AuditComponent implements OnInit {
  customSearch = false;
  selectedStartDate: any;
  selectedEndDate: any;
  startmin: any;
  startmax: any;
  endmin: any;
  endmax: any;
  NumberOfAudits = 0;
  MinEndDate: any;
  MaxStartDate: any;
  endDateEvent = false;
  selectedDateOption: any;
  selectedStatusOption: string;
  EventType: string;
  UnixStartDate: any;
  UnixEndDate: any;
  selectedDDLindex: any;
  totalCountGlobal: any;
  IsSqlDB = false;
  buttonCounter: any;
  sqlData: any;
  isRefresh = false;
  columnValues: any;
  element = $("<div id = 'gridpager' style='margin-left: 10px; margin-top: 11px; width: 100%; height: 100%;'></div>");
  orgName = [];
  heightOffset: number = 120;
  organizationOptions=[];
  orgId: any;
  orgUserRoleButtonContainer;
  constructor(
    private backendService: BackendService,
    private datePipe: DatePipe,
    private spinner: NgxSpinnerService,
    public dialog: MatDialog,
    private excelService: ExcelService
  ) {
  }
  @ViewChild('myAuditGrid', { static: false }) myAuditGrid: jqxGridComponent;
  @ViewChild('msgNotification', { static: false }) msgNotification: jqxNotificationComponent;

  startDateEventOpen(event: any): void {
    this.endDateEvent = false;
  }

  onStartDateValueChanged(event: any): void {
    if (this.endDateEvent == false) {
      var selecteddate = event.args.date;
      var dateevent = new Date();
      this.MinEndDate = selecteddate.setDate(selecteddate.getDate());
      this.MinEndDate = new Date(this.MinEndDate);
      this.endmin = new Date(this.MinEndDate);
      this.selectedStartDate = this.datePipe.transform(selecteddate, 'yyyy-MM-dd')
    }
  }

  onEndDateValueChanged(event: any): void {
    this.endDateEvent = true;
    var selecteddate = event.args.date;
    var dateevent = new Date();
    this.MaxStartDate = selecteddate.setDate(selecteddate.getDate());
    this.MaxStartDate = new Date(this.MaxStartDate);
    this.startmax = new Date(this.MaxStartDate);
    this.endmin = new Date(this.MinEndDate);
    this.selectedEndDate = this.datePipe.transform(selecteddate, 'yyyy-MM-dd')
  }
  ShowColumns: any[];
  AllColumns: any[] =
    [
      { label: 'Id', value: 'id' },
      { label: 'Event Action', value: 'eventaction' },
      { label: 'Event Type', value: 'eventtype' },
      { label: 'Event Time', value: 'eventtime' },
      { label: 'Result', value: 'result' },
      { label: 'Details Source', value: 'details' },
      { label: 'User Id', value: 'userId' },
      { label: 'Job Id', value: 'jobid' },
      { label: 'User Name', value: 'userName'}
    ];

  eventTypeActionOptions: any[] =
    [
      { label: 'All', value: 'All' },
      { label: 'System Action', value: 'System_Action' },
      { label: 'User Action', value: 'User_Action' },
    ];

  // userRoleOrgOptions: string[] = this.orgName  

  dateOptions: any[] =
    [
      { html: '<div>Today</div>', title: 'Today' },
      { html: '<div>Yesterday</div>', title: 'Yesterday' },
      { html: '<div>This week</div>', title: 'This week (Sunday to today)' },
      { html: '<div>Last 7 days</div>', title: 'Last 7 days (six days ago to today)' },
      { html: '<div>This month</div>', title: 'This month (1st of this month to today)' },
      { html: '<div>Last 31 days</div>', title: 'Last 31 days (30 days ago to today)' },
      { html: '<div>Custom</div>', title: 'Custom' },
    ];
  updatefilterconditions = (type: string, defaultconditions: any): string[] => {
    let stringcomparisonoperators = ['EMPTY', 'NOT_EMPTY', 'CONTAINS', 'DOES_NOT_CONTAIN', 'STARTS_WITH', 'ENDS_WITH', 'EQUAL'];
    let numericcomparisonoperators = ['LESS_THAN', 'GREATER_THAN'];
    let datecomparisonoperators = ['LESS_THAN', 'GREATER_THAN'];
    let booleancomparisonoperators = ['EQUAL', 'NOT_EQUAL'];
    switch (type) {
      case 'stringfilter':
        return stringcomparisonoperators;
      case 'numericfilter':
        return numericcomparisonoperators;
      case 'datefilter':
        return datecomparisonoperators;
      case 'booleanfilter':
        return booleancomparisonoperators;
    }
  };

  height;
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.height = event.target.innerHeight - this.heightOffset;
  }
  getHeight() {
    this.height = (window.screen.height - this.heightOffset);
    window.dispatchEvent(new Event('resize'));
  }
  getJSONResponse(value: any) {
    return JSON.parse(JSON.stringify(value));
  }
  convertStartDatetoUnix(date) {
    var reqstartDate = this.datePipe.transform(date, 'yyyy-MM-dd 00:00:00');
    var StringtoDate = new Date(reqstartDate);
    this.UnixStartDate = (StringtoDate.getTime() / 1000);
    this.UnixStartDate = this.UnixStartDate - 1;
  }
  convertEndDatetoUnix(date) {
    var reqEndDate = this.datePipe.transform(date, 'yyyy-MM-dd 23:59:59');
    var StringtoDate = new Date(reqEndDate);
    this.UnixEndDate = (StringtoDate.getTime() / 1000);
    this.UnixEndDate = this.UnixEndDate + 1;
  }

  getEventType(value) {

    this.EventType = value;
    this.getsqlData(value, this.selectedStartDate, this.selectedEndDate);
  }
  searchData() {
    PageNumberGlobal = 1;
    this.getEventType(this.selectedStatusOption);
  }
  ngAfterViewInit(): void {

    setTimeout(() => {
      // TIPPY JS.
      tippy('[data-tippy-content]');
    }, 500);

    //this.createButtons();
    if (this.buttonCounter == 1) {
      this.source.showtoolbar = true;
      this.createButtons();
    }
    this.selectedStatusOption = this.eventTypeActionOptions[0];
    let dateOpt = JSON.parse(JSON.stringify(this.dateOptions[0]));
    const tmp = document.createElement('DIV');
    tmp.innerHTML = dateOpt.html;
    this.selectedDateOption = tmp.textContent || tmp.innerText || '';
  }

  ngOnInit() {

    var iSuserloggedIN = localStorage.getItem('userloggedIN')
    if (iSuserloggedIN == "false") {
      this.backendService.setUserLoggedIn(false);
    }
    this.spinner.show();
    this.buttonCounter = 0;
    this.EventType = "All";
    PageNumberGlobal = 1;
    this.NumberOfAudits = 0;
    var dateevent = new Date();
    var startdateevent = dateevent.setDate(dateevent.getDate());
    this.selectedStartDate = this.datePipe.transform(dateevent, 'yyyy-MM-dd');
    this.selectedEndDate = this.datePipe.transform(dateevent, 'yyyy-MM-dd');

    this.getHeight();
    this.IsSqlDB = true;
    setTimeout(() => {
      this.spinner.hide();
    }, 10000);
    asyncScheduler.schedule(async () => {
      try {
        await this.loadOrganizationsforEditUser();
        // await this.loadGridColumns();
        await this.getsqlData('All', this.selectedStartDate, this.selectedEndDate);
       // this.spinner.hide()
      } catch (error) {
        console.error('An error occurred:', error);
      }
    }
    )
    this.IsSqlDB = false;
  }
  sendNotification(message, messageType) {
    this.msgNotification.elementRef.nativeElement.querySelector('.jqx-notification-content').innerHTML = message;
    this.msgNotification.template(messageType);
    this.msgNotification.open();
    this.spinner.hide()
  }
  async loadOrganizationsforEditUser() {
    const org_res = await this.loadOrganizationValues();
    organizationList = this.backendService.getJSONResponse(org_res).data.customerList;
    this.organizationOptions.push('Select All');
    this.orgName.push('Select All');
    organizationList.forEach((item) => {
      this.organizationOptions.push(item.customerName)
    })
    if (organizationList.length > 0) {
      let data = []
      data[0] =0
      this.orgName =data[0];
    }
  }
  loadOrganizationValues() {
    // this.organizationOptions = [];
    return this.backendService.getCustomers().toPromise();
  }

  loadGridColumns() {
    this.columnValues = this.ShowColumns;
    this.myAuditGrid.beginupdate();
    this.myAuditGrid.endupdate();
  }
  onPageChanged(event: any): void {
    this.spinner.show();
  }
  auditGridFilter(event: any): void {
    this.spinner.show();
  }
  getsqlData(eventType, startDate, endDate) {
    this.buttonCounter = this.buttonCounter + 1;
    if (this.buttonCounter == 1) {
      this.source.showtoolbar = true;
      this.createButtons();
    }
    this.convertStartDatetoUnix(startDate);
    this.convertEndDatetoUnix(endDate);
    let userName = localStorage.getItem('userName')
    this.source.data = { userName: userName, eventtype: eventType.value ? eventType.value : eventType, StartDate: this.UnixStartDate, EndDate: this.UnixEndDate, orgId: this.orgName };
    // console.log("this.source.data::", this.source.data);
    
    this.source.url = this.backendService.GetAllAuditDetails();
    if (this.isRefresh == false) {
     // this.myAuditGrid.clearfilters();
    }
    this.myAuditGrid.updatebounddata();
    this.isRefresh = false;
  }

  source: any = {
    datatype: 'json',
    datafields: [
      { name: 'id', type: 'number' },
      { name: 'eventaction', type: 'string' },
      { name: 'eventtype', type: 'string' },
      { name: 'eventtime', type: 'string' },
      { name: 'userId', type: 'number' },
      { name: 'details', type: 'string' },
      { name: 'result', type: 'string' },
      { name: 'jobid', type: 'number' },
      { name: 'userName', type: 'string'},
      { name: 'nsJboId', type: 'string'},
      { name: 'nsJobNumber', type: 'string'}
    ],
    userName: localStorage.getItem('userName'),
    sortcolumn: 'eventtime',
    sortdirection: 'desc',

    loader: false,
    enablebrowserselection: true,
    keyboardnavigation: false,

    beforeprocessing: (data: any) => {
      // console.log("beforeprocessing", data);
      this.source.totalrecords = data.totalRows;
      var results = data.rows;
      // console.log("data:::results", results);
      for (const index in results) {
        const val = results[index];
        var reqDate = this.datePipe.transform(val.eventtime, 'yyyy-MM-dd HH:mm:ss UTC');
        let LocalDate = new Date(reqDate);
        val.eventtime = LocalDate.toString();
        val.eventtype = val.eventtype
        val.id = val.id
        val.jobid = val.jobid
        val.details = val.details
        val.result = val.result
        val.userId = val.userId
        val.userName = val.userName
        val.nsJboId=val.nsJboId;
        val.nsJobNumber=val.nsJobNumber;
      }
      this.sqlData = results;
      this.spinner.hide();

    },
    filter: () => {
      // update the grid and send a request to the server.
      this.myAuditGrid.updatebounddata('filter');
    }, sort: () => {
      // update the grid and send a request to the server.
      this.myAuditGrid.updatebounddata('sort');
    },

  };


  sQLdataAdapter: any = new jqx.dataAdapter(this.source, {
    autoBind: true,
    beforeSend: function (jqXHR, settings) {
      this.token = localStorage.getItem('token');
      if (this.token == null) {
        jqXHR.abort();
      }
      jqXHR.setRequestHeader('Authorization', 'Bearer ' + this.token);
    },
    contentType: 'application/json; charset=utf-8',
    loadError: (xhr, status, error) =>{
      this.sendNotification("Unable to get the audit logs. Please refresh the page.", 'error')
    }
  });

  pagerrenderer = () => {
    this.element.empty();
    var datainfo = this.myAuditGrid.getdatainformation();
    var paginginfo = datainfo.paginginformation;

    ///Appending Buttons
    var data = this.myAuditGrid.getrows();
    var auditlogscount = this.source.totalrecords;
    this.totalCountGlobal = this.source.totalrecords
    //labels
    var pageSizelabel = $("<div style='font-size: 13px; margin: 2px 100px; margin-top:-4px; font-weight: bold; float: right;'></div>");
    var dropdown = $("<div style='margin-right: -75px; margin-top: -5px; padding: 0px; float: right; cursor: pointer;'> </div>")
    var leftButton = $("<div style='padding: 0px; float: right; cursor: pointer;'><div style='margin-left: 45px; width: 16px; height: 16px;'></div></div>")
    leftButton.find('div').addClass('jqx-icon-arrow-left');
    leftButton.width(36);
    // leftButton.jqxButton({ theme: theme });
    var rightButton = $("<div style='padding: 0px; margin: 0px 3px; float: right; cursor: pointer;'><div style='margin-left: 150px; width: 16px; height: 16px;'></div></div>");
    rightButton.find('div').addClass('jqx-icon-arrow-right');
    rightButton.width(36);

    var CurrentPageAuditslabel = $("<div style='font-size: 13px; margin: 2px 0px; margin-top:-4px; font-weight: bold; float: left;'></div>");

    if (auditlogscount > 0) {
      var data = this.myAuditGrid.getrows();
      this.NumberOfAudits = data.length;
      CurrentPageAuditslabel.text("Audit(s) displayed: " + this.NumberOfAudits);
      pageSizelabel.text(1 + paginginfo.pagenum * paginginfo.pagesize + "-" + Math.min(parseInt(datainfo.rowscount), (paginginfo.pagenum + 1) * paginginfo.pagesize) + ' of ' + datainfo.rowscount);
      var datasource = [];
      for (var i = 1; i <= paginginfo.pagescount; i++) {
        datasource.push(i);
      }
      if (PageNumberGlobal == 1) {
        this.selectedDDLindex = 0;
      }
      if (paginginfo.pagescount > 6) {
        dropdown.jqxDropDownList({ source: datasource, selectedIndex: this.selectedDDLindex, dropDownVerticalAlignment: "bottom", theme: "material", width: 52, height: 28, scrollBarSize: 5 });
      }
      else {
        dropdown.jqxDropDownList({ source: datasource, selectedIndex: this.selectedDDLindex, dropDownVerticalAlignment: "bottom", theme: "material", width: 52, height: 28, autoDropDownHeight: true });
      }
      dropdown.on('select', (event) => {
        var args = event.args;
        if (args) {
          this.selectedDDLindex = args.index;
          if (args.index >= 0)
            PageNumberGlobal = args.item.label;
          this.myAuditGrid.gotopage(args.index);
        }
      });
      // update buttons states.
      var leftbuttonresult = paginginfo.pagescount * paginginfo.pagenum;
      if (leftbuttonresult == 0) {
        leftButton.find('div').addClass("disabled");
      }
      else {
        leftButton.find('div').removeClass("disabled");
      }
      var rightbuttonresult = paginginfo.pagenum + 1;
      if (rightbuttonresult == paginginfo.pagescount) {
        rightButton.find('div').addClass("disabled");
      }
      else {
        rightButton.find('div').removeClass("disabled");
      }
      rightButton.click((event) => {

        if (rightbuttonresult < paginginfo.pagescount) {
          //PageNumberGlobal = 1;
          // go to next page.
          if (paginginfo.pagenum == 0) {
            PageNumberGlobal = 2;
          }
          else {
            PageNumberGlobal = parseInt(paginginfo.pagenum) + 2;
          }
          this.selectedDDLindex = this.selectedDDLindex + 1;
          this.myAuditGrid.gotonextpage();
        }
      });
      leftButton.click((event) => {
        if (leftbuttonresult > 0) {
          //PageNumberGlobal = 1;
          // go to prev page.
          PageNumberGlobal = parseInt(paginginfo.pagenum);
          this.selectedDDLindex = this.selectedDDLindex - 1;
          this.myAuditGrid.gotoprevpage();
        }
      });
      pageSizelabel.appendTo(this.element);
      dropdown.appendTo(this.element);
      leftButton.appendTo(this.element);
      rightButton.appendTo(this.element);
      CurrentPageAuditslabel.appendTo(this.element);
      return this.element;
    }
    else {
      CurrentPageAuditslabel.text("Audit(s) displayed: " + 0);
      pageSizelabel.text(0 + "-" + 0 + ' of ' + 0);
      CurrentPageAuditslabel.appendTo(this.element);
      return this.element;
    }
  }
  getPageData() {
    this.getEventType(this.selectedStatusOption);
  };



  onStatusChange(event: any): void {
    PageNumberGlobal = 1;
    this.myAuditGrid.clearselection();
    if (event.args.item.value == "All" || event.args.item.value == "System_Action" || event.args.item.value == "User_Action") {
      this.selectedStatusOption = event.args.item.value;
    } else {
      if(event.args.item.value == "Select All"){
        let data = []
      data[0] =0
      this.orgName =data[0]
      }else {
        this.orgName = event.args.item.value
        let foundOrgId = organizationList.find(item => item.customerName == this.orgName);
        this.orgName = foundOrgId.dbId
      }
    }
    this.getEventType(this.selectedStatusOption);
  };
  refreshGrid() {
      this.isRefresh = true;
      this.getsqlData(this.EventType, this.selectedStartDate, this.selectedEndDate);
      this.myAuditGrid.updatebounddata();
  }

  getDatesByOption(DateValue) {
    let startdate;
    let endDate;
    var dateevent = new Date();
    this.updateCustomSearch(false);
    endDate = JSON.stringify(dateevent);
    endDate = endDate.slice(1, 11);
    DateValue = DateValue.replace(/['"]+/g, '');
    if (DateValue == "Today") {
      startdate = this.datePipe.transform(dateevent, 'yyyy-MM-dd');
      endDate = this.datePipe.transform(dateevent, 'yyyy-MM-dd');
    }
    if (DateValue == "Yesterday") {
      let aDate = new Date();
      aDate.setDate(aDate.getDate() - 1);
      startdate = this.datePipe.transform(aDate, 'yyyy-MM-dd');
      endDate = this.datePipe.transform(aDate, 'yyyy-MM-dd');
    }

    if (DateValue == "Last 7 days (six days ago to today)") {
      var startdateevent = dateevent.setDate(dateevent.getDate() - 6);
      startdate = new Date(startdateevent);
      startdate = JSON.stringify(startdate);
      startdate = startdate.slice(1, 11);
    }
    if (DateValue == "This week (Sunday to today)") {
      var dateNow = new Date();
      var dateToday = new Date(dateNow.getFullYear(), dateNow.getMonth(), dateNow.getDate());
      var dateSunday = new Date(dateToday.getTime() - dateToday.getDay() * 24 * 3600 * 1000);
      var previousSunday = new Date(dateSunday);
      var startdateevent = previousSunday.setDate(previousSunday.getDate());
      var startdateevent = previousSunday.setDate(previousSunday.getDate() + 1);
      startdate = new Date(startdateevent);
      startdate = JSON.stringify(startdate);
      startdate = startdate.slice(1, 11);
    }
    if (DateValue == "This month (1st of this month to today)") {
      var dateNow = new Date();
      var firstDay = new Date(dateNow.getFullYear(), dateNow.getMonth(), 1);
      var firstday = new Date(firstDay);
      var startdateevent = dateevent.setDate(firstday.getDate());
      startdate = new Date(startdateevent);
      startdate = JSON.stringify(startdate);
      startdate = startdate.slice(1, 11);
    }
    if (DateValue == "Last 31 days (30 days ago to today)") {
      var startdateevent = dateevent.setDate(dateevent.getDate() - 29);
      startdate = new Date(startdateevent);
      startdate = JSON.stringify(startdate);
      startdate = startdate.slice(1, 11);
    }
    if (DateValue == "Custom") {
      this.customSearch = true;
      var startdateevent = dateevent.setDate(dateevent.getDate());
      startdate = new Date(startdateevent);
      this.startmax = new Date(startdateevent);
      /////endmin
      var endmindate = dateevent.setDate(dateevent.getDate() - 1);
      this.endmin = new Date(endmindate);
      var todayDate = new Date();
      startdate = this.datePipe.transform(todayDate, 'yyyy-MM-dd');
      endDate = this.datePipe.transform(todayDate, 'yyyy-MM-dd');
      this.startmax = new Date();
      this.endmax = new Date()
      this.updateCustomSearch(true);
    }
    this.selectedStartDate = startdate;
    this.selectedEndDate = endDate;
  };

  updateCustomSearch(statusbar) {
    if (statusbar) {
      customStartDateContainer.style.display = "block";
      customEndDateContainer.style.display = "block";
      $('#customsearchButton').show();
    }
    else {
      customStartDateContainer.style.display = "none";
      customEndDateContainer.style.display = "none";
      $('#customsearchButton').hide();
    }
  }

  onDateOptionChange(event: any): void {
    this.spinner.show();
    PageNumberGlobal = 1;
    this.selectedStartDate = '';
    this.selectedEndDate = '';
    this.customSearch = false;
    let startdate;
    let endDate;
    this.selectedDateOption = event.args.item.value;
    var DateObj = new Date();
    ////End date
    var dateevent = new Date();
    endDate = JSON.stringify(dateevent);
    endDate = endDate.slice(1, 11);
    this.getDatesByOption(JSON.stringify(this.selectedDateOption));
    this.getsqlData(this.EventType, this.selectedStartDate, this.selectedEndDate);
    this.myAuditGrid.updatebounddata();
  };


  counter: number = 1;
  tooltiprenderer = (element: any): void => {
    let id = `toolTipContainer${this.counter}`;
    element[0].id = id;
    let content = element[0].innerHTML;
    console.log("content", content);
    this.counter++;
  }

  visibilityrenderer = (row, columnfield, value, defaulthtml, columnproperties, rowdata, htmlElement) => {
    if (value == true) {
      return '<i class="material-icons" style="cursor:pointer">visibility</i>';
    }
    else {
      return '<i class="material-icons" style="cursor:pointer">visibility_off</i>';
    }
  };

  ChooseBoolItems: any[] = [
    { value: 1, label: "Yes" },
    { value: 0, label: "No" }
  ];

  columns: any[] =
    [
      { text: 'Event ID', datafield: 'id', filterable: false, width: '6%', align: 'center' },
      { text: 'Event Time', datafield: 'eventtime', width: '18%', filterable: false, cellsformat: 'MMMM-dd-yyyy HH:mm', align: 'center' },
      { text: 'Event Action', datafield: 'eventaction', width: '15%', filtertype: 'input', align: 'center' },
      { text: 'Event Type', datafield: 'eventtype', filtertype: 'input', width: '13%', align: 'center' },
      { text: 'User Name', datafield: 'userName', width: '13%', filtertype: 'input', align: 'center'},
      { text: 'Result', datafield: 'result', filtertype: 'input', width: '13%', align: 'center' },
      { text: 'Details Source', datafield: 'details', filtertype: 'input', width: '13%', align: 'center' },
      { text: 'NSC RecordId', datafield: 'jobid', filtertype: 'number', width: '13%', align: 'center' },
      { text: 'NS JobId', datafield: 'nsJboId', filtertype: 'input', width: '13%', align: 'center' },
      { text: 'NS JobNumber', datafield: 'nsJobNumber', filtertype: 'input', width: '13%', align: 'center' },
      // { text: 'User Id', datafield: 'userId', filtertype: 'input', width: '13%', align: 'center' },
    ];


  rendergridrows = (params: any): any => {
    return params.data;
  }
  ///////////////////////buttons creation///////////////
  createButtonsContainers(statusbar: any): void {

    if (statusbar[0] == null || statusbar[0] == 'undefined') {
    }
    else {
      let buttonsContainer = document.createElement('div');
      buttonsContainer.style.cssText = 'overflow: hidden; position: relative; margin: 5px; border-color: none; background: none;';

      let datepickerButtonContainer = document.createElement('div');
      datepickerButtonContainer.id = "datepickerButton";
      datepickerButtonContainer.className = 'panelButtonclass';
      datepickerButtonContainer.style.cssText = 'theme: material; float: left; margin-left: 5px;';
      datepickerButtonContainer.title = "Viewing Audits From";

      customStartDateContainer = document.createElement('div');
      customEndDateContainer = document.createElement('div');
      customsearchButtonContainer = document.createElement('div');

      customStartDateContainer.id = "customstartdate"
      customEndDateContainer.id = "customenddate"
      customsearchButtonContainer.id = 'customsearchButton';


      customStartDateContainer.style.cssText = 'theme: material;float: left; margin-left: 5px;';
      customEndDateContainer.style.cssText = 'theme: material;float: left; margin-left: 5px;';
      customsearchButtonContainer.style.cssText = 'theme: material;float: left; margin-left: 5px;';

      customsearchButtonContainer.style.display = "none";
      customStartDateContainer.style.display = "none";
      customEndDateContainer.style.display = "none";

      let eventTypeButtonContainer = document.createElement('div');
      eventTypeButtonContainer.id = "eventtypedropdown";
      eventTypeButtonContainer.className = 'panelButtonclass';
      eventTypeButtonContainer.style.cssText = 'theme: material; float: left; margin-left: 5px;';

      let orgUserRoleButtonContainer = document.createElement('div');
      orgUserRoleButtonContainer.id = "orgdropdown";
      orgUserRoleButtonContainer.className = 'panelButtonclass';
      orgUserRoleButtonContainer.style.cssText = 'theme: material; float: left; margin-left: 5px;';

      let refreshContainerAuditList = document.createElement('div');
      refreshContainerAuditList.id = 'refreshButton';
      refreshContainerAuditList.className = 'panelButtonclass';
      refreshContainerAuditList.style.cssText = 'float: left; margin-left: 5px;';

      let exportContainerAuditList = document.createElement('div');
      exportContainerAuditList.id = 'exportButton';
      exportContainerAuditList.className = 'panelButtonclass';
      exportContainerAuditList.style.cssText = 'float: left; margin-left: 5px;';


      if (localStorage.getItem('user_isadmin') == "true") {
        if (localStorage.getItem('user_roleName') == "SuperAdmin" || localStorage.getItem('user_roleName') == "HostAdmin" || localStorage.getItem('user_roleName') == "TenantAdmin") {

        }

      }
      buttonsContainer.appendChild(datepickerButtonContainer);
      buttonsContainer.appendChild(customStartDateContainer);
      buttonsContainer.appendChild(customEndDateContainer);
      buttonsContainer.appendChild(customsearchButtonContainer);
      buttonsContainer.appendChild(eventTypeButtonContainer);
      buttonsContainer.appendChild(orgUserRoleButtonContainer);
      buttonsContainer.appendChild(refreshContainerAuditList);
      buttonsContainer.appendChild(exportContainerAuditList)

      statusbar[0].appendChild(buttonsContainer);
    }
  }

  createButtons() {
    /////////////////Buttons visible for Admin Users
    let isAdmin = localStorage.getItem('user_isadmin');
    if (isAdmin == "true") {
      if (localStorage.getItem('user_roleName') == "SuperAdmin" || localStorage.getItem('user_roleName') == "HostAdmin" || localStorage.getItem('user_roleName') == "TenantAdmin") {
      }
    }

    let datepickerButton = jqwidgets.createInstance('#datepickerButton', 'jqxDropDownList', {
      width: 120, height: 25, theme: 'material', source: this.dateOptions, selectedIndex: 0, autoDropDownHeight: true
    });
    let customstartdate = jqwidgets.createInstance('#customstartdate', 'jqxDateTimeInput', {
      theme: 'material', width: 120, height: 30, min: this.startmin, max: this.startmax, formatString: "'dd-MMM-yyyy'"
    });
    let customenddate = jqwidgets.createInstance('#customenddate', 'jqxDateTimeInput', {
      theme: 'material', width: 120, height: 30, min: this.endmin, max: this.endmax, formatString: "'dd-MMM-yyyy'"
    });
    let customsearchButtonOptions = {
      width: 40, height: 30, value: '<img src="../../../assets/img/icon/search-jobs.png"  title= "Search Date range"  class="user-listicon"/>',
      imgPosition: 'center', theme: 'material', textPosition: 'center', template: 'link'
    }
    let customsearchButton = jqwidgets.createInstance('#customsearchButton', 'jqxButton', customsearchButtonOptions);
    //jqwidgets.createInstance('#datepickerButton', 'jqxButton', datepickerButtonOptions);

    datepickerButton.addEventHandler('change', (event: any): void => {
      ///console.log("datepickerButton.event: ",event);
      this.onDateOptionChange(event);
    });
    customsearchButton.addEventHandler('click', (event: any): void => {
      this.searchData();
    });
    customstartdate.addEventHandler('change', (event: any): void => {
      // console.log("customstartdate.event: ",event);
      this.onStartDateValueChanged(event)
    });
    customstartdate.addEventHandler('open', (event: any): void => {
      // console.log("customstartdate.open.event: ",event);
      this.startDateEventOpen(event)
    });
    customenddate.addEventHandler('change', (event: any): void => {
      // console.log("customenddate.event: ",event);
      this.onEndDateValueChanged(event)
    });

    let eventTypeButton = jqwidgets.createInstance('#eventtypedropdown', 'jqxDropDownList', { 
      width: 110, height: 25, theme: 'material', source: this.eventTypeActionOptions, selectedIndex: 0, autoDropDownHeight: true
    });

    eventTypeButton.addEventHandler('change', (event: any): void => {
      this.onStatusChange(event);
    });
       this.orgUserRoleButtonContainer = jqwidgets.createInstance('#orgdropdown', 'jqxDropDownList', {
        width: 250, height: 25, theme: 'material',filterable:true, source: this.organizationOptions, selectedIndex: 0, autoDropDownHeight: false, dropDownHeight: 200
  
      });
  
      this.orgUserRoleButtonContainer.addEventHandler('change', (event: any): void => {
        this.onStatusChange(event);
      });
    let exportButtonOptions = {
      width: 40, height: 25, value: '<img src="../../../assets/img/icon/export.png"  title= "Export Audit list"  class="user-listicon"/>',
      imgPosition: 'center', theme: 'material', textPosition: 'center', template: 'link'
    }
    let exportButton = jqwidgets.createInstance('#exportButton', 'jqxButton', exportButtonOptions);
    exportButton.addEventHandler('click', (event: any): void => {
      this.spinner.show();
      this.excelBtnOnClick();
    });
    let refreshButtonOptions = {
      width: 40, height: 25, value: '<img src="../../../assets/img/icon/refresh.png"  title= "Refresh Audit list"  class="user-listicon"/>',
      imgPosition: 'center', theme: 'material', textPosition: 'center', template: 'link'
    }
    let refreshButton = jqwidgets.createInstance('#refreshButton', 'jqxButton', refreshButtonOptions);
    refreshButton.addEventHandler('click', (event: any): void => {
      this.refreshGrid();
    });

  }
  excelBtnOnClick() {
    var data = this.myAuditGrid.getrows();
    var curdata = "Audit-List_"
    var exportdata = [];
    if(data.length > 0){
      for (var item of data) {
        const newitem = {
          'Event ID': item.id,
          'Event Time': item.eventtime,
          'Event Action': item.eventaction,
          'Event Type': item.eventtype,
          'User Name':item.userName,
          'Result': item.result,
          'Details Source': item.details,
          'NSC RecordId':item.jobid,
          'NS JobId': item.nsJboId,
          'NS JobNumber':item.nsJobNumber,
        }
        exportdata.push(newitem);
      }
      this.excelService.exportAsExcelFile(exportdata, curdata);
    } else {
      this.sendNotification("No Audit data to export", 'error')

    }
    this.spinner.hide()
  }
  Rowclick(event: any): void {
    this.heightOffset = 120;
    this.getHeight();
  }


}
