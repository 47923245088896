<h1 mat-dialog-title> <img class="img-icon mr-2" src="../../../assets/img/icon/tnc-icon.png" />
  Terms and Conditions <button mat-dialog-close type="button" class="close pull-right" (click)='dialogClose()'
    aria-label="Close">&times;</button></h1>
<hr>

<div mat-dialog-content style="scrollbar-width: thin;">
  <div class="wrapper wrapper-full-page">
    <div class="page-header">
      <div class="container">
        <div class="row">
          <div class="ml-auto mr-auto">
            <form class="form">
              <div class="card card-login card-hidden">
                <div class="card-header">
                  <div class="card-body tnc-overflow" *ngIf="termsAndConditionData">
                    <div class="product-license-discription">
                      <div class="d-flex justify-content-center">
                        <p [innerHTML]="termsAndConditionData.MainHeader1"></p>
                      </div>
                      <div class="d-flex justify-content-center">
                        <p [innerHTML]="termsAndConditionData.MainHeader2"></p>
                      </div>
                      <ng-container *ngFor="let subHeader of termsAndConditionData.SubHeaders">
                        <div class="d-flex justify-content-start">
                          <p [innerHTML]="subHeader.Para"></p>
                        </div>
                      </ng-container>
                      <div class="d-flex justify-content-center">
                        <p [innerHTML]="termsAndConditionData.TnCData.Header"></p>
                      </div>
                      <div class="d-flex justify-content-start">
                        <p [innerHTML]="termsAndConditionData.TnCData.Para"></p>
                      </div>
                      <ng-container *ngFor="let subHeaderItems of termsAndConditionData.TnCData.SubHeaderItems">
                        <div class="just-padding d-flex justify-content-start">
                          <div class="list-group list-group-root well d-flex justify-content-start">
                            <p><span class="sl-number">{{subHeaderItems.SLNo}}</span>
                              <span class="sl-heading" [innerHTML]="subHeaderItems.Header"></span>
                              <span [innerHTML]="subHeaderItems.Para"></span>
                            </p>
                            <ng-container *ngFor="let subHeaderLine of subHeaderItems.SubHeaderLines">
                              <p><span class="sl-line-number">{{subHeaderLine.SLNo}}</span>
                                <span *ngIf="subHeaderLine.Header!=''" class="sl-heading"
                                  [innerHTML]="subHeaderLine.Header"></span>
                                <span class="sl-line-heading " [innerHTML]="subHeaderLine.Para"></span>
                              </p>
                              <ng-container *ngFor="let subHeaderLineItem of subHeaderLine.SubHeaderLineItems">
                                <div class="list-group well d-flex justify-content-start">
                                  <div class="list-group-item">
                                    <span class="sl-line-number">{{subHeaderLineItem.SLNo}}</span>
                                    <span *ngIf="subHeaderLineItem.Header!=''"
                                      [innerHTML]="subHeaderLineItem.Header"></span>
                                    <span *ngIf="subHeaderLineItem.Para!=''"
                                      [innerHTML]="subHeaderLineItem.Para"></span>
                                    <ng-container *ngFor="let Item of subHeaderLineItem.Items">
                                      <div class="list-group well d-flex justify-content-start">
                                        <p>
                                          <span class="sl-line-number">{{Item.SLNo}}</span>
                                          <span *ngIf="Item.Header!=''" [innerHTML]="Item.Header"></span>
                                          <span *ngIf="Item.Para!=''" [innerHTML]="Item.Para"></span>
                                        </p>
                                      </div>
                                    </ng-container>
                                  </div>
                                </div>
                              </ng-container>
                            </ng-container>
                          </div>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<mat-dialog-actions align="end" style="margin-right: 20px; margin-bottom: 2px;">
  <button class='btn btn-viq-primary pull-right' (click)='dialogClose()'>Close</button>
</mat-dialog-actions>

<jqxNotification #msgNotification [position]="'top-right'" [opacity]="1.0" [autoOpen]="false"
    [autoClose]="true" [animationOpenDelay]="800" [autoCloseDelay]="5000" template.success>
</jqxNotification>