import { Component } from '@angular/core';
import { environment } from "src/environments/environment";

@Component({
    selector: 'app-footer-cmp',
    templateUrl: 'footer.component.html'
})

export class FooterComponent {
    test: Date = new Date();
    versionNumber = "";
    buildVersion = "";


    ngOnInit() {
        this.versionNumber = environment.Version;
        this.buildVersion = environment.BuildVersion;
    }
}
