import { Component, OnInit, Input, inject, ViewChild, SimpleChanges,Output, EventEmitter } from '@angular/core';
import { BackendService } from 'src/app/services/backend.service';
import { DatePipe } from '@angular/common';
import { jqxTextAreaComponent } from 'jqwidgets-ng/jqxtextarea';
import { OrderStatusUpdateDto } from 'src/app/models/apiRequestModels';
import { NgxSpinnerService } from 'ngx-spinner';
import { jqxNotificationComponent } from 'jqwidgets-ng/jqxnotification';

@Component({
  selector: 'app-order-status',
  templateUrl: './order-status.component.html',
  styleUrls: ['./order-status.component.css'],
  providers: [DatePipe]
})
export class OrderStatusComponent implements OnInit {
  @Input() dataFromParent: any;
  @ViewChild('notesComponent', { static: false }) notesComponent: jqxTextAreaComponent;
  @ViewChild('msgNotification', { static: false }) msgNotification: jqxNotificationComponent;
  @Output() notify = new EventEmitter<Promise<string>>();

  constructor(private backendService: BackendService,
    private spinner: NgxSpinnerService,
    private datePipe: DatePipe) { }
  orderStatuses: any = [];
  orderResponse: any = [];
  currentStatus: string;
  lastModifiedDate: string;
  defaultSelectedDate: Date;
  SelectedDate: Date;
  today: Date;

  ngOnInit(): void {
    this.today = new Date();
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes['dataFromParent'] && changes['dataFromParent'].currentValue) {
      this.orderResponse = this.dataFromParent;
      this.loadData();
    }
  }
  loadData()
  {
    this.orderStatuses = this.orderResponse.statusHistory;
    const date = new Date(this.orderResponse);
    this.lastModifiedDate = this.datePipe.transform(this.orderResponse.lastStatusUpdate, 'dd-MMM-yyyy');
    this.currentStatus = this.orderResponse.lastStatus;
    this.defaultSelectedDate = new Date();
  }
  updateOrder(data) {
    this.spinner.show()
    var orderStatusUpdateDto = new OrderStatusUpdateDto();
    orderStatusUpdateDto.status = data.status;
    orderStatusUpdateDto.notes = this.notesComponent.val();
    orderStatusUpdateDto.updatedAt = this.SelectedDate;
    console.log("OrderId", this.orderResponse.orderId);
    this.backendService.updateOrderStatus(this.orderResponse.orderId, orderStatusUpdateDto).subscribe(
      res => {
        console.log("res", res);
        this.spinner.hide();
        this.sendNotification("Successfully updated.", 'success');
        //this.backendService.getOrderDetailsById(this.orderResponse.orderId);
        this.getOrderDetails();
      },
      err => {
        console.log("error result :::", err.error.message)
        this.spinner.hide();
      })
  }
  onDateValueChanged(event: any): void {
    this.SelectedDate = event.args.date;
  }
  sendNotification(message, messageType) {
    this.msgNotification.elementRef.nativeElement.querySelector('.jqx-notification-content').innerHTML = message;
    this.msgNotification.template(messageType);
    this.msgNotification.open();
  }
  async getOrderDetails(){
  console.log("getOrderDetails starts...");
  this.orderResponse = null;
  const response = await new Promise<string>((resolve, reject) => {
    // Emit the event and expect a response from the parent
    this.notify.emit();  // Send the resolve function to the parent
  });
  this.orderResponse = response;
  this.loadData();
  }
}
