<div class="main-content">
  <div class="container-fluid">
      <div class="row">
          <div class="col-md-12">
              <div class="table-responsive mt-4">
                  <jqxGrid #myAuditGrid (onRowclick)="Rowclick($event)" aria-hidden="true" (onPagechanged)="onPageChanged($event)"
                      (onFilter)="auditGridFilter($event)"
                      [source]="sQLdataAdapter"
                      [columns]="columns" [enabletooltips]="true" [virtualmode]="true" [pagesize]="1000"
                      [pagesizeoptions]="['100', '200', '1000']" [pageable]="true"
                      [rendergridrows]="rendergridrows" [theme]="'material'" [width]="'99.99%'"
                      [height]="height" [sortable]="true" [filterable]="true" [showfilterrow]="true"
                      [showtoolbar]="true" [toolbarheight]="40" [renderstatusbar]="createButtonsContainers"
                      [selectionmode]="'multiplerowsadvanced'" [columnsresize]="true" [columnsreorder]="true"
                      [pagerrenderer]="pagerrenderer" [autoshowloadelement]="false"
                      [enablebrowserselection]="true" [keyboardnavigation]="false"
                      [scrollbarsize]= "6">
                    </jqxGrid>
                </div>
                
            </div>
        </div>
    </div>
</div>
<jqxNotification #msgNotification [position]="'top-right'" [opacity]="1.0" [autoOpen]="false"
[autoClose]="true" [animationOpenDelay]="800" [autoCloseDelay]="5000" template.success>
</jqxNotification>
