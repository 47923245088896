import {Injectable} from "@angular/core";
import {CognitoUtil} from "./cognito.service";
import {environment} from "src/environments/environment";

// import {Stuff} from "../secure/useractivity/useractivity.component";
// import * as AWS from "aws-sdk/global";
import * as AWS from 'aws-sdk';
import * as DynamoDB from "aws-sdk/clients/dynamodb";
import { UUID } from 'angular2-uuid';
import { Stuff } from '../adminpages/useractivity/useractivity.component';
/**
 * Created by Vladimir Budilov
 */

@Injectable({
    providedIn: 'root'
  })
export class DynamoDBService {
    private dynamodb;
    private docClient;
    private ddb;
    constructor(public cognitoUtil: CognitoUtil) {
        console.log("DynamoDBService: constructor");
        this.cognitoUtil.getAWS();
        AWS.config.credentials = new AWS.Credentials('accessKeyId', 'secretAccessKey', null);
        // AWS.config.update({
        //     accessKeyId: environment.accessKeyId,
        //     secretAccessKey: environment.secretAccessKey,
        //     region: environment.region
        // });
        AWS.config.update({
        region: environment.region
        });
        this.ddb = new AWS.DynamoDB({apiVersion: '2012-08-10'});
        //this.dynamodb = new AWS.DynamoDB();
        //this.docClient = new AWS.DynamoDB.DocumentClient();
    }

    getAWS() {
        return AWS;
    }

    getLogEntries(mapArray: Array<Stuff>) {
        console.log("DynamoDBService: reading from DDB with creds - " + AWS.config.credentials);
        var params = {
            TableName: environment.ddbTableName,
            KeyConditionExpression: "userId = :userId",
            ExpressionAttributeValues: {
                ":userId": this.cognitoUtil.getCognitoIdentity()
            }
        };

        var clientParams:any = {};
        if (environment.dynamodb_endpoint) {
            clientParams.endpoint = environment.dynamodb_endpoint;
        }
        var docClient = new DynamoDB.DocumentClient(clientParams);
        docClient.query(params, onQuery);

        function onQuery(err, data) {
            if (err) {
                console.error("DynamoDBService: Unable to query the table. Error JSON:", JSON.stringify(err, null, 2));
            } else {
                // print all the movies
                console.log("DynamoDBService: Query succeeded.");
                data.Items.forEach(function (logitem) {
                    mapArray.push({type: logitem.type, date: logitem.activityDate});
                });
            }
        }
    }

    writeLogEntry(type: string) {
        try {
            let date = new Date().toString();
            console.log("DynamoDBService: Writing log entry. Type:" + type + " Id: " + this.cognitoUtil.getCognitoIdentity() + " Date: " + date);
            //this.write(this.cognitoUtil.getCognitoIdentity(), date, type);
            this.newwrite(this.cognitoUtil.getCognitoIdentity(), date, type);
            //this.testdydb();
        } catch (exc) {
            console.log("DynamoDBService: Couldn't write to DDB: "+exc);
        }

    }

    write(data: string, date: string, type: string): void {

        console.log("DynamoDBService: reading from DDB with creds - " + AWS.config.credentials);
        console.log("DynamoDBService: writing " + type + " entry");
       let guid= UUID.UUID();
       console.log("DynamoDBService: guid:  " + guid );
        let clientParams:any = {
            params: {TableName: environment.ddbTableName}
        };
        // if (environment.dynamodb_endpoint) {
        //     clientParams.endpoint = environment.dynamodb_endpoint;
        //     // clientParams.IdentityPoolId = environment.identityPoolId;
        //      //clientParams.region= environment.region;
        // }
       // var DDB = new DynamoDB(clientParams);

        // Write the item to the table
        var itemParams =
            {
                TableName: environment.ddbTableName,
                Item: {
                    Id:{S: guid},
                    userId: {S: data},
                    activityDate: {S: date},
                    type: {S: type}
                }
            };
            this.ddb.putItem(itemParams, function (err, result) {
            if (err) {
                console.log("Error", err);
              } else {
                console.log("Success", result);
                console.log("DynamoDBService: wrote entry: " + JSON.stringify(result));
              }
           
        });
    }


    newwrite(data: string, date: string, type: string): void {
        console.log("DynamoDBService: writing " + type + " entry");
        let guid= UUID.UUID();
        console.log("DynamoDBService: guid:  " + guid );
        
        var docClient = new AWS.DynamoDB.DocumentClient();
            var params = {
                TableName: environment.ddbTableName,
                Item:{
                    "Id":guid,
                    "userId": data,
                    "activityDate": date,
                    "type":type
                }
            };
            console.log("Adding a new item...");
            docClient.put(params, function(err, data) {
                if (err) {
                    console.error("Unable to add item. Error JSON:", JSON.stringify(err, null, 2));
                } else {
                    console.log("Added item:", JSON.stringify(data, null, 2));
                }
            });
        }


    testdydb()
    {
            //     var AWS = require("aws-sdk");
            //     AWS.config.update({
            //     region: "ca-central-1",
            //    // endpoint: "http://localhost:8000"
            //     });
                this.cognitoUtil.getAWS();
                var docClient = new AWS.DynamoDB.DocumentClient();

                var table = environment.ddbTableName

                var year = 2015;
                var title = "The Big New Movie";
                let nguid= UUID.UUID();
                var params = {
                    TableName:table,
                    Item:{
                        "Id":nguid,
                        "year": year,
                        "title": title,
                        "info":{
                            "plot": "Nothing happens at all.",
                            "rating": 0
                        }
                    }
                };

                console.log("Adding a new item...");
                docClient.put(params, function(err, data) {
                    if (err) {
                        console.error("Unable to add item. Error JSON:", JSON.stringify(err, null, 2));
                    } else {
                        console.log("Added item:", JSON.stringify(data, null, 2));
                    }
                });
            }

}


