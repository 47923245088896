import { MatNativeDateModule } from '@angular/material/core';
import {  MatDialogRef } from '@angular/material/dialog';
import { MaterialModule } from './material.module';
import { JQXMaterialModule } from './tables/jqx-material.module';
import { NgxSpinnerModule } from 'ngx-spinner';
import { BaseAuthInterceptor } from './interceptor/base-auth-interceptor';
import { LoginComponent } from './login/login.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { HttpModule } from '@angular/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule, DatePipe } from '@angular/common';

import { AppComponent } from './app.component';

import { SidebarModule } from './sidebar/sidebar.module';
import { FooterModule } from './shared/footer/footer.module';
import { NavbarModule } from './shared/navbar/navbar.module';
import { FixedpluginModule } from './shared/fixedplugin/fixedplugin.module';
import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';

import { AppRoutes } from './app.routing';
import { ForceChangePasswordComponent } from './login/force-change-password/force-change-password.component';
import { UserRegistrationService } from './services/user-registration.service';
import { CognitoUtil } from './services/cognito.service';
import { ForgotPasswordComponent } from './login/forgot-password/forgot-password.component';
import { ForgotPasswordDialogComponent } from './login/forgot-password-dialog/forgot-password-dialog.component';
import { NgIdleModule } from '@ng-idle/core';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { UploadAudioFileComponent } from './tables/table-list/upload-audiofile/upload-audiofile.component';
import { NgxFileDropModule } from 'ngx-file-drop';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { UseractivityComponent } from './adminpages/useractivity/useractivity.component';
import { TermsAndConditionsComponent } from './login/terms-and-conditions/terms-and-conditions.component';
import { Ng2TelInputModule } from 'ng2-tel-input';
import { MFAComponent } from './login/mfa/mfa-otp.component';
import { jqxLoaderModule } from 'jqwidgets-ng/jqxloader';
import { ReplaceAudioFileComponent } from './tables/table-list/replace-audiofile/replace-audiofile.component';
import { ReplaceTranscriptFileComponent } from './tables/table-list/replace-transcriptfile/replace-transcriptfile.component';
import { FormDataComponent } from './tables/table-list/form-data/form-data.component';
import { SupportComponent } from './support/support.component';
import { ViewTNCComponent } from './adminpages/tenant/tenant-creation/view-tnc/view-tnc.component';
import { UserLandingComponent } from './login/user-landing/user-landing.component';
import { NgAudioRecorderModule } from 'ng-audio-recorder';
import { UserProfileComponent } from './user-profile/user.profile.component';
import { TranscriptionComponent } from './transcription/transcription.component';
import { ColumnChooserComponent } from './tables/table-list/column-chooser/column-chooser.component';
import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';
import { ColorPickerComponent } from './color-picker/color-picker.component';
import { PortalModule } from '@angular/cdk/portal';
import { OverlayModule } from '@angular/cdk/overlay';
import { JobHoldComponent } from './tables/table-list/job-hold/job-hold.component';
import { FindReplaceDialogComponent } from './tables/find-replace-dialog/find-replace-dialog.component';
import { DraggableDirective } from './tables/find-replace-dialog/draggable.directive';

//import { NotificationBannerComponent } from '../app/notification-banner/notification-banner.component';
import { DowntimeNotificationsComponent } from './adminpages/downtime-notifications/downtime-notifications.component';

jqx.credits = "75CE8878-FCD1-4EC7-9249-BA0F153A5DE8";

@NgModule({
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpModule,
    MaterialModule,
    //MatNativeDateModule,
    SidebarModule,
    NavbarModule,
    FooterModule,
    FixedpluginModule,
    HttpClientModule,
    NgxSpinnerModule,
    JQXMaterialModule,
    NgxFileDropModule,
    MatProgressBarModule,
    Ng2TelInputModule,
    NgAudioRecorderModule,
    NgIdleModule.forRoot(),
    NgIdleKeepaliveModule.forRoot(),
    RouterModule.forRoot(AppRoutes, {
      scrollPositionRestoration: 'top'
    }),
    jqxLoaderModule,
    // FroalaEditorModule,
    // FroalaViewModule
    FroalaEditorModule.forRoot(),
    FroalaViewModule.forRoot(),
    OverlayModule, 
    PortalModule,
    NavbarModule
  ],
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    AuthLayoutComponent,
    UseractivityComponent,
    LoginComponent,
    MFAComponent,
    ForceChangePasswordComponent,
    ForgotPasswordComponent,
    ForgotPasswordDialogComponent,
    UploadAudioFileComponent,
    TermsAndConditionsComponent,
    ReplaceAudioFileComponent,
    ReplaceTranscriptFileComponent,
    FormDataComponent,
    SupportComponent,
    ViewTNCComponent,
    UserLandingComponent,
    UserProfileComponent,
    TranscriptionComponent,
    ColumnChooserComponent,
    ColorPickerComponent,
    JobHoldComponent,
    FindReplaceDialogComponent,
    DraggableDirective,
    //NotificationBannerComponent
  ],
  providers: [
    DatePipe,
    { provide: HTTP_INTERCEPTORS, useClass: BaseAuthInterceptor, multi: true }
  ],
  exports: [
    RouterModule
  ],
  entryComponents: [
    ForceChangePasswordComponent, 
    ForgotPasswordDialogComponent, 
    UploadAudioFileComponent, 
    MFAComponent, 
    ReplaceAudioFileComponent, 
    ReplaceTranscriptFileComponent, 
    FormDataComponent, 
    ViewTNCComponent, 
    ColumnChooserComponent,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
