import { jqxNotificationComponent } from 'jqwidgets-ng/jqxnotification';
import { BackendService } from './../../../app/services/backend.service';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { Component, OnInit, Inject, ViewChild, Input } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ResponseMessage } from 'src/app/models/ResponseMessage';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-forgot-password-dialog',
  templateUrl: './forgot-password-dialog.component.html',
  styleUrls: ['./forgot-password-dialog.component.css'],
})
export class ForgotPasswordDialogComponent implements OnInit {

  form: FormGroup;
  submitted = false;
  ShownewPassword: boolean = false;
  ShowConfirmPassword: boolean = false;
  

  @ViewChild('msgNotification', { static: false }) msgNotification: jqxNotificationComponent;

  constructor(private formBuilder: FormBuilder, private router:Router,
              private backendService: BackendService,
              public dialogRef: MatDialogRef<ForgotPasswordDialogComponent>,
              private spinner: NgxSpinnerService,
              @Inject(MAT_DIALOG_DATA) public logindata: any) { }

  ngOnInit() {
    this.form = this.formBuilder.group({
      verificationcode: new FormControl(),
      newPassword: new FormControl(),
      confirmPassword: new FormControl()
  },{
      validator: MustMatch('newPassword','confirmPassword')
  });
  }

  sendNotification(message, messageType){
    this.msgNotification.elementRef.nativeElement.querySelector('.jqx-notification-content').innerHTML = message;
    this.msgNotification.template(messageType);
    this.msgNotification.open();
  }
  passwordToggleForNewPassword() {
    this.ShownewPassword= !this.ShownewPassword;
  }
passwordToggleForConfirmPassword(){
    this.ShowConfirmPassword=!this.ShowConfirmPassword
  }


  get f(){
      return this.form.controls;
  }
  isPasswordMatch = false;

  newPasswordChangeEvent() {
      const upperCase = document.getElementById('pwd-upper-case');
      const number = document.getElementById('pwd-number');
      const characters = document.getElementById('pwd-characters');
      const lowerCase = document.getElementById('pwd-lower-case');
      const specialCharacters = document.getElementById('pwd-special-characters');
      const value = this.form.controls.newPassword.value;

      if (value) {
          this.isPasswordMatch = true;
          if (value.match('(.*[A-Z])')) {
              
              upperCase.classList.add('password-text-underline');
          } else {
              this.isPasswordMatch = false;
              upperCase.classList.remove('password-text-underline');
          }

          if (value.match('(.*[0-9])')) {
              // this.isPasswordMatch = true;
              number.classList.add('password-text-underline');
          } else {
              this.isPasswordMatch = false;
              number.classList.remove('password-text-underline');
          }

          if (value.match('(.*[a-z])')) {
              // this.isPasswordMatch = true;
              lowerCase.classList.add('password-text-underline');
          } else {
              this.isPasswordMatch = false;
              lowerCase.classList.remove('password-text-underline');
          }

          if (value.match(/^[!@#$%^&*?a-zA-Z0-9]+$/)) { 
              // this.isPasswordMatch = true;
              specialCharacters.classList.add('password-text-underline');
          } else {
              this.isPasswordMatch = false;
              specialCharacters.classList.remove('password-text-underline');
          }

          if (value.length >= 8) {
              // this.isPasswordMatch = true;
              characters.classList.add('password-text-underline');
          } else {
              this.isPasswordMatch = false;
              characters.classList.remove('password-text-underline');
          }
      }
      if (value.length == 0) {
          this.isPasswordMatch = false;
          upperCase.classList.remove('password-text-underline');
          number.classList.remove('password-text-underline');
          characters.classList.remove('password-text-underline');
          lowerCase.classList.remove('password-text-underline');
          specialCharacters.classList.remove('password-text-underline');
      }

  }
//   isPasswordMatch = false;

//   newPasswordChangeEvent() {
//       const upperCase = document.getElementById('pwd-upper-case');
//       const number = document.getElementById('pwd-number');
//       const characters = document.getElementById('pwd-characters');
//       const lowerCase = document.getElementById('pwd-lower-case');
//       const specialCharacters = document.getElementById('pwd-special-characters');
//       const value = this.form.controls.newPassword.value;

//       if (value) {
//           if (value.match('(.*[A-Z])')) {
//               this.isPasswordMatch = true;
//               upperCase.classList.add('password-text-underline');
//           } else {
//               this.isPasswordMatch = false;
//               upperCase.classList.remove('password-text-underline');
//           }

//           if (value.match('(.*[0-9])')) {
//               this.isPasswordMatch = true;
//               number.classList.add('password-text-underline');
//           } else {
//               this.isPasswordMatch = false;
//               number.classList.remove('password-text-underline');
//           }

//           if (value.match('(.*[a-z])')) {
//               this.isPasswordMatch = true;
//               lowerCase.classList.add('password-text-underline');
//           } else {
//               this.isPasswordMatch = false;
//               lowerCase.classList.remove('password-text-underline');
//           }

//           if (value.match('(.*[^a-zA-Z0-9])')) {
//               this.isPasswordMatch = true;
//               specialCharacters.classList.add('password-text-underline');
//           } else {
//               this.isPasswordMatch = false;
//               specialCharacters.classList.remove('password-text-underline');
//           }

//           if (value.length >= 8) {
//               this.isPasswordMatch = true;
//               characters.classList.add('password-text-underline');
//           } else {
//               this.isPasswordMatch = false;
//               characters.classList.remove('password-text-underline');
//           }
//       }
//       if (value.length == 0) {
//           this.isPasswordMatch = false;
//           upperCase.classList.remove('password-text-underline');
//           number.classList.remove('password-text-underline');
//           characters.classList.remove('password-text-underline');
//           lowerCase.classList.remove('password-text-underline');
//           specialCharacters.classList.remove('password-text-underline');
//       }

//   }

    closeChangePasswordDialog() {
        this.submitted = false;
    }
    isValid(controlName, error) {
        return this.form.controls[controlName].hasError(error);
    }
    dialogClose() {
        this.dialogRef.close();
    }
    
    AvoidSpace() {
        this.sendNotification("Please enter the valid special character(Space is not allowed).", "error")
        return false;
    }

  changePassword(){
      this.submitted = true;
      const verificationcode =this.form.controls.verificationcode.value;
      const newPassword = this.form.controls.newPassword.value;
      const username = this.logindata.currentusername;

     
      if(this.form.controls.verificationcode.value == null)
      {
        this.sendNotification("Please enter the verification code.", 'error');
      }
      else if(this.form.controls.newPassword.value == null)
      {
        this.sendNotification("Please enter the new password.", 'error');
      }
      else if(this.form.controls.confirmPassword == null)
      {
        this.sendNotification("Please enter the confirm password.", 'error');
      }
      else if(!this.isPasswordMatch)
        {
            this.sendNotification('Please follow the password rules.', 'error');
        }

      if(this.submitted && this.form.valid && this.isPasswordMatch){
         
        this.spinner.show();
          this.logindata.currentusername,this.form.controls.verificationcode.toString(),this.form.controls.newPassword
          this.backendService.forgotpasswordsubmit(username,verificationcode,newPassword).subscribe(
              res => {
                const result = this.backendService.getJSONResponse(res);
                if(result!= null)
                {
                    if(result.data.isSuccess == true)
                            {
                                if(result.data.userStatus == ResponseMessage.PASSWORD_UPDATED)
                                {
                                    this.submitted = false;
                                    this.sendNotification("Password Updated. Please log in with the new password.", 'success');
                                    this.dialogClose();
                                    //this.router.navigateByUrl('/');
                                    console.log('force change password results :: ', res);
                                    window.location.reload();
                                    
                                }
                               
                            }
                            else
                            {
                                
                                if(result.data.userStatus == ResponseMessage.OLDPASSWORD_MATCHED)
                                {
                                    this.submitted = false;
                                    this.sendNotification("Entered password is being used in the last 5 Instances, please use a new password.", 'error');
                                    this.spinner.hide();
                                }
                                if(result.data.userStatus == ResponseMessage.PASSWORDCHANGE_UNSUCCESSFUL)
                                {
                                    this.submitted = false;
                                    this.sendNotification(ResponseMessage.PASSWORDCHANGE_UNSUCCESSFUL, 'error');
                                    this.spinner.hide();
                                }
                                if(result.data.userStatus == ResponseMessage.INCORRECT_USERNAME_PASSWORD)
                                {
                                    this.submitted = false;
                                    this.sendNotification(ResponseMessage.INCORRECT_USERNAME_PASSWORD, 'error');
                                    this.spinner.hide();
                                }
                                if(result.data.userStatus == ResponseMessage.USER_NOT_FOUND)
                                {
                                    this.submitted = false;
                                    this.sendNotification(ResponseMessage.USER_NOT_FOUND, 'error');
                                    this.spinner.hide();
                                }
                                if(result.data.userStatus == ResponseMessage.ADMIN_UPDATE_ERROR)
                                {
                                    this.submitted = false;
                                    this.sendNotification(ResponseMessage.ADMIN_UPDATE_ERROR, 'error');
                                    this.spinner.hide();
                                }
                            }
                        }
                //   this.sendNotification("Password Updated. Please log in with the new password.", 'success');
                //   this.dialogClose();
                //   this.router.navigateByUrl('/');
              },
              err => {
                  this.sendNotification(err, 'error');
              }
          );
      }
  }

}


// custom validator to check that two fields match
export function MustMatch(controlName: string, matchingControlName: string) {
  return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
          // return if another validator has already found an error on the matchingControl
          return;
      }

      // set error on matchingControl if validation fails
      if (control.value !== matchingControl.value) {
          matchingControl.setErrors({ mustMatch: true });
      } else {
          matchingControl.setErrors(null);
      }
  }
}
