import { Component,Inject, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-find-replace-dialog',
  templateUrl: './find-replace-dialog.component.html',
  styleUrls: ['./find-replace-dialog.component.css']
})
export class FindReplaceDialogComponent implements OnInit {

  findReplaceForm: FormGroup;
  findResult: { findText: string, replaceText: string , isMatchCase:boolean, Action: string  };
  @Output() findTextEmitter = new EventEmitter<{ findText: string, replaceText: string, isMatchCase:boolean, Action: string   }>();

  constructor( private dialogRef: MatDialogRef<FindReplaceDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { findText: string, replaceText?: string } ,// Add optional replaceText
  ) { }

  ngOnInit(): void {
    this.findReplaceForm = new FormBuilder().group({
      findText: new FormControl(''),
      replaceText: new FormControl(''),
      isMatchCase : new FormControl('')    
    });
    console.log("Data:::", this.data);
    this.findReplaceForm.controls.findText.setValue(this.data.findText);
  }
  onFindText() {
    const findText = this.findReplaceForm.controls.findText.value;
    const replaceText = this.findReplaceForm.controls.replaceText.value;
    const isMatchCase = this.findReplaceForm.controls.isMatchCase.value;
    var Action = "FindText";
      this.findResult = { findText, replaceText, isMatchCase, Action };
      console.log('Find and Replace:', findText, 'with', replaceText);
      this.findTextEmitter.emit(this.findResult);
  }
  onReplace(): void {
    const findText = this.findReplaceForm.controls.findText.value;
    const replaceText = this.findReplaceForm.controls.replaceText.value;
    const isMatchCase = this.findReplaceForm.controls.isMatchCase.value;
    var Action = "Replace";
    this.findResult = { findText, replaceText, isMatchCase, Action };
    // Ensure the replaceText is not empty before closing the dialog
    if (replaceText.trim() !== '') {
      //this.dialogRef.close({ findText, replaceText , button});
      this.findTextEmitter.emit(this.findResult);
    } else {
      alert('Please enter text to replace with.');
    }
  }
  onReplaceAll(): void {
    const findText = this.findReplaceForm.controls.findText.value;
    const replaceText = this.findReplaceForm.controls.replaceText.value;
    const isMatchCase = this.findReplaceForm.controls.isMatchCase.value;
    var Action = "ReplaceAll";
    this.findResult = { findText, replaceText, isMatchCase, Action };
    // Ensure the replaceText is not empty before closing the dialog
    if (replaceText.trim() !== '') {
      //this.dialogRef.close({ findText, replaceText , Action});
      this.findTextEmitter.emit(this.findResult);
    } else {
      alert('Please enter text to replace with.');
    }
  }

  onClose(): void {
    this.dialogRef.close();
  }
}
