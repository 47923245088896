<h1 *ngIf="!this.logindata.Verification" mat-dialog-title><img class="img-icon mr-2" src="../../../assets/img/icon/otp-icon.png" /> 
  Please enter the verification code that was sent to your cell phone to confirm your identity.
  <button mat-dialog-close type="button" class="close pull-right" (click)='closeDialog()' aria-label="Close">&times;</button></h1>
<h1 *ngIf="this.logindata.Verification" mat-dialog-title><img class="img-icon mr-2" src="../../../assets/img/icon/otp-icon.png" /> 
  Please enter the Verification code sent to your cell phone to confirm the Authorization.
  <button mat-dialog-close type="button" class="close pull-right" (click)='closeDialog()' aria-label="Close">&times;</button></h1>
  <hr>
<div mat-dialog-content>
  <div class="row">
    <div class="col-md-12">
      <form [formGroup]='mfaform'>
        <div class="form-row">
          <div class="form-group col-md-12">
            <mat-form-field class="example-full-width">
              <mat-label>Enter OTP</mat-label>
              <input matInput formControlName="onetimepassword" required (keydown.space)="AvoidSpace()" (keydown.enter)="changePassword()" [type]="show ? 'text' : 'password'" maxlength="6">
              <div class="action-icon" matSuffix (click)="passwordToggle()">
                <i class="viq-icon {{show ? 'viq-eye-close' : 'viq-eye-open'}}"></i>
              </div>
              <mat-error *ngIf="isValid('onetimepassword', 'required')">
               OTP is <strong>required</strong>
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
<mat-dialog-actions align="end" style="margin-right: 20px; margin-bottom: 2px;">
  <button class='btn btn-viq btn-viq-primary pull-right'
    (click)='changePassword()'>Submit</button>
  <button class='btn btn-viq-secondary pull-right ml-1' 
    (click)='closeDialog()'>Cancel</button>
</mat-dialog-actions>
<jqxNotification #msgNotification [position]="'top-right'" [opacity]="1.0" [autoOpen]="false"
    [autoClose]="true" [animationOpenDelay]="800" [autoCloseDelay]="5000" template.success>
</jqxNotification>
