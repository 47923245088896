import { Self, Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NG_VALIDATORS, NgControl, Validators } from '@angular/forms';
import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';

@Component({
    selector: 'app-color-picker',
    templateUrl: './color-picker.component.html',
    styleUrls: ['./color-picker.component.css'],
    providers: [
        { provide: NG_VALUE_ACCESSOR, multi: true, useExisting: ColorPickerComponent },
    ]
})
export class ColorPickerComponent implements OnInit, ControlValueAccessor {
    _isOnChange: (value: any) => void;
    overlayRef: OverlayRef;
    showColorPallateContainer = false;

    selctedColor = '#0000FF';
    colorCodes = [
        { colorCode: '#0000FF', colorValue: 'color0' },
        { colorCode: '#1e90ff', colorValue: 'color1' },
        { colorCode: '#981b1e', colorValue: 'color2' },
        { colorCode: '#001f3f', colorValue: 'color3' },
        { colorCode: '#205493', colorValue: 'color4' },
        { colorCode: '#4c2c92', colorValue: 'color5' },
        { colorCode: '#2e8540', colorValue: 'color6' },
        { colorCode: '#2980B9', colorValue: 'color7' },
        { colorCode: '#0071bc', colorValue: 'color8' },
        { colorCode: '#34495E', colorValue: 'color9' }];
    constructor(public overlay: Overlay, public viewContainerRef: ViewContainerRef) { }

    ngOnInit() { }

    writeValue(colorValue: any): void {
        if (colorValue) {
            this.selctedColor = colorValue;
        }
    }

    registerOnChange(fn: (_: any) => void) {
        this._isOnChange = fn;
    }

    registerOnTouched(fn: any): void { }

    showColorPallate() {
        this.showColorPallateContainer = !this.showColorPallateContainer;
    }

    onSelect(codes) {
        this.selctedColor = codes.colorCode;
        this._isOnChange(codes);
        this.showColorPallateContainer = false;
    }

    selectedOverlay() {
        this.showColorPallateContainer = false;
    }
}