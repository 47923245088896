import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { environment } from "src/environments/environment";
import { UserConfigInputModel } from '../models/apiRequestModels';


//const endpoint = 'https://il0vvwxou2.execute-api.ca-central-1.amazonaws.com/dev-viq/api/v1/'; /// client

//const endpoint = 'https://5e4zk1unj5.execute-api.us-east-1.amazonaws.com/prod-nswconnector/api/v1/'; /// production
const endpoint = environment.endpoint;

const httpOptions = {
        headers: new HttpHeaders({
            'Content-Type':  'application/json'
            })
        }
        const httpAuthOptions = {
          headers: new HttpHeaders({
              'Content-Type':  'application/json',
              'Authorization':localStorage.getItem('token')
              })
          }
  

@Injectable({
    providedIn: 'root'
})     
export class ApiGatewayService {

    constructor(private http: HttpClient) {
       

            }
    private extractData(res: Response) {
        let body = res;
        return body || { };
    }
    private handleError<T> (operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {
      
          // TODO: send the error to remote logging infrastructure
          console.error(error); // log to console instead
      
          // TODO: better job of transforming error for user consumption
          console.log(`${operation} failed: ${error.message}`);
      
          // Let the app keep running by returning an empty result.
          return of(result as T);
        };
      }
     UserConfig (userconfig:UserConfigInputModel): Observable<any> {
        // console.log("UserConfig: ",userconfig);
        return this.http.post<any>(endpoint + '/authenticate/userconfig', JSON.stringify(userconfig), httpOptions).pipe(
          tap(),
          catchError(this.handleError<any>('userconfig'))
        );
      }

      authenticate({ username, password }: { username: string; password: string; }) {
        return this.http.post(endpoint + '/authenticate/login', {'userName' : username, 'password' : password});
      }

     CustomerList (): Observable<any> {
        return this.http.post<any>(endpoint + '/customer/list', httpAuthOptions).pipe(
          tap(),
          catchError(this.handleError<any>('/customer/list'))
        );
      }
    
};
