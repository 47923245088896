import { jqxNotificationComponent } from 'jqwidgets-ng/jqxnotification';
import { BackendService } from './../../../app/services/backend.service';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { Component, OnInit, Inject, ViewChild, Input } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LoginComponent } from './../../../app/login/login.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { ResponseMessage } from '../../models/ResponseMessage';

@Component({
    selector: 'app-force-change-password',
    templateUrl: './force-change-password.component.html',
    styleUrls: ['./force-change-password.component.css'],
})
export class ForceChangePasswordComponent implements OnInit {

    form: FormGroup;
    submitted = false;
    ShownewPassword: boolean = false;
    ShowConfirmPassword: boolean = false;


    @ViewChild('msgNotification', { static: false }) msgNotification: jqxNotificationComponent;
    // @ViewChild('logincomponent', { static: false }) logincomponent: LoginComponent;
    // @ViewChild(LoginComponent, { static: true }) login;

    constructor(private formBuilder: FormBuilder,
        private backendService: BackendService,
        public dialogRef: MatDialogRef<ForceChangePasswordComponent>,
        private spinner: NgxSpinnerService,
        @Inject(MAT_DIALOG_DATA) public logindata: any) { }

    ngOnInit() {
        this.form = this.formBuilder.group({
            // oldPassword: new FormControl(),
            newPassword: new FormControl(),
            confirmPassword: new FormControl()
        }, {
            validator: MustMatch('newPassword', 'confirmPassword')
        });
    }

    sendNotification(message, messageType) {
        this.msgNotification.elementRef.nativeElement.querySelector('.jqx-notification-content').innerHTML = message;
        this.msgNotification.template(messageType);
        this.msgNotification.open();
    }


    get f() {
        return this.form.controls;
    }
    isPasswordMatch = false;

    newPasswordChangeEvent() {
        const upperCase = document.getElementById('pwd-upper-case');
        const number = document.getElementById('pwd-number');
        const characters = document.getElementById('pwd-characters');
        const lowerCase = document.getElementById('pwd-lower-case');
        const specialCharacters = document.getElementById('pwd-special-characters');
        const value = this.form.controls.newPassword.value;

        if (value) {
            this.isPasswordMatch = true;
            if (value.match('(.*[A-Z])')) {
                
                upperCase.classList.add('password-text-underline');
            } else {
                this.isPasswordMatch = false;
                upperCase.classList.remove('password-text-underline');
            }

            if (value.match('(.*[0-9])')) {
                // this.isPasswordMatch = true;
                number.classList.add('password-text-underline');
            } else {
                this.isPasswordMatch = false;
                number.classList.remove('password-text-underline');
            }

            if (value.match('(.*[a-z])')) {
                // this.isPasswordMatch = true;
                lowerCase.classList.add('password-text-underline');
            } else {
                this.isPasswordMatch = false;
                lowerCase.classList.remove('password-text-underline');
            }

            if (value.match(/^[!@#$%^&*?a-zA-Z0-9]+$/)){
                // this.isPasswordMatch = true;
                specialCharacters.classList.add('password-text-underline');
            } else {
                this.isPasswordMatch = false;
                specialCharacters.classList.remove('password-text-underline');
            }

            if (value.length >= 8) {
                // this.isPasswordMatch = true;
                characters.classList.add('password-text-underline');
            } else {
                this.isPasswordMatch = false;
                characters.classList.remove('password-text-underline');
            }
        }
        if (value.length == 0) {
            this.isPasswordMatch = false;
            upperCase.classList.remove('password-text-underline');
            number.classList.remove('password-text-underline');
            characters.classList.remove('password-text-underline');
            lowerCase.classList.remove('password-text-underline');
            specialCharacters.classList.remove('password-text-underline');
        }

    }

    closeChangePasswordDialog() {
        this.submitted = false;
    }
    isValid(controlName, error) {
        return this.form.controls[controlName].hasError(error);
    }
    dialogClose() {
        this.dialogRef.close();
    }

    AvoidSpace() {
        this.sendNotification("Please enter the valid special character(Space is not allowed).", "error")
        return false;
    }
    passwordToggleForNewPassword() {
        this.ShownewPassword= !this.ShownewPassword;
    }
    passwordToggleForConfirmPassword(){
        this.ShowConfirmPassword=!this.ShowConfirmPassword
    }

    changePassword() {
        this.submitted = true;
        const oldPassword = this.logindata.currentpassword;
        const newPassword = this.form.controls.newPassword;
        const username = this.logindata.currentusername;
        const confirmPassword = this.form.controls.confirmPassword;
        if (newPassword.value == null) {
            this.sendNotification("Please enter the new password.", 'error');
        }
        else if (confirmPassword.value == null) {
            this.sendNotification("Please enter the confirm password.", 'error');
        }
        else if(!this.isPasswordMatch)
        {
            this.sendNotification('Please follow the password rules.', 'error');
        }
        if (this.submitted && this.form.valid && this.isPasswordMatch) {
            console.log("this.form.valid", this.form.valid);
            console.log("this.isPasswordMatch", this.isPasswordMatch);
            this.spinner.show();
            const data = {
                'userName': username,
                'password': oldPassword,
                'newpassword': newPassword.value
            }
            this.backendService.firstTimeChangePassword(data).subscribe(
                res => {

                    const result = this.backendService.getJSONResponse(res);
                    if(result!= null)
                    {
                        if(result.data.isSuccess == true)
                        {
                            if(result.data.userStatus == ResponseMessage.PASSWORD_UPDATED)
                            {
                                this.submitted = false;
                                this.sendNotification("Password Updated. Please log in with the new password.", 'success');
                                this.dialogClose();
                                console.log('force change password results :: ', res);
                                this.spinner.hide();
                            }

                        }
                        else
                        {
                            if(result.data.userStatus == ResponseMessage.OLDPASSWORD_MATCHED)
                            {
                                this.submitted = false;
                                this.sendNotification("Entered password is being used in the last 5 Instances, please use a new password.", 'error');
                                this.spinner.hide();
                            }
                            if(result.data.userStatus == ResponseMessage.PASSWORDCHANGE_UNSUCCESSFUL)
                            {
                                this.submitted = false;
                                this.sendNotification(ResponseMessage.PASSWORDCHANGE_UNSUCCESSFUL, 'error');
                                this.spinner.hide();
                            }
                            if(result.data.userStatus == ResponseMessage.INCORRECT_USERNAME_PASSWORD)
                            {
                                this.submitted = false;
                                this.sendNotification(ResponseMessage.INCORRECT_USERNAME_PASSWORD, 'error');
                                this.spinner.hide();
                            }
                            if(result.data.userStatus == ResponseMessage.InvalidPasswordException)
                            {
                                this.submitted = false;
                                this.sendNotification(ResponseMessage.InvalidPasswordException + ". Password contains unsupportable special characters. Please check.", 'error');
                                this.spinner.hide();
                            }
                            if(result.data.userStatus == ResponseMessage.ERROR)
                            {
                                this.submitted = false;
                                this.sendNotification(ResponseMessage.ERROR + ". Failed to update the password. Please try again.", 'error');
                                this.spinner.hide();
                            }
                            if(result.data.userStatus == ResponseMessage.USER_NOT_FOUND)
                            {
                                this.submitted = false;
                                this.sendNotification(ResponseMessage.USER_NOT_FOUND, 'error');
                                this.spinner.hide();
                            }
                        }
                    }

                    // this.submitted = false;
                    // this.sendNotification("Password Updated. Please log in with the new password.", 'success');
                    // this.dialogClose();
                    // // this.dialogRef.close();
                    // console.log('force change password results :: ', res);
                    // this.spinner.hide();
                },
                err => {
                    console.log('error occured while force changing the password', err);
                    this.submitted = false;
                    this.sendNotification('error occured while force changing the password', 'error');
                    this.spinner.hide();
                }
            );
        }
    }

}


// custom validator to check that two fields match
export function MustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
        const control = formGroup.controls[controlName];
        const matchingControl = formGroup.controls[matchingControlName];

        if (matchingControl.errors && !matchingControl.errors.mustMatch) {
            // return if another validator has already found an error on the matchingControl
            return;
        }

        // set error on matchingControl if validation fails
        if (control.value !== matchingControl.value) {
            matchingControl.setErrors({ mustMatch: true });
        } else {
            matchingControl.setErrors(null);
        }
    }
}
