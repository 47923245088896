<br>
<div class="main-content">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-8 ml-auto mr-auto mt-3">
        <div class="page-categories">
          <br>
          <br>
          <ul class="nav nav-pills nav-pills-info nav-pills-icons justify-content-center" >
            <li class="nav-item">
              <a class="nav-link active" [ngClass]="{ 'active':activeTab==='myProfile'}" data-toggle="tab"
                href="#myProfile" title="Change My Profile" >
                <i class="material-icons">contact_emergency</i> Change My Profile
              </a>
            </li>
            <li class="nav-item"  *ngIf="isVisible">
              <a class="nav-link" [ngClass]="{ 'active':activeTab==='changePassword'}" data-toggle="tab"
                href="#changePassword"  title="Change Password">
                <i class="material-icons">password</i> Change Password
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" [ngClass]="{ 'active':activeTab==='appSettings'}" data-toggle="tab"
                href="#appSettings"  title="Application Settings">
                <i class="material-icons">settings</i> Application Settings
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" [ngClass]="{ 'active':activeTab==='helpCenter'}" data-toggle="tab" href="#helpCenter"
                 title="Help Center">
                <i class="material-icons">help_outline</i> Help Center
              </a>
            </li>
          </ul>
          <div class="tab-content tab-space tab-subcategories">
            <div class="tab-pane active" id="myProfile" [ngClass]="{ 'active':activeTab==='myProfile'}">
              <br>
              <div class="card">
                <div class="card-header mt-2">
                  <h2 class="header-text-3 text-viq-primary">Change My Profile</h2>
                  <hr class="hr-css">
                </div>
                <div class="card-body">
                  <form [formGroup]='userCreationForm'>
                    <div class="row col-md-12">
                        <mat-checkbox class="ml-3" color='primary' formControlName="isMFA" 
                        (change)='mfauserFlagChangeEvent($event)'>MFA
                        </mat-checkbox>
                    </div>
                    <div class="row col-md-12">
                      <div class="col-md-6">
                       
                        <mat-form-field class="example-full-width">
                          <mat-label>First Name</mat-label>
                          <input matInput tabindex="2" formControlName="firstName" pattern="^[a-zA-Z](?:[a-zA-Z0-9 ]*'{0,1}[a-zA-Z0-9])*$"  required  type='text'>
                          <mat-error *ngIf="isValid('firstName', 'required')">
                            First Name is <strong>required</strong>
                          </mat-error>
                        </mat-form-field>
                        <mat-form-field class="example-full-width">
                          <mat-label>Last Name</mat-label>
                          <input matInput tabindex="4" formControlName="lastName" pattern="^[a-zA-Z](?:[a-zA-Z0-9 ]*'{0,1}[a-zA-Z0-9])*$"  required type='text'>
                          <mat-error *ngIf="isValid('lastName', 'required')">
                            Last Name is <strong>required</strong>
                          </mat-error>
                        </mat-form-field>

                        <mat-form-field class="example-full-width">
                          <input tabindex="6" matInput ng2TelInput
                          [ng2TelInputOptions]="{separateDialCode:true, initialCountry: 'us'}" formControlName="phone"
                          (hasError)="onError($event)" (ng2TelOutput)="getNumber($event)"
                            (intlTelInputObject)="telInputObject($event)" (countryChange)="onCountryChange($event)" />
                           <!-- <mat-error *ngIf="isValid('phone', 'required')">
                            phone number is <strong>required</strong>
                          </mat-error> -->
                          <mat-error *ngIf="!hasError">
                            phone number is <strong>not valid</strong>
                          </mat-error>
                        </mat-form-field>
                      </div>
                      <div class="col-md-6">

                        <mat-form-field class="example-full-width">
                          <mat-label>Middle Name</mat-label>
                          <input matInput tabindex="3" formControlName="middleName" type='text'>
                        </mat-form-field>

                        <mat-form-field class="example-full-width">
                          <mat-label>Email</mat-label>
                          <input matInput tabindex="5" formControlName="email" (keyup)="emailchangeEvent($event)"
                            required type='email' pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{1,9}$">
                          <mat-error *ngIf="isValid('email', 'pattern')">
                            Email is <strong>not valid</strong>
                          </mat-error>
                          <mat-error *ngIf="isValid('email', 'required')">
                            Email is <strong>required</strong>
                          </mat-error>
                        </mat-form-field>
                      </div>
                    </div>
                  </form>
                  <br>
                  <div class="row">
                    <div class="col-md-12 text-center">
                      <button class='btn btn-viq btn-viq-primary' (click)='updateUser()'>Modify</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="tab-pane" id="changePassword" [ngClass]="{ 'active':activeTab==='changePassword'}">
              <br>
              <div class="card">
                <div class="card-header mt-2">
                  <h2 class="header-text-3 text-viq-primary">Change Password</h2>
                  <hr class="hr-css">
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-6">
                      <form [formGroup]='form'>
                        <div class="form-row ml-2">
                          <div class="form-group col-md-12">
                            <mat-form-field class="example-full-width">
                              <mat-label>Old Password</mat-label>
                              <input matInput formControlName="oldPassword" required (keydown.space)="AvoidSpace()"
                                type='password' [type]="showoldPassword ? 'text' : 'password'" maxlength="30">
                                <div class="action-icon" matSuffix (click)="passwordToggleoldPassword()">
                                  <i class="viq-icon {{showoldPassword ? 'viq-eye-close' : 'viq-eye-open'}}"></i>
                                </div>
                              <mat-error *ngIf="isChangePasswordValid('oldPassword', 'required')">
                                Old Password is <strong>required</strong>
                              </mat-error>
                            </mat-form-field>
                            <mat-form-field class="example-full-width">
                              <mat-label>New Password</mat-label>
                              <input matInput formControlName="newPassword" required (keyup)="newPasswordChangeEvent()"
                                (keydown.space)="AvoidSpace()" type='password' [type]="shownewPassword ? 'text' : 'password'" maxlength="30">
                                <div class="action-icon" matSuffix (click)="passwordTogglenewPassword()">
                                  <i class="viq-icon {{shownewPassword ? 'viq-eye-close' : 'viq-eye-open'}}"></i>
                                </div>
                              <mat-error *ngIf="isChangePasswordValid('newPassword', 'required')">
                                New Password is <strong>required</strong>
                              </mat-error>
                            </mat-form-field>
                            <mat-form-field class="example-full-width">
                              <mat-label>Confirm Password</mat-label>
                              <input matInput formControlName="confirmPassword" (keydown.enter)="changePassword()" required (keydown.space)="AvoidSpace()"
                                type='password' [type]="showconfirmPassword ? 'text' : 'password'" maxlength="30">
                                <div class="action-icon" matSuffix (click)="passwordToggleconfirmPassword()">
                                  <i class="viq-icon {{showconfirmPassword ? 'viq-eye-close' : 'viq-eye-open'}}"></i>
                                </div>
                              <mat-error *ngIf="isChangePasswordValid('confirmPassword', 'required')">
                                Confirm Password is <strong>required</strong>
                              </mat-error>
                              <mat-error *ngIf="isChangePasswordValid('confirmPassword', 'mustMatch')">
                                Confirm Password must match with New Password
                              </mat-error>
                            </mat-form-field>
                          </div>
                        </div>
                      </form>
                    </div>
                    <div class="col-md-1"></div>
                    <div class="col-md-5">
                      <h4 class="header-text-1">New Password must contain:</h4>
                      <div class='password-text mt-1'>
                        <span id="pwd-characters">
                          <p>At least 8 characters</p>
                        </span>
                        <span id="pwd-lower-case">
                          <p>At least 1 lower case letter (a-z)</p>
                        </span>
                        <span id="pwd-upper-case">
                          <p>At least 1 upper case letter (A-Z)</p>
                        </span>
                        <span id="pwd-number">
                          <p>At least 1 number (0-9)</p>
                        </span>
                        <span id="pwd-special-characters">
                          <p>At least 1 special character: !@#$%^&*?(space not allowed)</p>
                        </span>
                      </div>
                      <br>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12 text-center">
                      <button class='btn btn-viq btn-viq-primary' (click)='changePassword()'>Submit</button>
                      <button class='btn btn-viq btn-viq-primary' (click)='clearChangePasswordData()'>clear</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="tab-pane" id="appSettings" [ngClass]="{ 'active':activeTab==='appSettings'}">
              <div class="card">
                <div class="card-header mt-2">
                  <h2 class="header-text-3 text-viq-primary">App Preferences</h2>
                  <hr class="hr-css">
                </div>
                <div class="card-body">

                </div>
              </div>
            </div>
            <div class="tab-pane" id="helpCenter" [ngClass]="{ 'active':activeTab==='helpCenter'}">
              <div class="card">
                <div class="card-header mt-2">
                  <h2 class="header-text-3 text-viq-primary">Help Center</h2>
                  <hr class="hr-css">
                </div>
                <div class="card-body text-support ml-3">
                  <h5 class="header-text-1 mb-3">Support:</h5>
                    <!-- <p class="text-support"><span style="white-space:pre; word-wrap: break-word" >{{contactEmail}}</span><br />
                        <span style="white-space:pre;">{{contactNumber}}</span></p> -->
                  <p> {{ contactEmail }} </p> <br>       
                  <p> <span style="white-space:pre;">{{contactNumber}}</span> </p>
                  <hr>
                  <h5 class="header-text-1 mb-3">Disclaimer:</h5>
                  <p class=""> This system is to be used by authorized users only. The data in this
                    system may contain sensitive information protected by various Local, State, and Federal
                    statutes. All access or use of this system constitutes user understanding and acceptance of
                    these terms, and constitutes unconditional consent to review, monitoring, and action by
                    authorized personnel. While using this system, your use may be monitored, recorded, and
                    audited, and any violation may be subject to action that may result in criminal, civil, or
                    administrative penalties.</p>
                  <br>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<jqxNotification #msgNotification [position]="'top-right'" [opacity]="1.0" [autoOpen]="false"
    [autoClose]="true" [animationOpenDelay]="800" [autoCloseDelay]="5000" template.success>
</jqxNotification>