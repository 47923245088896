<h1 mat-dialog-title>
    <img class="img-icon mr-2" src="../../../assets/img/icon/form.png" alt="File Names" />
    File Names <button mat-dialog-close type="button" class="close pull-right"
      (click)='dialogClose()' aria-label="Close">&times;</button></h1>
  <hr>
  <div mat-dialog-content class="dialog-content">
    <div class='col-md-12'>
      <div *ngFor='let field of fileNamesList'>
        <div class="row col-md-12 ">
          <hr>
          <!-- <div class="col-md-2">
            <label>.</label>
          </div> -->
          <div class="col-md-12">
            <label class="text-form-note-l">{{field}}</label>
          </div>
          <!-- <div class="col-md-1" style="text-align: left;">:</div>
          <div class="col-md-6" style="text-align: left;"><label class="text-form-note-r">{{field.fileName}}</label></div> -->
        </div>
      </div>
      <br>
    </div>
  </div>
  <mat-dialog-actions align="end" style="margin-right: 20px; margin-bottom: 2px;">
    <button class='btn btn-viq-primary pull-right ml-1' (click)='dialogClose()'>Ok</button>
  </mat-dialog-actions>