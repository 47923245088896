import { jqxNotificationComponent } from 'jqwidgets-ng/jqxnotification';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Component, OnInit, Inject, ViewChild, EventEmitter, Output, ElementRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { NgxFileDropEntry, FileSystemFileEntry } from 'ngx-file-drop';
import { NgxSpinnerService } from 'ngx-spinner';
import { BackendService } from 'src/app/services/backend.service';
import { environment } from 'src/environments/environment';
import { jqxTextAreaComponent } from 'jqwidgets-ng/jqxtextarea';
import { NotesDialogData, MediaDialogData, AttachmentsDialogData, FileToUpload, JobCreateDto, JobCreateInputDto, EstimateDialogData } from 'src/app/models/apiRequestModels';
import * as JSZip from 'jszip';
import Swal from 'sweetalert2';
import { NgAudioRecorderService, OutputFormat } from 'ng-audio-recorder';
import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { cloneDeep, find, filter } from 'lodash';
import { MatRadioButton } from '@angular/material/radio';
import { NavigationStart, Router } from '@angular/router';
import { EMPTY, Observable, asyncScheduler, of, zip } from 'rxjs';
import { map, startWith, tap } from 'rxjs/operators';
import { jqxFormattedInputComponent } from 'jqwidgets-ng/jqxformattedinput';
import { ConfigurationServicePlaceholders } from 'aws-sdk/lib/config_service_placeholders';
import { error, log } from 'console';
import { ViewTNCComponent } from 'src/app/adminpages/tenant/tenant-creation/view-tnc/view-tnc.component';

let zipFile;
let AudioFileFormats: [];
let DocumentFileFormats: [];
let fileMinSize = environment.AudioFileSize;
let ListzipFiles = [];
let ListOfZippedDocuments = [];
export interface dropdownmodel {
    name: string;
}
@Component({
    selector: 'app-upload-media',
    templateUrl: './upload-media.component.html',
    styleUrls: ['./upload-media.component.css']
})

export class UploadMediaComponent implements OnInit {
    @Output()

    @ViewChild('file', { static: false }) file
    @ViewChild('fileUploader', { static: false }) fileUploader: ElementRef;
    @ViewChild('msgNotification', { static: false }) msgNotification: jqxNotificationComponent;
    @ViewChild('notesComponent', { static: false }) notesComponent: jqxTextAreaComponent;
    @ViewChild('customerNotesComponent', { static: false }) customerNotesComponent: jqxTextAreaComponent;
    //oneTrans
    @ViewChild('oneTrans', { static: false }) oneTrans: MatRadioButton;
    //sepTrans
    @ViewChild('sepTrans', { static: false }) sepTrans: MatRadioButton;
    @ViewChild('masterJob', { static: false }) masterJob: MatRadioButton;
    @ViewChild('myFormattedInput', { static: false }) myFormattedInput: jqxFormattedInputComponent;

    notify: EventEmitter<string> = new EventEmitter<string>();
    ViewTnCdialogRef: MatDialogRef<ViewTNCComponent>;
    ListZipFailedFiles: any;
    ListOfOriginalFiles = [];
    ListOfZipFiles = [];
    IsMergeAudiosGlobal: boolean;
    ListOfMergeAudioFiles = [];
    MergeAudioFilescount = 0;
    MergeAudioFileNameList = [];
    CompleteAudioFilescount = 0;
    MergeAudioFormat: string;
    ListOfAttachmentFiles = [];
    ListOfAttachments1Global: FileToUpload[] = [];
    Audiofile = new FileToUpload();
    ListOfDuplicateFiles = [];
    DuplicateFileNames;
    ListOfFileFormats: any[] = [];
    Attachmentfile = new FileToUpload();
    progress: number;
    FileType = "";
    public originalFile: File;
    public ListOfNames: string;
    public isDuplicate: boolean;
    public ListOfAudioFiles: NgxFileDropEntry[] = [];
    returnUrl: string;
    canUpload: boolean = false;
    MediaFileUploadUrl = "";
    removable = true;
    ref: any;
    isjob:any;
    showTransSelection: boolean = false;
    showTransSelectionMsg: boolean = false;
    showFileDragzone: boolean = false;
    showAudioFileList: boolean = false;
    showFileList: boolean = false;
    showNotes: boolean = false;
    showRecordzone: boolean = false;
    showAttachment: boolean = false;
    showMediaUpload: boolean = false;
    showRecording: boolean = false;
    showEstimateDetails: boolean = false;
    dialogTitle: string = "";
    altTitleNames: string = "";
    filelistTitle: string = "";
    dragButtonTitle: string = "";
    Notes = "";
    custNotes = "";
    notesDialogData = new NotesDialogData();
    mediaDialogData = new MediaDialogData();
    attachmentsDialogData = new AttachmentsDialogData();
    estimateDialogData = new EstimateDialogData();
    originalDataCopy;
    IsMergeAudios = false;
    isfutureorder: boolean;
    ListOfAttachments: FileToUpload[] = [];
    ListOfAudioFiles1: FileToUpload[] = [];
    ListofJobCreateDto: JobCreateDto[] = [];
    ListofJobCreateInputDto: JobCreateInputDto[] = [];
    exceedFileLimit: boolean;
    fileCount: number;
    maxNumberOfFiles: number;
    mode: string;
    resumeRecordShow: boolean = false;
    pauseButtonShow: boolean = false;
    interval;
    RecordingTimer: boolean = false;
    PauseRecordingTimer: boolean = false;
    stopButtonShow: boolean = false;
    startRecordShow: boolean = true;
    sec: any = '0' + 0;
    min: any = '0' + 0;
    hr: any = '0' + 0;
    GlobalOutput: any;
    image: string = "";
    transcriptionType: any;
    jobTypefilteredOptions: Observable<dropdownmodel[]>;
    jobTypeOptions: any[];
    EstimatejobtypeControl = new FormControl();
    selectedJobTypeExtId = "";
    selectedJobTypeDisplayName = "";
    jobCostMinuteRateEstimation = "";
    estimationForm: FormGroup;
    numberOfMins: number;
    totalEstimateAmount: number;
    receiptEmail: string;
    showEstimateAmount: boolean;
    showAcceptButton: boolean;
    isGenerateButtonEnable: boolean;
    termsAndConditionData?: {};
    languages: any[] = [];
    filteredLanguages: Observable<any[]>;
    showCustomerNotes: boolean;
    ForeignLanguagevalues = [
        { value: 0, name: '--- Not Selected ---' },
        { value: 1, name: 'Foreign Language - Timestamp' },
        { value: 2, name: 'Foreign Language Transcription' },
        { value: 3, name: 'Interpretation' },
        { value: 4, name: 'Double Column - Transcription & Translation' },
        { value: 5, name: 'Stacked - Transcription & Translation' }
    ];
    ForeignLanguageControl = new FormControl(this.ForeignLanguagevalues[0], Validators.required);
    languageControl = new FormControl(this.languages[0]);
    selectedIndex: number = 0;
    organizationEstimateMessage: string;
    TenantEstimateMessage: string;
    DisplayEstimationMessage: string;
    FlCostMinuteRateEstimation: "";
    flLanguageService = 0;
    selectedLanguage: string;
    isForeignLanguage: boolean;
    selectedLanguageIndex:number=0;
    estimateDateTime:string;

    constructor(private formBuilder: FormBuilder,
        private backendService: BackendService,
        public dialogRef: MatDialogRef<UploadMediaComponent>,
        private spinner: NgxSpinnerService,
        private audioRecorderService: NgAudioRecorderService,
        private datePipe: DatePipe,
        private http: HttpClient,
        private router: Router,
        public dialog: MatDialog,
        @Inject(MAT_DIALOG_DATA) public jobdata: any) { }

    ngOnInit() {
        this.getTermsAndConditions();

        this.filteredLanguages = this.languageControl.valueChanges.pipe(
            startWith(''),
            map(value => typeof value === 'string' ? value : value.Language),
            map(language => language ? this.filterLanguages(language) : this.languages.slice())
        );

        this.notify.emit("send data");
        this.ref = this.jobdata.ref;
        this.isjob = this.jobdata.isjob;
        this.router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                this.dialogClose();
            }
        });
        
        this.showCustomerNotes=( localStorage.getItem('CustomerNotesEnable') === 'true' ) ? true:false;
        this.showTransSelection = (this.jobdata.ref === "media" || this.jobdata.ref === "record") ? true : false;
        this.showTransSelectionMsg = (this.jobdata.ref === "media") ? true : false;
        this.showFileDragzone = (this.jobdata.ref === "media" || this.jobdata.ref === "attachDocs") ? true : false;
        this.showAudioFileList = (this.jobdata.ref === "media" || this.jobdata.ref === "record") ? true : false;
        this.showFileList = (this.jobdata.ref === "media" || this.jobdata.ref === "attachDocs" || this.jobdata.ref === "record") ? true : false;
        this.showRecordzone = (this.jobdata.ref === "record") ? true : false;

        this.showMediaUpload = (this.jobdata.ref === "media") ? true : false;
        this.showRecording = (this.jobdata.ref === "record") ? true : false;
        this.showAttachment = (this.jobdata.ref === "attachDocs") ? true : false;
        this.showNotes = (this.jobdata.ref === "addNotes") ? true : false;
        this.showEstimateDetails = (this.jobdata.ref === "estimate") ? true : false;
        if (this.showMediaUpload) {
            this.dialogTitle = "Select Audio/Video Files to Upload";
            this.filelistTitle = "List of selected and/or recorded Audio/Video files";
            this.dragButtonTitle = "Browse Audio/Video";
            this.image = "../../../assets/img/icon/upload-btn.png";
            this.altTitleNames = "Select Audio/Video Files";
        }
        if (this.showRecording) {
            this.dialogTitle = "Record Audio Files";
            this.filelistTitle = "List of selected and/or recorded Audio/Video files";
            this.image = "../../../assets/img/icon/record-btn.png";
            this.altTitleNames = "Record Audio";
        }
        if (this.showAttachment) {
            this.dialogTitle = "Attach Supporting Documents";
            this.filelistTitle = "List of attached supporting documents";
            this.dragButtonTitle = "Browse Documents";
            this.image = "../../../assets/img/icon/attachment-btn.png";
            this.altTitleNames = "Attach Supporting Documents";
        }
        if (this.showNotes) {
            this.dialogTitle = "Add Notes";
            this.image = "../../../assets/img/icon/add-notes-btn.png";
            this.altTitleNames = "Add Notes";
        }
        if (this.showEstimateDetails) {
            this.dialogTitle = "Calculate Estimate";
            this.image = "../../../assets/img/icon/cost_estimation.png";
            this.altTitleNames = "Calculate Estimate";
        }

        this.http.get('assets/files/FileFormats.txt', { responseType: 'json' })
            .subscribe(data => {
                this.ListOfFileFormats[0] = data;
                var FormatsList = this.ListOfFileFormats[0];
                AudioFileFormats = FormatsList[0].AudioFileFormats;
                DocumentFileFormats = FormatsList[1].DocumentFileFormats;
            });
        this.estimationForm = this.formBuilder.group({
            numberOfMins: new FormControl(''),
            receiptEmail: new FormControl(''),
        })
        this.showEstimateAmount = false;

    }
    onInput(event: any) {
        const input = event.target.value;
        event.target.value = input.replace(/[^0-9]/g, ''); // Remove any non-numeric characters
      }
    ngAfterViewInit() {
        asyncScheduler.schedule(() => {
            this.loadLanguages();
            this.originalDataCopy = cloneDeep(this.jobdata.data);
            //SET PREVIOUS DATA IF ANY.
            if (this.jobdata.ref === "addNotes") {
                this.notesDialogData = this.jobdata.data;
                this.notesComponent.val(this.notesDialogData.Notes);
                if(this.showCustomerNotes){
                    this.customerNotesComponent.val(this.notesDialogData.custNotes);
                }
            } else if (this.ref === "media" || this.ref === "record") {
                this.mediaDialogData = this.jobdata.data;
                this.ListOfAudioFiles = this.mediaDialogData.ListOfAudioFiles;
                this.ListOfOriginalFiles = this.mediaDialogData.ListOfOriginalFiles;
                this.canUpload = this.mediaDialogData.canUpload;
                this.originalFile = this.mediaDialogData.originalFile;
                this.FileType = this.mediaDialogData.FileType;
                ListzipFiles = this.mediaDialogData.ListzipFiles;
                this.ListOfAudioFiles1 = this.mediaDialogData.ListOfAudioFiles1;
                this.IsMergeAudios = this.mediaDialogData.IsMergeAudios;
                this.ListOfMergeAudioFiles = this.mediaDialogData.ListOfMergeAudioFiles;
                this.MergeAudioFileNameList = this.mediaDialogData.MergeAudioFileNameList;
                //this.oneTrans.checked = this.mediaDialogData.IsMergeAudios;

            } else if (this.ref === "attachDocs") {
                this.attachmentsDialogData = this.jobdata.data;
                this.ListOfAttachments = this.attachmentsDialogData.ListOfAttachments;
                this.ListOfAttachmentFiles = this.attachmentsDialogData.ListOfAttachmentFiles;
            }
            else if (this.ref === "estimate") {
                this.spinner.show();
                this.EstimateloadJobTypeValues(localStorage.getItem('departments'));
                this.estimateDialogData = this.jobdata.data;
                    this.isForeignLanguage = (/true/i).test(localStorage.getItem('isForeignLanguage'));
                    console.log("isForeignLanguage ::::", this.isForeignLanguage);
                    this.selectedIndex = this.isForeignLanguage == true ? 1 : 0;
                    console.log("Selected Index ::::", this.selectedIndex);
                    this.estimateDialogData ? this.estimationForm.controls.numberOfMins.setValue(this.estimateDialogData.numberOfMins) : null;
                    this.estimateDialogData ? this.estimationForm.controls.receiptEmail.setValue(this.estimateDialogData.receiptEmail) : null;
                    this.estimateDialogData ? this.totalEstimateAmount = this.estimateDialogData.totalEstimateAmount : null;
                    this.estimateDialogData ? this.showAcceptButton = this.estimateDialogData.showAcceptButton : null;
                    this.numberOfMins = this.estimateDialogData ? this.estimateDialogData.numberOfMins : null;
                    this.receiptEmail = this.estimateDialogData ? this.estimateDialogData.receiptEmail : null;
                    this.estimateDialogData.selectedIndex=this.selectedIndex;
                    console.log(" this.estimateDialogData::", this.estimateDialogData);
                    setTimeout(() => {
                    if (this.estimateDialogData.selectedIndex == 0) {
                        if (this.estimateDialogData.numberOfMins == 0 || this.estimateDialogData.numberOfMins == undefined) {
                            this.showEstimateAmount = false;
                        }
                        else {
                            this.showEstimateAmount = true; 
                            this.EstimatejobtypeControl.setValue({
                                'externalid': this.estimateDialogData.estimateJobTypeExtId,
                                'name': this.estimateDialogData.estimateJobTypeDisplayName,
                                'jobCostMinuteRateEstimation':  this.estimateDialogData.jobCostMinuteRateEstimation
                            }
                            )
                            this.jobTypeChangeEvent(this.EstimatejobtypeControl)
                            this.jobCostMinuteRateEstimation=this.estimateDialogData.jobCostMinuteRateEstimation;  
                        }
                    }
                    else {
                        if (this.estimateDialogData.numberOfMins == 0 || this.estimateDialogData.numberOfMins == undefined) {
                            this.showEstimateAmount = false;
                        } else {
                            var selectedLanguageIndex = this.findIndexByValue(this.estimateDialogData.selectedLanguage);
                            this.selectedLanguageIndex = selectedLanguageIndex;
                            this.showEstimateAmount = true;
                            this.ForeignLanguageControl.setValue(this.ForeignLanguagevalues[this.estimateDialogData.selectedFLlanguage])
                            this.languageControl.setValue(this.languages[this.selectedLanguageIndex]);
                            this.LoadflLanguageService(this.ForeignLanguageControl);
                            this.LoadLanguage(this.languageControl);
                            this.jobCostMinuteRateEstimation=this.estimateDialogData.jobCostMinuteRateEstimation; 
                        }
                    }
                    this.showEstimateAmount = (this.estimateDialogData.numberOfMins == 0 || this.estimateDialogData.numberOfMins == undefined) ? false : true;
                }, 2000);
            }
       })
    }
    jobtypeValuesList = []

    findIndexByValue(value) {
        for (var i = 0; i < this.languages.length; i++) {
            if (this.languages[i].Language === value) {
                return i;
            }
        }
    }
    async EstimateloadJobTypeValues(departmentId) {
        let jobTypeArray = [];
        this.jobtypeValuesList = [];
        var JobTypeDetails = await this.backendService.getDepartmentJobTypeValues(departmentId).toPromise();
        var jobtypeList = this.backendService.getJSONResponse(JobTypeDetails['data'].result);
        jobtypeList = jobtypeList.filter(item => item.externalId !== "Default");
        this.jobtypeValuesList = jobtypeList;
        for (let jobtype of jobtypeList) {
            let data = { 'id': jobtype.id, 'name': jobtype.displayName, 'externalid': jobtype.externalId, 'jobCostMinuteRateEstimation': jobtype.jobCostMinuteRateEstimation }
            jobTypeArray.push(data);
        }
        jobTypeArray.sort((a, b) => a.name.localeCompare(b.name));
        this.jobTypeOptions = jobTypeArray;
        this.jobTypefilteredOptions = this.EstimatejobtypeControl.valueChanges
            .pipe(
                startWith(''),
                map(value => typeof value === 'string' ? value : value['name']),
                map(name => name ? this.jobTypefilter(name) : this.jobTypeOptions.slice()),
            );
        if (this.jobTypeOptions.length == 1) {
            this.EstimatejobtypeControl.setValue(this.jobTypeOptions[0]);
            this.EstimatejobtypeControl.disable({ onlySelf: true });
            this.selectedJobTypeExtId = this.jobTypeOptions[0].externalid;
            this.selectedJobTypeDisplayName = this.jobTypeOptions[0].name;
        }
    }
    private jobTypefilter(name: any): dropdownmodel[] {
        const filterValue = name.toLowerCase();
        return this.jobTypeOptions.filter(option => option.name.toLowerCase().indexOf(filterValue) >= 0);
    }
    jobTypeChangeEvent(event: any): void {
        if (event) {
            this.selectedJobTypeExtId = event.externalid;
            this.selectedJobTypeDisplayName = event.name;
            this.jobCostMinuteRateEstimation = event.jobCostMinuteRateEstimation;
        }
        if( this.showEstimateAmount == true){
            this.selectedJobTypeExtId = event.value.externalid;
            this.selectedJobTypeDisplayName = event.value.name;
            this.jobCostMinuteRateEstimation = event.value.jobCostMinuteRateEstimation;
        }
    };
    jobtypeChange() {
        this.selectedJobTypeExtId = '';
        this.selectedJobTypeDisplayName = '';
        this.EstimatejobtypeControl.setValue('');
        this.showEstimateAmount = false;
    }
    changeMinutes() {
        this.showEstimateAmount = false;
    }
    GenerateEstimate() {
        this.selectedIndex = this.isForeignLanguage == true ? 1 : 0;
        console.log("this.selectedIndex:::", this.selectedIndex);
        this.numberOfMins = this.estimationForm.controls.numberOfMins.value;
        this.receiptEmail = this.estimationForm.controls.receiptEmail.value;
        this.estimateDateTime = this.getCurrentDateTime();
        if (this.selectedIndex == 0) {
            this.spinner.show();
            if (this.selectedJobTypeDisplayName == "" || this.selectedJobTypeDisplayName == null) {
                this.sendNotification("Please select Job type.", "error");
            }
            else if (this.numberOfMins == 0 || this.numberOfMins == null) {
                this.sendNotification("Please enter Number of minutes of Audio.", "error");
            }
            else {
                if (this.jobCostMinuteRateEstimation == null || Number(this.jobCostMinuteRateEstimation) == 0) {
                    this.mode = "jobCostMinuteRateEstimationNull";
                    this.sweetalertForEstimateWarnings();
                }
                else {
                    console.log("Number(this.jobCostMinuteRateEstimation)", Number(this.jobCostMinuteRateEstimation));
                    this.totalEstimateAmount = this.numberOfMins * Number(this.jobCostMinuteRateEstimation);
                    const formattedTotalEstimateAmount: string = this.totalEstimateAmount.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
                    if (this.totalEstimateAmount > 0) {
                        this.showEstimateAmount = true;
                        console.log("this.receiptEmail:::", this.receiptEmail);
                        if (this.receiptEmail == "" || this.receiptEmail == undefined) {
                            console.log("this.receiptEmail field is empty");
                        }
                        else {
                            const data = {
                                'toEmail': this.receiptEmail,
                                'numberofMinutesforEstimation': this.numberOfMins,
                                'perMinuteEstimationRate': (Number(this.jobCostMinuteRateEstimation)),
                                'totalEstimationCost': formattedTotalEstimateAmount,
                                'estimateDateTime':this.estimateDateTime
                            }
                            this.backendService.SendEstimationEmail(data).subscribe(
                                (response) => {
                                    console.log('Email sent successfully', response);
                                },
                                (error) => {
                                    console.error('Error sending email', error);
                                }
                            );
                            this.sendNotification("The estimation details were sent to the receipt email.", "success");
                        }
                    }
                }
            }
            this.spinner.hide();
        }
        else {
            if (this.flLanguageService == 0) {
                this.sendNotification("Please select Foreign Language Order.", "error");
            }
            else if (this.selectedLanguage == undefined || this.selectedLanguage == "--- Not Selected ---") {
                this.sendNotification("Please select Language.", "error");
            }
            else if (this.selectedLanguage != "" && this.selectedLanguage != "Spanish") {
                this.mode = "selectedLanguageNotEmpty";
                this.sweetalertForEstimateWarnings();
            }
            else if (this.numberOfMins == 0 || this.numberOfMins == null) {
                this.sendNotification("Please enter Number of minutes of Audio.", "error");
            }
            else if (this.flLanguageService == 1) {
                this.mode = "flLanguageServiceValue1";
                this.sweetalertForEstimateWarnings();
            }
            else {
                this.spinner.show();
                const data = {
                    'fLServiceType': this.flLanguageService,
                    'language': this.selectedLanguage,
                }
                console.log("data", data);
                this.backendService.getflratebylanguage(data).subscribe(
                    (response: any) => {
                        console.log('flrate values', response)
                        this.FlCostMinuteRateEstimation = response.data.flRate;
                        this.jobCostMinuteRateEstimation = this.FlCostMinuteRateEstimation;
                        this.totalEstimateAmount = this.numberOfMins * Number(this.jobCostMinuteRateEstimation);
                        const formattedTotalEstimateAmount: string = this.totalEstimateAmount.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
                        this.showEstimateAmount = true;
                        if (this.receiptEmail == "" || this.receiptEmail == undefined) {
                            console.log("this.receiptEmail field is empty");
                        }
                        else {
                            const data = {
                                'toEmail': this.receiptEmail,
                                'numberofMinutesforEstimation': this.numberOfMins,
                                'perMinuteEstimationRate': (Number(this.FlCostMinuteRateEstimation)),
                                'totalEstimationCost': formattedTotalEstimateAmount,
                                'estimateDateTime':this.estimateDateTime
                            }
                            this.backendService.SendEstimationEmail(data).subscribe(
                                (response) => {
                                    console.log('Email sent successfully', response);
                                },
                                (error) => {
                                    console.error('Error sending email', error);
                                }
                            );
                            this.sendNotification("The estimation details were sent to the receipt email.", "success");
                        }
                        this.spinner.hide();
                    },
                    (error) => {
                        console.error('Error while fetching flrate', error);
                    }
                );
            }
        }
    }
    getCurrentDateTime(): string {
        const now = new Date();
        return this.datePipe.transform(now, 'dd-MMM-yyyy HH:mm:ss') || '';
      }
    getTermsAndConditions() {
        this.backendService.getTermsAndConditionByTenant(localStorage.getItem('tenantId')).subscribe(
            res => {
                this.termsAndConditionData = JSON.parse(res['data'].tncjson);
                this.TenantEstimateMessage = res['data'].estimationMessage;
                this.organizationEstimateMessage = localStorage.getItem('estimationMessage');
                if (this.organizationEstimateMessage == null || this.organizationEstimateMessage == "") {
                    this.DisplayEstimationMessage = this.TenantEstimateMessage;
                }
                else if (this.TenantEstimateMessage == null || this.TenantEstimateMessage) {
                    this.DisplayEstimationMessage = this.organizationEstimateMessage;
                }
                else if ((this.organizationEstimateMessage == null || this.organizationEstimateMessage == "") && (this.TenantEstimateMessage == null || this.TenantEstimateMessage)) {
                    this.DisplayEstimationMessage = this.organizationEstimateMessage;
                }
                this.spinner.hide();
            },
            err => {
                console.log("error result :::", err.error.message)
                let errorResult = err.error.message;
                this.sendNotification(errorResult, 'error')
                this.spinner.hide();
            })
    }
    viewTnC(event: any) {
        console.log("viewtnc");
        this.openViewTNC(this.termsAndConditionData);
    }
    openViewTNC(tnc): void {
        if (this.ViewTnCdialogRef == null) {
            this.ViewTnCdialogRef = this.dialog.open(ViewTNCComponent, {
                width: '50%',
                maxHeight: '90vh',
                data: { "tnc": tnc },
                disableClose: false,
                panelClass: 'my-dialog'
            });
            this.ViewTnCdialogRef.afterClosed().subscribe(result => {
                this.ViewTnCdialogRef = null;
            });
        }
    }
    tabClick(tab) {
        this.selectedIndex = tab.index;
        if (this.selectedIndex == 1) {
            this.ForeignLanguageControl.setValue(this.ForeignLanguagevalues[this.estimateDialogData.selectedFLlanguage])
            this.languageControl.setValue(this.languages[this.selectedLanguageIndex]);
            this.estimationForm.controls.numberOfMins.setValue(this.numberOfMins);
            this.estimationForm.controls.receiptEmail.setValue(this.receiptEmail);
            this.jobCostMinuteRateEstimation = "";
            this.showEstimateAmount = (this.totalEstimateAmount > 0) ? true : false;
        }
        else  {
            this.estimationForm.controls.numberOfMins.setValue(this.numberOfMins);
            this.estimationForm.controls.receiptEmail.setValue(this.receiptEmail);
            this.showEstimateAmount = (this.totalEstimateAmount > 0) ? true : false;
            this.EstimatejobtypeControl.setValue({
                'externalid': this.selectedJobTypeExtId[0],
                'name': this.selectedJobTypeDisplayName[0]
            })
        }

    }
    loadLanguages() {
        this.http.get<any[]>('../assets/files/LanguagesList.txt')
            .subscribe(data => {
                this.languages = data;
                this.languageControl = new FormControl(this.languages[0]);
            });
        if (this.languages.length > 0) {
            this.languageControl.setValue(this.languages[0].Language);
        }
    }

    filterLanguages(name: string): any[] {
        return this.languages.filter(language =>
            language.Language.toLowerCase().indexOf(name.toLowerCase()) === 0);
    }



    languageChangeEvent(value: any) {
        console.log("value:::",value)
        this.selectedLanguage=value.option.value.Language;
        if(this.selectedLanguage == "--- Not Selected ---"){
            this.sendNotification("Select a Language", 'error');
        }
        else if (this.selectedLanguage != "" && this.selectedLanguage != "Spanish") {
            this.mode = "selectedLanguage";
            this.sweetalertForEstimateWarnings();
        }
    }
    LoadLanguage(value: any)
    {
        this.selectedLanguage= value.value.Language;
        if(this.selectedLanguage == "--- Not Selected ---"){
            this.sendNotification("Select a Language", 'error');
        }
        else if (this.selectedLanguage != "" && this.selectedLanguage != "Spanish") {
            this.mode = "selectedLanguage";
            this.sweetalertForEstimateWarnings();
        }
    }
    isValid(controlName, error) {
        return this.estimationForm.controls[controlName] && this.estimationForm.controls[controlName].hasError(error);
    }
    AcceptEstimate() {
        console.log("Accept Estimate...");
        this.estimateDialogData.jobCostMinuteRateEstimation = this.jobCostMinuteRateEstimation;
        this.estimateDialogData.totalEstimateAmount = this.totalEstimateAmount;
        this.estimateDialogData.numberOfMins = this.numberOfMins;
        this.estimateDialogData.receiptEmail = this.receiptEmail;
        this.estimateDialogData.selectedIndex = this.selectedIndex;
        if (this.selectedIndex == 0) {
            this.estimateDialogData.estimateJobTypeDisplayName = this.selectedJobTypeDisplayName;
            this.estimateDialogData.estimateJobTypeExtId = this.selectedJobTypeExtId;
            this.dialogRef.close(this.estimateDialogData);
            this.sendNotification("Estimate Accepted", "success");
        }
        else {
            if (this.totalEstimateAmount < 150 || this.totalEstimateAmount == 0) {
                this.spinner.hide();
                this.mode = "totalEstimateAmountLessthen150";
                this.sweetalertForEstimateWarnings();
            }
            else {
                this.estimateDialogData.selectedLanguage = this.selectedLanguage;
                this.estimateDialogData.selectedFLlanguage = this.flLanguageService;
                this.dialogRef.close(this.estimateDialogData);
                this.sendNotification("Estimate Accepted", "success");
            }
        }

    }
    flLanguageChangeEvent(event) {
        this.flLanguageService = event.option.value.value;
        if (this.flLanguageService == 1)
        {
            this.mode = "flLanguageServiceValue1";
            this.sweetalertForEstimateWarnings();
        }
        if(this.flLanguageService == 0){
            this.sendNotification("Select a Foreign Language", 'error');
        }
    }
    LoadflLanguageService(val) {
        this.flLanguageService = val.value.value;
        if (this.flLanguageService == 1)
        {
            this.mode = "flLanguageServiceValue1";
            this.sweetalertForEstimateWarnings();
        }  
        if(this.flLanguageService == 0){
            this.sendNotification("Select a Foreign Language", 'error');
        }
    }



    displayFn(dropdownmodel: dropdownmodel): string {
        return dropdownmodel && dropdownmodel.name ? dropdownmodel.name : '';
    }
    displayFnFL(ForeignLanguagevalues: any): string {
        return ForeignLanguagevalues ? ForeignLanguagevalues.name : '';
    }
    displayFnLanguage(language?: any): string | undefined {
        return language ? language.Language : '';
    }

    fileOver(event) {
        console.log('fileOver event : ' + event);
    }

    fileLeave(event) {
        console.log('fileLeave event : ' + event);
    }

    getFilesCount(): number {
        if (this.ref === "attachDocs") {
            return this.ListOfAttachments.length ? this.ListOfAttachments.length : 0;
        }
        else if (this.ref === "media" || this.ref === "record") {
            return this.ListOfAudioFiles.length ? this.ListOfAudioFiles.length : 0;
        }
    }

    removeFileByName(fileName) {
        if (this.IsMergeAudios == true) {
            this.remove();
        }
        else {
            var ListAfterRemove = [];
            this.ListOfOriginalFiles.forEach(filedetails => {
                if (filedetails.name == fileName) {
                }
                else {
                    ListAfterRemove.push(filedetails);
                }
            });
            this.ListOfOriginalFiles = ListAfterRemove;
            this.ListOfAudioFiles = this.ListOfOriginalFiles;
            if (this.ListOfAudioFiles.length == 0) {
                this.canUpload = true;
                this.ListOfOriginalFiles = [];
                ListzipFiles = [];
            }
            this.canUpload = true;
            this.file = null;
            this.originalFile = null;
        }

    }
    removeAttachmentByName(fileName) {
        var ListofAttachmentsAfterRemove = [];
        var ListOfAttachmentFilesAfterRemove = [];
        this.ListOfAttachments.forEach(filedetails => {
            if (filedetails.fileName == fileName) {
            }
            else {
                ListofAttachmentsAfterRemove.push(filedetails);
            }
        });
        this.ListOfAttachments = ListofAttachmentsAfterRemove;
        
        this.ListOfAttachmentFiles.forEach(filedetails => {
            if (filedetails.filename == fileName) {
            }
            else {
                ListOfAttachmentFilesAfterRemove.push(filedetails);
            }
        });
        this.ListOfAttachmentFiles = ListOfAttachmentFilesAfterRemove;
        ListOfZippedDocuments = this.ListOfAttachments;
        if (this.ListOfAttachments.length == 0) {
            this.canUpload = false;
        }
        console.log("removeAttachmentByName ListOfAttachments", this.ListOfAttachments);
        console.log("removeAttachmentByName ListOfAttachmentFiles", this.ListOfAttachmentFiles);
    }
    public async dropped(files: NgxFileDropEntry[]) {
        console.log("dropped started...", this.showAttachment);
       
        this.exceedFileLimit = this.ListOfAudioFiles.length == 0 ? false : this.exceedFileLimit;
       // console.log("ListOfAudioFiles.length::: ", this.ListOfAudioFiles.length);
        ListzipFiles = [];
        var fileNumber = 0;
        this.fileCount = 0;
        this.spinner.show();
        for (const droppedFile of files) {
            // Is it a file?
            //check for Media/Attachment
            if (this.ref == "media") {
                if (droppedFile.fileEntry.isFile) {
                    if (fileNumber == 0) {
                        this.fileCount = this.ListOfAudioFiles.length;
                    }
                    this.fileCount = this.fileCount + 1;
                    fileNumber = fileNumber + 1;
                    if (this.fileCount > this.maxNumberOfFiles && this.IsMergeAudios) {
                        this.exceedFileLimit = true;
                    }
                    else {
                        this.isDuplicate = false;
                        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
                        fileEntry.file((file: File) => {
                            this.file = file;
                            this.originalFile = file;
                            this.FileType = file.name.split(/[.;+_]/).pop().toLowerCase();
                            if (this.originalFile.size > fileMinSize) {
                                if (this.FileType === "mp3" || this.FileType === "wav" || this.FileType === "mp2" || this.FileType === "m4a" || this.FileType === "m4v" || this.FileType === "dss" || this.FileType === "ds2" || this.FileType === "aac" || this.FileType === "wma" || this.FileType === "oga" || this.FileType === "trm" || this.FileType === "mp4" || this.FileType === "m4v" || this.FileType === "opus" || this.FileType === "avi" || this.FileType === "ogg" || this.FileType === "mkv" || this.FileType === "wmv" || this.FileType === "vob" || this.FileType === "mov" || this.FileType === "mts" || this.FileType === "zip" || this.FileType === "mod") {
                                    //////For Zip format
                                    if (this.FileType === "zip") {
                                        this.canUpload = true;
                                        this.ListOfOriginalFiles = [];
                                        this.ListOfZipFiles = [];
                                        this.ListZipFailedFiles = "";
                                        console.log("zip file........");
                                        var new_zip = new JSZip();
                                        new_zip.loadAsync(this.originalFile)
                                        .then((zip) => {
                                                var count = 0;
                                                zip.forEach(fileContent => {
                                                    count = count + 1;
                                                    if (fileContent.includes("/")) {
                                                        if (count > 1) {
                                                            zip.file(fileContent).async("blob").then(async function (filedata: any) {
                                                                var fName = fileContent.substring(fileContent.lastIndexOf("/") + 1);
                                                                var fileExtension = fName.split(/[.;+_]/).pop().toLowerCase();
                                                                console.log("this.AudioFileFormats1::::", AudioFileFormats);

                                                                //check Audio file extension 
                                                                var ValidAudioExtension = AudioFileFormats.filter(x => x == fileExtension);
                                                                fName = fName.split('.').slice(0, -1).join('.')
                                                                fName = fName.replace(/[^a-zA-Z0-9]/g, ' ');
                                                                fName = fName.replace(/\s\s+/g, ' ');
                                                                fName = fName.trim();
                                                                fName = fName.replace(/[^a-zA-Z0-9]/g, '_');
                                                                fName = fName + "." + fileExtension;

                                                                filedata["name"] = fName;
                                                                filedata["FileName"] = fName;
                                                                if (ValidAudioExtension.length > 0) {
                                                                    console.log("Process audio files...Foldered Zip");
                                                                    if (filedata.size > fileMinSize) {
                                                                        zipFile = filedata;
                                                                        this.ListzipFiles.push(zipFile);
                                                                    }
                                                                    else {
                                                                        if (this.ListZipFailedFiles == "") {
                                                                            this.ListZipFailedFiles = filedata.name;
                                                                        }
                                                                        else {
                                                                            this.ListZipFailedFiles = this.ListZipFailedFiles + "," + filedata.name;
                                                                        }
                                                                    }

                                                                }
                                                                //check document file formats
                                                                var ValidDocumentExtension = DocumentFileFormats.filter(x => x == fileExtension);
                                                                if (ValidDocumentExtension.length > 0) {
                                                                    filedata["fileName"] = fName;
                                                                    filedata["filename"] = fName;
                                                                    this.Audiofile = new FileToUpload();
                                                                    this.Audiofile.fileName = fName;
                                                                    this.Audiofile.fileSize = 10;
                                                                    this.Audiofile.fileType = "application/" + fileExtension;
                                                                    this.Audiofile.lastModifiedTime = Date.now();
                                                                    var dateevent = new Date();
                                                                    this.Audiofile.lastModifiedDate = dateevent;
                                                                    ListOfZippedDocuments.push(this.Audiofile);
                                                                    this.ListOfAttachmentFiles.push(filedata);
                                                                }
                                                            });
                                                        }
                                                    }
                                                    else {
                                                        zip.file(fileContent).async("blob").then(async function (filedata) {
                                                            var fName = fileContent;
                                                            var fileExtension = fName.split(/[.;+_]/).pop().toLowerCase();
                                                            console.log("this.AudioFileFormats2::::", AudioFileFormats);

                                                            //check Audio file extension 
                                                            var ValidAudioExtension = AudioFileFormats.filter(x => x == fileExtension);
                                                            fName = fName.split('.').slice(0, -1).join('.')
                                                            fName = fName.replace(/[^a-zA-Z0-9]/g, ' ');
                                                            fName = fName.replace(/\s\s+/g, ' ');
                                                            fName = fName.trim();
                                                            fName = fName.replace(/[^a-zA-Z0-9]/g, '_');
                                                            fName = fName + "." + fileExtension;
                                                            filedata["name"] = fName;
                                                            filedata["FileName"] = fName;
                                                            console.log("separate files ...", filedata);
                                                            if (ValidAudioExtension.length > 0) {
                                                                console.log("Process audio files...Seperate zipfiles");
                                                                if (filedata.size > fileMinSize) {
                                                                    zipFile = filedata;
                                                                    ListzipFiles.push(zipFile);
                                                                    console.log("ListzipFiles.push", ListzipFiles);
                                                                }
                                                                else {
                                                                    if (this.ListZipFailedFiles == "") {
                                                                    }
                                                                    else {
                                                                    }
                                                                }
                                                            }
                                                            //check document file formats
                                                            var ValidDocumentExtension = DocumentFileFormats.filter(x => x == fileExtension);
                                                            if (ValidDocumentExtension.length > 0) {
                                                                filedata["fileName"] = fName;
                                                                filedata["filename"] = fName;
                                                                this.Audiofile = new FileToUpload();
                                                                this.Audiofile.fileName = fName;
                                                                this.Audiofile.fileSize = 10;
                                                                this.Audiofile.fileType = "application/" + fileExtension;
                                                                this.Audiofile.lastModifiedTime = Date.now();
                                                                var dateevent = new Date();
                                                                this.Audiofile.lastModifiedDate = dateevent;
                                                                ListOfZippedDocuments.push(this.Audiofile);
                                                                this.ListOfAttachmentFiles.push(filedata);
                                                            }
                                                        });
                                                    }
                                                });
                                            });
                                        setTimeout(() => {
                                            if (this.ListZipFailedFiles !== "") {
                                                this.sendNotification(this.ListZipFailedFiles + ' file(s) are less than 0 kb size.  Please upload files with mininum 5kb size.', 'error');
                                            }
                                        }, 1500);
                                        this.ListOfZipFiles.push(this.originalFile);
                                        this.canUpload = true;
                                        this.ListOfAudioFiles = ListzipFiles;
                                        this.ListOfAttachments = ListOfZippedDocuments;
                                        this.spinner.hide();
                                    }
                                    ///////Normal audio files
                                    else {
                                        if (this.IsMergeAudios) {
                                            if (this.MergeAudioFormat == null || this.MergeAudioFormat == "" || this.MergeAudioFormat == "undefined") {
                                                this.MergeAudioFormat = this.FileType;
                                            }
                                            if (this.MergeAudioFormat == this.FileType || (this.MergeAudioFormat == null || this.MergeAudioFormat == "" || this.MergeAudioFormat == "undefined")) {
                                                //console.log("merge Audios");
                                                if (!this.exceedFileLimit) {
                                                    this.processAudioFiles(file);
                                                    //console.log("this.isDuplicate", this.isDuplicate);
                                                }
                                            }
                                            else {
                                                this.sendNotification('Please select the ' + this.MergeAudioFormat + ' format files to Merge.', 'error');
                                            }
                                        }
                                        else {
                                            this.ListOfDuplicateFiles = [];
                                            this.DuplicateFileNames = null;

                                            this.backendService.isfileexist(file.name).subscribe(
                                                res => {
                                                    const fileResult = this.backendService.getJSONResponse(res);
                                                    if (!fileResult.data.fileNameExist) {
                                                        this.isDuplicate = false;
                                                        this.processAudioFiles(file);
                                                    }
                                                    else {
                                                        this.ListOfDuplicateFiles.push(file);
                                                        if (this.DuplicateFileNames == null) {
                                                            this.DuplicateFileNames = file.name;
                                                        }
                                                        else {
                                                            this.DuplicateFileNames = this.DuplicateFileNames + ", " + file.name;
                                                        }
                                                        console.log("DuplicateFileNames", this.DuplicateFileNames);
                                                    }
                                                    if (fileNumber == files.length) {
                                                        if (this.ListOfDuplicateFiles.length > 0) {
                                                            this.mode = "CheckFileName";
                                                            this.sweetalert();
                                                            this.spinner.hide();
                                                        }
                                                        else {
                                                            this.spinner.hide();
                                                        }
                                                    }
                                                },
                                                err => {
                                                    const er = this.backendService.getJSONResponse(err);
                                                    this.sendNotification('Problem occurred while checking audiofile exist. ' + JSON.stringify(er), 'error');
                                                    this.backendService.AWSLog("error", "Problem occurred while checking audiofile exist.: " + err).subscribe();
                                                    this.spinner.hide();
                                                }
                                            );
                                        }
                                    }
                                }
                                else {
                                    this.sendNotification('Selected file is ' + this.FileType + '. Please upload files with mp2, mp3, wav, m4a, dss, ds2, aac, wma, oga, trm, mts,mod and zip formats.', 'error');
                                    this.spinner.hide();
                                }
                                if (this.IsMergeAudios) {
                                    if (this.FileType != "zip") {
                                        this.ListOfAudioFiles = [];
                                        this.ListOfAudioFiles = this.ListOfMergeAudioFiles;
                                    }
                                    this.spinner.hide();
                                }
                            }
                            else {
                                this.sendNotification('Please upload files with mininum 5kb size.', 'error');
                            }
                        })
                    }
                }
            }
            else if (this.ref == "attachDocs") {
                // this.spinner.show();
                const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
                fileEntry.file((file: File) => {
                    //check for file size for attachments. Handle the size
                    var fileSize = file.size;
                    var maxSize = 314572800;
                    if(fileSize > maxSize)
                    {
                        this.sendNotification("The maximum attachment size is 300 MB. Please upload files smaller than 300 MB. ", 'error');
                        this.spinner.hide();
                        return;
                    }
                    var filenamewithoutextension = file.name.substr(file.name.lastIndexOf('\\') + 1);
                    filenamewithoutextension = filenamewithoutextension.substr(0, filenamewithoutextension.lastIndexOf('.'));
                    let noWhiteSpace = filenamewithoutextension.replace(/ /g, "_");
                    var desiredFilename = noWhiteSpace.replace(/[^\w\s]/gi, '')
                    let Fileextension = file.name.split(/[.;+_]/).pop().toLowerCase();
                    var desiredFileNameWithExtension = desiredFilename + "." + Fileextension;
                    const isDuplicate = this.ListOfAttachments.some(filedetails => filedetails.fileName === desiredFileNameWithExtension);
                    if (!isDuplicate) {
                        file["filename"] = desiredFileNameWithExtension;
                        
                        
                        this.ListOfAttachmentFiles.push(file);
                        this.readAndUploadAttachmentFile(file);
                    } else {
                        this.sendNotification(`${file.name} is already exist.`, "error");
                        this.spinner.hide();
                    }
                })
            }

        }
        if (this.IsMergeAudios == true && this.FileType == "zip") {
            this.spinner.show();
            console.log("ismerge", this.IsMergeAudios);
            this.mode = "CheckOTForZip";
            this.sweetalert();
        };
    }

    processAudioFiles(file) {
        this.ListOfZipFiles = [];
        ///Duplicate filename  verification
        this.ListOfOriginalFiles.forEach(filedetails => {
            if (filedetails.name == file.name) {
                this.isDuplicate = true;
            }
        })
        ///
        if (this.isDuplicate == true) {
            this.sendNotification(file.name + " is already exist.", "error");
            this.fileCount = this.fileCount - 1;
        }
        else {
            if (this.ListOfNames == "") {
                this.ListOfNames = file.name;
            }
            else {
                this.ListOfNames = this.ListOfNames + ", " + file.name;
            }
            this.ListOfOriginalFiles.push(file);
            if (this.IsMergeAudios == true) {
                this.readAndUploadAudioFile(file);
            }
            this.canUpload = true;
        }
        this.ListOfAudioFiles = this.ListOfOriginalFiles;
        // Limit the uploaded files to 100
        if (this.ListOfAudioFiles.length > this.maxNumberOfFiles && this.IsMergeAudios) {
            this.exceedFileLimit = true;
            console.log("this.exceedFileLimit", this.exceedFileLimit);
        }
        return this.isDuplicate;
    }

    sweetalertForEstimateWarnings() {

        var reqsubstring = "";
        if (this.mode == "jobCostMinuteRateEstimationNull") {
            reqsubstring = "The selected job type does not have an estimated cost. So, please config cost estimation rate for this Job type in NetScribe.";
        }
        if(this.mode == "selectedLanguageNotEmpty"){
            reqsubstring = " We are unable to provide an automated estimate at this time for that language - please contact sales@viqsolutions.com.";
        }
        if(this.mode == "flLanguageServiceValue1"){
            reqsubstring = "This service is included in the normal rate of your transcription request for that turnaround time.";
        }
        if(this.mode == "totalEstimateAmountLessthen150"){
            reqsubstring = "The estimation cost is less than 150$.";
        }
        if(this.mode == "selectedLanguage"){
            reqsubstring = "We are unable to provide an automated estimate at this time for that language - please contact sales@viqsolutions.com.";
        }
        Swal.fire({
            title: 'Warning',
            text: reqsubstring,
            showDenyButton: false,
            showCancelButton: false,
            confirmButtonText: `Ok`,
            cancelButtonText: `No`,
            denyButtonText: `Cancel`,
            confirmButtonColor: '#187EB1',
            cancelButtonColor: '#5b5b5b',
        }).then((result) => {
            //console.log("result", result);
        })
    }
    sweetalert() {

        var reqsubstring = "";
        if (this.mode == "CheckFileName") {
            reqsubstring = "It appears that you have already uploaded this audio file " + this.DuplicateFileNames + " for a different job.  Are you sure you want to upload this file?";
        }
        if (this.mode == "CheckOTForZip") {
            reqsubstring = "When uploading a .zip file, each file will result in its own transcription. They cannot be merged into One Transcript. Do you want to continue with Separate Transcripts?"
        }
        if (this.mode == "StopRecording") {
            reqsubstring = "Are you sure, you want to stop and submit the recording?";
        }
        if (this.mode == "ChangeTranscriptionType") {
            reqsubstring = "Are you sure, you want to change the transcription type? Please note that all the selected/recorded Audio/Video files will be deleted after the change. ";
        }
        Swal.fire({
            title: 'Confirmation',
            text: reqsubstring,
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: `Yes`,
            cancelButtonText: `No`,
            denyButtonText: `Cancel`,
            confirmButtonColor: '#187EB1',
            cancelButtonColor: '#5b5b5b',
        }).then((result) => {
           // console.log("result", result.isDismissed);
            if (result.value) {
                if (this.mode == "CheckFileName") {
                    this.ListOfDuplicateFiles.forEach(element => {
                        this.processAudioFiles(element);
                    });
                }
                if (this.mode == "CheckOTForZip") {
                    console.log("CheckOTForZip", result.value);
                    this.IsMergeAudios = false;
                    this.IsMergeAudiosGlobal = false;
                    console.log("ListOfAudioFiles", this.ListOfAudioFiles);
                    console.log("ListzipFiles", ListzipFiles);
                    this.spinner.hide();
                }
                if (this.mode == "StopRecording") {
                    this.startRecordShow = true;
                    this.pauseButtonShow = false;
                    this.stopButtonShow = false;
                    this.resumeRecordShow = false;
                    clearInterval(this.interval);
                    this.hr = '0' + 0;
                    this.min = '0' + 0;
                    this.sec = '0' + 0;
                    this.PauseRecordingTimer = false;
                    this.RecordingTimer = false;
                    this.StopRecordingprocess();
                }
                if (this.mode == "ChangeTranscriptionType") {
                    this.TranscriptionTypeChange();
                }
            }
            if (result.isDismissed) {
                if (this.mode == "CheckOTForZip") {
                    console.log("CheckOTForZip isDismissed", result.isDismissed);
                    this.remove();
                    this.spinner.hide();
                }
                if (this.mode == "StopRecording") {
                    this.pause();
                    this.resumeRecordShow = true;
                    this.pauseButtonShow = false;
                }

                //If the transcription type change is cancelled then we should reverse the change.
                if (this.mode == "ChangeTranscriptionType") {
                    if (this.transcriptionType == 1) {
                        //one Transcript
                        this.IsMergeAudios = true;
                        this.oneTrans.focus();
                        this.oneTrans.checked = true;
                    }
                    else if (this.transcriptionType == 2) {
                        //Individual Transcripts
                        this.IsMergeAudios = false;
                        this.sepTrans.focus();
                        this.sepTrans.checked = true;
                    }
                    this.transcriptionType = undefined;
                }
            }
        })
    }
    pause() {
        this.audioRecorderService.pause();
        this.resumeRecordShow = true;
        clearInterval(this.interval);
        this.RecordingTimer = false;
        this.PauseRecordingTimer = true;
    }
    resume() {
        this.audioRecorderService.resume();
        this.pauseButtonShow = true;
        this.resumeRecordShow = false;
        this.RecordingTime()
        this.PauseRecordingTimer = false;
        this.RecordingTimer = true;
    }
    stopRecording() {
        this.pause();
        this.pauseButtonShow = true;
        this.resumeRecordShow = false;
        this.mode = "StopRecording";
        this.sweetalert();
        this.spinner.hide();
    }
    StopRecordingprocess() {

        this.audioRecorderService.stopRecording(1).then((output) => {
            // do post output steps
            console.log("stopRecording::::");
            this.GlobalOutput = output;
            var dateevent = new Date();
            var currentDateTime = this.datePipe.transform(dateevent, 'yyyyMMddHHmmss')
            this.GlobalOutput['name'] = "RecordedFile-" + currentDateTime + ".mp3";
            console.log("output::::", this.GlobalOutput);
            this.processAudioFiles(this.GlobalOutput);
            this.originalFile = this.GlobalOutput;
            this.canUpload = true;
        }).catch(errrorCase => {
            // Handle Error
        });
    }
    startRecording() {
        this.audioRecorderService.startRecording();
        this.RecordingTime();
        this.RecordingTimer = true;
        this.PauseRecordingTimer = false;
    }
    OnclickStartButton() {
        this.pauseButtonShow = !this.pauseButtonShow;
        this.stopButtonShow = !this.stopButtonShow;
        this.startRecordShow = false;
    }
    OnclickPauseButton() {
        this.resumeRecordShow = !this.resumeRecordShow;
        this.pauseButtonShow = false;
        this.resumeRecordShow = true;
    }
    RecordingTime() {
        this.interval = setInterval(() => {
            this.sec++
            this.sec = this.sec < 10 ? '0' + this.sec : this.sec;
            if (this.sec === 60) {
                this.min++
                this.min = this.min < 10 ? '0' + this.min : this.min;
                this.sec = '0' + 0;
            }
            if (this.min === 60) {
                this.hr++
                this.hr = this.hr < 10 ? '0' + this.hr : this.hr;
                this.min = '0' + 0;
            }
        }, 1000)
    }

    sendNotification(message, messageType) {
        this.msgNotification.elementRef.nativeElement.querySelector('.jqx-notification-content').innerHTML = message;
        this.msgNotification.template(messageType);
        this.msgNotification.open();
    }

    getJSONResponse(value: any) {
        return JSON.parse(JSON.stringify(value));
    }

    removeJobAudioFiles() {
        this.ListOfAudioFiles = [];
    }

    clear() {
        this.removeJobAudioFiles();
    }

    dialogClose() {
        if (this.ref === "attachDocs") {
            this.attachmentsDialogData.ListOfAttachments = this.originalDataCopy.ListOfAttachments;
            this.attachmentsDialogData.ListOfAttachmentFiles = this.originalDataCopy.ListOfAttachmentFiles;
            this.dialogRef.close(this.attachmentsDialogData);
        }
        else if (this.ref === "media" || this.ref === "record") {

            this.mediaDialogData.ListOfAudioFiles = this.originalDataCopy.ListOfAudioFiles;
            this.mediaDialogData.ListOfOriginalFiles = this.originalDataCopy.ListOfOriginalFiles;
            this.mediaDialogData.canUpload = this.originalDataCopy.canUpload;
            this.mediaDialogData.originalFile = this.originalDataCopy.originalFile;
            this.mediaDialogData.FileType = this.originalDataCopy.FileType;
            this.mediaDialogData.ListzipFiles = this.originalDataCopy.ListzipFiles;
            this.mediaDialogData.ListOfAudioFiles1 = this.originalDataCopy.ListOfAudioFiles1;
            this.mediaDialogData.IsMergeAudios = this.originalDataCopy.IsMergeAudios;
            this.mediaDialogData.ListOfMergeAudioFiles = this.originalDataCopy.ListOfMergeAudioFiles;
            this.mediaDialogData.MergeAudioFileNameList = this.originalDataCopy.MergeAudioFileNameList;
            this.dialogRef.close(this.mediaDialogData);
        }
        else if (this.ref === "estimate") {
            this.dialogRef.close(this.estimateDialogData);
        }
        else if (this.ref === "addNotes") {
            this.dialogRef.close(this.notesDialogData);
        }
        else {
            this.dialogRef.close("clear");
        }
    }

    isSaveEnabled(): boolean {
        if (this.ref === "addNotes") {
            return true;
        }
        else if (this.ref === "attachDocs") {
            return true;
        }
        else if (this.ref === "media" || this.ref === "record") {
            return this.canUpload;
        }
        return false;
    }

    SaveDetails() {
        if (this.ref === "addNotes") {
            this.notesDialogData.Notes = this.notesComponent.val();
            if(this.showCustomerNotes){
                this.notesDialogData.custNotes = this.customerNotesComponent.val();
            }
            else{
                this.notesDialogData.custNotes=null;
            }
            console.log("NotesdialogData", this.notesDialogData);
            this.dialogRef.close(this.notesDialogData);
        }
        else if (this.ref === "attachDocs") {
            this.attachmentsDialogData.ListOfAttachments = this.ListOfAttachments;
            this.attachmentsDialogData.ListOfAttachmentFiles = this.ListOfAttachmentFiles;
            console.log("AttachmentsDialogData", this.attachmentsDialogData);
            this.dialogRef.close(this.attachmentsDialogData);
        }
        else if (this.ref === "media" || this.ref === "record") {
            this.mediaDialogData.ListOfAudioFiles = this.ListOfAudioFiles;
            this.mediaDialogData.ListOfOriginalFiles = this.ListOfOriginalFiles;
            this.mediaDialogData.canUpload = this.canUpload;
            this.mediaDialogData.originalFile = this.originalFile;
            this.mediaDialogData.FileType = (this.ref === "media") ? this.FileType : "mp3";
            this.mediaDialogData.ListzipFiles = ListzipFiles;
            this.mediaDialogData.ListOfAudioFiles1 = this.ListOfAudioFiles1;
            this.mediaDialogData.IsMergeAudios = this.IsMergeAudios;
            this.mediaDialogData.ListOfMergeAudioFiles = this.ListOfMergeAudioFiles;
            this.mediaDialogData.MergeAudioFileNameList = this.MergeAudioFileNameList;
            console.log("MediadialogData", this.mediaDialogData);
            this.dialogRef.close(this.mediaDialogData);
        }
    }

    TranscriptionTypeClick(value) {
        this.transcriptionType = value;
        if(( this.transcriptionType==1 && this.sepTrans.checked==false)||(this.transcriptionType==2 && this.oneTrans.checked==false)||(this.transcriptionType==3)){
            this.mode = "ChangeTranscriptionType";
            this.sweetalert();
        }        
    }

    TranscriptionTypeChange() {
        this.remove()
        if (this.transcriptionType == 1) {
            //Individual Transcripts
            this.IsMergeAudios = false;
            this.IsMergeAudiosGlobal = false;
            this.canUpload = this.isfutureorder ? true : false;
        }
        else if (this.transcriptionType == 2) {
            //one Transcript
            this.IsMergeAudios = true;
            this.IsMergeAudiosGlobal = true;
        }
        this.transcriptionType = undefined;
    }
    remove() {
        this.file = null;
        this.originalFile = null;
        this.canUpload = false;
        ///Multiple Audio files
        this.ListOfNames = "";
        this.ListOfOriginalFiles = [];
        ListzipFiles = [];
        this.ListOfAudioFiles = [];
        this.ListOfZipFiles = [];
        this.ListOfMergeAudioFiles = [];
        this.MergeAudioFileNameList = [];
        this.MergeAudioFilescount = 0;
        this.CompleteAudioFilescount = 0;
        this.ListOfAttachments = [];
        this.ListOfAudioFiles1 = [];
        this.MergeAudioFormat = "";
        this.ListofJobCreateDto = [];
        this.ListofJobCreateInputDto = [];
        this.ListOfAttachmentFiles = [];
        this.ListOfMergeAudioFiles = [];
        this.ListOfAttachments1Global = [];
    }
    private readAndUploadAudioFile(theFile: any) {
        // Removing special chars from filename
        var filenamewithoutextension = theFile.name.substr(theFile.name.lastIndexOf('\\') + 1);
        filenamewithoutextension = filenamewithoutextension.substr(0, filenamewithoutextension.lastIndexOf('.'));
        let noWhiteSpace = filenamewithoutextension.replace(/ /g, "_");
        var desiredFilename = noWhiteSpace.replace(/[^\w\s]/gi, '')
        let Fileextension = theFile.name.split(/[.;+_]/).pop().toLowerCase();
        var desiredFileNameWithExtension = desiredFilename + "." + Fileextension;
        var AudioFilecounter = this.ListOfOriginalFiles.length;
        this.Audiofile = new FileToUpload();
        if(this.mediaDialogData.isUploadProgressively)
        {
            this.Audiofile.fileName =  desiredFileNameWithExtension;
            theFile.FileName = desiredFileNameWithExtension;
        }
        else{
            this.Audiofile.fileName = AudioFilecounter + "-" + desiredFileNameWithExtension;
            theFile.FileName = AudioFilecounter + "-" + desiredFileNameWithExtension;
        }
        this.Audiofile.fileSize = theFile.size;
        this.Audiofile.fileType = theFile.type;
        if (theFile.type == "audio/webm") {
            this.Audiofile.fileType = "audio/mp3"
        }
        this.Audiofile.lastModifiedTime = theFile.lastModified;
        this.Audiofile.lastModifiedDate = theFile.lastModifiedDate;

        this.ListOfAudioFiles1.push(this.Audiofile);
        this.ListOfMergeAudioFiles.push(theFile);
        this.MergeAudioFileNameList.push(theFile.FileName);
        this.spinner.hide();
    }
    private readAndUploadAttachmentFile(theFile: any) {
        // Removing special chars from filename
        var filenamewithoutextension = theFile.name.substr(theFile.name.lastIndexOf('\\') + 1);
        filenamewithoutextension = filenamewithoutextension.substr(0, filenamewithoutextension.lastIndexOf('.'));
        let noWhiteSpace = filenamewithoutextension.replace(/ /g, "_");
        var desiredFilename = noWhiteSpace.replace(/[^\w\s]/gi, '')
        let Fileextension = theFile.name.split(/[.;+_]/).pop().toLowerCase();
        var desiredFileNameWithExtension = desiredFilename + "." + Fileextension;
        this.Attachmentfile = new FileToUpload();
        this.Attachmentfile.fileName = desiredFileNameWithExtension;
        this.Attachmentfile.fileSize = theFile.size;
        this.Attachmentfile.fileType = theFile.type;
        this.Attachmentfile.lastModifiedTime = theFile.lastModified;
        this.Attachmentfile.lastModifiedDate = theFile.lastModifiedDate;
        this.ListOfAttachments.push(this.Attachmentfile);
        console.log("readAndUploadAttachmentFile ListOfAttachments", this.ListOfAttachments);
        console.log("readAndUploadAttachmentFile ListOfAttachmentFiles", this.ListOfAttachmentFiles);
        this.spinner.hide();
    }
}


