import { DatePipe } from '@angular/common';
import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Console } from 'console';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import { jqxNotificationComponent } from 'jqwidgets-ng/jqxnotification';
import { NgxSpinnerService } from 'ngx-spinner';
import { asyncScheduler } from 'rxjs';
import { BackendService } from 'src/app/services/backend.service';
import { ExcelService } from 'src/app/services/excel.service';
 
declare var tippy: any;
declare const $: any;
let PageNumberGlobal: any;
let customStartDateContainer;
let customEndDateContainer;
let customsearchButtonContainer;
let datepickerButton;
let customenddate;
let customstartdate;
let customenddateTracking;
let customstartdateTracking;

@Component({
  selector: 'app-net-scribe-uploader',
  templateUrl: './net-scribe-uploader.component.html',
  styleUrls: ['./net-scribe-uploader.component.css']
})

export class NetScribeUploaderComponent implements OnInit {
  customSearch = false;
  selectedStartDate: any;
  selectedEndDate: any;
  startmin: any;
  startmax: any;
  endmin: any;
  endmax: any;
  NumberOfSessions = 0;
  MinEndDate: any;
  MaxStartDate: any;
  endDateEvent = false;
  endDateEventTracking = false;
  selectedDateOption: any;
  selectedDateOptionTacking: any;
  selectedStatusOption: string;
  selectedStatusOptionTracking: string;
  UnixStartDate: any;
  UnixEndDate: any;
  UnixStartDateTracking: any;
  UnixEndDateTracking: any;
  selectedDDLindex: any;
  selectedDDLindexTracking: any;
  totalCountGlobal: any;
  IsSqlDB = false;
  buttonCounter: any;
  sqlSessionData: any;
  isRefresh = false;
  columnValues: any;
  EventType: string;
  EventTypeTracking: string;
  element = $("<div id = 'gridpager' style='margin-left: 10px; margin-top: 11px; width: 100%; height: 100%;'></div>");
  heightOffset: number = 150;
  DisplayCustomStartDate:any;
  DisplayCustomEnddate:any;

  tabIndex: number = 0;
  DisplayCustomStartDateTracking: Date;
  DisplayCustomEnddateTracking: Date;
  customSearchTracking: boolean;
  startmaxTracking: Date;
  endminTracking: Date;
  endmaxTracking: Date;
  selectedStartDateTracking: any;
  selectedEndDateTracking: any;
  MinEndDateTracking: any;
  MaxStartDateTracking: any;
  startminTracking: Date;
  constructor(
    private backendService: BackendService,
    private datePipe: DatePipe,
    private datePipeTracking: DatePipe,
    private spinner: NgxSpinnerService,
    public dialog: MatDialog,
    private excelService: ExcelService
  ) { }
  @ViewChild('myFileUploadSessionsGrid', { static: false }) myFileUploadSessionsGrid: jqxGridComponent;
  @ViewChild('myFileUploadTrackingGrid', { static: false }) myFileUploadTrackingGrid: jqxGridComponent;
  @ViewChild('msgNotification', { static: false }) msgNotification: jqxNotificationComponent;
  startDateEventOpen(event: any): void {
    this.endDateEvent = false;
  }
  startDateEventOpenTracking(event: any): void {
    this.endDateEventTracking = false;
  }
  onStartDateValueChangedSession(event: any): void {
    if (this.endDateEvent == false) {
      var selecteddate = event.args.date;
      var dateevent = new Date();
      this.MinEndDate = selecteddate.setDate(selecteddate.getDate());
      this.MinEndDate = new Date(this.MinEndDate);
      this.endmin = new Date(this.MinEndDate);
      this.selectedStartDate = this.datePipe.transform(selecteddate, 'yyyy-MM-ddT00:00')
      this.endmin = new Date(this.selectedStartDate);
      customenddate.min = this.endmin;
      var todayDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
      this.DisplayCustomStartDate = new Date(this.selectedStartDate);
    }
  }

  onEndDateValueChangedSession(event: any): void {
    this.endDateEvent = true;
    var selecteddate = event.args.date;
    var dateevent = new Date();
    this.MaxStartDate = selecteddate.setDate(selecteddate.getDate());
    this.MaxStartDate = new Date(this.MaxStartDate);
    this.startmax = new Date(this.MaxStartDate);
    customstartdate.max = this.startmax;
    this.selectedEndDate = this.datePipe.transform(selecteddate, 'yyyy-MM-ddT00:00')
    this.DisplayCustomEnddate = new Date(this.selectedEndDate);
  }
  onStartDateValueChangedTracking(event: any): void {
    if (this.endDateEventTracking == false) {
      var selecteddate = event.args.date;
      var dateevent = new Date();
      this.MinEndDateTracking = selecteddate.setDate(selecteddate.getDate());
      this.MinEndDateTracking = new Date(this.MinEndDateTracking);
      this.endminTracking = new Date(this.MinEndDateTracking);
      this.selectedStartDateTracking = this.datePipeTracking.transform(selecteddate, 'yyyy-MM-ddT00:00')
      this.endminTracking = new Date(this.selectedStartDateTracking);
      customenddateTracking.min = this.endminTracking;
      var todayDate = this.datePipeTracking.transform(new Date(), 'yyyy-MM-dd');
      this.DisplayCustomStartDateTracking = new Date(this.selectedStartDateTracking);
    }
  }

  onEndDateValueChangedTracking(event: any): void {
    this.endDateEventTracking = true;
    var selecteddate = event.args.date;
    var dateevent = new Date();
    this.MaxStartDateTracking = selecteddate.setDate(selecteddate.getDate());
    this.MaxStartDateTracking = new Date(this.MaxStartDateTracking);
    this.startmaxTracking = new Date(this.MaxStartDateTracking);
    customstartdateTracking.max = this.startmaxTracking;
    this.selectedEndDateTracking = this.datePipeTracking.transform(selecteddate, 'yyyy-MM-ddT00:00')
    this.DisplayCustomEnddateTracking = new Date(this.selectedEndDateTracking);
  }
  tabs = [
    { title: 'FileUploadSessions' },
    { title: 'FileUploadTracking' }
  ];
    ShowFileUploadSessionsColumns: any[];
    AllFileUploadSessionsColumns: any[] =
    [
      { label: 'id', value: 'id' },
      { label: 'sessionId', value: 'sessionId' },
      { label: 'uploadingUserName', value: 'uploadingUserName' },
      { label: 'sessionCreationTime', value: 'sessionCreationTime' },
      { label: 'sessionEndTime', value: 'sessionEndTime' },
      { label: 'fileSessionStatus', value: 'fileSessionStatus' },
      { label: 'folderNameOnSystem', value: 'folderNameOnSystem' },
      { label: 'fileCountOnDevice', value: 'fileCountOnDevice' },
      { label: 'fileCountOnSystem', value: 'fileCountOnSystem'},
      { label: 'fileCountOnServer', value: 'fileCountOnServer' },
      { label: 'uploadSystemIP', value: 'uploadSystemIP' },
      { label: 'createdDate', value: 'createdDate' },
      { label: 'modifiedDate', value: 'modifiedDate'}
    ];
    ShowFileUploadTrackingColumns: any[];
    AllFileUploadTrackingColumns: any[] =
    [
      { label: 'Id', value: 'id' },
      { label: 'TrackingId', value: 'trackingId' },
      { label: 'FileUploadSessionId', value: 'fileUploadSessionId' },
      { label: 'FilenameOnRecorder', value: 'filenameOnRecorder' },
      { label: 'TransferStartTime', value: 'transferStartTime' },
      { label: 'TransferEndTime', value: 'transferEndTime' },
      { label: 'UploadStartTime', value: 'uploadStartTime' },
      { label: 'UploadEndTime', value: 'uploadEndTime' },
      { label: 'FileTrackingStatus', value: 'fileTrackingStatus'},
      { label: 'DictationDateTime', value: 'dictationDateTime' },
      { label: 'UploadSystemIP', value: 'uploadSystemIP' },
      { label: 'JobTypeExtearnalId', value: 'jobTypeExtearnalId' },
      { label: 'AudioDuration', value: 'audioDuration'},
      { label: 'AuthorExternalId', value: 'authorExternalId' },
      { label: 'CreatedDate', value: 'createdDate'},
      { label: 'ModifiedDate', value: 'modifiedDate' }
    ];
  dateOptions: any[] =
    [
      { html: '<div>Today</div>', title: 'Today' },
      { html: '<div>Yesterday</div>', title: 'Yesterday' },
      { html: '<div>This week</div>', title: 'This week (Sunday to today)' },
      { html: '<div>Last 7 days</div>', title: 'Last 7 days (six days ago to today)' },
      { html: '<div>This month</div>', title: 'This month (1st of this month to today)' },
      { html: '<div>Last 31 days</div>', title: 'Last 31 days (30 days ago to today)' },
      { html: '<div>Custom</div>', title: 'Custom' },
    ];
    dateOptionsTracking: any[] =
    [
      { html: '<div>Today</div>', title: 'Today' },
      { html: '<div>Yesterday</div>', title: 'Yesterday' },
      { html: '<div>This week</div>', title: 'This week (Sunday to today)' },
      { html: '<div>Last 7 days</div>', title: 'Last 7 days (six days ago to today)' },
      { html: '<div>This month</div>', title: 'This month (1st of this month to today)' },
      { html: '<div>Last 31 days</div>', title: 'Last 31 days (30 days ago to today)' },
      { html: '<div>Custom</div>', title: 'Custom' },
    ];
  updatefilterconditions = (type: string, defaultconditions: any): string[] => {
    let stringcomparisonoperators = ['EMPTY', 'NOT_EMPTY', 'CONTAINS', 'DOES_NOT_CONTAIN', 'STARTS_WITH', 'ENDS_WITH', 'EQUAL'];
    let numericcomparisonoperators = ['LESS_THAN', 'GREATER_THAN'];
    let datecomparisonoperators = ['LESS_THAN', 'GREATER_THAN'];
    let booleancomparisonoperators = ['EQUAL', 'NOT_EQUAL'];
    switch (type) {
      case 'stringfilter':
        return stringcomparisonoperators;
      case 'numericfilter':
        return numericcomparisonoperators;
      case 'datefilter':
        return datecomparisonoperators;
      case 'booleanfilter':
        return booleancomparisonoperators;
    }
  };

  height;
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.height = event.target.innerHeight - this.heightOffset;
  }
  getHeight() {
    this.height = (window.screen.height - this.heightOffset);
    window.dispatchEvent(new Event('resize'));
  }
  getJSONResponse(value: any) {
    return JSON.parse(JSON.stringify(value));
  }
  convertStartDatetoUnix(date) {
    var reqstartDate = this.datePipe.transform(date, 'yyyy-MM-dd 00:00:00');
    var StringtoDate = new Date(reqstartDate);
    if(this.tabIndex == 0){

      this.UnixStartDate = (StringtoDate.getTime() / 1000);
      this.UnixStartDate = this.UnixStartDate - 1;
    } else {
      this.UnixStartDateTracking = (StringtoDate.getTime() / 1000);
      this.UnixStartDateTracking = this.UnixStartDateTracking - 1;
    }
  }
  convertEndDatetoUnix(date) {
    var reqEndDate = this.datePipe.transform(date, 'yyyy-MM-dd 23:59:59');
    var StringtoDate = new Date(reqEndDate);
    if(this.tabIndex == 0){

    this.UnixEndDate = (StringtoDate.getTime() / 1000);
    this.UnixEndDate = this.UnixEndDate + 1;
  } else {
    this.UnixEndDateTracking = (StringtoDate.getTime() / 1000);
    this.UnixEndDateTracking = this.UnixEndDateTracking + 1;
  }
  }

  getEventType(value) {

    this.EventType = value;
    this.getsqlData(this.selectedStartDate, this.selectedEndDate);
  }
  getEventTypeTracking(value) {

    this.EventTypeTracking = value;
    this.getsqlData(this.selectedStartDateTracking, this.selectedEndDateTracking);
  }
  searchData() {
    PageNumberGlobal = 1;
    this.getEventType(this.selectedStatusOption);
  }
  searchDataTracking() {
    PageNumberGlobal = 1;
    this.getEventTypeTracking(this.selectedStatusOptionTracking);
  }
  ngAfterViewInit(): void {

    setTimeout(() => {
      // TIPPY JS.
      tippy('[data-tippy-content]');
    }, 500);
    if (this.tabIndex == 0) {
      this.FileUploadSessionssource.showtoolbar = true;
      this.FileUploadTrackingsource.showtoolbar = false;
      this.createButtonsForSessions();
    } else {
    setTimeout(() => {  
      this.FileUploadTrackingsource.showtoolbar = true;
      this.FileUploadSessionssource.showtoolbar = false;
      this.createButtonsFortracking();
      }, 3000);
  }
  if(this.tabIndex == 0){
      let dateOpt = JSON.parse(JSON.stringify(this.dateOptions[0]));
      const tmp = document.createElement('DIV');
      tmp.innerHTML = dateOpt.html;
      this.selectedDateOption = tmp.textContent || tmp.innerText || '';
    } else {
      let dateOpt = JSON.parse(JSON.stringify(this.dateOptionsTracking[0]));
      const tmp = document.createElement('DIV');
      tmp.innerHTML = dateOpt.html;
      this.selectedDateOptionTacking = tmp.textContent || tmp.innerText || '';
    }
  }
  ngOnInit() {
    var iSuserloggedIN = localStorage.getItem('userloggedIN')
    if (iSuserloggedIN == "false") {
      this.backendService.setUserLoggedIn(false);
    }
    this.spinner.show();
    this.buttonCounter = 0;
    PageNumberGlobal = 1;
    var dateevent = new Date();
    var startdateevent = dateevent.setDate(dateevent.getDate());
    this.selectedStartDate = this.datePipe.transform(dateevent, 'yyyy-MM-dd');
    this.selectedEndDate = this.datePipe.transform(dateevent, 'yyyy-MM-dd');
    var todayDate = new Date();
    this.DisplayCustomStartDate = todayDate;
    this.DisplayCustomEnddate = todayDate;
    this.getHeight();
    this.IsSqlDB = true;
    setTimeout(() => {
      this.spinner.hide();
    }, 10000);
    asyncScheduler.schedule(async () => {
      try {
        await this.getsqlData(this.selectedStartDate, this.selectedEndDate);
      } catch (error) {
        console.error('An error occurred:', error);
      }
    }
    )
    this.IsSqlDB = false;
  }
  sendNotification(message, messageType) {
    this.msgNotification.elementRef.nativeElement.querySelector('.jqx-notification-content').innerHTML = message;
    this.msgNotification.template(messageType);
    this.msgNotification.open();
    this.spinner.hide()
  }
  loadFileUploadSessionsGridColumns() {
    this.columnValues = this.ShowFileUploadSessionsColumns;
    this.myFileUploadSessionsGrid.beginupdate();
    this.myFileUploadSessionsGrid.endupdate();
  }
  loadFileUploadTrackingGridColumns() {
    this.columnValues = this.ShowFileUploadTrackingColumns;
    this.myFileUploadTrackingGrid.beginupdate();
    this.myFileUploadTrackingGrid.endupdate();
  }
  onPageChanged(event: any): void {
    this.spinner.show();
  }
  onPageChangedForSession(event: any): void {
    this.spinner.show();
  }
  SessionGridFilter(event: any): void {
    this.spinner.show();
  }
  onPageChangedForTracking(event: any): void {
    this.spinner.show();
  }
  TrackingGridFilter(event: any): void {
    this.spinner.show();
  }
  getsqlData( startDate, endDate) {
    this.convertStartDatetoUnix(startDate);
    this.convertEndDatetoUnix(endDate);
    const userName = localStorage.getItem('userName');
    if(this.tabIndex == 0){
      this.FileUploadSessionssource.data={ userName, StartDate: this.UnixStartDate, EndDate: this.UnixEndDate, isSession: true};
      this.FileUploadSessionssource.url = this.backendService.GetAllNetScribeUploaderDetails();
    }
    else if (this.tabIndex == 1){
      this.FileUploadTrackingsource.data={ userName, StartDate: this.UnixStartDateTracking, EndDate: this.UnixEndDateTracking, isSession: false};
      this.FileUploadTrackingsource.url = this.backendService.GetAllNetScribeUploaderDetails();
    }
   this.tabIndex == 0 ?  this.myFileUploadSessionsGrid.updatebounddata('filter') : this.myFileUploadTrackingGrid.updatebounddata('filter');
    this.isRefresh = false;
  }

  FileUploadSessionssource: any = {
    datatype: 'json',
    datafields: [
      { name: 'id', type: 'number' },
      { name: 'sessionId', type : 'string'},
      { name: 'uploadingUserName',type: 'string'  },
      { name: 'sessionCreationTime',type : 'string'},
      { name: 'sessionEndTime', type : 'string'},
      { name: 'fileSessionStatus', type : 'string'},
      { name: 'folderNameOnSystem', type : 'string'},
      { name: 'fileCountOnDevice', type: 'number' },
      { name: 'fileCountOnSystem', type: 'number' },
      { name: 'fileCountOnServer', type: 'number'  },
      { name: 'uploadSystemIP', type : 'string' },
      { name: 'createdDate', type : 'string'},
      { name: 'modifiedDate',type : 'string'}
    ],
    userName: localStorage.getItem('userName'),
    sortcolumn: 'createdDate',
    sortdirection: 'desc',
    loader: false,
    enablebrowserselection: true,
    keyboardnavigation: false,
    beforeprocessing: (data: any) => {
       console.log("beforeprocessing", data);
      this.FileUploadSessionssource.totalrecords = data.totalRows;
      var results = data.rows;
       console.log("data:::results", results);
      for (const index in results) {
        const val = results[index];
        val.sessionCreationTime= new Date(this.datePipe.transform(val.sessionCreationTime, 'yyyy-MM-dd HH:mm:ss UTC')).toString();
        val.sessionEndTime= new Date(this.datePipe.transform(val.sessionEndTime, 'yyyy-MM-dd HH:mm:ss UTC')).toString();
        val.createdDate= new Date(this.datePipe.transform(val.createdDate, 'yyyy-MM-dd HH:mm:ss UTC')).toString();
        val.modifiedDate= new Date(this.datePipe.transform(val.modifiedDate, 'yyyy-MM-dd HH:mm:ss UTC')).toString();
        val.id = val.id
        val.sessionId = val.sessionId
        val.uploadingUserName = val.uploadingUserName
        val.fileSessionStatus = val.fileSessionStatus
        val.folderNameOnSystem = val.folderNameOnSystem
        val.fileCountOnDevice = val.fileCountOnDevice
        val.fileCountOnSystem = val.fileCountOnSystem
        val.fileCountOnServer=val.fileCountOnServer;
        val.uploadSystemIP=val.uploadSystemIP;
      }
      this.sqlSessionData = results;
      this.spinner.hide();

    },
    filter: () => {
      // update the grid and send a request to the server.
      this.myFileUploadSessionsGrid.updatebounddata('filter');
    }, sort: () => {
      // update the grid and send a request to the server.
      this.myFileUploadSessionsGrid.updatebounddata('sort');
    },
  };
  FileUploadTrackingsource: any = {
    datatype: 'json',
    datafields: [
      { name: 'id', type: 'number' },
      { name: 'trackingId', type : 'string'},
      { name: 'fileUploadSessionId',type: 'number' },
      { name: 'filenameOnRecorder',type: 'number' },
      { name: 'transferStartTime',type : 'string' },
      { name: 'transferEndTime',type : 'string' },
      { name: 'uploadStartTime',type : 'string' },
      { name: 'uploadEndTime',type : 'string' },
      { name: 'fileTrackingStatus',type : 'string' },
      { name: 'dictationDateTime', type : 'string'},
      { name: 'uploadSystemIP',type : 'string' },
      { name: 'jobTypeExtearnalId', type : 'string'},
      { name: 'audioDuration', type: 'number'},
      { name: 'authorExternalId',type : 'string' },
      { name: 'createdDate', type : 'string'},
      { name: 'modifiedDate',type : 'string' }
    ],
    userName: localStorage.getItem('userName'),
    sortcolumn: 'createdDate',
    sortdirection: 'desc',
    loader: false,
    enablebrowserselection: true,
    keyboardnavigation: false,
    beforeprocessing: (data: any) => {
       console.log("beforeprocessing", data);
      this.FileUploadTrackingsource.totalrecords = data.totalRows;
      var results = data.rows;
       console.log("data:::results", results);
      for (const index in results) {
        const val = results[index];
        val.transferStartTime= new Date(this.datePipe.transform(val.transferStartTime, 'yyyy-MM-dd HH:mm:ss UTC')).toString();
        val.transferEndTime= new Date(this.datePipe.transform(val.transferEndTime, 'yyyy-MM-dd HH:mm:ss UTC')).toString();
        val.uploadStartTime= new Date(this.datePipe.transform(val.uploadStartTime, 'yyyy-MM-dd HH:mm:ss UTC')).toString();
        val.uploadEndTime= new Date(this.datePipe.transform(val.uploadEndTime, 'yyyy-MM-dd HH:mm:ss UTC')).toString();
        val.dictationDateTime= new Date(this.datePipe.transform(val.dictationDateTime, 'yyyy-MM-dd HH:mm:ss UTC')).toString();
        val.createdDate= new Date(this.datePipe.transform(val.createdDate, 'yyyy-MM-dd HH:mm:ss UTC')).toString();
        val.modifiedDate= new Date(this.datePipe.transform(val.modifiedDate, 'yyyy-MM-dd HH:mm:ss UTC')).toString();
        val.id = val.id
        val.trackingId = val.trackingId
        val.fileUploadSessionId = val.fileUploadSessionId
        val.filenameOnRecorder = val.filenameOnRecorder
        val.fileTrackingStatus = val.fileTrackingStatus
        val.uploadSystemIP = val.uploadSystemIP
        val.jobTypeExtearnalId=val.jobTypeExtearnalId;
        val.audioDuration=val.audioDuration;
        val.authorExternalId=val.authorExternalId;
      }
      this.sqlSessionData = results;
      this.spinner.hide();
    },
    filter: () => {
      // update the grid and send a request to the server.
      console.log("filter::;;");
      this.myFileUploadTrackingGrid.updatebounddata('filter');
    }, sort: () => {
      // update the grid and send a request to the server.
      this.myFileUploadTrackingGrid.updatebounddata('sort');
    },
  };
  sQLdataAdapterForSession: any = new jqx.dataAdapter(this.FileUploadSessionssource, {
    autoBind: true,
    beforeSend: function (jqXHR, settings) {
      this.token = localStorage.getItem('token');
      if (this.token == null) {
        jqXHR.abort();
      }
      jqXHR.setRequestHeader('Authorization', 'Bearer ' + this.token);
    },
    contentType: 'application/json; charset=utf-8',
    loadError: (xhr, status, error) =>{
      (this.tabIndex == 0) ? this.sendNotification("Unable to get the Session Details. Please refresh the page.", 'error') : this.sendNotification("Unable to get the Tracking Details. Please refresh the page.", 'error'); 
    }
  });
  sQLdataAdapterForTracking: any = new jqx.dataAdapter(this.FileUploadTrackingsource, {
    autoBind: true,
    beforeSend: function (jqXHR, settings) {
      this.token = localStorage.getItem('token');
      if (this.token == null) {
        jqXHR.abort();
      }
      jqXHR.setRequestHeader('Authorization', 'Bearer ' + this.token);
    },
    contentType: 'application/json; charset=utf-8',
    loadError: (xhr, status, error) =>{
      (this.tabIndex == 0) ? this.sendNotification("Unable to get the Session Details. Please refresh the page.", 'error') : this.sendNotification("Unable to get the Tracking Details. Please refresh the page.", 'error'); 
    }
  });
  pagerrendererForSession = () => {
    this.element.empty();
    var datainfo =this.myFileUploadSessionsGrid.getdatainformation();
    var paginginfo = datainfo.paginginformation;
    ///Appending Buttons
    var data = this.myFileUploadSessionsGrid.getrows();
    var recordscount = this.FileUploadSessionssource.totalrecords;
    this.totalCountGlobal = this.FileUploadSessionssource.totalrecords;
    //labels
    var pageSizelabel = $("<div style='font-size: 13px; margin: 2px 100px; margin-top:-4px; font-weight: bold; float: right;'></div>");
    var dropdown = $("<div style='margin-right: -75px; margin-top: -5px; padding: 0px; float: right; cursor: pointer;'> </div>")
    var leftButton = $("<div style='padding: 0px; float: right; cursor: pointer;'><div style='margin-left: 45px; width: 16px; height: 16px;'></div></div>")
    leftButton.find('div').addClass('jqx-icon-arrow-left');
    leftButton.width(36);
    var rightButton = $("<div style='padding: 0px; margin: 0px 3px; float: right; cursor: pointer;'><div style='margin-left: 150px; width: 16px; height: 16px;'></div></div>");
    rightButton.find('div').addClass('jqx-icon-arrow-right');
    rightButton.width(36);
    var CurrentPagelabel = $("<div style='font-size: 13px; margin: 2px 0px; margin-top:-4px; font-weight: bold; float: left;'></div>");
    if (recordscount > 0) {
      var data = this.myFileUploadSessionsGrid.getrows();
      this.NumberOfSessions = data.length;
      CurrentPagelabel.text("Session(s) displayed: " + this.NumberOfSessions);
      pageSizelabel.text(1 + paginginfo.pagenum * paginginfo.pagesize + "-" + Math.min(parseInt(datainfo.rowscount), (paginginfo.pagenum + 1) * paginginfo.pagesize) + ' of ' + datainfo.rowscount);
      var datasource = [];
      for (var i = 1; i <= paginginfo.pagescount; i++) {
        datasource.push(i);
      }
      if (PageNumberGlobal == 1) {
        this.selectedDDLindex = 0;
      }
      if (paginginfo.pagescount > 6) {
        dropdown.jqxDropDownList({ source: datasource, selectedIndex: this.selectedDDLindex, dropDownVerticalAlignment: "bottom", theme: "material", width: 52, height: 28, scrollBarSize: 5 });
      }
      else {
        dropdown.jqxDropDownList({ source: datasource, selectedIndex: this.selectedDDLindex, dropDownVerticalAlignment: "bottom", theme: "material", width: 52, height: 28, autoDropDownHeight: true });
      }
      dropdown.on('select', (event) => {
        var args = event.args;
        if (args) {
          this.selectedDDLindex = args.index;
          if (args.index >= 0)
            PageNumberGlobal = args.item.label;
           this.myFileUploadSessionsGrid.gotopage(args.index);
        }
      });
      // update buttons states.
      var leftbuttonresult = paginginfo.pagescount * paginginfo.pagenum;
      if (leftbuttonresult == 0) {
        leftButton.find('div').addClass("disabled");
      }
      else {
        leftButton.find('div').removeClass("disabled");
      }
      var rightbuttonresult = paginginfo.pagenum + 1;
      if (rightbuttonresult == paginginfo.pagescount) {
        rightButton.find('div').addClass("disabled");
      }
      else {
        rightButton.find('div').removeClass("disabled");
      }
      rightButton.click((event) => {
        if (rightbuttonresult < paginginfo.pagescount) {
          // go to next page.
          if (paginginfo.pagenum == 0) {
            PageNumberGlobal = 2;
          }
          else {
            PageNumberGlobal = parseInt(paginginfo.pagenum) + 2;
          }
          this.selectedDDLindex = this.selectedDDLindex + 1;
          this.myFileUploadSessionsGrid.gotonextpage();
        }
      });
      leftButton.click((event) => {
        if (leftbuttonresult > 0) {
          // go to prev page.
          PageNumberGlobal = parseInt(paginginfo.pagenum);
          this.selectedDDLindex = this.selectedDDLindex - 1;
          this.myFileUploadSessionsGrid.gotoprevpage();
        }
      });
      pageSizelabel.appendTo(this.element);
      dropdown.appendTo(this.element);
      leftButton.appendTo(this.element);
      rightButton.appendTo(this.element);
      CurrentPagelabel.appendTo(this.element);
      return this.element;
    }
    else {
       CurrentPagelabel.text("Session(s) displayed: " + 0);
      pageSizelabel.text(0 + "-" + 0 + ' of ' + 0);
      CurrentPagelabel.appendTo(this.element);
      return this.element;
    }
  }
  pagerrendererForTracking = () => {
    this.element.empty();
    var datainfo = this.myFileUploadTrackingGrid.getdatainformation();
    var paginginfo = datainfo.paginginformation;
    ///Appending Buttons
    var data = this.myFileUploadTrackingGrid.getrows();
    var recordscount = this.FileUploadTrackingsource.totalrecords;
    this.totalCountGlobal = this.FileUploadTrackingsource.totalrecords;
    //labels
    var pageSizelabel = $("<div style='font-size: 13px; margin: 2px 100px; margin-top:-4px; font-weight: bold; float: right;'></div>");
    var dropdown = $("<div style='margin-right: -75px; margin-top: -5px; padding: 0px; float: right; cursor: pointer;'> </div>")
    var leftButton = $("<div style='padding: 0px; float: right; cursor: pointer;'><div style='margin-left: 45px; width: 16px; height: 16px;'></div></div>")
    leftButton.find('div').addClass('jqx-icon-arrow-left');
    leftButton.width(36);
    // leftButton.jqxButton({ theme: theme });
    var rightButton = $("<div style='padding: 0px; margin: 0px 3px; float: right; cursor: pointer;'><div style='margin-left: 150px; width: 16px; height: 16px;'></div></div>");
    rightButton.find('div').addClass('jqx-icon-arrow-right');
    rightButton.width(36);
    var CurrentPagelabel = $("<div style='font-size: 13px; margin: 2px 0px; margin-top:-4px; font-weight: bold; float: left;'></div>");
    if (recordscount > 0) {
      var data = this.myFileUploadTrackingGrid.getrows();
      this.NumberOfSessions = data.length;
      CurrentPagelabel.text("Tracking(s) displayed: " + this.NumberOfSessions);
      pageSizelabel.text(1 + paginginfo.pagenum * paginginfo.pagesize + "-" + Math.min(parseInt(datainfo.rowscount), (paginginfo.pagenum + 1) * paginginfo.pagesize) + ' of ' + datainfo.rowscount);
      var datasource = [];
      for (var i = 1; i <= paginginfo.pagescount; i++) {
        datasource.push(i);
      }
      if (PageNumberGlobal == 1) {
        this.selectedDDLindexTracking = 0;
      }
      if (paginginfo.pagescount > 6) {
        dropdown.jqxDropDownList({ source: datasource, selectedIndex: this.selectedDDLindexTracking, dropDownVerticalAlignment: "bottom", theme: "material", width: 52, height: 28, scrollBarSize: 5 });
      }
      else {
        dropdown.jqxDropDownList({ source: datasource, selectedIndex: this.selectedDDLindexTracking, dropDownVerticalAlignment: "bottom", theme: "material", width: 52, height: 28, autoDropDownHeight: true });
      }
      dropdown.on('select', (event) => {
        var args = event.args;
        if (args) {
          this.selectedDDLindexTracking = args.index;
          if (args.index >= 0)
            PageNumberGlobal = args.item.label;
           this.myFileUploadTrackingGrid.gotopage(args.index);
        }
      });
      // update buttons states.
      var leftbuttonresult = paginginfo.pagescount * paginginfo.pagenum;
      if (leftbuttonresult == 0) {
        leftButton.find('div').addClass("disabled");
      }
      else {
        leftButton.find('div').removeClass("disabled");
      }
      var rightbuttonresult = paginginfo.pagenum + 1;
      if (rightbuttonresult == paginginfo.pagescount) {
        rightButton.find('div').addClass("disabled");
      }
      else {
        rightButton.find('div').removeClass("disabled");
      }
      rightButton.click((event) => {
        if (rightbuttonresult < paginginfo.pagescount) {
          // go to next page.
          if (paginginfo.pagenum == 0) {
            PageNumberGlobal = 2;
          }
          else {
            PageNumberGlobal = parseInt(paginginfo.pagenum) + 2;
          }
          this.selectedDDLindexTracking = this.selectedDDLindexTracking + 1;
          this.myFileUploadTrackingGrid.gotonextpage();
        }
      });
      leftButton.click((event) => {
        if (leftbuttonresult > 0) {
          // go to prev page.
          PageNumberGlobal = parseInt(paginginfo.pagenum);
          this.selectedDDLindexTracking = this.selectedDDLindexTracking - 1;
           this.myFileUploadTrackingGrid.gotoprevpage();
        }
      });
      pageSizelabel.appendTo(this.element);
      dropdown.appendTo(this.element);
      leftButton.appendTo(this.element);
      rightButton.appendTo(this.element);
      CurrentPagelabel.appendTo(this.element);
      return this.element;
    }
    else {
       CurrentPagelabel.text("Tracking(s) displayed:  " + 0) ;
      pageSizelabel.text(0 + "-" + 0 + ' of ' + 0);
      CurrentPagelabel.appendTo(this.element);
      return this.element;
    }
  }
  refreshGrid() {
      this.isRefresh = true;
      (this.tabIndex == 0 ) ? this.getsqlData(this.selectedStartDate, this.selectedEndDate) : this.getsqlData(this.selectedStartDateTracking, this.selectedEndDateTracking);
      (this.tabIndex == 0 ) ? this.myFileUploadSessionsGrid.updatebounddata() : this.myFileUploadTrackingGrid.updatebounddata();
  }
  tabClick(tab) {
    this.tabIndex = tab.index;
    this.spinner.show();
    setTimeout(() => {
      var todayDate = new Date();
      datepickerButton.selectedIndex = 0;
      if (this.tabIndex == 0) {
        this.selectedStartDate = '';
        this.selectedEndDate = '';
        this.DisplayCustomStartDate = todayDate;
        this.DisplayCustomEnddate = todayDate;
        this.startmin = null;
        this.startmax = todayDate;
        this.endmin = null;
        this.endmax = todayDate;
        this.FileUploadSessionssource.showtoolbar = true;
        this.FileUploadTrackingsource.showtoolbar = false;
        this.createButtonsForSessions();
        this.getDatesByOptionSession("Today");
        this.getsqlData(this.selectedStartDate, this.selectedEndDate);
      } else {
        this.selectedStartDateTracking = '';
        this.selectedEndDateTracking = '';
        this.DisplayCustomStartDateTracking = todayDate;
        this.DisplayCustomEnddateTracking = todayDate;
        this.startminTracking = null;
        this.startmaxTracking = todayDate;
        this.endminTracking = null;
        this.endmaxTracking = todayDate;
        this.FileUploadTrackingsource.showtoolbar = true;
        this.FileUploadSessionssource.showtoolbar = false;
        this.createButtonsFortracking()
        this.getDatesByOptionTracking("Today");
        this.getsqlData(this.selectedStartDateTracking, this.selectedEndDateTracking);
        this.spinner.hide();
      }
    }, 3000);
  }
  getDatesByOptionSession(DateValue) {
    let startdate;
    let endDate;
    var dateevent = new Date();
    this.updateCustomSearch(false);
    endDate = JSON.stringify(dateevent);
    endDate = endDate.slice(1, 11);
    DateValue = DateValue.replace(/['"]+/g, '');
    (this.tabIndex == 0 &&  DateValue != "Custom") ? $('#customstartdate').hide() : $('#customstartdateTracking').hide();
    (this.tabIndex == 0 && DateValue != "Custom" ) ? $('#customenddate').hide() : $('#customenddateTracking').hide();
    if (DateValue == "Today") {
      startdate = this.datePipe.transform(dateevent, 'yyyy-MM-dd');
      endDate = this.datePipe.transform(dateevent, 'yyyy-MM-dd');
    }
    if (DateValue == "Yesterday") {
      let aDate = new Date();
      aDate.setDate(aDate.getDate() - 1);
      startdate = this.datePipe.transform(aDate, 'yyyy-MM-dd');
      endDate = this.datePipe.transform(aDate, 'yyyy-MM-dd');
    }
    if (DateValue == "Last 7 days (six days ago to today)") {
      var startdateevent = dateevent.setDate(dateevent.getDate() - 6);
      startdate = new Date(startdateevent);
      startdate = JSON.stringify(startdate);
      startdate = startdate.slice(1, 11);
    }
    if (DateValue == "This week (Sunday to today)") {
      var dateNow = new Date();
      var dateToday = new Date(dateNow.getFullYear(), dateNow.getMonth(), dateNow.getDate());
      var dateSunday = new Date(dateToday.getTime() - dateToday.getDay() * 24 * 3600 * 1000);
      var previousSunday = new Date(dateSunday);
      var startdateevent = previousSunday.setDate(previousSunday.getDate());
      var startdateevent = previousSunday.setDate(previousSunday.getDate() + 1);
      startdate = new Date(startdateevent);
      startdate = JSON.stringify(startdate);
      startdate = startdate.slice(1, 11);
    }
    if (DateValue == "This month (1st of this month to today)") {
      var dateNow = new Date();
      var firstDay = new Date(dateNow.getFullYear(), dateNow.getMonth(), 1);
      var firstday = new Date(firstDay);
      var startdateevent = dateevent.setDate(firstday.getDate());
      startdate = new Date(startdateevent);
      startdate = JSON.stringify(startdate);
      startdate = startdate.slice(1, 11);
    }
    if (DateValue == "Last 31 days (30 days ago to today)") {
      var startdateevent = dateevent.setDate(dateevent.getDate() - 29);
      startdate = new Date(startdateevent);
      startdate = JSON.stringify(startdate);
      startdate = startdate.slice(1, 11);
    }
    if (DateValue == "Custom") {
    (this.tabIndex == 0 ) ? $('#customstartdate').show() : $('#customstartdateTracking').show();
    (this.tabIndex == 0 ) ? $('#customenddate').show() : $('#customenddateTracking').show();
      var todayDate = new Date();
      this.DisplayCustomStartDate =  todayDate;
      this.DisplayCustomEnddate = todayDate;
      this.customSearch = true;
      var startdateevent = dateevent.setDate(dateevent.getDate());
      startdate = new Date(startdateevent);
      this.startmax = new Date(startdateevent);
      /////endmin
      var endmindate = dateevent.setDate(dateevent.getDate() - 1);
      this.endmin = new Date(endmindate);
      var todayDate = new Date();
      startdate = this.datePipe.transform(todayDate, 'yyyy-MM-dd');
      endDate = this.datePipe.transform(todayDate, 'yyyy-MM-dd');
      this.startmax = new Date();
      this.endmax = new Date();
      this.updateCustomSearch(true);
    }
    this.selectedStartDate = startdate;
    this.selectedEndDate = endDate;
  };
  getDatesByOptionTracking(DateValue) {
    let startdate;
    let endDate;
    var dateevent = new Date();
    this.updateCustomSearch(false);
    endDate = JSON.stringify(dateevent);
    endDate = endDate.slice(1, 11);
    DateValue = DateValue.replace(/['"]+/g, '');
    (this.tabIndex == 0 &&  DateValue != "Custom") ? $('#customstartdate').hide() : $('#customstartdateTracking').hide();
    (this.tabIndex == 0 && DateValue != "Custom" ) ? $('#customenddate').hide() : $('#customenddateTracking').hide();
    if (DateValue == "Today") {
      startdate = this.datePipeTracking.transform(dateevent, 'yyyy-MM-dd');
      endDate = this.datePipeTracking.transform(dateevent, 'yyyy-MM-dd');
    }
    if (DateValue == "Yesterday") {
      let aDate = new Date();
      aDate.setDate(aDate.getDate() - 1);
      startdate = this.datePipeTracking.transform(aDate, 'yyyy-MM-dd');
      endDate = this.datePipeTracking.transform(aDate, 'yyyy-MM-dd');
    }

    if (DateValue == "Last 7 days (six days ago to today)") {
      var startdateevent = dateevent.setDate(dateevent.getDate() - 6);
      startdate = new Date(startdateevent);
      startdate = JSON.stringify(startdate);
      startdate = startdate.slice(1, 11);
    }
    if (DateValue == "This week (Sunday to today)") {
      var dateNow = new Date();
      var dateToday = new Date(dateNow.getFullYear(), dateNow.getMonth(), dateNow.getDate());
      var dateSunday = new Date(dateToday.getTime() - dateToday.getDay() * 24 * 3600 * 1000);
      var previousSunday = new Date(dateSunday);
      var startdateevent = previousSunday.setDate(previousSunday.getDate());
      var startdateevent = previousSunday.setDate(previousSunday.getDate() + 1);
      startdate = new Date(startdateevent);
      startdate = JSON.stringify(startdate);
      startdate = startdate.slice(1, 11);
    }
    if (DateValue == "This month (1st of this month to today)") {
      var dateNow = new Date();
      var firstDay = new Date(dateNow.getFullYear(), dateNow.getMonth(), 1);
      var firstday = new Date(firstDay);
      var startdateevent = dateevent.setDate(firstday.getDate());
      startdate = new Date(startdateevent);
      startdate = JSON.stringify(startdate);
      startdate = startdate.slice(1, 11);
    }
    if (DateValue == "Last 31 days (30 days ago to today)") {
      var startdateevent = dateevent.setDate(dateevent.getDate() - 29);
      startdate = new Date(startdateevent);
      startdate = JSON.stringify(startdate);
      startdate = startdate.slice(1, 11);
    }
    if (DateValue == "Custom") {
    (this.tabIndex == 0 ) ? $('#customstartdate').show() : $('#customstartdateTracking').show();
    (this.tabIndex == 0 ) ? $('#customenddate').show() : $('#customenddateTracking').show();
      var todayDate = new Date();
      this.DisplayCustomStartDateTracking =  todayDate;
      this.DisplayCustomEnddateTracking = todayDate;
      this.customSearchTracking = true;
      var startdateevent = dateevent.setDate(dateevent.getDate());
      startdate = new Date(startdateevent);
      this.startmaxTracking = new Date(startdateevent);
      /////endmin
      var endmindate = dateevent.setDate(dateevent.getDate() - 1);
      this.endminTracking = new Date(endmindate);
      var todayDate = new Date();
      startdate = this.datePipeTracking.transform(todayDate, 'yyyy-MM-dd');
      endDate = this.datePipeTracking.transform(todayDate, 'yyyy-MM-dd');
      this.startmaxTracking = new Date();
      this.endmaxTracking = new Date();
      customenddate.max = this.endmaxTracking;
      this.updateCustomSearch(true);
    }
    this.selectedStartDateTracking = startdate;
    this.selectedEndDateTracking = endDate;
  };
  updateCustomSearch(statusbar) {
    if (statusbar) {
      customStartDateContainer.style.display = "block";
      customEndDateContainer.style.display = "block";
      (this.tabIndex == 0 ) ? $('#customsearchButtonSession').show() : $('#customsearchButtonTracking').show();
    }
    else {
      customStartDateContainer.style.display = "none";
      customEndDateContainer.style.display = "none";
      (this.tabIndex == 0 ) ? $('#customsearchButtonSession').hide() : $('#customsearchButtonTracking').hide();
    }
  }
  onDateOptionChangeForSession(event: any): void {
    this.spinner.show();
    PageNumberGlobal = 1;
    this.selectedStartDate = '';
    this.selectedEndDate = '';
    this.customSearch = false;
    let startdate;
    let endDate;
    this.selectedDateOption = event.args.item.value;
    var DateObj = new Date();
    ////End date
    var dateevent = new Date();
    endDate = JSON.stringify(dateevent);
    endDate = endDate.slice(1, 11);
    this.getDatesByOptionSession(JSON.stringify(this.selectedDateOption));
    this.getsqlData(this.selectedStartDate, this.selectedEndDate);
  };
  onDateOptionChangeForTracking(event: any): void {
    this.spinner.show();
    PageNumberGlobal = 1;
    this.selectedStartDateTracking = '';
    this.selectedEndDateTracking = '';
    this.customSearchTracking = false;
    let startdate;
    let endDate;
    this.selectedDateOptionTacking = event.args.item.value;
    var DateObj = new Date();
    ////End date
    var dateevent = new Date();
    endDate = JSON.stringify(dateevent);
    endDate = endDate.slice(1, 11);
    this.getDatesByOptionTracking(JSON.stringify(this.selectedDateOptionTacking));
    this.getsqlData(this.selectedStartDateTracking, this.selectedEndDateTracking);
  };
  counter: number = 1;
  tooltiprenderer = (element: any): void => {
    let id = `toolTipContainer${this.counter}`;
    element[0].id = id;
    let content = element[0].innerHTML;
    console.log("content", content);
    this.counter++;
  }

  visibilityrenderer = (row, columnfield, value, defaulthtml, columnproperties, rowdata, htmlElement) => {
    if (value == true) {
      return '<i class="material-icons" style="cursor:pointer">visibility</i>';
    }
    else {
      return '<i class="material-icons" style="cursor:pointer">visibility_off</i>';
    }
  };

  ChooseBoolItems: any[] = [
    { value: 1, label: "Yes" },
    { value: 0, label: "No" }
  ];
    FileUploadSessionsColumns: any[] =
    [
      { text: 'ID', datafield: 'id', filtertype: 'number', width: '6%', align: 'center' },
      { text: 'Session Id', datafield: 'sessionId', width: '18%', filterable: false, align: 'center' },
      { text: 'Uploading UserName', datafield: 'uploadingUserName', width: '15%', filtertype: 'input', align: 'center' },
      { text: 'Session CreationTime', datafield: 'sessionCreationTime', filterable: false, width: '13%',cellsformat: 'MMMM-dd-yyyy HH:mm', align: 'center' },
      { text: 'Session EndTime', datafield: 'sessionEndTime', width: '13%', filterable: false ,cellsformat: 'MMMM-dd-yyyy HH:mm', align: 'center'},
      { text: 'File Session Status', datafield: 'fileSessionStatus', filtertype: 'input', width: '13%', align: 'center' },
      { text: 'FolderName OnSystem', datafield: 'folderNameOnSystem', filtertype: 'input', width: '13%', align: 'center' },
      { text: 'FileCount OnDevice', datafield: 'fileCountOnDevice', filtertype: 'number', width: '13%', align: 'center' },
      { text: 'FileCount OnSystem', datafield: 'fileCountOnSystem', filtertype: 'number', width: '13%', align: 'center' },
      { text: 'FileCount OnServer', datafield: 'fileCountOnServer', filtertype: 'number', width: '13%', align: 'center' },
      { text: 'Upload System IP', datafield: 'uploadSystemIP', filtertype: false, width: '13%', align: 'center' },
      { text: 'CreatedDate', datafield: 'createdDate', filterable: false, width: '13%', cellsformat: 'MMMM-dd-yyyy HH:mm',align: 'center' },
      { text: 'Modified Date', datafield: 'modifiedDate', filterable: false, width: '13%',cellsformat: 'MMMM-dd-yyyy HH:mm', align: 'center' },
    ];
    FileUploadTrackingColumns: any[] =
    [
      { text: 'ID', datafield: 'id', filtertype: 'number', width: '6%', align: 'center' },
      { text: 'Tracking Id', datafield: 'trackingId', width: '18%', filterable: false, align: 'center' },
      { text: 'FileUpload SessionId', datafield: 'fileUploadSessionId', width: '15%', filtertype: 'number', align: 'center' },
      { text: 'Filename OnRecorder', datafield: 'filenameOnRecorder', filtertype: 'input', width: '13%', align: 'center' },
      { text: 'Transfer StartTime', datafield: 'transferStartTime', width: '13%', cellsformat: 'MMMM-dd-yyyy HH:mm', filterable: false, align: 'center'},
      { text: 'Transfer EndTime', datafield: 'transferEndTime', filterable: false, cellsformat: 'MMMM-dd-yyyy HH:mm', width: '13%', align: 'center' },
      { text: 'Upload StartTime', datafield: 'uploadStartTime',filterable: false, cellsformat: 'MMMM-dd-yyyy HH:mm', width: '13%', align: 'center' },
      { text: 'Upload EndTime', datafield: 'uploadEndTime', filterable: false, width: '13%', cellsformat: 'MMMM-dd-yyyy HH:mm', align: 'center' },
      { text: 'File Tracking Status', datafield: 'fileTrackingStatus', filtertype: 'input', width: '13%', align: 'center' },
      { text: 'Dictation DateTime', datafield: 'dictationDateTime', filterable: false, width: '13%',  cellsformat: 'MMMM-dd-yyyy HH:mm',align: 'center' },
      { text: 'Upload System IP', datafield: 'uploadSystemIP', filtertype: 'input', width: '13%', align: 'center' },
      { text: 'JobType Extearnal Id', datafield: 'jobTypeExtearnalId', width: '13%', filtertype: 'input', align: 'center'},
      { text: 'Audio Duration', datafield: 'audioDuration', filtertype: 'input', width: '13%', align: 'center' },
      { text: 'Author External Id', datafield: 'authorExternalId', filtertype: 'input', width: '13%', align: 'center' },
      { text: 'Created Date', datafield: 'createdDate', filterable: false, width: '13%', cellsformat: 'MMMM-dd-yyyy HH:mm', align: 'center' },
      { text: 'Modified Date', datafield: 'modifiedDate', filterable: false, width: '13%', cellsformat: 'MMMM-dd-yyyy HH:mm', align: 'center' },
    ];
  rendergridrows = (params: any): any => {
    return params.data;
  }
  ///////////////////////buttons creation///////////////
  createButtonsForSessionsContainers(statusbar: any): void {
    console.log("triggered", statusbar);
    if (statusbar[0] == null || statusbar[0] == 'undefined') {
    }
    else {
      let buttonsContainer = document.createElement('div');
      buttonsContainer.style.cssText = 'overflow: hidden; position: relative; margin: 5px; border-color: none; background: none;';

      let datepickerButtonContainer = document.createElement('div');
      datepickerButtonContainer.id = "datepickerButtonForSession";
      datepickerButtonContainer.className = 'panelButtonclass';
      datepickerButtonContainer.style.cssText = 'theme: material; float: left; margin-left: 5px;';
      datepickerButtonContainer.title = "Viewing Session From";

      customStartDateContainer = document.createElement('div');
      customEndDateContainer = document.createElement('div');
      customsearchButtonContainer = document.createElement('div');

      customStartDateContainer.id = "customstartdate"
      customEndDateContainer.id = "customenddate"
      customsearchButtonContainer.id = 'customsearchButtonSession';


      customStartDateContainer.style.cssText = 'theme: material;float: left; margin-left: 5px;';
      customEndDateContainer.style.cssText = 'theme: material;float: left; margin-left: 5px;';
      customsearchButtonContainer.style.cssText = 'theme: material;float: left; margin-left: 5px;';

      customsearchButtonContainer.style.display = "none";
      customStartDateContainer.style.display = "none";
      customEndDateContainer.style.display = "none";

      let refreshContainerFileUploadLists = document.createElement('div');
      refreshContainerFileUploadLists.id = 'refreshButtonForSession';
      refreshContainerFileUploadLists.className = 'panelButtonclass';
      refreshContainerFileUploadLists.style.cssText = 'float: left; margin-left: 5px;';

      let exportContainerFileUploadLists = document.createElement('div');
      exportContainerFileUploadLists.id = 'exportButtonForSession';
      exportContainerFileUploadLists.className = 'panelButtonclass';
      exportContainerFileUploadLists.style.cssText = 'float: left; margin-left: 5px;';


      if (localStorage.getItem('user_isadmin') == "true") {
        if (localStorage.getItem('user_roleName') == "SuperAdmin" || localStorage.getItem('user_roleName') == "HostAdmin" || localStorage.getItem('user_roleName') == "TenantAdmin") {

        }
      }
      buttonsContainer.appendChild(datepickerButtonContainer);
      buttonsContainer.appendChild(customStartDateContainer);
      buttonsContainer.appendChild(customEndDateContainer);
      buttonsContainer.appendChild(customsearchButtonContainer);
      buttonsContainer.appendChild(refreshContainerFileUploadLists);
      buttonsContainer.appendChild(exportContainerFileUploadLists)

      statusbar[0].appendChild(buttonsContainer);
    }
  }
  createButtonsForTrackingContainers(statusbar: any): void {
    console.log("triggered", statusbar);
    
    if (statusbar[0] == null || statusbar[0] == 'undefined') {
    }
    else {
      let buttonsContainer = document.createElement('div');
      buttonsContainer.style.cssText = 'overflow: hidden; position: relative; margin: 5px; border-color: none; background: none;';

      let datepickerButtonContainer = document.createElement('div');
      datepickerButtonContainer.id = "datepickerButtonForTracking";
      datepickerButtonContainer.className = 'panelButtonclass';
      datepickerButtonContainer.style.cssText = 'theme: material; float: left; margin-left: 5px;';
      datepickerButtonContainer.title = "Viewing Session From";

      customStartDateContainer = document.createElement('div');
      customEndDateContainer = document.createElement('div');
      customsearchButtonContainer = document.createElement('div');

      customStartDateContainer.id = "customstartdateTracking"
      customEndDateContainer.id = "customenddateTracking"
      customsearchButtonContainer.id = 'customsearchButtonTracking';


      customStartDateContainer.style.cssText = 'theme: material;float: left; margin-left: 5px;';
      customEndDateContainer.style.cssText = 'theme: material;float: left; margin-left: 5px;';
      customsearchButtonContainer.style.cssText = 'theme: material;float: left; margin-left: 5px;';

      customsearchButtonContainer.style.display = "none";
      customStartDateContainer.style.display = "none";
      customEndDateContainer.style.display = "none";

      let refreshContainerFileUploadLists = document.createElement('div');
      refreshContainerFileUploadLists.id = 'refreshButtonForTracking';
      refreshContainerFileUploadLists.className = 'panelButtonclass';
      refreshContainerFileUploadLists.style.cssText = 'float: left; margin-left: 5px;';

      let exportContainerFileUploadLists = document.createElement('div');
      exportContainerFileUploadLists.id = 'exportButtonForTracking';
      exportContainerFileUploadLists.className = 'panelButtonclass';
      exportContainerFileUploadLists.style.cssText = 'float: left; margin-left: 5px;';


      if (localStorage.getItem('user_isadmin') == "true") {
        if (localStorage.getItem('user_roleName') == "SuperAdmin" || localStorage.getItem('user_roleName') == "HostAdmin" || localStorage.getItem('user_roleName') == "TenantAdmin") {
        }
      }
      buttonsContainer.appendChild(datepickerButtonContainer);
      buttonsContainer.appendChild(customStartDateContainer);
      buttonsContainer.appendChild(customEndDateContainer);
      buttonsContainer.appendChild(customsearchButtonContainer);
      buttonsContainer.appendChild(refreshContainerFileUploadLists);
      buttonsContainer.appendChild(exportContainerFileUploadLists)

      statusbar[0].appendChild(buttonsContainer);
    }
  }
  createButtonsForSessions() {
    /////////////////Buttons visible for Admin Users
    let isAdmin = localStorage.getItem('user_isadmin');
    if (isAdmin == "true") {
      if (localStorage.getItem('user_roleName') == "SuperAdmin" || localStorage.getItem('user_roleName') == "HostAdmin" || localStorage.getItem('user_roleName') == "TenantAdmin") {
      }
    }
    datepickerButton = jqwidgets.createInstance('#datepickerButtonForSession', 'jqxDropDownList', {
      width: 120, height: 25, theme: 'material', source: this.dateOptions, selectedIndex: 0, autoDropDownHeight: true
    });
     customstartdate = jqwidgets.createInstance('#customstartdate', 'jqxDateTimeInput', {
      theme: 'material', width: 120, height: 30,value: this.DisplayCustomStartDate, min: this.startmin, max: this.startmax, formatString: "'dd-MMM-yyyy'"
    });
     customenddate = jqwidgets.createInstance('#customenddate', 'jqxDateTimeInput', {
      theme: 'material', width: 120, height: 30,value: this.DisplayCustomEnddate, min: this.endmin, max: this.endmax, formatString: "'dd-MMM-yyyy'"
    });
    let customsearchButtonOptions = {
      width: 40, height: 30, value: '<img src="../../../assets/img/icon/search-jobs.png"  title= "Search Date range"  class="user-listicon"/>',
      imgPosition: 'center', theme: 'material', textPosition: 'center', template: 'link'
    }
    let customsearchButton = jqwidgets.createInstance('#customsearchButtonSession', 'jqxButton', customsearchButtonOptions);
    datepickerButton.addEventHandler('change', (event: any): void => {
      this.onDateOptionChangeForSession(event);
    });
    customsearchButton.addEventHandler('click', (event: any): void => {
      this.selectedEndDate = (this.selectedEndDate == "") ? this.datePipe.transform(new Date(), 'yyyy-MM-dd') : this.selectedEndDate;
      this.searchData();
    });
    customstartdate.addEventHandler('change', (event: any): void => {
      this.onStartDateValueChangedSession(event)
    });
    customstartdate.addEventHandler('open', (event: any): void => {
      this.startDateEventOpen(event)
    });
    customenddate.addEventHandler('change', (event: any): void => {
      this.onEndDateValueChangedSession(event)
    });

    let exportButtonOptions = {
      width: 40, height: 25, value: '<img src="../../../assets/img/icon/export.png"  title= "Export File Upload lists"  class="user-listicon"/>',
      imgPosition: 'center', theme: 'material', textPosition: 'center', template: 'link'
    }
    let exportButton = jqwidgets.createInstance('#exportButtonForSession', 'jqxButton', exportButtonOptions);
    exportButton.addEventHandler('click', (event: any): void => {
      this.spinner.show();
      this.excelBtnForFileUploadSessionsOnClick() 
    });
    let refreshButtonOptions = {
      width: 40, height: 25, value: '<img src="../../../assets/img/icon/refresh.png"  title= "Refresh File Upload lists"  class="user-listicon"/>',
      imgPosition: 'center', theme: 'material', textPosition: 'center', template: 'link'
    }
    let refreshButton = jqwidgets.createInstance('#refreshButtonForSession', 'jqxButton', refreshButtonOptions);
    refreshButton.addEventHandler('click', (event: any): void => {
      this.refreshGrid();
    });

  }
  createButtonsFortracking() {
    /////////////////Buttons visible for Admin Users
    let isAdmin = localStorage.getItem('user_isadmin');
    if (isAdmin == "true") {
      if (localStorage.getItem('user_roleName') == "SuperAdmin" || localStorage.getItem('user_roleName') == "HostAdmin" || localStorage.getItem('user_roleName') == "TenantAdmin") {
      }
    }
    datepickerButton = jqwidgets.createInstance('#datepickerButtonForTracking', 'jqxDropDownList', {
      width: 120, height: 25, theme: 'material', source: this.dateOptionsTracking, selectedIndex: 0, autoDropDownHeight: true
    });
     customstartdateTracking = jqwidgets.createInstance('#customstartdateTracking', 'jqxDateTimeInput', {
      theme: 'material', width: 120, height: 30,value: this.DisplayCustomStartDateTracking, min: this.startminTracking, max: this.startmaxTracking, formatString: "'dd-MMM-yyyy'"
    });
     customenddateTracking = jqwidgets.createInstance('#customenddateTracking', 'jqxDateTimeInput', {
      theme: 'material', width: 120, height: 30,value: this.DisplayCustomEnddateTracking, min: this.endminTracking, max: this.endmaxTracking, formatString: "'dd-MMM-yyyy'"
    });
    let customsearchButtonOptions1 = {
      width: 40, height: 30, value: '<img src="../../../assets/img/icon/search-jobs.png"  title= "Search Date range"  class="user-listicon"/>',
      imgPosition: 'center', theme: 'material', textPosition: 'center', template: 'link'
    }
    let customsearchButton = jqwidgets.createInstance('#customsearchButtonTracking', 'jqxButton', customsearchButtonOptions1);
    datepickerButton.addEventHandler('change', (event: any): void => {
      this.onDateOptionChangeForTracking(event);
    });
    customsearchButton.addEventHandler('click', (event: any): void => {
      this.selectedEndDateTracking = (this.selectedEndDateTracking == "") ? this.datePipeTracking.transform(new Date(), 'yyyy-MM-dd') : this.selectedEndDateTracking;
      this.searchDataTracking();
    });
    customstartdateTracking.addEventHandler('change', (event: any): void => {
      this.onStartDateValueChangedTracking(event)
    });
    customstartdateTracking.addEventHandler('open', (event: any): void => {
      this.startDateEventOpenTracking(event)
    });
    customenddateTracking.addEventHandler('change', (event: any): void => {
      this.onEndDateValueChangedTracking(event)
    });

    let exportButtonOptions = {
      width: 40, height: 25, value: '<img src="../../../assets/img/icon/export.png"  title= "Export File Upload lists"  class="user-listicon"/>',
      imgPosition: 'center', theme: 'material', textPosition: 'center', template: 'link'
    }
    let exportButton = jqwidgets.createInstance('#exportButtonForTracking', 'jqxButton', exportButtonOptions);
    exportButton.addEventHandler('click', (event: any): void => {
      this.spinner.show();
       this.excelBtnForFileUploadTrackingOnClick();
    });
    let refreshButtonOptions = {
      width: 40, height: 25, value: '<img src="../../../assets/img/icon/refresh.png"  title= "Refresh File Upload lists"  class="user-listicon"/>',
      imgPosition: 'center', theme: 'material', textPosition: 'center', template: 'link'
    }
    let refreshButton = jqwidgets.createInstance('#refreshButtonForTracking', 'jqxButton', refreshButtonOptions);
    refreshButton.addEventHandler('click', (event: any): void => {
      this.refreshGrid();
    });

  }
  ///////////////////////buttons creation///////////////
  
  excelBtnForFileUploadSessionsOnClick() {
    var data = this.myFileUploadSessionsGrid.getrows();
    var curdata = "Sessions-List_"
    var exportdata = [];
    if(data.length > 0){
      for (var item of data) {
        const newitem = {
          'ID': item.id,
          'Session Id': item.sessionId,
          'Uploading UserName': item.uploadingUserName,
          'Session CreationTime': item.sessionCreationTime,
          'Session EndTime':item.sessionEndTime,
          'File Session Status': item.fileSessionStatus,
          'FolderName OnSystem': item.folderNameOnSystem,
          'FileCount OnDevice':item.fileCountOnDevice,
          'FileCount OnSystem': item.fileCountOnSystem,
          'FileCount OnServer':item.fileCountOnServer,
          'Upload System IP':item.uploadSystemIP,
          'CreatedDate': item.createdDate,
          'Modified Date':item.modifiedDate,
        }
        exportdata.push(newitem);
      }
      this.excelService.exportAsExcelFile(exportdata, curdata);
    } else {
      this.sendNotification("No Sessions data to export", 'error')

    }
    this.spinner.hide()
  }
  excelBtnForFileUploadTrackingOnClick() {
    var data = this.myFileUploadTrackingGrid.getrows();
    var curdata = "Tracking-List_"
    var exportdata = [];
    if(data.length > 0){
      for (var item of data) {
        const newitem = {
          'ID': item.id,
          'Tracking Id': item.trackingId,
          'FileUpload SessionId': item.fileUploadSessionId,
          'Filename OnRecorder': item.filenameOnRecorder,
          'Transfer StartTime':item.transferStartTime,
          'Transfer EndTime': item.transferEndTime,
          'Upload StartTime': item.uploadStartTime,
          'Upload EndTime':item.uploadEndTime,
          'File Tracking Status': item.fileTrackingStatus,
          'Dictation DateTime':item.dictationDateTime,
          'Upload System IP':item.uploadSystemIP,
          'JobType Extearnal Id': item.jobTypeExtearnalId,
          'Audio Duration':item.audioDuration,
          'Author External Id':item.authorExternalId,
          'CreatedDate': item.createdDate,
          'Modified Date':item.modifiedDate,
        }
        exportdata.push(newitem);
      }
      this.excelService.exportAsExcelFile(exportdata, curdata);
    } else {
      this.sendNotification("No Sessions data to export", 'error')
    }
    this.spinner.hide()
  }
  Rowclick(event: any): void {
    this.heightOffset = 120;
    this.getHeight();
  }
}