<div class="logo">
  <a class="simple-text logo-mini">
    <div class="logo-img">
      <img class='nsc-logo' src="/assets/img/{{brandname}}/VIQ-Logo-White.png" alt="Netscribe logo" />
    </div>
  </a>
  <a href="" class="simple-text logo-normal viq-logo hide-logo" aria-label="Viq Logo" >
  </a>
</div>
<div class="sidebar-wrapper">
  <ul class="nav">
    <li routerLinkActive="active" *ngFor="let menuitem of menuItems" class="nav-item">
      <!--If is a single link-->
      <a [routerLink]="[menuitem.path]" *ngIf="menuitem.type === 'link'" class="nav-link"
        [attr.data-tippy-content]="menuitem.title" data-tippy-trigger="mouseenter focus" data-tippy-arrow="true"
        data-tippy-placement="right" data-tippy-offset="[0, 5]">
        <i class="material-icons-outlined ">{{menuitem.icontype}}</i>
        <p style="padding-left: 30%;">{{menuitem.title}}</p>
      </a>
      <!--If it have a submenu-->
      <a data-toggle="collapse" href="#{{menuitem.collapse}}" *ngIf="menuitem.type === 'sub'" (click)="updatePS()"
        class="nav-link" [attr.data-tippy-content]="menuitem.title" data-tippy-trigger="mouseenter focus"
        data-tippy-arrow="true" data-tippy-placement="right" data-tippy-offset="[0, 5]">
        <i class="material-icons-outlined">{{menuitem.icontype}}</i>
        <p style="padding-left: 30%;">{{menuitem.title}}<b class="caret"></b></p>
      </a>

      <!--Display the submenu items-->
      <div id="{{menuitem.collapse}}" class="collapse" *ngIf="menuitem.type === 'sub'">
        <ul class="nav">
          <li routerLinkActive="active" *ngFor="let childitem of menuitem.children" class="nav-item">
            <a [routerLink]="[menuitem.path, childitem.path]" class="nav-link"
              [attr.data-tippy-content]="childitem.title" data-tippy-trigger="mouseenter focus" data-tippy-arrow="true"
              data-tippy-placement="right" data-tippy-offset="[0, 5]">
              <i class="material-icons-outlined" style="padding-left: 10%;">{{childitem.ab}}</i>
              <span class="sidebar-normal" style="padding-left: 30%;">{{childitem.title}}</span>
            </a>
          </li>
        </ul>
      </div>
    </li>
  </ul>
</div>