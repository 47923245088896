<router-outlet></router-outlet>

<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-clip-rotate-multiple"
  [fullScreen]="true">
  <p style="color: white"> Loading... </p>
</ngx-spinner>

<jqxNotification #msgNotification [position]="'top-right'" [opacity]="1.0" [autoOpen]="false"
    [autoClose]="true" [animationOpenDelay]="800" [autoCloseDelay]="5000" template.success>
</jqxNotification>

<!-- <div class="notification-banner" *ngIf="isBannarEnable"> -->
  <!-- <app-notification-banner
  *ngIf="isBannarEnable"
    [message]="bannerMessage"
    [link]="bannerLink"
    [linkText]="bannerLinkText"
    [type]="bannerType">
  </app-notification-banner> -->
  <!-- <button class="close-button" *ngIf="isBannerCloseEnable" (click)="bannerClose()"><mat-icon style="font-size: 1.2em;color: black; margin-top: 3px;">cancel</mat-icon></button> -->
  <!-- </div> -->
<router-outlet></router-outlet>
