<div class="main-content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <mat-tab-group  (selectedTabChange)="tabClick($event)" [(selectedIndex)]="tabIndex">
            <mat-tab label="FileUpload Sessions">
              <div class="table-responsive mt-4">
                <jqxGrid 
                  #myFileUploadSessionsGrid 
                  (onRowclick)="Rowclick($event)" 
                  (onPagechanged)="onPageChangedForSession($event)"
                  (onFilter)="SessionGridFilter($event)"
                  [source]="sQLdataAdapterForSession"
                  [columns]="FileUploadSessionsColumns" 
                  [enabletooltips]="true" 
                  [pagesize]="1000"
                  [pagesizeoptions]="['100', '200', '1000']" 
                  [pageable]="true"
                  [rendergridrows]="rendergridrows" 
                  [theme]="'material'" 
                  [width]="'99.99%'"
                  [height]="height" 
                  [sortable]="true" 
                  [filterable]="true" 
                  [showfilterrow]="true"
                  [showtoolbar]="true" 
                  [toolbarheight]="40" 
                  [renderstatusbar]="createButtonsForSessionsContainers"
                  [selectionmode]="'multiplerowsadvanced'" 
                  [columnsresize]="true" 
                  [columnsreorder]="true"
                  [pagerrenderer]="pagerrendererForSession" 
                  [autoshowloadelement]="false"
                  [enablebrowserselection]="true" 
                  [keyboardnavigation]="false"
                  [scrollbarsize]="6">
                </jqxGrid>
              </div>
            </mat-tab>
            
            <mat-tab label="FileUpload Tracking">
                <div class="table-responsive mt-4">
                  <jqxGrid 
                    #myFileUploadTrackingGrid 
                    (onRowclick)="Rowclick($event)" 
                    (onPagechanged)="onPageChangedForTracking($event)"
                    (onFilter)="TrackingGridFilter($event)"
                    [source]="sQLdataAdapterForTracking"
                    [columns]="FileUploadTrackingColumns" 
                    [enabletooltips]="true" 
                    [pagesize]="1000"
                    [pagesizeoptions]="['100', '200', '1000']" 
                    [pageable]="true"
                    [rendergridrows]="rendergridrows" 
                    [theme]="'material'" 
                    [width]="'99.99%'"
                    [height]="height" 
                    [sortable]="true" 
                    [filterable]="true" 
                    [showfilterrow]="true"
                    [showtoolbar]="true" 
                    [toolbarheight]="40" 
                    [renderstatusbar]="createButtonsForTrackingContainers"
                    [selectionmode]="'multiplerowsadvanced'" 
                    [columnsresize]="true" 
                    [columnsreorder]="true"
                    [pagerrenderer]="pagerrendererForTracking" 
                    [autoshowloadelement]="false"
                    [enablebrowserselection]="true" 
                    [keyboardnavigation]="false"
                    [scrollbarsize]="6">
                  </jqxGrid>
                </div>
              </mat-tab>
          </mat-tab-group>
        </div>
      </div>
    </div>
  </div>
  
  <jqxNotification #msgNotification [position]="'top-right'" [opacity]="1.0" [autoOpen]="false"
  [autoClose]="true" [animationOpenDelay]="800" [autoCloseDelay]="5000" template.success>
  </jqxNotification>

