import { BackendService } from './backend.service';
import { Injectable, ViewChild } from '@angular/core';
import { CanActivate, Router, ActivatedRoute, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { JwtHelperService } from "@auth0/angular-jwt";

@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate{

  helper = new JwtHelperService();

  constructor(
    private backendService: BackendService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) { }


  sendNotification(message, messageType) {
    this.backendService.notificationRef.elementRef.nativeElement.querySelector('.jqx-notification-content').innerHTML = message;
    this.backendService.notificationRef.template(messageType);
    this.backendService.notificationRef.open();
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot){

    // var iSuserloggedIN = localStorage.getItem('userloggedIN')
    // if(iSuserloggedIN == "false")
    // {
    //     this.backendService.setUserLoggedIn(false);
    // }else
    // {
    //   this.router.navigateByUrl('/admin'); 
    // }
    const isAdmin = localStorage.getItem('user_isadmin')
    if(isAdmin == "true"){
      return true;
    }
    if(this.backendService.notificationRef)
       // this.sendNotification('Currently logged in user not authorized to requested URL.', 'error');
    setTimeout(() => {
        this.router.navigate(['/admin/job-list']);
    }, 1000);
    return false;
  }
}
