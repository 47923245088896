import { jqxNotificationComponent } from 'jqwidgets-ng/jqxnotification';
import { BackendService } from './../../../app/services/backend.service';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { Component, OnInit, Inject, ViewChild, Input } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LoginResponse } from 'src/app/models/apiRequestModels';
import { NgxSpinnerService } from 'ngx-spinner';
import { ResponseMessage } from 'src/app/models/ResponseMessage';

@Component({
    selector: 'app-mfa-otp',
    templateUrl: './mfa-otp.component.html',
    styleUrls: ['./mfa-otp.component.css'],
})
export class MFAComponent implements OnInit {

    mfaform: FormGroup;
    submitted = false;
    private mfaresponse :LoginResponse;
    errormessage = "";
    show: boolean = false;
    @ViewChild('msgNotification', { static: false }) msgNotification: jqxNotificationComponent;
    // @ViewChild('logincomponent', { static: false }) logincomponent: LoginComponent;
    // @ViewChild(LoginComponent, { static: true }) login;

    constructor(private formBuilder: FormBuilder,
        private backendService: BackendService,
        private spinner: NgxSpinnerService,
        public dialogRef: MatDialogRef<MFAComponent>,
        @Inject(MAT_DIALOG_DATA) public logindata: any) { }

    ngOnInit() {
        console.log("this.logindata",this.logindata);
        
        this.mfaform = this.formBuilder.group({
            onetimepassword: new FormControl()
        }, {
        });
    }

    sendNotification(message, messageType) {
        this.msgNotification.elementRef.nativeElement.querySelector('.jqx-notification-content').innerHTML = message;
        this.msgNotification.template(messageType);
        this.msgNotification.open();
    }


    get f() {
        return this.mfaform.controls;
    }
    isPasswordMatch = false;

    otpEvent() {
        const value = this.mfaform.controls.onetimepassword.value;
        if (value.length == 0) {
            this.isPasswordMatch = false;
        }

    }

    closeotpDialog() {
        this.submitted = false;
    }
    isValid(controlName, error) {
        return this.mfaform.controls[controlName].hasError(error);
    }
    dialogClose(result : any) {
        //this.dialogRef.close();
        console.log("result::;", result);
        this.dialogRef.close({event:"MFA",data:result});
    }
    closeDialog(){
        this.dialogRef.close({event:'Cancel'});
      }
    AvoidSpace() {
        this.sendNotification("Please enter the valid special character(Space is not allowed).", "error")
        return false;
    }
    passwordToggle() {
        this.show = !this.show;
      }
    changePassword() {
        console.log("submit click...", this.logindata);
        this.spinner.show();
        this.submitted = true;
        const oldPassword = this.logindata.currentpassword;
        const username = this.logindata.currentusername;
        const confirmPassword = this.mfaform.controls.confirmPassword;
        if (this.mfaform.controls.onetimepassword.value == null) {
            this.sendNotification("Please enter the OTP.", 'error');
            this.spinner.hide();
        }

        if (this.submitted && this.mfaform.valid) {

            const data = {
                'userName': username,
                'password': oldPassword,
                'mfaCode': this.mfaform.controls.onetimepassword.value,
                'sessionID': this.logindata.loginresponse.sessionID
            }
            
            if(this.logindata.Verification)
            {
                this.backendService.UserPhoneNumberverification(data).subscribe(
                    res => {
                        const result = this.backendService.getJSONResponse(res);
                        if(result!= null)
                        {
                            if(result.data.isSuccess == true)
                            {
                                if(result.data.userStatus == ResponseMessage.authenticated)
                                {
                                    this.mfaresponse = JSON.parse(JSON.stringify(result.data));
                                    // console.log("mfaresponse");
                                    // console.log(this.mfaresponse);
                                    this.submitted = false;
                                    this.dialogClose(this.mfaresponse);
                                }
                            }
                            else
                            {
                                if(result.data.userStatus == null){
                                    this.submitted = false;
                                    this.sendNotification('Please enter valid code', 'error');
                                    this.spinner.hide();
                                }
                                else if(result.data.userStatus == ResponseMessage.INVALID_CODE)
                                {
                                    this.errormessage = ResponseMessage.INVALID_CODE;
                                    this.submitted = false;
                                    this.sendNotification('Please enter valid code', 'error');
                                    this.spinner.hide();
                                    
                                }
                                else if(result.data.userStatus == ResponseMessage.SESSION_EXPIRED)
                                {
                                    this.errormessage = ResponseMessage.SESSION_EXPIRED;
                                    this.submitted = false;
                                    this.sendNotification(this.errormessage, 'error');
                                    this.spinner.hide();
                                    
                                }
                                else
                                {
                                    this.errormessage = result.data.userStatus;
                                    this.submitted = false;
                                    this.sendNotification(this.errormessage, 'error');
                                    this.spinner.hide();
                                    
                                }
    
                            }
                       }
                    },
                    err => {
                        console.log('error occured while authenticating OTP', err);
                        this.errormessage = 'error occured while authenticating OTP';
                        this.submitted = false;
                        this.sendNotification(this.errormessage, 'error');
                        this.spinner.hide();
                    }
                );
            }
            else{
                console.log("MFA OTP");
                this.backendService.mfaverification(data).subscribe(
                    res => {
                        const result = this.backendService.getJSONResponse(res);
                        if(result!= null)
                        {
                            if(result.data.isSuccess == true)
                            {
                                if(result.data.userStatus == ResponseMessage.authenticated)
                                {
                                    this.mfaresponse = JSON.parse(JSON.stringify(result.data));
                                    // console.log("mfaresponse");
                                    // console.log(this.mfaresponse);
                                    this.submitted = false;
                                    this.dialogClose(this.mfaresponse);
                                }
                            }
                            else
                            {
                                if(result.data.userStatus == null){
                                    this.submitted = false;
                                    this.sendNotification('Please enter valid code', 'error');
                                    this.spinner.hide();
                                }
                                else if(result.data.userStatus == ResponseMessage.INVALID_CODE)
                                {
                                    this.errormessage = ResponseMessage.INVALID_CODE;
                                    this.submitted = false;
                                    this.sendNotification('Please enter valid code', 'error');
                                    this.spinner.hide();
                                    
                                }
                                else if(result.data.userStatus == ResponseMessage.SESSION_EXPIRED)
                                {
                                    this.errormessage = ResponseMessage.SESSION_EXPIRED;
                                    this.submitted = false;
                                    this.sendNotification(this.errormessage, 'error');
                                    this.spinner.hide();
                                    
                                }
                                else
                                {
                                    this.errormessage = result.data.userStatus;
                                    this.submitted = false;
                                    this.sendNotification(this.errormessage, 'error');
                                    this.spinner.hide();
                                    
                                }
    
                            }
                       }
                    },
                    err => {
                        console.log('error occured while authenticating OTP', err);
                        this.errormessage = 'error occured while authenticating OTP';
                        this.submitted = false;
                        this.sendNotification(this.errormessage, 'error');
                        this.spinner.hide();
                    }
                );
            }
            
        }
    }

}


// custom validator to check that two fields match
export function MustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
        const control = formGroup.controls[controlName];
        const matchingControl = formGroup.controls[matchingControlName];

        if (matchingControl.errors && !matchingControl.errors.mustMatch) {
            // return if another validator has already found an error on the matchingControl
            return;
        }

        // set error on matchingControl if validation fails
        if (control.value !== matchingControl.value) {
            matchingControl.setErrors({ mustMatch: true });
        } else {
            matchingControl.setErrors(null);
        }
    }
}
