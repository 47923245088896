// import { ViewChild } from "@angular/core";
import axios from "axios";
// import { jqxNotificationComponent } from "jqwidgets-ng/jqxnotification";
import { NgxSpinnerService } from "ngx-spinner";
import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpProgressEvent } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { error } from "console";
import { DatePipe } from "@angular/common";

@Injectable({
  providedIn: 'root'
})

export class FileAccessService {
   constructor(private datePipe: DatePipe,private spinner: NgxSpinnerService) { }
    async downloadWithProgress(url: string, fileName: any, IsProgress) {
    return new Observable(observer => {
      axios({
        headers: {
          'Access-Control-Allow-Origin': '*'
        },
        url: url,
        method: 'GET',
        responseType: 'blob',
        // onDownloadProgress: this.calcProgress.bind(this, url, IsProgress)// important
        onDownloadProgress: (progressEvent) => {
          const percentLoaded = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
          observer.next({type: 'progress', value: percentLoaded});
        },
        onUploadProgress: (progressEvent) => {
          const percentLoaded = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
          observer.next({type: 'progress', value: percentLoaded});
        }
      })
      .then(response => {
        observer.next({type: 'response', value: response});
        const url = window.URL.createObjectURL(new Blob([response.data]));
        //this.progress=0
        //console.log(event.value.data.size);
        //this.filesize = event.value.data.size;
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        observer.complete();
      })
      .catch(error => {
        observer.error(error);
      });
    });
  }
  async uploadWithProgress(attachmenturl, filedetails) {
    return new Observable(observer => {
      axios.put(attachmenturl, filedetails, {
        onUploadProgress: (progressEvent) => {
          const percentLoaded = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
          observer.next({type: 'progress', value: percentLoaded});
        }
      })
      .then(response => {
        observer.next({type: 'response', value: response});
        observer.complete();
      })
      .catch(error => {
        observer.error(error);
      });
    });
  }
  async excelFileDownload(ExportUrl,FileName) {
    await axios({
      headers: {
        'Access-Control-Allow-Origin': '*'
      },
      url: ExportUrl,
      method: 'GET',
      responseType: 'blob', // important
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        var date = new Date();
        var curdata = FileName + this.datePipe.transform(date, "yyyyMMddHHmmss") + ".csv";
        link.setAttribute('download', curdata);
        document.body.appendChild(link);
        link.click();
        this.spinner.hide();
      })
      .catch(err => console.log(err));
  }


  ReleaseNotesFileUpload(file: File, presignedUrl: string): Promise<any> {
    const formData = new FormData();
    formData.append('file', file);
  
    return axios.put(presignedUrl, file, {
      headers: {
        'Content-Type': file.type,  // Ensure the correct content type is set
      }
    })
    .then(response => {
      console.log('File successfully uploaded:', response);
    })
    .catch(error => {
      console.error('Error during file upload:', error);
    });
  }
  ReleaseNotesFileDownload(presignedUrl: string): Promise<any> {
    return axios.get(presignedUrl, {
      responseType: 'blob', // Indicate that we expect a binary file
    })
    .then(response => {
      // Extract the original file name from the presigned URL and decode it
      const urlParts = presignedUrl.split('?')[0]; // Strip off the query parameters
      let fileName = urlParts.substring(urlParts.lastIndexOf('/') + 1); // Get the file name after the last '/'
      // Decode the file name (e.g., replace '%20' with ' ')
      fileName = decodeURIComponent(fileName); 
      const blob = new Blob([response.data], { type: response.headers['content-type'] });
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = downloadUrl;
      // Use the decoded file name for the download
      link.download = fileName; 
      link.click();
      // Clean up the URL object after the download
      window.URL.revokeObjectURL(downloadUrl);
      console.log('File downloaded successfully:', fileName);
      return response;
    })
    .catch(error => {
      console.error('Error downloading file:', error);
      throw error;
    });
  }
  
  
  
  

 }
