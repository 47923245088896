import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OrderStatus } from 'src/app/models/apiRequestModels';
import { BackendService } from 'src/app/services/backend.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.css']
})
export class OrderDetailsComponent implements OnInit {

  constructor(private router: Router,
    private spinner: NgxSpinnerService,
    private backendService: BackendService) { }
  orderResponse: any = [];
  async ngOnInit() {
    await this.getOrderDetails();
  }
  goToOrderList() {
    const route = '/admin/courtorders';
    this.router.navigate([route]); // Correct way to navigate
  }
  async getOrderDetails() {
    console.log("Order Details getOrderDetails starts...");
    var orderId = this.backendService.getOrderId();
    this.spinner.show();
    this.backendService.getOrderDetailsById(orderId).subscribe(
      response => {
        this.orderResponse = this.backendService.getJSONResponse(response).data;
        this.spinner.hide();
        return this.orderResponse;
      },
      (error) => {
        console.error('Error:', error);
        this.spinner.hide();
        return null;
      })
  }
  ngOnDestroy() {
    localStorage.removeItem('orderId');
  }

}
