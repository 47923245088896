<!-- wraper-login-main -->
<div class="wraper-login-main position-relative overflow-hidden">
    <div class="container-fluid px-0">
        <!-- login-main -->
        <div class="login-main d-flex">
            <!-- login-main-form -->
            <div class="login-main-form d-flex flex-wrap justify-content-center position-relative z-index-2">
                <!-- login-main-form-curve -->
                <div class="login-main-form-curve position-absolute">
                    <img src="../../assets/myviq/assets/images/login/Form-Right-Curve.png" alt="Form-Right-Curve">
                </div>
                <!-- login-main-form-curve -->
                <!-- login-main-form-holder -->
                <div class="login-main-form-holder w-100 h-100 d-flex flex-wrap justified-items-center">
                    <div class="align-self-center w-100">
                        <!-- login-main-form-heading -->
                        <div class="login-main-form-heading section-heading text-center">
                            <div class="brand-logo">
                                <img src="./assets/img/{{brandname}}/VIQ_Logo.png" alt="VIQ">
                            </div>
                            <h1 class="title">Welcome Back!</h1>
                            <h2 class="subtitle">Login to your account</h2>
                        </div>
                        <!-- login-main-form-heading -->
                        <!-- login-main-form-section -->
                        <div class="login-main-form-section contact-form mx-auto">
                            <form [formGroup]="form">
                                <!-- form-row -->
                                <div class="form-row d-block mx-0">
                                    <mat-form-field>
                                        <mat-label>User Name <span>*</span></mat-label>
                                        <input id="uname" matInput type="email" (keydown.enter)="getdomain()"
                                            formControlName='userName' autofocus>
                                    </mat-form-field>
                                </div>
                                <div class="form-row d-block mx-0 mb-0">
                                    <button [disabled]='!isloginbtn' class="login-button w-100" mat-flat-button
                                        color="primary" (click)="getdomain()">Log In
                                    </button>
                                </div>
                                <!-- form-row -->
                                <div class="form-row d-block mt-5 mx-0 text-center">
                                    <!-- <p class="login-trouble">Having trouble? <a [routerLink]="['/forgot-password']" [queryParams]="{params: 'admin'}">Contact Admin</a></p> -->
                                    <!-- <p class="login-help">Don’t have an account yet? <a routerLink="/sign-up">Sign up</a></p> -->
                                    <p> V{{ versionNumber }}, [{{ buildVersion }}]</p>
                                </div>
                            </form>
                        </div>
                        <!-- login-main-form-section -->
                    </div>
                    <div class="align-self-end w-100 mt-5">
                        <!-- login-main-form-footer -->
                        <div class="login-main-form-footer text-center">
                            <!-- copyright-note -->
                            <ul class="copyright-note d-xl-none d-lg-none d-md-block d-sm-block d-block">&copy; VIQ
                                Solutions Inc.
                                {{today | date: 'yyyy'}}. All Rights Reserved.</ul>
                            <!-- copyright-note -->
                        </div>
                        <!-- login-main-form-footer -->
                    </div>
                </div>
                <!-- login-main-form-holder -->
            </div>
            <!-- login-main-form -->
            <!-- login-main-poster -->
            <div
                class="login-main-poster flex-fill d-xl-flex d-lg-flex d-md-none d-sm-none d-none flex-wrap justify-content-center position-relative z-index-1">
                <div class="align-self-center w-100 py-5">
                    <!-- login-main-poster-heading -->
                    <div class="login-main-poster-heading section-heading text-center">
                        <div class="poster-image mx-auto mb-5">
                            <!-- ../../assets/myviq/assets/images/login/Login-Poster.png -->
                            <img src="../../assets/myviq/assets/images/login/Reset-Poster.png" alt="Forget-Poster">
                        </div>
                        <h3 class="title mx-auto">Securely speed the capture, creation, and management of large volumes
                            of
                            information</h3>
                    </div>
                    <!-- login-main-poster-heading -->
                </div>
                <!-- login-main-poster-footer -->
                <div class="login-main-poster-footer position-absolute w-100 text-center">
                    <!-- copyright-note -->
                    <ul class="copyright-note">&copy; VIQ Solutions Inc. {{today | date: 'yyyy'}}. All Rights Reserved.
                    </ul>
                    <!-- copyright-note -->
                </div>
                <!-- login-main-poster-footer -->
            </div>
            <!-- login-main-poster -->
        </div>
        <!-- login-main -->
    </div>
</div>
<!-- wraper-login-main -->

<jqxNotification #msgNotification [position]="'top-right'" [opacity]="1.0" [autoOpen]="false" [autoClose]="true"
    [animationOpenDelay]="800" [autoCloseDelay]="5000" template.success>
</jqxNotification>
<jqxLoader #jqxLoader [imagePosition]="'top'" [width]="100" [height]="60">
</jqxLoader>