import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { Subscription } from 'rxjs';
import { jqxNotificationComponent } from 'jqwidgets-ng/jqxnotification';
import { BackendService } from './services/backend.service';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import * as JSZip from 'jszip';
import { NgxSpinnerService } from "ngx-spinner";
import { DatePipe, DOCUMENT } from '@angular/common';
import { Observable, of } from 'rxjs';
import { catchError, map, filter } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from "src/environments/environment";
import { NotificationService } from './services/notification.service';

@Component({
    selector: 'app-my-app',
    templateUrl: './app.component.html'
})

export class AppComponent implements OnInit {
  isBannerCloseEnable:boolean=true;
  isBannarEnable:boolean=false;
  isBannerClose:boolean=false;
  // closeBanner:boolean=true;
  bannerMessage = "";
  // bannerLink = '/release-notes';
  // bannerLinkText = 'Read the release notes';
  bannerType = 'warning'; // Can be 'info', 'warning', 'success', 'error'
  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null;
  title = 'angular-idle-timeout';
  userLoggedIn: string;
  private _router: Subscription;
  private currentURL;
  MaintenanceEnddate: any;
  @ViewChild('msgNotification', { static: false }) msgNotification: jqxNotificationComponent;

  constructor( private idle: Idle, private keepalive: Keepalive, private httpClient: HttpClient,private notificationService: NotificationService,
    private router: Router , private backendService :BackendService, private spinner: NgxSpinnerService, @Inject(DOCUMENT) private document: Document,
    private datePipe: DatePipe) {
    this.getbrandfolder();
    const zip = new JSZip();
    // sets an idle timeout of 5 seconds, for testing purposes.
    idle.setIdle(1);
    // sets a timeout period of 5 seconds. after 10 seconds of inactivity, the user will be considered timed out.
    idle.setTimeout(1800);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
    idle.onIdleEnd.subscribe(() => { 
      this.idleState = 'No longer idle.'
      //console.log(this.idleState);
      this.reset();
      //   this.closeBanner = ((localStorage.getItem('closeBanner')=='true')) ? true :false;
      //   // if(!this.closeBanner){
      //   const todayDate = new Date(this.datePipe.transform(new Date(), 'yyyy-MM-ddTHH:mm:ss')).toISOString(); 
      //   this.isBannarEnable =  (todayDate < localStorage.getItem('MaintenanceEndTime') && ((localStorage.getItem('IsEnable') == 'true' )) ? true : false);
      // // }
      // this.isBannerCloseEnable = ( this.isBannarEnable && this.router.url !== '/login' && (localStorage.getItem('IsEnable') == 'true' ) ) ?  true : false;
    });
    
    idle.onTimeout.subscribe(() => {
     // this.childModal.hide();
      this.idleState = 'Timed out!';
      this.timedOut = true;
      this.logout();
      console.log(this.idleState);
      this.backendService.setUserLoggedIn(false);
     // this.router.navigate(['/']);
    });
    
    idle.onIdleStart.subscribe(() => {
        this.idleState = 'You\'ve gone idle!'
       // console.log("idle state",this.idleState);
        const currentDateTime = new Date();
        //console.log("onidlestart",currentDateTime.toString());

      //  this.childModal.show();
    });
    
    idle.onTimeoutWarning.subscribe((countdown) => {
      this.idleState = 'You will time out in ' + countdown + ' seconds! and Current Date time '+ new Date().toString();
     // console.log(this.idleState);
    });

    // sets the ping interval to 15 seconds
    keepalive.interval(15); 

    keepalive.onPing.subscribe(() => this.lastPing = new Date());
    this.reset();

    var islogedin = localStorage.getItem('isloggedin');
   
    this.backendService.getUserLoggedIn().subscribe(userLoggedIn => {
      if (userLoggedIn) {
        idle.watch()
        this.timedOut = false;
      } else {
        idle.stop();
      }
    })
  }
  async getbrandfolder()
  {
// console.log("currentURL: ");
      // console.log(this.document.location.href);

      this.currentURL = this.document.location.href;
      // console.log("currentURL: "+ new URL(this.currentURL).origin);
      //console.log(new URL(this.currentURL));
      let currentpage = new URL(this.currentURL).pathname;
      let pathname = new URL(this.currentURL).hostname;
      let brand ;
      let brandname: string;
      //console.log("pathname: "+ pathname);
      brand = pathname.split("-"); // a list
      let lstbaseUrls = environment.baseUrls.split(",");
      // if(pathname == environment.baseURL || pathname.includes("localhost"))
      // {
      //   brandname = "";
      // }
      // else{
      //   brand = pathname.split("-"); // a list
      //   if(!Boolean(brand[0]))
      //     {
      //       brandname = "";
      //     }
      //     else{
      //       brandname = brand[0]
      //     }
      // }


      // if(environment.baseURL.includes(brand[0]))
      // {
      //   brandname = "";
      //   if(brand == "localhost" || !Boolean(brand))
      //   {
      //     brandname = "";
      //   }
      //   else{
      //     brandname = brand[0]
      //   }
      // }
      // else
      // {
      //   if(brand == "localhost" || !Boolean(brand))
      //     {
      //       brandname = "";
      //     }
      //     else{
      //       brandname = brand[0]
      //     }
      // }
      if(lstbaseUrls.includes(pathname))
      {
        brandname = "default";
      }
      else
      {
        if(brand[0] == "localhost" || !Boolean(brand[0]))
          {
            brandname = "default";
          }
          else{
            brandname = brand[0]
          }
      }
      // if(!Boolean(brand[1]))
      // {
      //   brandname = "";
      // }
      // else
      // {
      //   if(brand[0] == "localhost" || !Boolean(brand[0]))
      //   {
      //     brandname = "";
      //   }
      //   else{
      //     brandname = brand[0]
      //   }
       
      // }
      //console.log("Sessionbrand: "+brandname);
      localStorage.setItem('brand',  brandname);
      localStorage.setItem('requestUrl',  pathname);
      var curpage = currentpage.replace(/[^a-zA-Z0-9]/g, '');
      if(curpage == 'nclanding' )
      {
        localStorage.setItem('idpuser', 'true');
        localStorage.setItem('page',  currentpage.replace(/[^a-zA-Z0-9]/g, ''));
      }
      else if(curpage == 'login' )
      {
        localStorage.setItem('idpuser', 'false');
        localStorage.setItem('page',  currentpage.replace(/[^a-zA-Z0-9]/g, ''));
      }
      
      // let isexist:boolean;
      // let brandfolder;
      // if(brand)
      // {
      //   const folderPaths = `./assets/img/${brand.toLocaleLowerCase()}`;
      //   const filepath =  folderPaths+'/login_bg.png';
      //   console.log("filepath: "+filepath);
      // 
      //   // await this.httpClient.get(filepath).subscribe((data) => {
      //   //   console.log("brand: "+brand);
      //   //   brandfolder = brand;
      //   //   localStorage.setItem('brand',  brandfolder);
      //   //   // HANDLE file found
      //   // }, (err) => {
      //   //    console.log("err: "+err);
           
      //   //   // HANDLE file not found
      //   //   if (err.status === 404) {
      //   //     brandfolder = "";
      //   //     localStorage.setItem('brand',  brandfolder);
      //   //   }
      //   // });


      //   //  this.getFolder(brand).subscribe(data => {
      //   //   console.log("data: ");
      //   //   console.log(data);
      //   //   isexist = data});
      //   //   if(!isexist)
      //   //   {
      //   //     brand = "";
      //   //   }
      //   }
  }

  getFolder(subFolder: string): Observable<boolean> {
    const folderPath = `./assets/img/${subFolder.toLocaleLowerCase()}`;
    return this.httpClient
      .get(`${folderPath}/login_bg.png`, { observe: 'response', responseType: 'blob' })
      .pipe(
        map(response => {
          return true;
        }),
        catchError(error => {
          return of(false);
        })
      );
  }
  reset() {
    this.idle.watch();
    //xthis.idleState = 'Started.';
    this.timedOut = false;
  }

  hideChildModal(): void {
   // this.childModal.hide();
  }

  stay() {
    //this.childModal.hide();
    this.reset();
  }

  logout() {
    console.log("logout")
    //this.childModal.hide();
    this.backendService.setUserLoggedIn(false);
    this.router.navigate(['/login']);
  }
    ngOnInit() {
      // this.closeBanner =((localStorage.getItem('IsEnable') == 'true' )) ? false:true;
      //this.isBannerCloseEnable = true
      // this.notificationService.getNotification().subscribe(notification => {
      //   if (notification) {
      //     this.bannerMessage = "hello;"//notification.message;
      //     this.bannerLink = notification.link;
      //     this.bannerLinkText = notification.linkText || 'Learn More';
      //     this.bannerType = notification.type || 'info';
      //   }
      // });
    // console.log("appcomponent starts...", localStorage.getItem('refreshed'));
    //   if (localStorage.getItem('refreshed') === null) {
    //     localStorage['refreshed'] = true;
    //     window.location.reload(true);
    //     console.log("refresh is null...")
    //   } else {
    //     localStorage.removeItem('refreshed');
    //     console.log("refresh is not null...")
    //   }
    // var iSuserloggedIN = localStorage.getItem('userloggedIN')
    // if(iSuserloggedIN == "false")
    // {
    //     this.backendService.setUserLoggedIn(false);
    // }else
    // {
    //   this.router.navigateByUrl('/admin'); 
    // }
    this.router.events
    .pipe(filter(event => event instanceof NavigationEnd))
    .subscribe((event: NavigationEnd) => {
      var startTime =this.datePipe.transform(new Date(localStorage.getItem('MaintenanceStartTime')) + 'z', 'medium')
      var endTime =this.datePipe.transform(new Date(localStorage.getItem('MaintenanceEndTime')) + 'z', 'medium')
      var banMessage=  "Scheduled Maintenace from " + startTime + " to "+ endTime + "."+ localStorage.getItem('MaintenanceMessage');
      const cleanUrl = event.url.split('?')[0]; // Get clean URL
      this.isBannarEnable=false;
      // if(!this.closeBanner){
        const todayDate = new Date(this.datePipe.transform(new Date(), 'yyyy-MM-ddTHH:mm:ss')).toISOString();
        this.isBannarEnable =  (todayDate < localStorage.getItem('MaintenanceEndTime')  && ((localStorage.getItem('IsEnable') == 'true' ) ? true : false));
        //this.isBannerClose =(todayDate < localStorage.getItem('MaintenanceEndTime') && ((localStorage.getItem('IsEnable') == 'true' ) ? true : false))
        this.bannerMessage = banMessage;
        //console.log("Banner Enabled:", this.isBannarEnable, "Banner Message:", this.bannerMessage);
      // }
      this.isBannerCloseEnable = ( this.isBannarEnable && this.router.url !== '/login' && (localStorage.getItem('IsEnable') == 'true')) ?  true : false;
    });
      this._router = this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
        const body = document.getElementsByTagName('body')[0];
        const modalBackdrop = document.getElementsByClassName('modal-backdrop')[0];
        if (body.classList.contains('modal-open')) {
          body.classList.remove('modal-open');
          modalBackdrop.remove();
        }
      });
      //this.resettimer();
      console.log("reset got hit");   
      this.reset();
    }
    // bannerClose(){
    //   this.isBannerCloseEnable=false;
    //   this.isBannarEnable= false;
    //   // this.closeBanner=true;
    //   localStorage.setItem('closeBanner','true');
    // }
    
    ngOnDestroy(): void {
     // clearTimeout(this.timeout);
     console.log("ngOnDestroy method called");
     this.idle.stop();
    this.keepalive.stop();
    }

    ngAfterViewInit(){
      this.backendService.notificationRef = this.msgNotification;
    }
}
