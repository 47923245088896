<div class="half-page card-one scrollable-container">
  <div class="row orderStatus-Header">
    <div class="col-md-6 top-margin2">
      <label class="header-info">Status : </label>
      <label class="header-content"> {{ currentStatus }}</label>
    </div>
    <div class="col-md-6 text-end top-margin2">
      <label class="header-info">Last Status Update : </label>
      <label class="header-content">{{ lastModifiedDate }}</label>
    </div>
  </div>
  <div style="margin: 2% 1% 0% 1%;">
    <mat-accordion multi>
      <div *ngFor="let order of orderStatuses">
          <mat-expansion-panel [disabled]="!(order.isCompleted || order.isEditable)">

            <!-- Panel Header -->
            <mat-expansion-panel-header>
              <mat-card class="order-card" [ngClass]="{ 'completed': order.isCompleted }">
                <mat-card-title>
                  <mat-icon class="status-icon"
                    [ngClass]="{ 'completed-icon': !order.isCompleted, 'completed-icon': order.isCompleted }">
                    {{ order.isCompleted ? 'check_circle' : 'sync' }}
                  </mat-icon>
                  <span class="status-tittle">{{ order.status }}</span>
                </mat-card-title>
              </mat-card>
            </mat-expansion-panel-header>

            <!-- Panel Body -->
            <mat-card-content class="status-content">
              <p class="label">Notes:</p>
              <p *ngIf="order.isCompleted" class="note-text">{{ order.notes }}</p>
              <jqxTextArea *ngIf="!order.isCompleted" aria-hidden="true" #notesComponent [width]="'99%'" [height]="80"
                maxLength=1000 style="border-radius: 12px;">
              </jqxTextArea>
              <div class="date-status-row">
                <div *ngIf="order.isCompleted; else datePickerTemplate">
                  <p class="label">{{ order.createdTime | date: 'dd-MMM-yyyy' }}</p>
                </div>
                <ng-template #datePickerTemplate>
                  <p class="label">{{ today  | date: 'dd-MMM-yyyy' }}</p>
                  <!-- <jqxDateTimeInput #dateTimeInput id="myDateTimeInput" [(ngModel)]="defaultSelectedDate" 
                  [width]="200" [height]="28"
                    [formatString]="'dd-MMM-yyyy'" theme="material"
                    (onValueChanged)="onDateValueChanged($event)"
                    style="border-color: #007bff; border-radius: 20px; padding: 5px 10px; font-size: 14px; background-color: #f0f8ff; color: #007bff; margin-left: 1%;">
                  </jqxDateTimeInput> -->
                </ng-template>
                <span *ngIf="order.isCompleted" class="status">
                  <span class="label">Status:</span>
                  <span [ngClass]="{ 'completed-text': order.isCompleted }">
                    {{ order.isCompleted ? 'Completed' : 'In Progress' }}
                  </span>
                </span>
                <div *ngIf="!order.isCompleted">
                  <button class="update-button" (click)="updateOrder(order)">Update</button>
                </div>
              </div>
            </mat-card-content>
          </mat-expansion-panel>
      </div>
    </mat-accordion>
  </div>
</div>
<jqxNotification #msgNotification [position]="'top-right'" [opacity]="1.0" [autoOpen]="false"
    [autoClose]="true" [animationOpenDelay]="800" [autoCloseDelay]="5000" template.success>
</jqxNotification>