<div class="container">
  <div class="color-selector-container">
    <div class="color-selector" [ngStyle]="{backgroundColor:selctedColor}">
    </div>
    <!--DropDown Button -->
    <div class="dropdown-icon" (click)="showColorPallate()">
      <i class="material-symbols-outlined color-picker-icon">expand_more</i>
    </div>
    <div>
    </div>
  </div>
  <div class="color-picker-container" *ngIf="showColorPallateContainer">
    <div (click)="onSelect(codes)" class="color-picker" *ngFor="let codes of colorCodes"
      [ngStyle]="{backgroundColor:codes.colorCode}">
    </div>
  </div>
</div>