<div class="main-content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class='card'></div>
                <div class="card-header card-header-rose card-header-icon"
                    style="margin-bottom: -20px;margin-top: 5px;padding: revert;">
                    <div class='row' style="margin-left: 1px;height: 35px;">
                        <p style="margin-left: 10px;margin-right: 10px;margin-top: 3px;"><b>Order Created </b></p>
                        <span class='d-inline-block' style="float: left; margin-left: 0px;">
                            <div style="cursor: pointer;">
                                <jqxDropDownList aria-hidden="true" #myDateDropDownList [theme]="'material'"
                                    (onSelect)="onDateOptionChange($event)" style="margin-right: 10px;" height.auto
                                    [autoDropDownHeight]="true" [width]="170" [height]="30" [source]="dateOptions"
                                    [selectedIndex]="selectedIndex">
                                </jqxDropDownList>
                            </div>
                        </span>
                        <div class="single-line" style="display: none; " *ngIf='customSearch'>
                            <span class='d-inline-block' style="float: left;">
                                <jqxDateTimeInput aria-hidden="true" (onValueChanged)="onStartDateValueChanged($event)"
                                    (onOpen)="startDateEventOpen($event)" [readonly]=true [min]="startmin"
                                    [max]="startmax" [value]="DisplayCustomStartDate" [width]="120" [height]="30"
                                    style="border-color: white;" [formatString]="'dd-MMM-yyyy'" theme="material">
                                    >
                                </jqxDateTimeInput>
                            </span>
                            <span class='d-inline-block' style="float: left; margin-left: 2px;">
                                <jqxDateTimeInput aria-hidden="true" (onValueChanged)="onEndDateValueChanged($event)"
                                    [readonly]=true [min]="endmin" [value]="DisplayCustomEnddate" [max]="endmax"
                                    [width]="120" [height]="30" style="border-color: white;"
                                    [formatString]="'dd-MMM-yyyy'" theme="material">
                                </jqxDateTimeInput>
                            </span>
                            <img src="../../../assets/img/icon/search-jobs.png" alt="Search Jobs"
                                style="margin-left: 5px; width: 25px;cursor: pointer;margin-top: 3px;"
                                (click)='searchData()' textPosition="center">
                        </div>
                    </div>
                </div>
                <div class="table-responsive mt-4">
                    <jqxGrid aria-hidden="true" #myGrid (onRowclick)="Rowclick($event)"
                        (onPagechanged)="onPageChanged($event)" (onFilter)="orderGridFilter($event)"
                        [source]="sQLdataAdapter" [columns]="columns" [enabletooltips]="true" [virtualmode]="true"
                        [pagesize]="1000" [pagesizeoptions]="['100', '200', '1000']" [pageable]="true"
                        [rendergridrows]="rendergridrows" [theme]="'material'" [width]="'99.99%'" [height]="height"
                        [sortable]="true" [filterable]="true" [showfilterrow]="true" [showtoolbar]="true"
                        [toolbarheight]="40" [renderstatusbar]="createButtonsContainers"
                        [selectionmode]="'multiplerowsadvanced'" [columnsresize]="true" [columnsreorder]="true"
                        [pagerrenderer]="pagerrenderer" [autoshowloadelement]="false" [enablebrowserselection]="true" (onRowdoubleclick)="onRowdoubleclick($event)"
                        [keyboardnavigation]="false" [scrollbarsize]="6">
                    </jqxGrid>
                </div>
            </div>
        </div>
    </div>
</div>
<jqxNotification #msgNotification [position]="'top-right'" [opacity]="1.0" [autoOpen]="false" [autoClose]="true"
    [animationOpenDelay]="800" [autoCloseDelay]="5000" template.success>
</jqxNotification>