import { BackendService } from './backend.service';
import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRoute, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { JwtHelperService } from "@auth0/angular-jwt";

@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate{

  helper = new JwtHelperService();

  constructor(
    private backendService: BackendService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):boolean {

     var iSuserloggedIN = localStorage.getItem('userloggedIN')
     console.log("iSuserloggedIN: ",iSuserloggedIN);
     if(iSuserloggedIN == "true")
     {
        this.router.navigateByUrl('/admin'); 
     }
     return true;
    // if(iSuserloggedIN == "false")
    // {
    //     // this.backendService.setUserLoggedIn(false);
    //     return false;
    // }else
    // {
    //   this.router.navigateByUrl('/admin'); 
    //   return true;
    // }
  }
}