<div class="half-page card-two scrollable-container">
  <div class="row orderInfo-Header">
    <div class="col-md-6 top-margin2">
      <label class="header-info">Case Number : </label>
      <label class="header-content">{{ caseNumber }}</label>
    </div>
    <div class="col-md-6 text-end top-margin2">
      <label class="header-info">Not Paid</label>
    </div>
  </div>

  <!-- app.component.html -->

  <div style="margin: 2% 1% 0% 1%;">
    <mat-card class="mat-card">
      <!-- Material Tabs inside the mat-card -->
      <mat-tab-group class="equal-tabs">
        <mat-tab label="Order Information">
          <div class="tab-content">
            <mat-card class="orderForm-card bg-color">
              <mat-card-header>
                <mat-card-title class="card-title">Order Form</mat-card-title>
              </mat-card-header>

              <mat-card-content class="scrollable-content">
                <div *ngFor="let field of orderFormData">
                  <div class="row col-md-12 reduce-lines-space">
                    <hr>
                    <!-- Label for the Key -->
                    <div class="col-md-5" style="text-align: left;">
                      <label class="text-form-label">{{ field.displayLabel }}</label>
                    </div>
                    <!-- Separator Colon -->
                    <div class="col-md-1" style="text-align: left;">:</div>
                    <!-- Value for the Field -->
                    <div class="col-md-6" style="text-align: left;">
                      <label class="text-form-value">{{ field.value }}</label>
                    </div>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>
            <mat-card class="requestorInfo-card bg-color">
              <mat-card-header>
                <mat-card-title class="card-title">Order Details</mat-card-title>
              </mat-card-header>

              <mat-card-content class="scrollable-content">
                <div *ngFor="let field of orderInfo | keyvalue">
                    <div class="row col-md-12 reduce-lines-space">
                      <hr>
                      <!-- Label for the Key -->
                      <div class="col-md-5" style="text-align: left;">
                        <label class="text-form-label">{{ splitCamelCase(field.key) }}</label>
                      </div>
                      <!-- Separator Colon -->
                      <div class="col-md-1" style="text-align: left;">:</div>
                      <!-- Value for the Field -->
                      <div class="col-md-6" style="text-align: left;">
                        <label class="text-form-value">{{ field.value }}</label>
                      </div>
                    </div>
                </div>
              </mat-card-content>
            </mat-card>
            <mat-card class="requestorInfo-card bg-color">
              <mat-card-header>
                <mat-card-title class="card-title">Requestor Information</mat-card-title>
              </mat-card-header>

              <mat-card-content class="scrollable-content">
                <div *ngFor="let field of requestorDetails | keyvalue">
                  <div class="row col-md-12 reduce-lines-space">
                    <hr>
                    <!-- Label for the Key -->
                    <div class="col-md-5" style="text-align: left;">
                      <label class="text-form-label formatted-text">{{ splitCamelCase(field.key) }}</label>
                    </div>
                    <!-- Separator Colon -->
                    <div class="col-md-1" style="text-align: left;">:</div>
                    <!-- Value for the Field -->
                    <div class="col-md-6" style="text-align: left;">
                      <label class="text-form-value">{{ field.value }}</label>
                    </div>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>

            <mat-card class="courtInfo-card bg-color">
              <mat-card-header>
                <mat-card-title class="card-title">Court Information</mat-card-title>
              </mat-card-header>

              <mat-card-content class="scrollable-content">
                <div *ngFor="let field of courtInfo | keyvalue">
                  <div class="row col-md-12 reduce-lines-space">
                    <hr>
                    <!-- Label for the Key -->
                    <div class="col-md-5" style="text-align: left;">
                      <label class="text-form-label">{{ splitCamelCase(field.key) }}</label>
                    </div>
                    <!-- Separator Colon -->
                    <div class="col-md-1" style="text-align: left;">:</div>
                    <!-- Value for the Field -->
                    <div class="col-md-6" style="text-align: left;">
                      <label class="text-form-value">{{ field.value }}</label>
                    </div>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>
          </div>
        </mat-tab>
        <!-- <mat-tab label="Transaction">
      <div class="tab-content">
        <p>Transaction details go here.</p>
      </div>
    </mat-tab>
    <mat-tab label="Payment">
      <div class="tab-content">
        <p>Payment details go here.</p>
      </div>
    </mat-tab> -->
      </mat-tab-group>
    </mat-card>
  </div>
</div>