export enum ResponseMessage
{
INCORRECT_USERNAME_PASSWORD = "INCORRECT_USERNAME_PASSWORD",
PasswordResetRequiredException="PasswordResetRequiredException",
USER_NOT_FOUND="USER_NOT_FOUND",
authenticated="authenticated",
UserNotConfirmedException="UserNotConfirmedException",
PASSWORD_UPDATED="PASSWORD_UPDATED",
SESSION_EXPIRED="SESSION_EXPIRED",
ERROR="ERROR",
ADMIN_UPDATE_ERROR="ADMIN_UPDATE_ERROR",
CODE_EXPIRED="CODE_EXPIRED",
INVALID_CODE="INVALID_CODE",
EMAIL_ADDRESS_NOT_CONFIRMED="EMAIL_ADDRESS_NOT_CONFIRMED",
VarificationCodeSent="VarificationCodeSent",
UNABLE_TO_UPDATED="UNABLE_TO_UPDATED",
OLDPASSWORD_MATCHED="OLDPASSWORD_MATCHED",
PASSWORDCHANGE_UNSUCCESSFUL="PASSWORDCHANGE_UNSUCCESSFUL",
NEW_PASSWORD_REQUIRED="NEW_PASSWORD_REQUIRED",
SMS_MFA="SMS_MFA",
PASSWORD_EXPIRED="PASSWORD_EXPIRED",
USER_IS_DISABLED="USER_IS_DISABLED",
LimitExceededException = "LimitExceededException",
InvalidPasswordException = "InvalidPasswordException",
TooManyRequestsException = "TooManyRequestsException",
InternalErrorException = "InternalErrorException",
InvalidParameterException = "InvalidParameterException"

};