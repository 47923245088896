<div class="container-fluid">
    <div class='row' style="margin-top : 4%">
        <div class='col-md-12'>
            <div class='row'>
                <div class="row col-md-12">
                    <div class="col-md-2">
                        <button mat-raised-button color="primary" (click)="goToOrderList()"
                            style="background-color: white;color: #187EB1 !important;">
                            ← Back to Order List
                        </button>
                    </div>
                    <div class="col-md-10">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class='row'>
        <div class='col-md-12 order-container'>
            <div class='col-md-6'><app-order-status [dataFromParent]="orderResponse" (notify)="getOrderDetails()"></app-order-status></div>
            <div class='col-md-6'><app-order-information [dataFromParent]="orderResponse"></app-order-information></div>
        </div>
    </div>
</div>