<div class="find-replace-dialog " appDraggable >
    <h2 style="text-align: center;">Find and Replace</h2>
    <form [formGroup]='findReplaceForm' style="margin-top: 1%;">
        <div style="margin: 1%;">
            <mat-form-field class="example-full-width">
                <mat-label>Find What:</mat-label>
                <input matInput formControlName="findText" type='text' maxlength=250 placeholder="Enter text to find">
            </mat-form-field>
        </div>
        <div class="row">
            <div class="col-md-3">
            <button id="findButton" class="btn btn-viq btn-viq-primary ml-2 "
                (click)="onFindText()">Find</button>
            </div>
            <div class="col-md-9">
                <mat-checkbox formControlName="isMatchCase">Match case
                </mat-checkbox>
            </div>
        </div>
        
        <div class="row" style="margin: 1%;">
            <mat-form-field class="example-full-width">
                <mat-label>Replace With:</mat-label>
                <input matInput formControlName="replaceText" type='text' maxlength=250 placeholder="Enter text to replace with">
            </mat-form-field>
        </div>
    
        <button id="replaceButton" class="btn btn-viq btn-viq-primary ml-2 "
            (click)="onReplace()">Replace</button>
        <button id="replaceAllButton" class="btn btn-viq btn-viq-primary ml-2 " (click)="onReplaceAll()">Replace
            All</button>
        <button id="closeButton" class="btn btn-viq btn-viq-primary ml-2 " (click)="onClose()">Close</button>
</form>
</div>
