<div class="main-content">
  <div class="container-fluid">
    <div class='row'>
      <div class='col-md-12'>
        <div class='card' style="height: 75vh;">

          <div class="card-header">
            <div class='row'>
              <!-- <div class="row col-md-12"> -->
              <div class="col-md-2">
                <button mat-raised-button color="primary" (click)="goToOrderList()"
                  style="background-color: white;color: #187EB1 !important;">
                  ← Back to Order List
                </button>
              </div>
            </div>
            <app-progress-bar [progress]="progress" (progressUpdated)="onProgressUpdate($event)"></app-progress-bar>
            <hr class="hr-css">
          </div>
          <div class="card-body">
            <div class='row'>
              <div class='col-md-5' style='margin-left:20px;'>
                <div class='row scroll hidehorizontalScroll'>
                  <div class="col-md-12" style="height: 56vh;overflow-y: scroll;">
                    <div>
                      <mat-form-field class="example-full-width">
                        <mat-label>Organizations </mat-label>
                        <input id='organization-values' tabindex="9" name='organization-values' type="text" matInput
                          required [formControl]="organizationControl" [matAutocomplete]="organizationValuesAuto"
                          (change)='organizationChange()'>
                        <mat-autocomplete id='organization-values-auto' #organizationValuesAuto="matAutocomplete"
                          (optionSelected)="organizationChangeEvent($event)" [displayWith]="displayFnForOrganization">
                          <mat-option *ngFor="let organization of organizationfilteredOptions | async"
                            [value]="organization">
                            {{organization.customerName}}
                          </mat-option>
                        </mat-autocomplete>
                        <mat-error *ngIf="organizationControl.hasError('required')">
                          Organization is <strong>required</strong>
                        </mat-error>
                      </mat-form-field>
                    </div>
                    <div>
                      <mat-form-field class="example-full-width">
                        <mat-label>Department </mat-label>
                        <input id='department-values' name='department-values' type="text" matInput required
                          [formControl]="departmentControl" [matAutocomplete]="departmentValuesAuto"
                          (change)='departmentChange()'>
                        <mat-autocomplete id='department-values-auto' #departmentValuesAuto="matAutocomplete"
                          (optionSelected)="departmentChangeEvent($event)" [displayWith]="displayFn">
                          <mat-option *ngFor="let dept of deptfilteredOptions | async" [value]="dept">
                            {{dept.name}}
                          </mat-option>
                        </mat-autocomplete>
                        <mat-error *ngIf="departmentControl.hasError('required')">
                          Department is <strong>required</strong>
                        </mat-error>
                      </mat-form-field>
                    </div>
                    <div>
                      <mat-form-field class="example-full-width">
                        <mat-label>Job Type</mat-label>
                        <input id='jobtype-values' type="text" matInput [formControl]="jobtypeControl" required
                          [matAutocomplete]="jobtypeValuesAuto" (change)='jobtypeChange()'>
                        <mat-autocomplete id='jobtype-values-auto' #jobtypeValuesAuto="matAutocomplete"
                          [displayWith]="displayFn" (optionSelected)="jobTypeChangeEvent($event.option.value)">
                          <mat-option *ngFor="let jobtype of jobTypefilteredOptions | async" [value]="jobtype">
                            {{jobtype.name}}
                          </mat-option>
                        </mat-autocomplete>
                        <mat-error *ngIf="jobtypeControl.hasError('required')">
                          Job Type is <strong>required</strong>
                        </mat-error>
                      </mat-form-field>
                    </div>
                    <div>
                      <mat-form-field class="example-full-width">
                        <mat-label>Form Type</mat-label>
                        <input id='formtype-values' type="text" matInput [formControl]="formtypeControl" required
                          [matAutocomplete]="formtypeValuesAuto" (change)='formtypeChange()'>
                        <mat-autocomplete id='formtype-values-auto' #formtypeValuesAuto="matAutocomplete"
                          [displayWith]="displayFnForFormTypes"
                          (optionSelected)="formTypeChangeEvent($event.option.value.id)">
                          <mat-option *ngFor="let formtype of formTypefilteredOptions | async" [value]="formtype">
                            {{formtype.formName}}
                          </mat-option>
                        </mat-autocomplete>
                        <mat-error *ngIf="formtypeControl.hasError('required')">
                          Form Type is <strong>required</strong>
                        </mat-error>
                      </mat-form-field>
                    </div>
                    <div>
                      <mat-form-field class="example-full-width">
                        <mat-label>Requestor</mat-label>
                        <input id='author-values' type="text" matInput [formControl]="authorControl" required
                          [matAutocomplete]="authorValuesAuto" (change)='authorChange()'>
                        <mat-autocomplete id='author-values-auto' #authorValuesAuto="matAutocomplete"
                          [displayWith]="displayFn" (optionSelected)="authorChangeEvent($event.option.value)">
                          <mat-option *ngFor="let author of authorfilteredOptions | async" [value]="author">
                            {{author.name}}
                          </mat-option>
                        </mat-autocomplete>
                        <mat-error *ngIf="authorControl.hasError('required')">
                          Requestor is <strong>required</strong>
                        </mat-error>
                      </mat-form-field>
                    </div>

                    <form [formGroup]='orderCreationForm'>
                      <div class="row" style="margin-top: 5px;">
                        <div class='col-md-6'>
                          <mat-form-field class="example-full-width">
                            <mat-label>Language</mat-label>
                            <input matInput tabindex="4" formControlName="language" type='text'>
                            <mat-error *ngIf="isValid('language', 'required')">
                              Language is <strong>required</strong>
                            </mat-error>
                          </mat-form-field>
                        </div>
                        <div class='col-md-6'>
                          <mat-form-field class="example-full-width">
                            <mat-label>Case Number</mat-label>
                            <input matInput tabindex="4" formControlName="caseNumber" type='text'>
                            <mat-error *ngIf="isValid('caseNumber', 'required')">
                              CaseNumber is <strong>required</strong>
                            </mat-error>
                          </mat-form-field>
                        </div>
                      </div>

                      <div>
                        <div class="row" style="margin-top: 5px;">
                          <div class='col-md-6'>
                            <div>
                              <label class="ml-1 font-gray-shade">Hearing Date</label>
                              <jqxDateTimeInput #myDateTimeInput (onValueChanged)="onValueChanged($event)"
                                aria-required="true" required [theme]="'material'" [width]="'95%'" aria-hidden="true"
                                [formatString]="'MMMM-dd-yyyy'">
                              </jqxDateTimeInput>
                            </div>
                          </div>

                          <div class='col-md-6'>
                            <div>
                              <mat-checkbox formControlName="ishighPriority">High Priority</mat-checkbox>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>

                  </div>
                </div>
              </div>
              <mat-divider [vertical]="true"></mat-divider>
              <div class='col-md-6' style='height: 56vh;overflow-y: scroll;margin-left:50px;'>
                <p class="header-text-2">Form details:</p>
                <hr>
                <div class='row scroll'>
                  <div class='col-md-12'>
                    <form [formGroup]="FormFieldsGroup" *ngIf="showFormFields">
                      <div class="form-row" *ngFor="let field of formfieldsList">
                        <!-- DROPDOWN -->
                        <mat-form-field *ngIf="field.fieldType == 'dropdown'" class="example-full-width">
                          <mat-label>{{ field.displayLabel }}</mat-label>
                          <mat-select [formControlName]="field.propertyName" [required]="field.mandatory">
                            <mat-option *ngFor="let opt of field.options" [value]="opt.Name">
                              {{ opt.Name }}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>

                        <!-- CHECKBOX (Moved outside) -->
                        <mat-checkbox *ngIf="field.fieldType == 'checkbox'" [formControlName]="field.propertyName"
                          [required]="field.mandatory">
                          {{ field.displayLabel }}
                        </mat-checkbox>

                        <!-- TEXTBOX -->
                        <mat-form-field *ngIf="field.fieldType == 'textbox'" class="example-full-width">
                          <mat-label>{{ field.displayLabel }}</mat-label>
                          <input matInput [formControlName]="field.propertyName" [required]="field.mandatory"
                            maxlength="128">
                        </mat-form-field>

                        <!-- DATEPICKER (Fixed structure) -->
                        <mat-form-field *ngIf="field.fieldType == 'datepicker'" class="example-full-width">
                          <mat-label>{{ field.displayLabel }}</mat-label>
                          <input matInput [matDatepicker]="picker" [formControlName]="field.propertyName"
                            [required]="field.mandatory">
                          <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                          <mat-datepicker #picker></mat-datepicker>
                        </mat-form-field>

                        <!-- ERROR MESSAGE (Works for all fields) -->
                        <mat-error *ngIf="isValidFormFieldsGroup(field.propertyName, 'required')">
                          {{ field.displayLabel }} is <strong>required</strong>
                        </mat-error>

                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class='row mt-2'>
          <div class="col-md-9">
            <button type="button" class="btn btn-outline-primary upload-btn-1 ml-2 mr-1" (click)="selectMediaFiles()">
              <img class="img-upload mr-1" src="../../../assets/img/icon/upload-btn.png" title="Select Media files"
                style="height: 95%;" />
              Select audio/video
              <span class="position-absolute top-0 start-100 translate-middle badge badge-success file-count-badge">{{
                getListOfAudioFiles()}}</span>
            </button>
            <button type="button" class="btn btn-outline-primary upload-btn-1 ml-1 mr-1" (click)="attachDocuments()">
              <img class="img-upload mr-1" src="../../../assets/img/icon/attachment-btn.png"
                title="Attach reference documents" style="height: 95%;" />
              Supporting Files
              <span class="position-absolute top-0 start-100 translate-middle badge badge-success file-count-badge">{{
                getListOfAttachmentFiles()}}</span>
            </button>
            <button type="button" class="btn btn-outline-primary upload-btn-1 ml-1 mr-1" [disabled]='!isshowEstimation'
              (click)="calculateEstimate()">
              <img class="img-upload mr-1" src="../../../assets/img/icon/cost_estimation.png" title="Estimate"
                style="height: 95%;" />
              Get Estimate
              <span *ngIf="isEstimate()"
                class="position-absolute top-0 start-100 translate-middle badge badge-success notes-badge">
                <i class="material-icons-outlined" style="font-size: 10px; font-weight: 500;">done</i>
              </span>
            </button>
          </div>
          <div class="col-md-3 btn-upload">
            <!-- <div class="col-md-3 btn-upload" style="margin-left: 75%;"> -->
            <button type="button" class="btn btn-viq btn-viq-primary pull-right" (click)="createOrder()">
              {{buttonText}}</button>
            <button type="button" class="btn btn-viq-secondary pull-right ml-1" (click)="clear()">
              Clear
            </button>
            <div>
            </div>
            <!-- </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<jqxNotification #msgNotification [position]="'top-right'" [opacity]="1.0" [autoOpen]="false" [autoClose]="true"
  [animationOpenDelay]="800" [autoCloseDelay]="5000" template.success>
</jqxNotification>